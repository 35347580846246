import { Injectable } from '@angular/core';
import { HttpService } from '../_services/http.service';
import * as configList from '../../assets/config/config';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class PasswordDataService {
  private passwordRules: any;
  constructor(private httpClient: HttpService, private logger: NGXLogger) { 
    
  }
  get passwordRulesData(): any {
    return this.passwordRules;
  }
  set passwordRulesData(pswdData:any){
    this.passwordRules = pswdData;
  }

  getDataFromJson() {
    this.httpClient.getJsonContent(configList.pwdconfigjson).subscribe(
      (data: any) => {
        this.passwordRulesData = data;
      },
      error => {
        this.logger.debug('Error while getting filters');
      }
    );
  }

}
