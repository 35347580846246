import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as configlist from '../../assets/config/config';
import { CONFIGUREPORTALREQUEST } from '../_models/ConfigurePortal/configureportalrequest';
import { HttpService } from '../_services/http.service';
import { PasswordDataService } from '../_services/password-data.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public configurePortalRequest = new CONFIGUREPORTALREQUEST();
  constructor(private router: Router, private httpService: HttpService, private sanitizer: DomSanitizer,
    private passwordData: PasswordDataService) { }

  /** Get login Role ID */
  getRoleId(): number {
    const roleid = sessionStorage.getItem('loginRoleId') ? sessionStorage.getItem('loginRoleId') : -1;
    return +roleid;
  }

  /** Get user Role ID */
  getUserRoleId(): number {
    const roleid = sessionStorage.getItem('userRoleId') ? sessionStorage.getItem('userRoleId') : -1;
    return +roleid;
  }

  /** Get user screen name */
  getScreenName(): string {
    const screenname = sessionStorage.getItem('loggedInUserName') ? sessionStorage.getItem('loggedInUserName') : (sessionStorage.getItem('loggedInUserId') ? sessionStorage.getItem('loggedInUserId'):null);
    return screenname;
  }
  /** Get user screen ID */
  getScreenId(): number {
    const screenId = sessionStorage.getItem('loginScreenId') ? sessionStorage.getItem('loginScreenId') : 1;
    return +screenId;
  }

  getConfigPortalReq(screenid, portletid) {
    this.configurePortalRequest = Object.assign({});
    this.configurePortalRequest.screenId = screenid;
    this.configurePortalRequest.updatedBy = this.getScreenName();
    this.configurePortalRequest.portletId = portletid;

    return this.configurePortalRequest;
  }

  /** Check for Object size/length */
  isEmptyObject(obj: any) {
    return (obj && (Object.keys(obj).length === 0));
  }

  /**
   * To get document Id inner text
   */
  getInnertext(id) {
    return document.getElementById(id).innerText;
  }

  getInnerHTML(id) {
    return document.getElementById(id).innerHTML;
  }

  /** Check user details and navigate to landing page */
 homeNavigation() {
    if (sessionStorage.getItem('loginRoleId') && sessionStorage.getItem('loginScreenId')) {
      	//this.httpService.getPrivilegesByRole(sessionStorage.getItem('userRoleId')).subscribe(privilegeData => {
        //sessionStorage.setItem('privilegeData', JSON.stringify(privilegeData.privilageDetailsList));
        //localStorage.setItem('privilegeData', JSON.stringify(privilegeData.privilageDetailsList));
        if (Number(sessionStorage.getItem('loginRoleId')) === configlist.pageConst.roleConduentInternal &&
            Number(sessionStorage.getItem('loginScreenId')) === configlist.pageConst.pageConduentInternal
            && sessionStorage.getItem('isTempPwd') !== configlist.pageConst.constYes
            && sessionStorage.getItem('loginUserStatus') === configlist.pageConst.active) {
            this.router.navigate(['/conduentInternal']);
        } else if (Number(sessionStorage.getItem('loginRoleId')) === configlist.pageConst.roleProvider &&
            Number(sessionStorage.getItem('loginScreenId')) === configlist.pageConst.pageProvider
            && sessionStorage.getItem('isTempPwd') !== configlist.pageConst.constYes
            && sessionStorage.getItem('loginUserStatus') === configlist.pageConst.active) {
            this.router.navigate(['/provider']);
        } else if (Number(sessionStorage.getItem('loginRoleId')) === configlist.pageConst.roleClient &&
            Number(sessionStorage.getItem('loginScreenId')) === configlist.pageConst.pageClient
            && sessionStorage.getItem('isTempPwd') !== configlist.pageConst.constYes
            && sessionStorage.getItem('loginUserStatus') === configlist.pageConst.active) {
            this.router.navigate(['/client']);
        } 
        // else if (Number(sessionStorage.getItem('loginRoleId')) === configlist.pageConst.roleAdmin &&
        //     Number(sessionStorage.getItem('loginScreenId')) === configlist.pageConst.screenAdmin
        //     && sessionStorage.getItem('isTempPwd') !== configlist.pageConst.constYes
        //     && sessionStorage.getItem('loginUserStatus') === configlist.pageConst.active) {
        //     this.router.navigate(['/admin']);
        // } 
        else if (Number(sessionStorage.getItem('loginRoleId')) === configlist.pageConst.rolePublicFacing &&
            Number(sessionStorage.getItem('loginScreenId')) === configlist.pageConst.pagePublicFacing) {
            this.router.navigate(['/publicFacing']);
        } else {
          sessionStorage.setItem('loginRoleId', '-1');
          sessionStorage.setItem('userRoleId', '-1');
          sessionStorage.setItem('privilegeData', null);
          sessionStorage.setItem('loginScreenId', '1');
          sessionStorage.setItem('logOut', 'Y');
          sessionStorage.setItem('isLoggedIn', 'false');
          sessionStorage.setItem('loggedInUserName', null);
          this.router.navigate(['/publicFacing']);
        }
      //},
      //error => {
      //  console.log('error in role privilege');
      //});
    } else {
        this.router.navigate(['/publicFacing']);
    }
  }

  /* Validate if user is authorized to view the reqested URL */
  authorizeUser() {
    if (sessionStorage.getItem('isLoggedIn') !== 'true') {
      this.router.navigate(['/unauthorized']);
    }
  }

  /** Image file type check */
  verifyType(fileType) {
    if (fileType.startsWith('image') && (fileType.indexOf('jpeg')  !== -1 || fileType.indexOf('png')  !== -1
    || fileType.indexOf('gif')  !== -1 || fileType.indexOf('bmp')  !== -1)) {
      return true;
    }
    return false;
  }
  /** Category image set form data */
  formData(fileInput) {
    const formdata: FormData = new FormData();
    formdata.append('categoryImage', fileInput);
    return formdata;
  }
  /** Verify file size of image */
  verifySize(fileSize) {
    if (fileSize <= 50000) {
      return true;
    }
    return false;
  }
  /** Truncate given source and display */
  truncate(source: string) {
    if (source !== null) {
      return source.length > 18 ? source.slice(0, 17) + '…' : source;
    }
  }
  truncateOrderTab(source: string) {
    if (source !== null) {
      return source.length > 30 ? source.slice(0, 29) + '…' : source;
    }
  }
  /** To get the current date object and return */
  getCurrentDate() {
    const currentDate = new Date();
    currentDate.setMilliseconds(0);
    return currentDate;
  }
  checkMinDate(tempDate: string) {
    let checkMinDate = new Date(tempDate);
      if (checkMinDate > this.getCurrentDate()) {
        checkMinDate = this.getCurrentDate();
      }
    return checkMinDate;
  }
  /** For only calendar type setting date */
  getCalCurrentDate() {
    const currentDate = new Date();
    currentDate.setHours(0);
    currentDate.setMinutes(0);
    currentDate.setSeconds(0);
    currentDate.setMilliseconds(0);
    return currentDate;
  }
  checkCalMinDate(tempDate: string) {
    let checkMinDate = new Date(tempDate);
      if (checkMinDate > this.getCurrentDate()) {
        checkMinDate = this.getCurrentDate();
      }
    checkMinDate.setHours(0);
    checkMinDate.setMinutes(0);
    checkMinDate.setSeconds(0);
    checkMinDate.setMilliseconds(0);
    return checkMinDate;
  }
  /** Application image set form data */
  appFormData(fileInput) {
    const formdata: FormData = new FormData();
    formdata.append('applicationImage', fileInput);
    return formdata;
  }
  
  getPasswordLength() {
    const screenData = JSON.parse(sessionStorage.getItem('screenInfoData'));
    return screenData.pwdMinLength;
  }

  getPasswordRegex() {
    let pwdRegex = '';
    pwdRegex = configlist.pageConst.passwordRegex;
    pwdRegex = pwdRegex.replace('<pwdLegnth>', this.getPasswordLength());
    const passwordRegex = new RegExp(pwdRegex);
    console.log(passwordRegex);
    return passwordRegex;
  }
  /** To form regrex and check with field value */
  getRegexValidate(pswdRegexConst: string, pswdRegexLength: string = "1") {
    let pwdRegex = '';
    let pswdData = this.passwordData.passwordRulesData;
    if (pswdRegexConst === configlist.pageConst.passwordSpecialCharRegex) {
      pwdRegex = pswdRegexConst.replace('<splCharacters>', pswdData.splCharAllowed);
      pwdRegex = pwdRegex.replace('<charLength>', pswdRegexLength);
    } else {
      pwdRegex = pswdRegexConst.replace('<charLength>', pswdRegexLength);
    }    
    // console.log(passwordRegex);
    return pwdRegex;
  }

  getMinMaxRegex(pswdRegexConst: string, pswdRegexLength: string = "1") {
    let pwdRegex = '';
    let passwordRegex: RegExp;
    let pswdData = this.passwordData.passwordRulesData;
    pwdRegex = pswdRegexConst.replace('<min>', pswdData.passwordMinLength);
    pwdRegex = pwdRegex.replace('<max>', pswdData.passwordMaxLength);
    passwordRegex = new RegExp(pwdRegex);
    return passwordRegex;
  }
  
  getPasswordErrorMessage(){
    return 'static';
  }
  /** Error messages for password field forming dynamically */
  // Must contain categories 
  pswdMustContainErrorMsg() {
    let pswdData = this.passwordData.passwordRulesData;
    let passwordError = configlist.errorConst.newPasswordMustContainError;
    passwordError = passwordError.replace('<charLength>', pswdData.passwordContainCategories);
    return passwordError;
  }
  // Upper Case error
  pswdUpperCaseErrorMessage() {
    let pswdData = this.passwordData.passwordRulesData;
    let passwordError = configlist.errorConst.newPasswordCapError;
    passwordError = passwordError.replace('<charLength>', pswdData.passwordUpperCaseLength);
    return passwordError;
  }
  // Lower Case error
  pswdLowerCaseErrorMessage() {
    let pswdData = this.passwordData.passwordRulesData;
    let passwordError = configlist.errorConst.newPasswordSmallError;
    passwordError = passwordError.replace('<charLength>', pswdData.passwordLowerCaseLength);
    return passwordError;
  }
  // Number error
  pswdNumberErrorMessage() {
    let pswdData = this.passwordData.passwordRulesData;
    let passwordError = configlist.errorConst.newPasswordNumberError;
    passwordError = passwordError.replace('<charLength>', pswdData.passwordNumberLength);
    return passwordError;
  }
  // Special Character error
  pswdSpecialCharErrorMessage() {
    let pswdData = this.passwordData.passwordRulesData;
    let passwordError = configlist.errorConst.newPasswordSplError;
    passwordError = passwordError.replace('<charLength>', pswdData.passwordSpecialCharLength);
    passwordError = passwordError.replace('<specialChar>', pswdData.splCharAllowed);
    return passwordError;
  }
  // Min & Max length error
  pswdMinMaxErrorMessage() {
    let pswdData = this.passwordData.passwordRulesData;
    let passwordError = configlist.errorConst.newPasswordLengthError;
    passwordError = passwordError.replace('<min>', pswdData.passwordMinLength);
    passwordError = passwordError.replace('<max>', pswdData.passwordMaxLength);
    return passwordError;
  }
  //Find and replace anchor tags with newly created span tags
  pushDescription (catDesc){
    let snippet = document.createElement("div");
    snippet.innerHTML = catDesc;
    let links = snippet.getElementsByTagName("a");
    let counter = links.length;
    if (links.length > 0) {
      for (let i = 0; i < counter; i++) {
        // let newItem = document.createElement("div");
        // let newUrl = "'" + links[0].href + "'";
        // newItem.innerHTML = '<p tabindex="0" onclick="window.open(' + newUrl + ',\'_blank\')" class="mouse-over spanColor">' + links[0].innerHTML + '</p>';
        // let tnode = links[0].parentNode;
        // tnode.replaceChild(newItem, links[0]);
        links[i].setAttribute('alt',  links[i].innerHTML);
      }
      // return this.sanitizer.bypassSecurityTrustHtml(snippet.innerHTML.toString());
      return snippet.innerHTML.toString();
    } else {
      return catDesc;
    }
  }

  // Crypto parsed key
  keyParserData() {
    const key = configlist.pageConst.cryptoKey;
    const encodedKey = CryptoJS.enc.Base64.stringify(
      CryptoJS.enc.Utf8.parse(key)
    );
    console.log(encodedKey);
    return CryptoJS.enc.Base64.parse(encodedKey);
  }
  // Crypto Encryption method
  encryptData(data: object| string) {
    const parsedBase64Key = this.keyParserData();
    console.log(parsedBase64Key);
    let originalText: string;
    // check the data object type and perform action
    if (typeof data === 'object') {
      originalText = JSON.stringify(data);
    } else {
      originalText = data;
    }    
    const encryptedData = CryptoJS.AES.encrypt(
      originalText,
      parsedBase64Key,
      {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }
    );
    // this is the decrypted data as a string
    const parsedData = encryptedData.toString();
    console.log('parseddata', parsedData);
    return parsedData;
  }

  // Crypto Decrypt method
  decryptData(data: string) {
    const encryptedCipherText = data;
    const parsedBase64Key = this.keyParserData();
    const decryptedData = CryptoJS.AES.decrypt(
      encryptedCipherText,
      parsedBase64Key,
      {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }
    );
    // this is the decrypted data as a string
    let decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);
    decryptedText = JSON.parse(decryptedText);
    return decryptedText;
  }
}
