import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from '../_services/http.service';
import * as configList from '../../assets/config/config';
import * as auditmessagelist from '../../assets/config/auditmessages';
import { CONFIGUREPORTALREQUEST } from '../_models/ConfigurePortal/configureportalrequest';
import { USERACTIVITYLOG } from '../_models/UserActivityTrack/useractivitylog';
import { UserService } from '../_services/user.service';
import {Token} from '../_models/TOKEN';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})

export class DisclaimerComponent implements OnInit {
  @ViewChild('openModal',{static:true}) openModal: ElementRef;
  isDisagreeClicked = false;
  securityDisclaimerBody = '';
  acceptText = '';
  rejectText = '';
  eventTargetId = '';
  accessedMessage = '';
  isCategory = '';
  portletId: Number = configList.pageConst.NAPortletId;
  isTargetIdExist = false;
  screenId: Number;
  public userActivityTrackRequest: CONFIGUREPORTALREQUEST;
  public userAccessedLog: USERACTIVITYLOG;
  model: Token = { username: '', password: '' };
  screenData: any;

  constructor(private httpClient: HttpClient, private router: Router, private activeRoute:ActivatedRoute, private httpService: HttpService, private userDet: UserService, private logger: NGXLogger) { }

  agree() {
    sessionStorage.setItem('isAgreeClicked', 'true');
    localStorage.setItem('isAgreeClicked', 'true');
    this.router.navigate(['/publicFacing']);
  }
  disagree(){
    sessionStorage.setItem('isAgreeClicked', 'false');
    localStorage.setItem('isAgreeClicked', 'false');
    this.router.navigate(['/unauthorized']);
  }

  ngOnInit() {
    this.screenData = this.activeRoute.snapshot.data.userdata; // get data from resolver
    if(this.screenData.disclaimerFlag === configList.pageConst.constNo) {
      this.router.navigate(['/publicFacing']);
    } else {
      document.getElementById('pageheader').setAttribute('tabindex', '-1');
      document.getElementById('pageheader').focus();
      // Trigger click function to open the disclaimer modal window
      this.openModal.nativeElement.click();
      if (!(localStorage.getItem('acceptLabelText') && localStorage.getItem('rejectLabelText') && localStorage.getItem('ssoMode'))) {
        this.getScreenDetails();
      }

    this.httpService.get_Disclaimer()
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.securityDisclaimerBody = data.securityDisclaimer;
          // this.acceptText = sessionStorage.getItem('acceptLabelText');
          // this.rejectText = sessionStorage.getItem('rejectLabelText');
          this.acceptText = localStorage.getItem('acceptLabelText');
          this.rejectText = localStorage.getItem('rejectLabelText');
        },
        (error) => {
          console.error('error in disclaimer service call');
        }
      );
    }
  }

  /**
 * To Get Parent Element Id
 */
getClosest(elem: any) {
  if (elem.id !== undefined && elem.id !== '') {
    this.isTargetIdExist = true;
    return elem.id;
  } else {
    this.isTargetIdExist = false;
  }
  for ( ; elem && elem !== document; elem = elem.parentNode ) {
    if (elem.id !== undefined && elem.id !== '') {
      return elem.id;
    }
  }
}

getScreenDetails(): void {  
  // this.model = {username: configList.pageConst.username, password: configList.pageConst.password};
  // this.httpService.getToken(this.model).subscribe(
  //  (res: HttpResponse<any>) => {
  //    sessionStorage.setItem('JWTToken', res.headers.get('Authorization'));
  //    if (res.headers.get('Authorization') !== null) {
        if (!sessionStorage.getItem('screenInfoData')) {
          this.httpService.get_Screen(this.userDet.getScreenId(), this.userDet.getRoleId())
          .pipe(first())
          .subscribe(
            (data: any) => {
              this.getDisclamerDisplay(data);             
            },
            (error) => {
              this.logger.debug('error in get screen service call');
            });
        } else {
          this.getDisclamerDisplay(JSON.parse(sessionStorage.getItem('screenInfoData')));
        }        
    //  }
    // },
  // (error) => {
   // this.logger.debug('error in get token service call');
  // });
}

  /** Disclaimer screen labels logic */
  getDisclamerDisplay(data) {
    localStorage.setItem('acceptLabelText', data.eulaAccLabel);
    localStorage.setItem('rejectLabelText', data.eulaRejLabel);
	localStorage.setItem('ssoMode', data.ssoMode);
    sessionStorage.setItem('ssoMode', data.ssoMode);
    this.acceptText = localStorage.getItem('acceptLabelText');
    this.rejectText = localStorage.getItem('rejectLabelText'); 
  }

  /**
   * On Mouse Down in a entire page
   * event is triggered
   */
  @HostListener('mousedown', ['$event'])
  onMouseDown(event: any) {
    // Mouse Down event Consts
    this.eventTargetId = '';
    this.accessedMessage = '';
    this.isCategory = '';
    this.portletId = configList.pageConst.NAPortletId;
    this.isCategory = configList.pageConst.NotApplicable;
    this.screenId = this.userDet.getScreenId();

    const elemId = this.getClosest(event.target);

    if (elemId !== undefined && elemId === 'agreeButton') {
      this.eventTargetId =  elemId;
      this.accessedMessage = auditmessagelist.auditMessages.commonAccessed + ' ' +
                             auditmessagelist.auditMessages.publicfacingPage;
    }

    if (this.accessedMessage !== undefined &&
      this.accessedMessage !== '' &&
      this.accessedMessage !== '\n') { // If Accessed Message Exists
        // Service Request Preparation
        this.userActivityTrackRequest = new CONFIGUREPORTALREQUEST();
        this.userAccessedLog = new USERACTIVITYLOG();
        this.userActivityTrackRequest = this.userDet.getConfigPortalReq(this.screenId, this.portletId);
        if (this.userActivityTrackRequest.updatedBy === 'null') {
          this.userActivityTrackRequest.updatedBy = configList.pageConst.publicIP;
        }
        this.userActivityTrackRequest.activityCode = configList.activityCode.accessedPublicFacing;

        this.userAccessedLog.accessedMessageId = configList.pageConst.NotApplicable;

        this.userAccessedLog.accessedMessage = this.accessedMessage;
        this.userAccessedLog.isCategory = this.isCategory;

        this.userActivityTrackRequest.userAccessedLog = this.userAccessedLog;

        // Sending request to Service
        this.httpService.updateUserAccessedActivity(this.userActivityTrackRequest).subscribe(
          data => {
          },
          error => {
            console.log(error);
          }
        );
      }
  }

}
