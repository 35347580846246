import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from '../../_services/http.service';
import { UserService } from '../../_services/user.service';
import { pageConst} from '../../../assets/config/config';
import { NGXLogger } from 'ngx-logger';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-application-links',
  templateUrl: './application-links.component.html',
  styleUrls: ['./application-links.component.scss']
})
export class ApplicationLinksComponent implements OnInit {
  res: any = {
    'title': 'APPLICATION LINKS'
  };
  resVal  = [];
  resKeys: any;
  data = [];
  @Input() linksTileLabel: string;
  public constVal = pageConst;
  public appLinksImgData = [];

  constructor(private httpService: HttpService, private userDet: UserService,
    private logger: NGXLogger, private sanitizer: DomSanitizer, private router: Router) {
    // httpService.getProgInfo(this.userDet.getScreenId(), this.userDet.getRoleId()).subscribe(
    httpService.getAppLinks(this.userDet.getScreenName()).subscribe(
      (data: any) => {
        this.resVal = Object.values(data.applicationResponse);
        this.resKeys = Object.keys(data.applicationResponse);
        this.data = data;
        this.loadImages();
      }
    );
    console.log(this.data);
  }

  ngOnInit() {
  }

  /** function to load images into list */
  loadImages() {
    this.resVal.forEach((imgObj, index) => {
      if (index < 3) {
        if (imgObj.catImageExists) {
          this.httpService.getAppImage(imgObj.categoryId)
          .subscribe(
            (data: any) => {
                const urlCreator = window.URL;
                const imagePath = this.sanitizer.bypassSecurityTrustUrl(
                  urlCreator.createObjectURL(data));
                  let url = this.pushUrl(imgObj.categoryDesc);
                this.appLinksImgData.splice(index, 0, {categoryName: imgObj.categoryName, categoryId: imgObj.categoryId,
                    categoryDesc: this.pushDescription(url,imgObj.categoryDesc), catImgPath: imagePath, catImageExists: imgObj.catImageExists,
                    categoryUrl: url});
            },
            (error) => {
              let defaultPath = 'assets/images/app.png';
              this.logger.debug('error in get screen service call');
              let url = this.pushUrl(imgObj.categoryDesc);
              this.appLinksImgData.splice(index, 0, {categoryName: imgObj.categoryName, categoryId: imgObj.categoryId,
                categoryDesc: this.pushDescription(url,imgObj.categoryDesc), catImageExists: false, categoryUrl: url });
            }
          );
        } else {
          let url = this.pushUrl(imgObj.categoryDesc);
          this.appLinksImgData.push({categoryName: imgObj.categoryName, categoryId: imgObj.categoryId,
            categoryDesc: this.pushDescription(url,imgObj.categoryDesc), catImageExists: imgObj.catImageExists,
            categoryUrl: url});
        }
      }
    });
  }

  // /** navigate to category page */
  // categoryNavigate(catId: number) {
  //   this.router.navigate(['/applicationLinks/applicationLinksDetailedPage', catId]);
  // }
  /** navigate to category page */
  // categoryNavigate(catObjData) {
  //   let contents = catObjData,
  //   snippet = document.createElement("div");
  //   snippet.innerHTML = contents;
  //   let links = snippet.getElementsByTagName("a");
  //   if (links.length) {
  //     let lastURL = links[0].href;
  //     window.open(lastURL, "_blank");
  //   }
  // }

  categoryNavigate(index) {
    let contents;
      contents = this.appLinksImgData[index].categoryUrl; 
      window.open(contents, "_blank");
  }
  applicationLinksPortlet() {
    // tslint:disable-next-line:max-line-length
    this.router.navigate(['/applicationLinks']);
  }

  pushDescription (url,catDesc){
    let snippet = document.createElement("div");
    snippet.innerHTML = catDesc;
    let links = snippet.getElementsByTagName("a");
    let newItem = document.createElement("span");
    let newUrl = "'"+url+"'";
    newItem.innerHTML = '<span tabindex="0" (keyup.enter)="window.open('+newUrl+',\'_blank\')" onclick="window.open('+newUrl+',\'_blank\')" class="mouse-over spanColor">'+links[0].innerHTML+'</span>';
    let tnode = links[0].parentNode;
    tnode.replaceChild(newItem, links[0]);
    return this.sanitizer.bypassSecurityTrustHtml(snippet.innerHTML.toString());
  }
  pushUrl(catDesc) {
    let contents = catDesc;

    let snippet = document.createElement("div");
    snippet.innerHTML = contents;
    let links = snippet.getElementsByTagName("a");
    
    if (links.length) {
      return links[0].href;
      // window.open(lastURL, "_blank");
    }
  }
}
