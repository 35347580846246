import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from '../_services/http.service';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';
import * as configList from '../../assets/config/config';
import { CONFIGUREPORTALREQUEST } from '../_models/ConfigurePortal/configureportalrequest';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { SECURITYQS } from '../_models/SECURITYQS';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-reset-security-question',
  templateUrl: './reset-security-question.component.html',
  styleUrls: ['./reset-security-question.component.scss']
})
export class ResetSecurityQuestionComponent implements OnInit {
  @ViewChild('qsModal') qsModal: any; // needed for ADA
  @ViewChild('qsMsg') qsMsg: any; // needed for ADA
  public security: SECURITYQS;
  securityQSitems: any[];
  public ansSpace = false;
  public questionLength;
  public errorMsg: string;
  public showAlertQS = false;
  public answerEmpty = false;
  public disableSave = false;
  duplicateFlag = false;
  duplicateErrorMsg = '';
  securityQsForm: FormGroup;
  secQuesUser = [];
  public noOfQuestions;
  public tooltipconstdata = configList.tooltipConst;
  public configurePortalRequest: CONFIGUREPORTALREQUEST;

  constructor(private httpService: HttpService, private logger: NGXLogger, 
    private router: Router, private formBuilder: FormBuilder, private userDet: UserService) {
    // Method to check if the user is authorised to view this page
    this.userDet.authorizeUser();
  }

  ngOnInit() {
    this.security = new SECURITYQS();
    this.getUserQuestions();
    this.securityQsForm = this.formBuilder.group({
      securityQuestions: this.formBuilder.array([]),
    },
    { validator: this.checkDuplicateQs }
    );
  }

  onChanges() {
    for (let i = 0; i < 3; i++) {
      if (this.securityQsForm.get('securityQuestions').get(String(i)) !== null) {
        this.securityQsForm.get('securityQuestions').get(String(i)).get('answer_' + i).valueChanges.subscribe(val => {
        this.securityQsForm.get('securityQuestions').get(String(i)).get('answer_' + i).markAsTouched();
        });
      }
    }
  }
  /* This method returns the appropriate error message if same question has been selected in two different
  dropdowns in the edit security question form*/
  getDuplicateError() {
    return this.securityQsForm.hasError('duplicateError') ? configList.errorConst.DuplicateMessage : '';
  }

  /* This method returns the appropriate error message for answer field in the edit security question form*/
  getSecurityQuestionError(i) {
    return this.securityQsForm.get('securityQuestions').get(String(i)).get('question_' + i).hasError('quesError') ?
    'Field must be selected' : '';
}

  /* This method returns the appropriate error message for answer field in the edit security question form*/
  getSecurityAnswerError(i) {
      return this.securityQsForm.get('securityQuestions').get(String(i)).get('answer_' + i).hasError('required') ?
      configList.errorConst.fieldError :
      this.securityQsForm.get('securityQuestions').get(String(i)).get('answer_' + i).hasError('spaceError') ?
      configList.errorConst.onlySpaces : '';
  }

  /** This method is used to define the fields that would be present in the FormArray */
  getSecurityQSFields(i) {
    const ques = 'question_' + i;
    const ans = 'answer_' + i;
    return this.formBuilder.group({
      [ques]: [{value: ''}, [Validators.required, this.checkQuestion]],
      [ans]: [{value: ''}, [Validators.required, this.checkSpaces]]
    });
  }

  /* This method is used to check if a field contains only spaces */
  checkSpaces(control: FormControl) {
    let condition = false;
    if (control.value !== null && (control.value).toString().trim() === '' && (control.value).toString().length > 0) {
      condition = true;
    }
    return condition ? { spaceError: true } : null;
  }
  /* This method is used to check if a field contains only spaces */
  checkQuestion(control: FormControl) {
    let condition = false;
    if (control.value === 0) {
      condition = true;
    }
    return condition ? { quesError: true } : null;
  }

  /* This method is used to check if an object is empty */
  isEmptyObj(obj: any) {
    return (obj && (Object.keys(obj).length === 0));
  }

  /** This method is called to save the security questions configured by the user */
  saveSecurityQuestions() {
    this.configurePortalRequest = new CONFIGUREPORTALREQUEST();
    this.configurePortalRequest.screenId = this.userDet.getScreenId();
    this.configurePortalRequest.updatedBy = this.userDet.getScreenName();
    this.configurePortalRequest.portletId = configList.pageConst.NAPortletId;

    const modifySecQues = Object();
    modifySecQues.userLoginId = this.userDet.getScreenName();
    modifySecQues.detailsList = [];
    const list = [];
    for (let i = 0; i < this.questionLength; i++) {
      const detailList = Object();
      const newValue = Object();
      newValue.questionId = Number(this.securityQsForm.get('securityQuestions').get(String(i)).get('question_' + i).value);
      newValue.answer = this.securityQsForm.get('securityQuestions').get(String(i)).get('answer_' + i).value;
      detailList.userSecQueId = null;
      detailList.oldValue = null;
      detailList.newValue = newValue;
      list.push(detailList);
    }
    modifySecQues.detailsList = list;
    this.configurePortalRequest.activityCode = configList.activityCode.modifyNewSecurityQuestion;
    this.configurePortalRequest.modifySecQues = modifySecQues;
    let finalData = Object();
    finalData.messageContent = this.userDet.encryptData(this.configurePortalRequest);
    // API call for saving sec qs
    this.httpService.secureUpdateUserLogin(finalData).subscribe(data => {
      data = this.userDet.decryptData(data.messageContent);
      if (data.userSecQueResp.responseCode === 0) {
        this.errorMsg = configList.errorConst.PasswordSuccess;
      } else {
        this.errorMsg = configList.errorConst.SystemError;
      }
      this.showAlertQS = true;
      if (this.qsModal !== undefined) {
        this.qsModal.nativeElement.style.display = 'block';
      }
      if (this.qsMsg !== undefined) {
        this.qsMsg.nativeElement.focus();
      }
    },
    error => {
      this.logger.debug('Error while saving Security Question');
    }
    );
  }

  /** This method relinquishes all the changes made to the form and redirects to the public-facing page */
  cancelData(): void {
    this.securityQsForm.reset();
    for (let i = 0; i < this.questionLength ; i++) {
      this.securityQsForm.get('securityQuestions').get(String(i)).get('question_' + i).setValue(0);
    }
    this.reroutePublic();
  }

  /** This method is used to re-route to public-facing page */
  reroutePublic() {
    sessionStorage.setItem('loginRoleId', '-1');
    sessionStorage.setItem('userRoleId', '-1');
    sessionStorage.setItem('privilegeData', null);
    sessionStorage.setItem('loginScreenId', '1');
    sessionStorage.setItem('logOut', 'Y');
    sessionStorage.setItem('isLoggedIn', 'false');
    sessionStorage.setItem('loggedInUserName', null);
    sessionStorage.removeItem('screenInfoData');
    localStorage.removeItem('screenInfoData');
    this.router.navigate(['/publicFacing']);
  }

  /** This method is used to check if the same question has been selected in two or more dropdowns */
  checkDuplicateQs(form: FormGroup) {
    const counter = +(document.getElementById('quesNumber') as HTMLInputElement).value;
    let condition = false;
    for ( let i = 0 ; i < counter; i++) {
      if (form.get('securityQuestions').get(String(i)) !== null) {
        const currentVal = form.get('securityQuestions').get(String(i)).get('question_' + i);
        for ( let j = 0 ; j < counter; j++) {
          if (form.get('securityQuestions').get(String(j)) !== null) {
            const selectedVal = form.get('securityQuestions').get(String(j)).get('question_' + j);
            if ( i !== j && currentVal.value !== 0
                && selectedVal.value !== 0 && (selectedVal.value === currentVal.value)) {
              condition = true;
              break;
            }
          }
        }
      }
    }
    return condition ? { duplicateError: true } : null;
  }

  /** This method is used to get the security questions answered by the user */
  getUserQuestions() {
    this.httpService.getSecurityQuestion().subscribe(data => {
      this.questionLength = data.noOfQue;
      if (data.usersSecQue.length < this.questionLength) {
        this.questionLength = data.usersSecQue.length;
      }
      this.securityQSitems = data.usersSecQue;
      console.log(this.securityQSitems);
      (document.getElementById('quesNumber') as HTMLInputElement).value = this.questionLength;

    for (let i = 0; i < this.questionLength ; i++) {
      const arrayControl = <FormArray>this.securityQsForm.controls['securityQuestions'];
      arrayControl.push(this.getSecurityQSFields(i));
      this.securityQsForm.get('securityQuestions').get(String(i)).get('question_' + i).setValue(0);
      this.securityQsForm.get('securityQuestions').get(String(i)).get('answer_' + i).setValue('');
      this.onChanges();
    }
    },
    error => {
      this.logger.debug('Error while fetching Security Questions');
    }
    );
  }
  /** To get the form element data using form group dynamically */
  get formData() { 
    return <FormArray>this.securityQsForm.get('securityQuestions'); 
  }
}
