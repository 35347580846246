import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from '../_services/http.service';
import { NGXLogger } from 'ngx-logger';
import { UserService } from '../_services/user.service';
import * as configList from '../../assets/config/config';
import { CONFIGUREPORTALREQUEST } from '../_models/ConfigurePortal/configureportalrequest';
import { USERACCTRACKREQUEST } from '../_models/useracceptancetracking/useracctrackrequest';
import { USERACCTRACKDETAILS } from '../_models/useracceptancetracking/useracctrackdetails';
import { LoaderService } from '../_services/loader.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'signInAnnouncement',
  templateUrl: './sign-in-announcement.component.html',
  styleUrls: ['./sign-in-announcement.component.scss']
})
export class SignInAnnouncementComponent implements OnInit {
  @ViewChild('announcement') announcement: ElementRef;
  @ViewChild('errorMsg') errorMsg: ElementRef;
  data = [];
  signOnAnnouncementBody = '';
  errorMsgContent = '';
  notifyUser = '';
  userAccepted = false;
  acceptText = '';
  rejectText = '';
  public screenId: any;
  public userRoleId: number;
  userId = '';
  public screenDetails: any;
  public signOnData: any;
  public configurePortalRequest: CONFIGUREPORTALREQUEST;
  public modifyUserAcceptanceTracking: USERACCTRACKREQUEST;
  public showAdminModal = false;
  public ssoMode : boolean;


  constructor(private router: Router, private httpService: HttpService, private logger: NGXLogger,
    private route: ActivatedRoute, private userDet: UserService, private loaderService: LoaderService) { }

  announcementHeader = '';
  announcementBody = '';
  announcementEnabled = '';
  announcementStatus = '';
  // disclaimerUrl = '';


  ngOnInit() {
  	this.getssoMode();
    document.getElementById('signheader').setAttribute('tabindex', '-1');
    document.getElementById('signheader').focus();
    this.screenId = this.userDet.getScreenId();
    this.userRoleId = this.userDet.getRoleId();
    this.userId = this.userDet.getScreenName();
    this.checkAdmin();
  }
  
  checkAdmin() {
  	if (this.ssoMode && sessionStorage.getItem('loginRoleId') === '3' && sessionStorage.getItem('loginScreenId') === '4') {
  		/* this.errorMsgContent = configList.errorConst.adminAccess;
  		this.errorMsg.nativeElement.click(); */
  		sessionStorage.removeItem('screenInfoData');
	    sessionStorage.setItem('loginRoleId', '-1');
	    sessionStorage.setItem('privilegeData', null);
	    sessionStorage.setItem('userRoleId', '-1');
	    sessionStorage.setItem('loginScreenId', '1');
	    sessionStorage.setItem('logOut', 'Y');
	    sessionStorage.setItem('isLoggedIn', 'false');
	    sessionStorage.setItem('loggedInUserName', null);
	    sessionStorage.removeItem('authToken');
      let tempAgreeClicked = localStorage.getItem('isAgreeClicked');
      localStorage.clear();
      if(tempAgreeClicked != null){
        localStorage.setItem('isAgreeClicked',tempAgreeClicked);
      }
  		this.router.navigate(['/unauthorized']);
  	} else {
  		this.getScreenDetails();
  	}
  }
  
  

  agree() {
     if (sessionStorage.getItem('loginUserStatus') === 'A') {
      // Fetch Privilege List of logged in User
      this.loaderService.isLoading.next(true);
      // this.httpService.getPrivilegesByRole(sessionStorage.getItem('userRoleId')).subscribe(privilegeData => {
        // sessionStorage.setItem('privilegeData', JSON.stringify(privilegeData.privilageDetailsList));
        // localStorage.setItem('privilegeData', JSON.stringify(privilegeData.privilageDetailsList));
        this.loaderService.isLoading.next(false);
        if (sessionStorage.getItem('loginRoleId') === '4' && sessionStorage.getItem('loginScreenId') === '5') {
          this.router.navigate(['/conduentInternal']);
        }
       // else if (this.ssoMode && sessionStorage.getItem('loginRoleId') === '3' && sessionStorage.getItem('loginScreenId') === '4') {
        //   this.loaderService.isLoading.next(true);
        //   //this.router.navigate(['/admin']);
	     //    this.errorMsgContent = configList.errorConst.adminAccess;
         // 	 this.errorMsg.nativeElement.click();
       // } 
        else if (sessionStorage.getItem('loginRoleId') === '2' && sessionStorage.getItem('loginScreenId') === '3') {
          this.router.navigate(['/provider']);
        } else if (sessionStorage.getItem('loginRoleId') === '1' && sessionStorage.getItem('loginScreenId') === '2') {
          this.router.navigate(['/client']);
        }
      // },
      // error => {
      //   this.logger.debug('error in role privilege');
      // });
    }
  }
  
  /*To Get sso mode is enabled or not if enabled, assign ssoMode variable to true */
  getssoMode() {
   this.ssoMode = sessionStorage.getItem('ssoMode') === 'Y' ? true : false;
  }
  
  /* Get the screen info for Tiles */
  getScreenDetails(): void {
    const tempScreenId = +sessionStorage.getItem('loginScreenId');
    const tempRoleId = +sessionStorage.getItem('loginRoleId');
    if (!sessionStorage.getItem('screenInfoData')) {
      this.httpService.get_Screen( tempScreenId, tempRoleId )
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.getSignOnDisplay(data);
        },
        (error) => {
          this.logger.debug('error in get screen service call');
          this.errorMsgContent = configList.errorConst.SystemError;
          this.errorMsg.nativeElement.click();
      });
    } else {
      this.getSignOnDisplay(JSON.parse(sessionStorage.getItem('screenInfoData')));
    }
    
  }
  /** Sign On Display logic */
  getSignOnDisplay(data) {
    this.screenDetails = data;
    if (data.signInFlag === configList.pageConst.constYes) {
      this.getSignonData(data);
    } else {
      this.agree();
    }
  }
  getSignonData(screenData): void {
    // Trigger click function to open the disclaimer modal window
    this.acceptText = screenData.eulaAccLabel;
    this.rejectText = screenData.eulaRejLabel;

    this.httpService.get_Announcement(this.userRoleId, this.screenId, this.userId)
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.signOnData = data;
          if (data != null && data.showSignOn === configList.pageConst.constYes) {
            this.signOnAnnouncementBody = data.message;
            this.userAccepted = false;
            if (data.userActivityDetails !== null && data.userActivityDetails.isUserAccepted != null) {
              this.userAccepted = true;
              this.notifyUser = configList.pageConst.NotifyUserSignOn;
            }
            this.announcement.nativeElement.click();
          } else if (data != null && data.showSignOn === configList.pageConst.constNo) {
            this.agree();
          } else {
            this.errorMsgContent = configList.errorConst.SystemError;
            this.errorMsg.nativeElement.click();
          }
        },
        (error) => {
          console.error('error in Sign-On Announcement service call');
          this.errorMsgContent = configList.errorConst.SystemError;
          this.errorMsg.nativeElement.click();
        }
      );
  }

  acknowledgementCheck(acceptFlag) {
    if (this.screenDetails.ackTrackingFlag === configList.pageConst.constYes) {
      this.updateUserDetails(acceptFlag);
    } else {
      if (acceptFlag) {
        this.agree();
      } else {
        this.reject();
      }
    }
  }

  updateUserDetails(acceptFlag) {
    this.configurePortalRequest = new CONFIGUREPORTALREQUEST();
    this.modifyUserAcceptanceTracking = new USERACCTRACKREQUEST();
    let saveOldValue = Object();
    const saveNewValue = Object();

    this.configurePortalRequest.screenId = this.userDet.getScreenId();
    this.configurePortalRequest.updatedBy = this.userDet.getScreenName();
    this.configurePortalRequest.portletId = configList.pageConst.NAPortletId;
    this.configurePortalRequest.activityCode = configList.activityCode.modifyUserAccTrack;
    if (this.signOnData.userActivityDetails !== undefined && this.signOnData.userActivityDetails !== null) {
      if (this.signOnData.userActivityDetails.isUserAccepted != null) {
        saveOldValue.announcementId = this.signOnData.userActivityDetails.announcementId;
        saveOldValue.isUserAccepted = this.signOnData.userActivityDetails.isUserAccepted;
      } else {
        saveOldValue = null;
      }
    } else {
      saveOldValue = null;
    }
    if (acceptFlag) {
      saveNewValue.announcementId = this.signOnData.msgId;
      saveNewValue.isUserAccepted = configList.pageConst.constYes;
    } else {
      saveNewValue.announcementId = null;
      saveNewValue.isUserAccepted = configList.pageConst.constNo;
    }
    this.modifyUserAcceptanceTracking.oldValue = saveOldValue;
    this.modifyUserAcceptanceTracking.newValue = saveNewValue;
    this.configurePortalRequest.modifyUserAcceptanceTracking = this.modifyUserAcceptanceTracking;
    this.httpService.updateUserAccTrack(this.configurePortalRequest).subscribe(
      (data: any) => {
        if (data.userAcceptanceTrackingResponse.responseCode === 0) {
          if (acceptFlag) {
            this.agree();
          } else {
            this.reject();
          }
        } else {
          this.errorMsgContent = configList.errorConst.SystemError;
          this.errorMsg.nativeElement.click();
        }
      },
      (error) => {
        console.error('error in Sign-On Announcement User Tracking Update service call');
        this.errorMsgContent = configList.errorConst.SystemError;
        this.errorMsg.nativeElement.click();
      }
    );
  }
  reject() {
    sessionStorage.setItem('loginRoleId', '-1');
    sessionStorage.setItem('userRoleId', '-1');
    //sessionStorage.setItem('privilegeData', null);
    sessionStorage.setItem('loginScreenId', '1');
    sessionStorage.setItem('logOut', 'Y');
    sessionStorage.setItem('isLoggedIn', 'false');
    sessionStorage.setItem('loggedInUserName', null);
    sessionStorage.setItem('loggedInFirstName', null);
    this.router.navigate(['/publicFacing']);
  }

}
