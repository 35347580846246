import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from '../_services/http.service';
import { NGXLogger } from 'ngx-logger';
import { NgModel } from '@angular/forms';
import { Router } from '@angular/router';
import * as configList from '../../assets/config/config';
import { UserService } from '../_services/user.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CONFIGUREPORTALREQUEST } from '../_models/ConfigurePortal/configureportalrequest';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  @ViewChild('userId') userId: NgModel;
  @ViewChild('submitBtn') submitBtn: any; // needed for ADA
  @ViewChild('userField') userField: any;
  @ViewChild('msg') msg: any;
  @ViewChild('msgText') msgText: any;
  public configurePortalRequest: CONFIGUREPORTALREQUEST;
  public errorMsg: string;
  public showAlertQS = false;
  public disableSave = false;
  passwordForm: FormGroup;
  public tooltipconstdata = configList.tooltipConst;

  constructor(private httpService: HttpService, private formBuilder: FormBuilder, private userDet: UserService,
    private logger: NGXLogger, private router: Router) { }

  ngOnInit() {
    if (this.msgText !== undefined) {
      this.msgText.nativeElement.removeAttribute('tabindex');
    }
    this.passwordForm = this.formBuilder.group({
      userId: ['', [Validators.required, this.checkSpaces]]
    });
    this.onChanges();
  }
  get f() { return this.passwordForm.controls; }

  /* This method is used to check if a field contains only spaces */
  checkSpaces(control: FormControl) {
    let condition = false;
    if (control.value !== null && (control.value).toString().trim() === '' && (control.value).toString().length > 0) {
      condition = true;
    }
    return condition ? { spaceError: true } : null;
  }

  /* This method is used to carry out some functionalities when the values of certain fields change */
  onChanges(): void {
    this.passwordForm.get('userId').valueChanges.subscribe(val => {
      this.passwordForm.get('userId').markAsTouched();
    });
  }

  /* This method returns the appropriate error message for new password field*/
  getUserError() {
    return this.passwordForm.get('userId').hasError('required') ? configList.errorConst.fieldError :
    this.passwordForm.get('userId').hasError('spaceError') ? configList.errorConst.onlySpaces : '';
  }

  /** This method relinquishes all the changes made to the form and redirects to the public-facing page */
  reRoutePublic(): void {
    this.passwordForm.reset();
    this.disableSave = false;
    sessionStorage.setItem('loginRoleId', '-1');
    sessionStorage.setItem('userRoleId', '-1');
    sessionStorage.setItem('privilegeData', null);
    sessionStorage.setItem('loginScreenId', '1');
    sessionStorage.setItem('logOut', 'Y');
    sessionStorage.setItem('isLoggedIn', 'false');
    sessionStorage.setItem('loggedInUserName', null);
    this.router.navigate(['/publicFacing']);
  }

  /** This method is called to Save the new password */
  submitUserId() {
    if (this.msgText !== undefined) {
      this.msgText.nativeElement.removeAttribute('tabindex');
    }
    this.configurePortalRequest = new CONFIGUREPORTALREQUEST();
    this.configurePortalRequest.screenId = configList.pageConst.pagePublicFacing;
    this.configurePortalRequest.updatedBy = this.f.userId.value;
    this.configurePortalRequest.portletId = configList.pageConst.NAPortletId;
    this.configurePortalRequest.activityCode = configList.activityCode.forgotPassword;
    const ruleEngineData = Object();
    ruleEngineData.userLoginId = this.f.userId.value;
    this.configurePortalRequest.ruleEngineData = ruleEngineData;
    let finalData = Object();
    finalData.messageContent = this.userDet.encryptData(this.configurePortalRequest);

    // API call for saving reset password
    this.httpService.secureUserLoginTrack(finalData).subscribe(data => {
      data = this.userDet.decryptData(data.messageContent);
      sessionStorage.setItem('LoginToken', data.loginToken);
      if (data.ruleEngineResp.responseCode === 'RP003') {
        if (this.userField !== undefined) {
          this.userField.nativeElement.style.display = 'none';
        }
        if (this.submitBtn !== undefined) {
          this.submitBtn.nativeElement.style.display = 'none';
        }
        if (this.msg !== undefined) {
          this.msg.nativeElement.classList.remove('hidden');
          this.msg.nativeElement.classList.add('mt-5');
          if (this.msgText !== undefined) {
            this.msgText.nativeElement.style.color = 'blue';
            this.msgText.nativeElement.setAttribute('tabindex', '0');
            this.msgText.nativeElement.focus();
          }
        }
        this.errorMsg = configList.errorConst.generateEmail;
      } else if (data.ruleEngineResp.responseCode === 'RP101') {
        this.errorMsg = configList.errorConst.invalidUser;
        if (this.msg !== undefined) {
          this.msg.nativeElement.classList.remove('hidden');
          if (this.msgText !== undefined) {
            this.msgText.nativeElement.style.color = 'red';
            this.msgText.nativeElement.setAttribute('tabindex', '0');
            this.msgText.nativeElement.focus();
          }
        }
      } else if (data.ruleEngineResp.responseCode === 'RP115') {
        this.errorMsg = data.ruleEngineResp.responseDesc;
        this.f.userId.disable();
        if (this.submitBtn !== undefined) {
          this.submitBtn.nativeElement.style.display = 'none';
        }
        if (this.msg !== undefined) {
          this.msg.nativeElement.classList.remove('hidden');
          if (this.msgText !== undefined) {
            this.msgText.nativeElement.style.color = 'red';
            this.msgText.nativeElement.setAttribute('tabindex', '0');
            this.msgText.nativeElement.focus();
          }
        }
      } else if (data.ruleEngineResp.responseCode === 'RP119') {
        this.errorMsg = data.ruleEngineResp.responseDesc;
        this.f.userId.disable();
        if (this.submitBtn !== undefined) {
          this.submitBtn.nativeElement.style.display = 'none';
        }
        if (this.msg !== undefined) {
          this.msg.nativeElement.classList.remove('hidden');
          if (this.msgText !== undefined) {
            this.msgText.nativeElement.style.color = 'red';
            this.msgText.nativeElement.setAttribute('tabindex', '0');
            this.msgText.nativeElement.focus();
          }
        }
      } else if (data.ruleEngineResp.responseCode === 'RP120') {
        this.errorMsg = data.ruleEngineResp.responseDesc;
        this.f.userId.disable();
        if (this.submitBtn !== undefined) {
          this.submitBtn.nativeElement.style.display = 'none';
        }
        if (this.msg !== undefined) {
          this.msg.nativeElement.classList.remove('hidden');
          if (this.msgText !== undefined) {
            this.msgText.nativeElement.style.color = 'red';
            this.msgText.nativeElement.setAttribute('tabindex', '0');
            this.msgText.nativeElement.focus();
          }
        }
      } 
      else {
        this.errorMsg = configList.errorConst.SystemError;
        if (this.msg !== undefined) {
          this.msg.nativeElement.classList.remove('hidden');
          if (this.msgText !== undefined) {
            this.msgText.nativeElement.style.color = 'red';
            this.msgText.nativeElement.setAttribute('tabindex', '0');
            this.msgText.nativeElement.focus();
          }
        }
      }
    },
    error => {
      this.logger.debug('Error while submitting forgot password');
    }
    );
  }
}

