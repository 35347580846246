import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from '../../_services/http.service';
import { UserService } from '../../_services/user.service';
import { pageConst} from '../../../assets/config/config';
import { NGXLogger } from 'ngx-logger';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {

  resVal = [];
  resKeys: any;
  data = [];
  @Input() contTileLabel: string;
  public constVal = pageConst;
  public progContactImgData = [];

  constructor(private httpService: HttpService, private userDet: UserService,
    private logger: NGXLogger, private sanitizer: DomSanitizer, private router: Router) {
    httpService.getProgContact(this.userDet.getScreenId(), this.userDet.getRoleId()).subscribe(
      (data: any) => {
        this.resVal = Object.values(data.programContactsResponse);
        this.resKeys = Object.keys(data.programContactsResponse);
        this.data = data;
        this.loadImages();
      }
    );
  }

  ngOnInit() {
  }

  /** function to load images into list */
  loadImages() {
    this.resVal.forEach((imgObj, index) => {
      if (index < 3) {
        if (imgObj.catImageExists) {
          this.httpService.getImage(imgObj.categoryId, this.userDet.getScreenId(), this.constVal.tileProgramContacts)
          .subscribe(
            (data: any) => {
                const urlCreator = window.URL;
                const imagePath = this.sanitizer.bypassSecurityTrustUrl(
                  urlCreator.createObjectURL(data));
                this.progContactImgData.splice(index, 0, {categoryName: imgObj.categoryName, categoryId: imgObj.categoryId,
                    categoryDesc: this.userDet.pushDescription(imgObj.categoryDesc), catImgPath: imagePath, catImageExists: imgObj.catImageExists});
            },
            (error) => {
                this.logger.debug('error in get screen service call');
                this.progContactImgData.splice(index, 0, {categoryName: imgObj.categoryName, categoryId: imgObj.categoryId,
                  categoryDesc: this.userDet.pushDescription(imgObj.categoryDesc), catImageExists: false });
            }
          );
        } else {
          this.progContactImgData.push({categoryName: imgObj.categoryName, categoryId: imgObj.categoryId,
            categoryDesc: this.userDet.pushDescription(imgObj.categoryDesc), catImageExists: imgObj.catImageExists});
        }
      }
    });
  }

  /** navigate to category page */
  categoryNavigate(catId: number) {
    this.router.navigate(['/programContacts/programContactsDetailedPage', catId]);
  }
  programcntcsportlet() {
    this.router.navigate(['/programContacts']);
  }
}
