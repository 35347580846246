import { Component, OnInit, HostListener } from '@angular/core';
import { HttpService } from '../../_services/http.service';
import * as configlist from '../../../assets/config/config';
import * as auditmessageslist from '../../../assets/config/auditmessages';
import * as PageSer from '../../../assets/config/pageConfig';
import { Router, NavigationEnd } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { filter, first } from 'rxjs/operators';
import { FormsModule } from '@angular/forms';
import { UserService } from '../../_services/user.service';
import { CONFIGUREPORTALREQUEST } from '../../_models/ConfigurePortal/configureportalrequest';
import { USERACTIVITYLOG } from '../../_models/UserActivityTrack/useractivitylog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-faqpage',
    templateUrl: './faqpage.component.html',
    styleUrls: ['./faqpage.component.scss'],
    providers: [FormsModule]
})
export class FaqpageComponent implements OnInit {

    private allItems: any[];
    // pager object
    pager: any = {};
    // paged items
    pagedItems: any[];
    resVal = [];
    resKeys: any;
    data = [];
    data1: any;
    faqData: any;
    navigationSubscription: any;
    id: any;
    pageName: string;
    pageTitle: string;
    pageUrl: string;
    setflg = 'x';
    catkeytot = '';
    filteredcat = '';
    searchList = [];
    faqobj = [];
    Slen = '';
    srflag = false;
    filteredguidelineList: any;
    catkeys = [];
    faqVal = [];
    ClientName = '';
    sid = '';
    sDesc = '';
    catId = false;
    public samp: any;
    jKeys = '';
    faqDetailObj: any;
    categoryName = '';
    LineItem = false;
    searchEnabled = false;
    prgrmTileLabel: string;
    contactsTileLabel: string;
    newsTileLabel: string;
    faqTileLabel: string;
    userHelpLabel: string;
    priorAuthLabel: string;
    appLinkslabel: string;
    pageNumber: number;
    m: number;
    n: number;
    homeTitleLabel: string;
    public finalData = [];
    eventTargetId = '';
    accessedMessage = '';
    screenId: Number;
    public userActivityTrackRequest: CONFIGUREPORTALREQUEST;
    public userAccessedLog: USERACTIVITYLOG;
    public activityPortletId = 0;
    public activityPortletName = '';
    public applications: any;
    public isTargetIdExist = false;
    public pageFullUrl: string;

    constructor(private httpService: HttpService, private router: Router,
        private userDet: UserService, private logger: NGXLogger, private sanitizer: DomSanitizer) {
        // Code Change 1 Begins
        this.navigationSubscription = this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd))
            .subscribe(() => {
                let root = this.router.routerState.snapshot.root;
                this.httpService.get_Screen(this.userDet.getScreenId(), this.userDet.getRoleId())
                    .pipe(first())
                    .subscribe(
                        (data1: any) => {
                            this.prgrmTileLabel = data1.piLabel;
                            this.contactsTileLabel = data1.pcLabel;
                            this.newsTileLabel = data1.naLabel;
                            this.faqTileLabel = data1.faqLabel;
                            this.userHelpLabel = data1.userHelpLabel;
                            this.priorAuthLabel = data1.paLabel;
                            this.appLinkslabel = configlist.pageConst.tileApplicationLinksName;
                            this.activityPortletName = '';
                            while (root) {
                                if (root.children && root.children.length) {
                                    root = root.children[0];
                                } else if (root.data && root.data['title']) {
                                    this.pageTitle = root.data['title'];
                                    if (this.pageTitle === 'FAQ') {
                                        this.pageName = this.faqTileLabel;
                                        this.activityPortletId = configlist.pageConst.tileFAQ;
                                        this.activityPortletName = configlist.pageConst.tileFAQName;
                                    } else if (this.pageTitle === 'Program Information') {
                                        this.pageName = this.prgrmTileLabel;
                                        this.activityPortletId = configlist.pageConst.tileProgramInfo;
                                        this.activityPortletName = configlist.pageConst.tileProgramInfoName;
                                    } else if (this.pageTitle === 'Program Contacts') {
                                        this.pageName = this.contactsTileLabel;
                                        this.activityPortletId = configlist.pageConst.tileProgramContacts;
                                        this.activityPortletName = configlist.pageConst.tileProgramContactsName;
                                    } else if (this.pageTitle === 'News & Alerts') {
                                        this.pageName = this.newsTileLabel;
                                        this.activityPortletId = configlist.pageConst.tileNewsAndAlerts;
                                        this.activityPortletName = configlist.pageConst.tileNewsAndAlertsName;
                                    } else if (this.pageTitle === 'User Help') {
                                        this.pageName = this.userHelpLabel;
                                        this.activityPortletId = configlist.pageConst.tileUserHelp;
                                        this.activityPortletName = configlist.pageConst.tileUserHelpName;
                                    }  else if (this.pageTitle === 'Prior Authorization') {
                                        this.pageName = this.priorAuthLabel;
                                        this.activityPortletId = configlist.pageConst.tilePriorAuth;
                                        this.activityPortletName = configlist.pageConst.tilePriorAuthName;
                                    }  else if (this.pageTitle === 'Application Links') {
                                        this.pageName = this.appLinkslabel;
                                        this.activityPortletId = configlist.pageConst.tileApplicationLinks;
                                        this.activityPortletName = configlist.pageConst.tileApplicationLinksName;
                                    }
                                    return;
                                } else {
                                    return;
                                }
                            }
                        },
                        (error) => {
                            this.logger.debug('error in get screen service call');
                        });
                // Code Change 1 Ends
                while (root) {
                    if (root.children && root.children.length) {
                        root = root.children[0];
                    } else if (root.data && root.data['title']) {
                        this.pageTitle = root.data['title'];
                        localStorage.setItem('name', root.data['title']);
                        console.log(localStorage.getItem('name'));

                        if (this.pageTitle === 'FAQ') {
                            // alert('hi');
                            this.pageUrl = 'faqdetailedpage';
                            this.pageFullUrl = '/faqpage/faqdetailedpage';
                            httpService.getJSON(configlist.faqUrl, this.userDet.getRoleId(), this.userDet.getScreenId()).subscribe(
                                (data: any) => {
                                    data.faqResponse.forEach(obj => {
                                        obj.categoryDesc = this.userDet.pushDescription(obj.categoryDesc);
                                    });
                                    this.resVal = Object.values(data.faqResponse);
                                    // this.resKeys = Object.keys(data.faqResponse);
                                    this.resKeys = this.resVal.map(catList => catList.categoryName);
                                    this.faqobj = data.faqResponse;
                                    this.pageName = this.faqTileLabel;
                                    this.data = data;
                                    this.allItems = this.resKeys;
                                    this.setPage(1);
                                    // console.log(this.allItems.length);
                                    // Data prepared for search results
                                    this.resVal.forEach((data1, i) => {
                                        data1.infoItemList.forEach((data2, index) => {
                                            data2.answer = this.getTextAndModify(data2.answer);
                                        this.finalData.push({question: data2.question, answer: data2.answer,
                                            categoryName: data2.categoryName, categoryId: data1.categoryId});
                                      });
                                    });
                                }
                            );
                        } else if (this.pageTitle === 'Program Information') {
                            this.pageUrl = 'programInfoDetailedPage';
                            this.pageFullUrl = '/programInfo/programInfoDetailedPage';
                            httpService.getJSONPost(configlist.prgmUrl, this.userDet.getRoleId(), this.userDet.getScreenId()).subscribe(
                                (data: any) => {
                                    // console.log(data.categoryName);
                                    data.programInfoResponse.forEach(obj => {
                                        obj.categoryDesc = this.userDet.pushDescription(obj.categoryDesc);
                                    });
                                    this.resVal = Object.values(data.programInfoResponse);
                                    // this.resKeys = Object.keys(data.programInfoResponse);
                                    this.resKeys = this.resVal.map(catList => catList.categoryName);
                                    this.faqobj = data.programInfoResponse;
                                    this.pageName = this.prgrmTileLabel;
                                    this.data = data;
                                    this.allItems = this.resKeys;
                                    this.setPage(1);
                                    // console.log(this.allItems.length);
                                    // Data prepared for search results
                                    this.resVal.forEach((data1, i) => {
                                        data1.infoItemList.forEach((data2, index) => {
                                            data2.information = this.getTextAndModify(data2.information);
                                        this.finalData.push({ information: data2.information,
                                             categoryName: data2.categoryName, categoryId: data1.categoryId});
                                      });
                                    });

                                }
                            );
                        } else if (this.pageTitle === 'Program Contacts') {
                            this.pageUrl = 'programContactsDetailedPage';
                            this.pageFullUrl = '/programContacts/programContactsDetailedPage';
                            httpService.getJSONPost(configlist.contactUrl, this.userDet.getRoleId(), this.userDet.getScreenId()).subscribe(
                                (data: any) => {
                                    // console.log(data.categoryName);
                                    data.programContactsResponse.forEach(obj => {
                                        obj.categoryDesc = this.userDet.pushDescription(obj.categoryDesc);
                                    });
                                    this.resVal = Object.values(data.programContactsResponse);
                                    // this.resKeys = Object.keys(data.programContactsResponse);
                                    this.resKeys = this.resVal.map(catList => catList.categoryName);
                                    this.pageName = this.contactsTileLabel;
                                    this.faqobj = data.programContactsResponse;
                                    this.data = data;
                                    this.allItems = this.resKeys;
                                    this.setPage(1);
                                    // Data prepared for search results
                                    this.resVal.forEach((data1, i) => {
                                        data1.contactItemList.forEach((data2, index) => {
                                        this.finalData.push({ contactName: data2.contactName, phnNumber: data2.phnNumber,
                                            address: data2.address, emailId: data2.emailId, categoryName: data2.categoryName,
                                            categoryId: data1.categoryId});
                                      });
                                    });
                                }
                            );
                        } else if (this.pageTitle === 'News & Alerts') {
                            this.pageUrl = 'newsAlertsDetailedPage';
                            this.pageFullUrl = '/newsAlerts/newsAlertsDetailedPage';
                            httpService.getJSONPost(configlist.newsUrl, this.userDet.getRoleId(), this.userDet.getScreenId()).subscribe(
                                (data: any) => {
                                    // console.log(data.categoryName);
                                    data.newsAlertsResponse.forEach(obj => {
                                        obj.categoryDesc = this.userDet.pushDescription(obj.categoryDesc);
                                    });
                                    this.resVal = Object.values(data.newsAlertsResponse);
                                    // this.resKeys = Object.keys(data.newsAlertsResponse);
                                    this.resKeys = this.resVal.map(catList => catList.categoryName);
                                    this.pageName = this.newsTileLabel;
                                    this.faqobj = data.newsAlertsResponse;
                                    this.data = data;
                                    this.allItems = this.resKeys;
                                    this.setPage(1);
                                    // console.log(this.allItems.length);
                                    // Data prepared for search results
                                    this.resVal.forEach((data1, i) => {
                                        data1.newsAlertsItemList.forEach((data2, index) => {
                                            data2.news = this.getTextAndModify(data2.news);
                                        this.finalData.push({ news: data2.news,
                                             categoryName: data2.categoryName, categoryId: data1.categoryId});
                                      });
                                    });
                                }
                            );
                        } else if (this.pageTitle === 'User Help') {
                            this.pageUrl = 'userHelpDetailedpage';
                            this.pageFullUrl = '/userHelp/userHelpDetailedpage';
                            httpService.getJSONPost(configlist.userHelpUrl, this.userDet.getRoleId(), this.userDet.getScreenId()).subscribe(
                                (data: any) => {
                                    // console.log(data.categoryName);
                                    data.userHelpResponse.forEach(obj => {
                                        obj.categoryDesc = this.userDet.pushDescription(obj.categoryDesc);
                                    });
                                    this.resVal = Object.values(data.userHelpResponse);
                                    // this.resKeys = Object.keys(data.userHelpResponse);
                                    this.resKeys = this.resVal.map(catList => catList.categoryName);
                                    this.pageName = this.userHelpLabel;
                                    this.faqobj = data.userHelpResponse;
                                    this.data = data;
                                    this.allItems = this.resKeys;
                                    this.setPage(1);
                                    // console.log(this.allItems.length);
                                     // Data prepared for search results
                                     this.resVal.forEach((data1, i) => {
                                        data1.uhItemList.forEach((data2, index) => {
                                            data2.helpInformation = this.getTextAndModify(data2.helpInformation);
                                        this.finalData.push({ helpInformation: data2.helpInformation,
                                             categoryName: data2.categoryName, categoryId: data1.categoryId});
                                      });
                                    });
                                }
                            );
                        } else if (this.pageTitle === 'Prior Authorization') {
                            this.pageUrl = 'priorAuthDetailedPage';
                            this.pageFullUrl = '/priorAuth/priorAuthDetailedPage';
                            httpService.getJSON(configlist.priorAuthUrl, this.userDet.getRoleId(), this.userDet.getScreenId()).subscribe(
                                (data: any) => {
                                    data.priorAuthResponse.forEach((obj) => {
                                        let url = this.pushUrl(obj.categoryDesc);
                                        obj.categoryDesc = this.pushDescription(url, obj.categoryDesc);
                                    });
                                    let priorAuthData = Object.values(data.priorAuthResponse);
                                    httpService.getAppLinks(this.userDet.getScreenName()).subscribe((appLinks: any) => {
                                        appLinks.applicationResponse.forEach((obj) => {
                                            let url = this.pushUrl(obj.categoryDesc);
                                            obj.categoryDesc = this.pushDescription(url, obj.categoryDesc);
                                            obj.appMgntItemList[0].applicationTextUrl = obj.categoryDesc;
                                        });
                                        this.applications = Object.values(appLinks.applicationResponse);
                                        // alert(JSON.stringify(this.applications));
                                        // if (applications) {
                                        //     applications = applications.map((app) => {
                                        //         return destructuring(app);
                                        //     })
                                        // }
                                        if(this.applications.find(obj => obj.categoryName === 'WebPA')) {
                                            this.resVal = priorAuthData;
                                            this.resVal.forEach((data1, i) => {
                                                data1.priorAuthItemList.forEach((data2, index) => {
                                                    data2.priorAuthInfo = this.getTextAndModify(data2.priorAuthInfo);
                                                    this.finalData.push({
                                                        priorAuthInfo: data2.priorAuthInfo,
                                                        categoryName: data2.categoryName, categoryId: data1.categoryId
                                                    });
                                                });
                                            });
                                            this.resVal = [...priorAuthData, ...this.applications];
                                            this.faqobj = [...data.priorAuthResponse , ...appLinks.applicationResponse];
                                        } else{
                                            this.resVal = this.applications;
                                            this.faqobj = appLinks.applicationResponse;
                                        }                                        
                                        this.pageName = this.priorAuthLabel;
                                        this.data = data;
                                        
                                        this.applications.forEach((data1, i) => {
                                            data1.appMgntItemList.forEach((data2, index) => {
                                                data2.priorAuthInfo = this.getTextAndModify(data2.applicationTextUrl);
                                                this.finalData.push({
                                                    priorAuthInfo: data2.priorAuthInfo, paId: data2.applicationId,
                                                    categoryName: data2.categoryName, categoryId: data1.categoryId
                                                });
                                            });
                                        });
                                        
                                        this.resKeys = this.resVal.map(catList => catList.categoryName);
                                        this.allItems = this.resKeys;
                                        this.setPage(1);
                                        console.log(this.finalData);
                                    });
                                }
                            );
                        } else if (this.pageTitle === 'Application Links') {
                            this.pageUrl = 'applicationLinksDetailedPage';
                            this.pageFullUrl = '/applicationLinks/applicationLinksDetailedPage';
                            httpService.getAppLinks(this.userDet.getScreenName()).subscribe(
                                (data: any) => {
                                    // console.log(data.categoryName);
                                    data.applicationResponse.forEach(obj => {
                                        let url = this.pushUrl(obj.categoryDesc);
                                        obj.categoryDesc = this.pushDescription(url,obj.categoryDesc);
                                    });
                                    this.resVal = Object.values(data.applicationResponse);
                                    console.log(data.applicationResponse);
                                    // this.resKeys = Object.keys(data.programInfoResponse);
                                    this.resKeys = this.resVal.map(catList => catList.categoryName);
                                    this.faqobj = data.applicationResponse;
                                    this.pageName = this.appLinkslabel;
                                    this.data = data;
                                    this.allItems = this.resKeys;
                                    this.setPage(1);
                                    // console.log(this.allItems.length);
                                    // Data prepared for search results
                                    this.resVal.forEach((data1, i) => {
                                        data1.appMgntItemList.forEach((data2, index) => {
                                            data2.applicationTextUrl = this.getTextAndModify(data2.applicationTextUrl);
                                        this.finalData.push({ applicationTextUrl: data2.applicationTextUrl,
                                             categoryName: data2.categoryName, categoryId: data1.categoryId});
                                      });
                                    });
                                }
                            );
                        }

                        return;
                    } else {
                        return;
                    }
                }
            });
        //   alert(this.pageTitle)
    }

    ngOnInit() {
        document.getElementById('faqheader').setAttribute('tabindex', '-1');
        document.getElementById('faqheader').focus();
        this.homeTitleLabel = configlist.pageConst.Home;
    }

    /**
     * On Mouse Down in a entire page
     * event is triggered
     */
    @HostListener('mousedown', ['$event'])
    onMouseDown(event: any) {
        // Mouse Down event Consts
        this.eventTargetId = '';
        this.accessedMessage = '';
        this.screenId = this.userDet.getScreenId();

        const elemId = this.getClosest(event.target); // get event element Id
        this.eventTargetId = elemId;
        if (this.eventTargetId !== undefined) {
            if (!this.isTargetIdExist && event.target.href !== undefined && event.target.href !== '') {
                if (this.eventTargetId.indexOf('cat_info') !== -1) {
                    this.accessedMessage =  configlist.pageConst.categoryList + ' - ' +
                                            this.activityPortletName + ' - ' +  configlist.pageConst.categoryName + ' link : ' +
                                            event.target.href;
                  }
            } else {
                if (this.eventTargetId.indexOf('qam') !== -1) { // If event Id has Quick Access Menu Category
                    this.accessedMessage =  configlist.pageConst.categoryList + ' - ' + configlist.pageConst.QAMName + ' - ' +
                    this.activityPortletName + ' - ' +  configlist.pageConst.categoryName + ' : ' + event.target.innerText;
                } else if ( elemId.indexOf('cat_info') !== -1) {
                    // When Event Element Id is Cateogory Information or Cateogory Description or Read More Link
                    this.eventTargetId =  elemId;
                    const splitElementIdArray = this.eventTargetId.split('_');
                    if (this.eventTargetId.indexOf('name') !== -1) {
                    this.accessedMessage =  configlist.pageConst.categoryList + ' - ' +
                                            this.activityPortletName + ' - ' +  configlist.pageConst.categoryName + ' : ' +
                                            event.target.innerText;
                    } else {
                    this.accessedMessage =  configlist.pageConst.categoryList + ' - ' +
                                            this.activityPortletName + ' - ' + configlist.pageConst.categoryName + ' : ' +
                                            this.userDet.getInnertext(splitElementIdArray[0] + '_' + splitElementIdArray[1]
                                            + '_name_' + splitElementIdArray[splitElementIdArray.length - 1]);
                    }
                } else if (this.eventTargetId.indexOf('page-') !== -1) {// If event Id has pagination
                    this.accessedMessage =  configlist.pageConst.categoryList + ' - ' +
                                            this.activityPortletName + ' - ' + auditmessageslist.auditMessages.auditPagination;
                } else if (this.eventTargetId.indexOf('home-') !== -1) {// If event Id has Home Link
                    this.accessedMessage =  configlist.pageConst.categoryList + ' - ' +
                    this.activityPortletName + ' - ' + auditmessageslist.auditMessages.auditHomePage;
                } else if (this.eventTargetId.indexOf('search_content_value_') !== -1) {// If event Id has Searched Items
                    this.accessedMessage =  configlist.pageConst.categoryList + ' - ' +
                                        this.activityPortletName + ' - ' + auditmessageslist.auditMessages.commonAccessed + ' ' +
                                            this.userDet.getInnertext(this.eventTargetId) + ' ' +
                                            auditmessageslist.auditMessages.commonSearched + ' ' +
                                            this.ClientName;
                } else if ( this.eventTargetId.indexOf('header-main') !== -1 ||
                            this.eventTargetId.indexOf('navigateHome') !== -1 ||
                            this.eventTargetId.indexOf('pos') !== -1 ||
                            this.eventTargetId.indexOf('clientName') !== -1 ||
                            this.eventTargetId.indexOf('loginPanel') !== -1 ||
                            this.eventTargetId.indexOf('loggedinPanel') !== -1) {// If event Id has Header Id's
                    this.activityPortletId = configlist.pageConst.NAPortletId;
                    this.accessedMessage =  configlist.pageConst.categoryList + ' - ' +
                                            auditmessageslist.auditMessages.commonClicked + ' ' +
                                                            auditmessageslist.auditMessages.commonHeader;
                } else if (this.eventTargetId.indexOf('eulaPopUp') !== -1) {
                    this.activityPortletId = configlist.pageConst.NAPortletId;
                    this.accessedMessage =  configlist.pageConst.categoryList + ' - ' +
                                            auditmessageslist.auditMessages.commonClicked + ' ' +
                                            auditmessageslist.auditMessages.eulaFooter;
                } else if (this.eventTargetId.indexOf('securityPolicyPopUp') !== -1) {
                    this.activityPortletId = configlist.pageConst.NAPortletId;
                    this.accessedMessage =  configlist.pageConst.categoryList + ' ' +
                                            auditmessageslist.auditMessages.commonClicked + ' ' +
                                            auditmessageslist.auditMessages.securityPolicyFooter;
                } else if (this.eventTargetId.indexOf('securityDisclaimerPopUp') !== -1) {
                    this.activityPortletId = configlist.pageConst.NAPortletId;
                    this.accessedMessage =  configlist.pageConst.categoryList + ' - ' +
                                            auditmessageslist.auditMessages.commonClicked + ' ' +
                                            auditmessageslist.auditMessages.disclaimerFooter;
                } else if (this.eventTargetId.indexOf('contactUsPopUp') !== -1) {
                    this.activityPortletId = configlist.pageConst.NAPortletId;
                    this.accessedMessage =  configlist.pageConst.categoryList + ' - ' +
                                            auditmessageslist.auditMessages.commonClicked + ' ' +
                                            auditmessageslist.auditMessages.contactUsFooter;
                }
            }
        }

        if (this.accessedMessage !== undefined &&
            this.accessedMessage !== '' &&
            this.accessedMessage !== '\n') {// If Accessed Message Exists
                // Service Request Preparation
            this.userActivityTrackRequest = new CONFIGUREPORTALREQUEST();
            this.userAccessedLog = new USERACTIVITYLOG();
            this.userActivityTrackRequest = this.userDet.getConfigPortalReq(this.screenId, this.activityPortletId);
            if (this.userActivityTrackRequest.updatedBy === 'null') {
                this.userActivityTrackRequest.updatedBy = configlist.pageConst.publicIP;
            }
            this.userActivityTrackRequest.activityCode = configlist.activityCode.userActivityLog;

            if (this.eventTargetId.indexOf('_') !== -1) {
                this.userAccessedLog.accessedMessageId = this.getMessageId(this.eventTargetId);
            } else {
                this.userAccessedLog.accessedMessageId = configlist.pageConst.NotApplicable;
            }
            if (this.activityPortletId === configlist.pageConst.NAPortletId) {
                this.userAccessedLog.isCategory = configlist.pageConst.NotApplicable;
            } else {
                this.userAccessedLog.isCategory = configlist.pageConst.constYes;
            }
            this.userAccessedLog.accessedMessage = this.accessedMessage;
            this.userActivityTrackRequest.userAccessedLog = this.userAccessedLog;
            // Sending request to Service
            this.httpService.updateUserAccessedActivity(this.userActivityTrackRequest).subscribe(
              data => {
              },
              error => {
                console.log(error);
              }
            );
          }
    }

    /**
     * Get Accessed Element Id Number for Rest Call
     */
    getMessageId(elementId) {
        const splitElementIdArray = elementId.split('_');
        return splitElementIdArray[splitElementIdArray.length - 1];
    }

    /**
   * To Get Parent Element Id
   */
  getClosest(elem: any) {
    if (elem.id !== undefined && elem.id !== '') {
      this.isTargetIdExist = true;
      return elem.id;
    } else {
      this.isTargetIdExist = false;
    }
    for ( ; elem && elem !== document; elem = elem.parentNode ) {
      if (elem.id !== undefined && elem.id !== '') {
        return elem.id;
      }
    }
  }

    /**   Navigate Home based on role  Publicfacing, Client & Provider */
    navigateHome() {
      this.userDet.homeNavigation();
    }
    getTextAndModify(itemObj: any) {
        if (itemObj !== undefined && itemObj !== null) {
          document.getElementById('tempInfoHTML').innerHTML = itemObj;
          let textContent = '';
          textContent = document.getElementById('tempInfoHTML').innerText;
          return textContent;
        } else {
          return '';
        }
      }
    setPage(page: number) {
        // get pager object from service
        // this.pager = PageSer.PagerService.getPager(this.allItems.length, page, 6);
        this.pageNumber = 1;

        // get current page of items
        this.pagedItems = [...this.allItems];
        this.loadImages();
    }
    /** function to load images into list */
    loadImages() {
        if (this.pageTitle === 'Application Links') {
            this.getApplicationImages();
        } else {
            this.pagedItems.forEach((items) => {
                this.resVal.forEach((imgObj, index) => {
                    if (imgObj.categoryName === items) {
                        if (imgObj.catImageExists) {
                            if (imgObj.appMgntItemList) {
                                // this.getApplicationImages();
                                this.httpService.getAppImage(imgObj.categoryId)
                            .subscribe(
                                (data: any) => {
                                    const urlCreator = window.URL;
                                    const imagePath = this.sanitizer.bypassSecurityTrustUrl(
                                        urlCreator.createObjectURL(data));
                                    // this.pagedItems.splice(index,0,{categoryName: imgObj.categoryName, categoryId: imgObj.categoryId,
                                    //    categoryDesc: imgObj.categoryDesc, catImgPath: imagePath, catImageExists: imgObj.catImageExists});
                                    this.resVal[index].catImgPath = imagePath;
                                },
                                (error) => {
                                    let defaultPath = null;
                                    defaultPath = 'assets/images/app.png';
                                    const imagePath = this.sanitizer.bypassSecurityTrustUrl(defaultPath);
                                    this.resVal[index].catImgPath = imagePath;
                                    this.logger.debug('error in get screen service call');
                                }
                            );
                            } else {
                                this.httpService.getImage(imgObj.categoryId, this.userDet.getScreenId(), this.activityPortletId)
                                    .subscribe(
                                        (data: any) => {
                                            const urlCreator = window.URL;
                                            const imagePath = this.sanitizer.bypassSecurityTrustUrl(
                                                urlCreator.createObjectURL(data));
                                                this.resVal[index].catImgPath = imagePath;
                                        },
                                        (error) => {
                                            let defaultPath = null;
                                            if (this.pageTitle === 'FAQ') {
                                                defaultPath = 'assets/images/faq.jpg';
                                            } else if (this.pageTitle === 'Program Information') {
                                                defaultPath = 'assets/images/program.jpg';
                                            } else if (this.pageTitle === 'Program Contacts') {
                                                defaultPath = 'assets/images/contact.jpg';
                                            } else if (this.pageTitle === 'News & Alerts') {
                                                defaultPath = 'assets/images/news.jpg';
                                            } else if (this.pageTitle === 'User Help') {
                                                defaultPath = 'assets/images/userhelp.jpg';
                                            }
                                            const imagePath = this.sanitizer.bypassSecurityTrustUrl(defaultPath);
                                            this.resVal[index].catImgPath = imagePath;
                                            this.logger.debug('error in get screen service call');
                                        }
                                    );
                            }

                        }
                    }
                });
            });
        }
    }

    getApplicationImages() {
        this.pagedItems.forEach((items) => {
            this.resVal.forEach((imgObj, index) => {
                if (imgObj.categoryName === items) {
                    if (imgObj.catImageExists) {
                        this.httpService.getAppImage(imgObj.categoryId)
                            .subscribe(
                                (data: any) => {
                                    const urlCreator = window.URL;
                                    const imagePath = this.sanitizer.bypassSecurityTrustUrl(
                                        urlCreator.createObjectURL(data));
                                    // this.pagedItems.splice(index,0,{categoryName: imgObj.categoryName, categoryId: imgObj.categoryId,
                                    //    categoryDesc: imgObj.categoryDesc, catImgPath: imagePath, catImageExists: imgObj.catImageExists});
                                    this.resVal[index].catImgPath = imagePath;
                                },
                                (error) => {
                                    let defaultPath = null;
                                    defaultPath = 'assets/images/app.png';
                                    const imagePath = this.sanitizer.bypassSecurityTrustUrl(defaultPath);
                                    this.resVal[index].catImgPath = imagePath;
                                    this.logger.debug('error in get screen service call');
                                }
                            );
                    }
                }
            });
        });
    }

    showCategory(i: number, j: number) {
        this.m = i + (this.pageNumber - 1) * 6;
        this.n = j + (this.pageNumber - 1) * 6;

        if (this.pageNumber === 1 && i === j) {
            return true;
        } else if (this.pageNumber > 1 && this.n === (this.m + (this.pageNumber - 1) * 6)) {
            return true;
        }
        return false;
    }

    reload() {
        // this.router.routeReuseStrategy.shouldReuseRoute = function () {
        //     return true;
        //   };
        this.setPage(this.pager.currentPage);
        this.searchList = [];
        this.catId = false;
        this.categoryName = '';
        this.searchEnabled = false;
        this.ClientName = '';
    }

    // Push a search term into the observable stream.
    searchClient(): void {
        // alert('hi');
        let terms = this.ClientName;
        while (terms.indexOf('  ') !== -1) {
            terms = terms.replace(/  /g, ' ');
        }
        const term = terms.toLowerCase();
        const tlen = term;
        // alert(searchterm);
        if (this.Slen === '') {
            this.Slen = term;
        } if (this.Slen !== tlen) {
            this.searchList = [];
            if (this.LineItem) {
                this.catId = true;
            } else {
                this.catId = false;
            }
        }

        if (!term) {
            this.setPage(this.pager.currentPage);
            this.searchList = [];
            this.catId = false;
            this.categoryName = '';
            this.searchEnabled = false;
        }
        this.srflag = term ? true : false;

        this.traverse_data(this.faqobj, term);

    }

    onselectClient(selectedObj) {
        // alert(selectedObj);
        let startindex = 0;
        let endindex = 0;
        let seletedCat = '';
        let selectedLineItem = '';

        this.searchEnabled = true;
        if (selectedObj.indexOf('[') !== -1) {
            startindex = selectedObj.indexOf('[');
            endindex = selectedObj.indexOf(']');
            seletedCat = selectedObj.substring(startindex + 1, endindex);
            selectedLineItem = selectedObj.substring(0, startindex - 1);
            this.pagedItems = [];
            this.pagedItems.push(seletedCat);
            this.catId = true;
            this.LineItem = true;
            this.fetchdata(seletedCat, selectedLineItem);
        } else {
            this.pagedItems = [];
            this.pagedItems.push(selectedObj);
            this.catId = true;
            this.LineItem = false;
            this.fetchdata(selectedObj, 'NolineItem');
        }
        // alert(selectedObj);
    }

    traverse_data(obj, sTrem) {

        // tslint:disable-next-line:forin
        for (const prop in obj) {
            // this.catkeys = Object.values(prop);
            // this.catkeys.forEach((k) => {
            //     this.catkeytot += k;
            // });

            // this.filteredguidelineList = this.resKeys.filter((el) => {
            //     return el.toLowerCase().indexOf(this.catkeytot.toLowerCase()) > -1;
            // });
            // tslint:disable-next-line:forin
            // this.resKeys.forEach((data, index) => {
            //     if (this.resKeys[keys].toLowerCase() === prop[index].toLowerCase()) {

            //     }
            //   });
            for (const keys in this.resKeys) {
                if (this.resKeys[keys].toLowerCase() === obj[keys].categoryName) {
                    // alert(this.resKeys[keys]+' = '+prop );
                    this.filteredguidelineList = [];
                    this.filteredguidelineList.push(obj[keys].categoryName);
                }
            }

            if (this.filteredguidelineList.length > 0) {
                this.filteredcat = this.filteredguidelineList[0];
            }
            this.searchList = [];
            if (typeof obj[prop] === 'object') {
                // object
                // tslint:disable-next-line:radix
                this.traverse_data(obj[prop], sTrem);
                this.catkeytot = '';
            } else {
                const ke = obj[prop].toString().toLowerCase();
                const searchData = obj[prop].toString();
                if (ke.includes(sTrem) && sTrem.length > 2 && this.pageTitle !== 'Program Contacts') {
                    const pos = ke.indexOf(sTrem);
                    // this.searchList = [];
                    if (this.filteredcat !== null || this.filteredcat !== '' || this.filteredcat) {
                        this.searchList.push(this.filteredcat + '');
                    }
                    if (searchData !== this.filteredcat) {
                        if (pos < 10) {
                            this.searchList.push(searchData.substring(0, 90) + ' [' + this.filteredcat + ']');
                        } else {
                            this.searchList.push(searchData.substring((pos - 45), (pos + 55)) + ' [' + this.filteredcat + ']');
                        }
                    }
                    this.searchList = Array.from(new Set(this.searchList));
                    if (this.searchList[0] === 'No Search results found') {
                        this.searchList = this.searchList.slice(1);
                    }
                    // alert(this.searchList);
                }
                if (ke.includes(sTrem) && sTrem.length > 2 && this.pageTitle === 'Program Contacts') {
                    const pos = ke.indexOf(sTrem);
                    // this.searchList = [];
                    if (this.filteredcat !== null || this.filteredcat !== '' || this.filteredcat) {
                        this.searchList.push(this.filteredcat + '');
                    }
                    if (searchData !== this.filteredcat) {
                        if (pos < 10) {
                            this.searchList.push(searchData.substring(0, 90) + ' [' + this.filteredcat + ']');
                        } else {
                            this.searchList.push(searchData.substring((pos - 45), (pos + 55)) + ' [' + this.filteredcat + ']');
                        }
                    }

                    this.searchList = Array.from(new Set(this.searchList));
                    if (this.searchList[0] === 'No Search results found') {
                        this.searchList = this.searchList.slice(1);
                    }
                    // alert(this.searchList);
                }
            }
            if (this.searchList.length < 1) {
                this.searchList.push('No Search results found');
            }
        }
    }

    getObjectData(obj, sTermCat) {
        // alert('in getobjects');
        // tslint:disable-next-line:forin
        for (const prop in obj) {
            this.jKeys = '';
            this.catkeys = Object.values(prop);
            this.catkeys.forEach((k) => {
                this.catkeytot += k;
                this.jKeys += k;
            });

            this.filteredguidelineList = this.resKeys.filter((el) => {
                return el.toLowerCase().indexOf(this.catkeytot.toLowerCase()) > -1;
            });

            if (this.filteredguidelineList.length > 0) {
                this.filteredcat = this.filteredguidelineList[0];
            }
            if (typeof obj[prop] === 'object') {
                // alert(sTermCat.toLowerCase() + ' - ' + this.filteredcat.toLowerCase() + ' - ' + this.jKeys);
                if (sTermCat.toLowerCase() === this.filteredcat.toLowerCase() &&
                    (this.jKeys === 'infoItemList' || this.jKeys === 'newsAlertsItemList'
                        || this.jKeys === 'contactItemList' || this.jKeys === 'uhItemList' || this.jKeys === 'appMgntItemList')) {
                    this.faqDetailObj = obj[prop];
                    // alert(JSON.stringify(obj[prop]));
                }
                // object
                // tslint:disable-next-line:radix
                this.getObjectData(obj[prop], sTermCat);
                this.catkeytot = '';
            }
        }
    }

    fetchdata(selectedkey, lineItemData) {
        // alert('hi  ' + selectedkey + '  ' + lineItem);
        this.categoryName = selectedkey;
        const SItem = lineItemData.toLowerCase();
        this.getObjectData(this.faqobj, selectedkey);
        for (const sval in this.resVal) {
            if (this.resVal[sval].categoryName === selectedkey && this.pageTitle === 'FAQ') {

                this.sid = this.resVal[sval].categoryId;
                this.sDesc = this.resVal[sval].categoryDesc;

                if (lineItemData !== 'NolineItem') {
                    this.LineItem = true;
                    let flag = true;
                    // alert('has line item '+ this.LineItem +'  '+this.catId );
                    // tslint:disable-next-line:forin
                    for (const sqval in this.faqDetailObj) {
                        const objFaq = this.faqDetailObj[sqval];
                        this.LineItem = true;
                        // alert(typeof(SItem) + ' - ' + typeof(objFaq.question.toLowerCase()));
                        if ((objFaq.question.toLowerCase()).includes(SItem) ||
                            (objFaq.answer.toLowerCase()).includes(SItem)) {
                            this.pagedItems = [];
                            this.pagedItems.push(objFaq);
                            flag = false;
                            // alert('in true'+ sqval);
                            // alert((this.pagedItems).length);
                            // alert('qstn    ' + JSON.stringify(objFaq));
                        } else {
                            if (flag) {
                                this.LineItem = false;
                            } else {
                                this.LineItem = true;
                            }
                        }
                    }
                }
                //  alert(this.sid);
                // this.router.navigate(['faqdetailedpage', this.sid]);
            } else if (this.resVal[sval].categoryName === selectedkey && this.pageTitle === 'Program Information') {
                // alert('hi');
                this.sid = this.resVal[sval].categoryId;
                this.sDesc = this.resVal[sval].categoryDesc;
                if (lineItemData !== 'NolineItem') {
                    let flag = true;
                    // tslint:disable-next-line:forin
                    for (const sqval in this.faqDetailObj) {
                        const objFaq = this.faqDetailObj[sqval];
                        this.LineItem = true;
                        // alert(objFaq.information+'      JIko       '+selectedkey);
                        if ((objFaq.information.toLowerCase()).includes(SItem)) {
                            this.pagedItems = [];
                            this.pagedItems.push(objFaq);
                            flag = false;
                            // alert('ans    '+JSON.stringify(objFaq));
                        } else {
                            if (flag) {
                                this.LineItem = false;
                            } else {
                                this.LineItem = true;
                            }
                        }
                    }
                    //   alert(this.sid);
                    // this.router.navigate(['faqdetailedpage', this.sid]);
                }
            } else if (this.resVal[sval].categoryName === selectedkey && this.pageTitle === 'Program Contacts') {
                // alert(this.catId+'  hi in if elsse  '+ lineItem);
                this.sid = this.resVal[sval].categoryId;
                this.sDesc = this.resVal[sval].categoryDesc;
                if (lineItemData !== 'NolineItem') {
                    this.LineItem = true;
                    let flag = true;
                    // tslint:disable-next-line:forin
                    for (const sqval in this.faqDetailObj) {
                        const objFaq = this.faqDetailObj[sqval];
                        this.LineItem = true;
                        // alert(objFaq.contactName+'      JIko       '+selectedkey);
                        if ((objFaq.contactName.toLowerCase()).includes(SItem) ||
                            (objFaq.emailId.toLowerCase()).includes(SItem) ||
                            ((objFaq.phnNumber).toString().toLowerCase()).includes(SItem)) {
                            this.pagedItems = [];
                            this.pagedItems.push(objFaq);
                            flag = false;
                            // alert('qstn    '+JSON.stringify(objFaq));
                        } else {
                            if (flag) {
                                this.LineItem = false;
                            } else {
                                this.LineItem = true;
                            }
                            // alert('false');
                        }
                    }
                }
                //   alert(this.sid);
                // this.router.navigate(['faqdetailedpage', this.sid]);
            } else if (this.resVal[sval].categoryName === selectedkey && this.pageTitle === 'News & Alerts') {
                // alert('hi');
                this.sid = this.resVal[sval].categoryId;
                this.sDesc = this.resVal[sval].categoryDesc;
                if (lineItemData !== 'NolineItem') {
                    let flag = true;
                    this.LineItem = true;
                    // tslint:disable-next-line:forin
                    for (const sqval in this.faqDetailObj) {
                        const objFaq = this.faqDetailObj[sqval];
                        this.LineItem = true;
                        // alert(objFaq.news+'      JIko       '+selectedkey);
                        if ((objFaq.news.toLowerCase()).includes(SItem)) {
                            this.pagedItems = [];
                            this.pagedItems.push(objFaq);
                            flag = false;
                            // alert('ans    '+JSON.stringify(objFaq));
                        } else {
                            if (flag) {
                                this.LineItem = false;
                            } else {
                                this.LineItem = true;
                            }
                        }
                    }
                    //   alert(this.sid);
                    // this.router.navigate(['faqdetailedpage', this.sid]);
                }
            } else if (this.resVal[sval].categoryName === selectedkey && this.pageTitle === 'User Help') {
                // alert('hi in user help ');
                this.sid = this.resVal[sval].categoryId;
                this.sDesc = this.resVal[sval].categoryDesc;
                if (lineItemData !== 'NolineItem') {
                    this.LineItem = true;
                    let flag = true;
                    // tslint:disable-next-line:forin
                    for (const sqval in this.faqDetailObj) {
                        const objFaq = this.faqDetailObj[sqval];
                        this.LineItem = true;
                        // alert(objFaq.helpInformation.toLowerCase()+'      JIko       '+SItem);
                        // alert('hi vikky  '+(objFaq.helpInformation.toLowerCase()).includes(SItem));
                        if ((objFaq.helpInformation.toLowerCase()).includes(SItem)) {
                            // alert('in if');
                            this.pagedItems = [];
                            this.pagedItems.push(objFaq);
                            flag = false;
                            // alert('ans    '+JSON.stringify(objFaq));
                        } else {
                            if (flag) {
                                this.LineItem = false;
                            } else {
                                this.LineItem = true;
                            }
                        }
                    }
                }
                //   alert(this.sid);
                // this.router.navigate(['faqdetailedpage', this.sid]);
            }else if (this.resVal[sval].categoryName === selectedkey && this.pageTitle === 'Application Links') {
                // alert('hi');
                this.sid = this.resVal[sval].categoryId;
                this.sDesc = this.resVal[sval].categoryDesc;
                if (lineItemData !== 'NolineItem') {
                    let flag = true;
                    // tslint:disable-next-line:forin
                    for (const sqval in this.faqDetailObj) {
                        const objFaq = this.faqDetailObj[sqval];
                        this.LineItem = true;
                        // alert(objFaq.information+'      JIko       '+selectedkey);
                        if ((objFaq.information.toLowerCase()).includes(SItem)) {
                            this.pagedItems = [];
                            this.pagedItems.push(objFaq);
                            flag = false;
                            // alert('ans    '+JSON.stringify(objFaq));
                        } else {
                            if (flag) {
                                this.LineItem = false;
                            } else {
                                this.LineItem = true;
                            }
                        }
                    }
                    //   alert(this.sid);
                    // this.router.navigate(['faqdetailedpage', this.sid]);
                }
            }
            this.searchList = [];
            // this.catId = false;
            document.getElementById('search-result').style.border = 'none';
        }
    }

    getCatImage() {
        const categoryId = 3664;
        this.httpService.getImage(categoryId, this.userDet.getScreenId(), configlist.pageConst.tileProgramInfo)
          .subscribe(
            (data: any) => {
                const urlCreator = window.URL;
                const imagePath = this.sanitizer.bypassSecurityTrustUrl(
                  urlCreator.createObjectURL(data));
                  return imagePath;
            },
            (error) => {
                this.logger.debug('error in get screen service call');
            }
          );
    }

/** To navigate and scroll for an appropriate information item */
    scroll(categoryId, infoId) {
        if (categoryId) {
            this.httpService.sendInformationId(infoId);
            this.router.navigate([this.pageFullUrl, categoryId]);
        }
    }
    
    clickEvent(id, arrowactive) {
        if (arrowactive === 'down') {
            document.getElementById('down_' + id).classList.add('hidden');
            document.getElementById('right_' + id).classList.remove('hidden');
        } else if (arrowactive === 'right') {
            document.getElementById('down_' + id).classList.remove('hidden');
            document.getElementById('right_' + id).classList.add('hidden');
        }
    }
    
    
  /** push Url to the JSON */
  pushUrl(catDesc) {
    let contents = catDesc;

    let snippet = document.createElement("div");
    snippet.innerHTML = contents;
    let links = snippet.getElementsByTagName("a");
    
    if (links.length) {
      return links[0].href;
      // window.open(lastURL, "_blank");
    }
  }

  pushDescription (url,catDesc){
    let snippet = document.createElement("div");
    snippet.innerHTML = catDesc;
    let links = snippet.getElementsByTagName("a");
    let newItem = document.createElement("span");
    let newUrl = "'"+url+"'";
    newItem.innerHTML = '<span tabindex="0" onclick="window.open('+newUrl+',\'_blank\')" class="mouse-over spanColor">'+links[0].innerHTML+'</span>';
    let tnode = links[0].parentNode;
    tnode.replaceChild(newItem, links[0]);
    return this.sanitizer.bypassSecurityTrustHtml(snippet.innerHTML.toString());
  }
      
}
