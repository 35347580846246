// import { ErrorStateMatcher } from '@angular/material';
import {ErrorStateMatcher} from '@angular/material/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';

export class AttemptErrorMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      return control.touched && (form.hasError('attemptLess') || control.hasError('required') || control.hasError('pattern'));
    }
}

export class SessionErrorMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      return control.touched && (form.hasError('sessionLess') || control.hasError('required') || control.hasError('pattern'));
    }
}

export class TimeErrorMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      return control.touched && (form.hasError('timeLess') || control.hasError('required') || control.hasError('pattern'));
    }
}

export class ZipErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.touched && (form.hasError('zipError') || control.hasError('required') || control.hasError('pattern'));
  }
}

export class PasswordErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.touched && (form.hasError('passwordError') || control.hasError('required'));
  }
}

export class OldPasswordErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.touched && (form.hasError('oldPasswordError') || control.hasError('required') || control.hasError('pattern'));
  }
}

export class NewPasswordErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.touched && (form.hasError('pswdUpperCaseError') || form.hasError('pswdLowerCaseError')
     || form.hasError('pswdNumberError') || form.hasError('pswdSpecialCharError')
    || control.hasError('required'));
  }
}

export class CrossFieldErrorMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      return control.touched && form.invalid;
    }
}

export class IntervalErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.touched && (form.hasError('intervalLess') || control.hasError('required') || control.hasError('pattern'));
  }
}

export class EmailErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.touched && (form.hasError('dupEmailError') || control.hasError('required') || control.hasError('pattern'));
  }
}

export class UserIdErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.touched && (form.hasError('dupUserError') || form.hasError('spaceError') || control.hasError('required') || control.hasError('pattern'));
  }
}
