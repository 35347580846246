import { Component, OnInit, Input} from '@angular/core';
import { HttpService } from '../../_services/http.service';
import { UserService } from '../../_services/user.service';
import { pageConst} from '../../../assets/config/config';
import { NGXLogger } from 'ngx-logger';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
resVal  = [];
resKeys: any;
data = [];
@Input() newsTileLabel: string;
public constVal = pageConst;
public progNewsImgData = [];

    constructor(private httpService: HttpService, private userDet: UserService, private logger: NGXLogger,  private sanitizer: DomSanitizer, private router: Router) {
         httpService.getNewsnAlerts(this.userDet.getScreenId(), this.userDet.getRoleId()).subscribe(
            (data: any) => {
                this.resVal = Object.values(data.newsAlertsResponse);
                this.resKeys  = Object.keys(data.newsAlertsResponse);
                this.data = data;
                this.loadImages();
            }
        );
    }

    ngOnInit() {        
    }

    /** function to load images into list */
    loadImages() {
        this.resVal.forEach((imgObj, index) => {
        if (index < 3) {
            if (imgObj.catImageExists) {
            this.httpService.getImage(imgObj.categoryId, this.userDet.getScreenId(), this.constVal.tileNewsAndAlerts)
            .subscribe(
                (data: any) => {
                    const urlCreator = window.URL;
                    const imagePath = this.sanitizer.bypassSecurityTrustUrl(
                    urlCreator.createObjectURL(data));
                    this.progNewsImgData.splice(index,0,{categoryName: imgObj.categoryName, categoryId: imgObj.categoryId,
                        categoryDesc: this.userDet.pushDescription(imgObj.categoryDesc), catImgPath: imagePath, catImageExists: imgObj.catImageExists});
                },
                (error) => {
                    this.logger.debug('error in get screen service call');
                    this.progNewsImgData.splice(index,0,{categoryName: imgObj.categoryName, categoryId: imgObj.categoryId,
                        categoryDesc: this.userDet.pushDescription(imgObj.categoryDesc), catImageExists: false });
                }
            );
            } else {
            this.progNewsImgData.push({categoryName: imgObj.categoryName, categoryId: imgObj.categoryId,
                categoryDesc: this.userDet.pushDescription(imgObj.categoryDesc), catImageExists: imgObj.catImageExists});
            }
        }
        });
    }

    /** navigate to category page */
  categoryNavigate(catId: number) {
    this.router.navigate(['/newsAlerts/newsAlertsDetailedPage', catId]);
  }
  newsalertsportlet() {
    this.router.navigate(['/newsAlerts']);

}
}
