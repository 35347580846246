import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpService } from '../../_services/http.service';
import { HttpResponse} from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { UserService } from '../../_services/user.service';
import { pageConst, errorConst, activityCode } from '../../../assets/config/config';
import * as auditmessagelist from '../../../assets/config/auditmessages';
import {Token} from '../../_models/TOKEN';
import { CONFIGUREPORTALREQUEST } from '../../_models/ConfigurePortal/configureportalrequest';
import { USERACTIVITYLOG } from '../../_models/UserActivityTrack/useractivitylog';
import { KeycloakService } from '../../keycloak/keycloak.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-public-facing',
  templateUrl: './public-facing.component.html',
  styleUrls: ['./public-facing.component.scss']
})
export class PublicFacingComponent implements OnInit {
  model: Token = { username: '', password: '' };
  public pageData: any;
  public constVal = pageConst;
  showPF = false;
  eventTargetId = '';
  accessedMessage = '';
  isCategory = '';
  portletId: Number = pageConst.NAPortletId;
  isTargetIdExist = false;
  screenId: Number;
  public userActivityTrackRequest: CONFIGUREPORTALREQUEST;
  public userAccessedLog: USERACTIVITYLOG;
  public dataLength: any;
  public colMdVaue: any;
  roleId: number;
  portletName = '';
  @ViewChild('formSurveyModal') formSurveyModal: any;
  public formSurveyUrl: string;
  public showAlertMFS = false;


  constructor(private router: Router, private httpService: HttpService, private logger: NGXLogger,
    private userDet: UserService,  private kc: KeycloakService, public sanitizer: DomSanitizer) {
    sessionStorage.setItem('location', window.location.href);
    if (sessionStorage.getItem('logOut') === pageConst.constYes) {
      sessionStorage.setItem('logOut', pageConst.constNo);
      if(sessionStorage.getItem('ssoMode') === pageConst.constYes) {
        console.log("🚀 ~ file: public-facing.component.ts:49 ~ PublicFacingComponent ~ logout: before")
        this.kc.logout();
        console.log("🚀 ~ file: public-facing.component.ts:53 ~ PublicFacingComponent ~ logout: after")
      }
    }
    if ((sessionStorage.getItem('loginRoleId') === null || sessionStorage.getItem('loginRoleId') === '-1') &&
          sessionStorage.getItem('location').indexOf('publicFacing') > -1) {
      
      let tempAgreeClicked = localStorage.getItem('isAgreeClicked');
      let tempScreenInfo = localStorage.getItem('screenInfoData');
      localStorage.clear();
      if(tempScreenInfo != null){
        localStorage.setItem('screenInfoData',tempScreenInfo);
      }
      if(tempAgreeClicked != null){
        localStorage.setItem('isAgreeClicked',tempAgreeClicked);
      }
      sessionStorage.setItem('loginRoleId', '-1');
      sessionStorage.setItem('userRoleId', '-1');
      sessionStorage.setItem('privilegeData', null);
      sessionStorage.setItem('loginScreenId', '1');
      sessionStorage.setItem('isLoggedIn', 'false');
      sessionStorage.setItem('loggedInUserName', null);
    }
    if (!sessionStorage.getItem('loginRoleId') && !sessionStorage.getItem('loginScreenId')) {
      sessionStorage.setItem('loginRoleId', String(this.userDet.getRoleId()));
      sessionStorage.setItem('loginScreenId', String(this.userDet.getScreenId()));
    }
    if (localStorage.getItem('isLoggedIn') === 'true' && sessionStorage.getItem('isLoggedIn') !== 'true') {
      sessionStorage.setItem('isLoggedIn', localStorage.getItem('isLoggedIn'));
      sessionStorage.setItem('loggedInUserName', localStorage.getItem('loggedInUserName'));
      sessionStorage.setItem('loginRoleId', localStorage.getItem('loginRoleId'));
      sessionStorage.setItem('userRoleId', localStorage.getItem('userRoleId'));
      sessionStorage.setItem('privilegeData', localStorage.getItem('privilegeData'));
      sessionStorage.setItem('loginScreenId', localStorage.getItem('loginScreenId'));
      sessionStorage.setItem('loginUserStatus', localStorage.getItem('loginUserStatus'));
      sessionStorage.setItem('logOut', localStorage.getItem('logOut'));
      sessionStorage.setItem('isAgreeClicked', localStorage.getItem('isAgreeClicked'));
      sessionStorage.setItem('addressDetails', localStorage.getItem('addressDetails'));
    }
    if ((sessionStorage.getItem('loginRoleId') === '1' && sessionStorage.getItem('location').indexOf('provider') > -1) ||
        (sessionStorage.getItem('loginRoleId') === '2' && sessionStorage.getItem('location').indexOf('client') > -1)) {
      this.router.navigate(['/unauthorized']);
    }
    window.addEventListener('popstate', function(event) {
      if (sessionStorage.getItem('location').indexOf('publicFacing') > -1 && sessionStorage.getItem('logOut') === 'Y') {
        sessionStorage.setItem('loginRoleId', '-1');
        sessionStorage.setItem('privilegeData', null);
        sessionStorage.setItem('userRoleId', '-1');
        sessionStorage.setItem('loginScreenId', '1');
        sessionStorage.setItem('isLoggedIn', 'false');
        sessionStorage.setItem('loggedInUserName', null);
        sessionStorage.setItem('loggedInFirstName', null);
        this.history.pushState(null, this.document.title, sessionStorage.getItem('location'));
      }
    });
  }

  ngOnInit() {
    this.pageData = {};
    this.pageData.piFlag = this.constVal.constNo;
    this.pageData.pcFlag = this.constVal.constNo;
    this.pageData.naFlag = this.constVal.constNo;
    // this.formSurveyUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
    this.formSurveyUrl = '';
    this.getScreenDetails();
    if (localStorage.getItem('isAgreeClicked') === 'true') {
      document.getElementById('public-facing').classList.remove('blurry');
    }
  }

/**
 * To Get Parent Element Id
 */
  getClosest(elem: any) {
    if (elem.id !== undefined && elem.id !== '') {
      this.isTargetIdExist = true;
      return elem.id;
    } else {
      this.isTargetIdExist = false;
    }
    for ( ; elem && elem !== document; elem = elem.parentNode ) {
      if (elem.id !== undefined && elem.id !== '') {
        return elem.id;
      }
    }
  }

  /**
   * On Mouse Down in a entire page
   * event is triggered
   */
  @HostListener('mousedown', ['$event'])
  onMouseDown(event: any) {
    // Mouse Down event Consts
    this.eventTargetId = '';
    this.portletName = '';
    this.accessedMessage = '';
    this.isCategory = '';
    this.portletId = pageConst.NAPortletId;
    this.screenId = this.userDet.getScreenId();

    const elemId = this.getClosest(event.target); // get event element Id
    if (!this.isTargetIdExist && event.target.href !== undefined && event.target.href !== '') { // Only used for page links
      if ( elemId !== undefined && elemId.indexOf('wlcmsg') !== -1) {
        this.portletId = pageConst.tileWelcomeMessage;
        this.portletName = pageConst.tileWelcomeMessageName;
        this.eventTargetId =  elemId;
        this.accessedMessage =  this.portletName + ' link : ' + event.target.href;
        this.isCategory = pageConst.constNo;
      } else if (elemId !== undefined && elemId.indexOf('cat_info')) {
        const splitElementIdArray = elemId.split('_');
        if (splitElementIdArray[0] === 'pa') {
          this.portletId = pageConst.tilePriorAuth;
          this.portletName = pageConst.tilePriorAuthName;
        } else if (splitElementIdArray[0] === 'al') {
          this.portletId = pageConst.tileApplicationLinks;
          this.portletName = pageConst.tileApplicationLinksName;
        }
        this.eventTargetId =  elemId;
        this.accessedMessage =  this.portletName + ' link : ' + event.target.href;
        this.isCategory = pageConst.constYes;
      }
    } else {
      if  (elemId !== undefined) {
        if (elemId === 'carousel-left-arrow' || elemId === 'carousel-right-arrow' ) {
          // When Event Element Id is Welcome Message Carousel Arrows
          this.portletId = pageConst.tileWelcomeMessage;
          this.portletName = pageConst.tileWelcomeMessageName;
          this.eventTargetId = this.getActiveId(elemId);
          this.accessedMessage =  this.portletName + ' : ' +
                                this.userDet.getInnertext(this.eventTargetId);
          this.isCategory = pageConst.constYes;
        } else if ( elemId.indexOf('wlcmsg') !== -1 ) {
          // When Event Element Id is Welcome Message Content or Carousel Icons
          this.portletId = pageConst.tileWelcomeMessage;
          this.portletName = pageConst.tileWelcomeMessageName;
          this.eventTargetId =  elemId;
          if ( elemId.indexOf('carousel') !== -1 ) { // Accessed Message for Carousel Icons
            const splitElementIdArray = this.eventTargetId.split('_');
            const messageId = splitElementIdArray[0] + '_' + splitElementIdArray[splitElementIdArray.length - 1];
            this.accessedMessage =  this.portletName + ' : ' +
                                     this.userDet.getInnertext(messageId);
          } else { // Accessed Message for Content;
            this.accessedMessage =  this.portletName + ' : ' +
                                    event.target.innerText;
          }
          this.isCategory = pageConst.constNo;
        } else if ( elemId.indexOf('cat_info') !== -1) {
          // When Event Element Id is Cateogory Information or Cateogory Description or Read More Link
          this.eventTargetId =  elemId;
          const splitElementIdArray = this.eventTargetId.split('_');
          if (splitElementIdArray[0] === 'pi') {
            this.portletId = pageConst.tileProgramInfo;
            this.portletName = pageConst.tileProgramInfoName;
          } else if (splitElementIdArray[0] === 'pc') {
            this.portletId = pageConst.tileProgramContacts;
            this.portletName = pageConst.tileProgramContactsName;
          } else if (splitElementIdArray[0] === 'na') {
            this.portletId = pageConst.tileNewsAndAlerts;
            this.portletName = pageConst.tileNewsAndAlertsName;
          } else if (splitElementIdArray[0] === 'pa') {
            this.portletId = pageConst.tilePriorAuth;
            this.portletName = pageConst.tilePriorAuthName;
          } else if (splitElementIdArray[0] === 'al') {
            this.portletId = pageConst.tileApplicationLinks;
            this.portletName = pageConst.tileApplicationLinksName;
          }

          if (splitElementIdArray[0] === 'al' || splitElementIdArray[0] === 'pa') {
            let contents = '';
            if (this.eventTargetId.indexOf('desc') !== -1) {
              // contents = this.userDet.getInnerHTML(this.eventTargetId);
              this.accessedMessage =  this.portletName + ' - ' + pageConst.categoryName
                                      + ' : ' + this.userDet.getInnertext(this.eventTargetId);
            } else {
              contents = this.userDet.getInnerHTML(splitElementIdArray[0] + '_' + splitElementIdArray[1]
              + '_' + splitElementIdArray[2] + '_desc_' +
              splitElementIdArray[splitElementIdArray.length - 1]);
              const snippet = document.createElement('div');
              snippet.innerHTML = contents;
              const links = snippet.getElementsByTagName('a');
              if (links.length) {
                this.accessedMessage =  this.portletName + ' link : ' + links[0].href;
              }
            }
          } else {
            if (this.eventTargetId.indexOf('name') !== -1) {
              this.accessedMessage =  this.portletName + ' - ' + pageConst.categoryName
                                    + ' : ' + event.target.innerText;
            } else {
              this.accessedMessage =  this.portletName + ' - ' + pageConst.categoryName
                                      + ' : ' + this.userDet.getInnertext(splitElementIdArray[0] + '_' + splitElementIdArray[1]
                                      + '_' + splitElementIdArray[2] + '_name_' +
                                      splitElementIdArray[splitElementIdArray.length - 1]);
            }
          }
          this.isCategory = pageConst.constYes;
        }  else if ( elemId.indexOf('tile-link') !== -1) {
          // When Event Element Id is FAQ/User Help Tile link
          this.eventTargetId =  elemId;
          const splitElementIdArray = elemId.split('-');
          let accessedTile = '';
          if (splitElementIdArray[0] === 'pi') {
            this.portletId = pageConst.tileProgramInfo;
            this.portletName = pageConst.tileProgramInfoName;
          } else if (splitElementIdArray[0] === 'pc') {
            this.portletId = pageConst.tileProgramContacts;
            this.portletName = pageConst.tileProgramInfoName;
          } else if (splitElementIdArray[0] === 'na') {
            this.portletId = pageConst.tileNewsAndAlerts;
            this.portletName = pageConst.tileProgramInfoName;
          } else if (splitElementIdArray[0] === 'faq') {
            this.portletId = pageConst.tileFAQ;
            accessedTile = 'FAQ';
            this.portletName = pageConst.tileFAQName;
          } else if (splitElementIdArray[0] === 'uh') {
            this.portletId = pageConst.tileUserHelp;
            accessedTile = 'User Help';
            this.portletName = pageConst.tileUserHelpName;
          } else if (splitElementIdArray[0] === 'pa') {
            this.portletId = pageConst.tilePriorAuth;
            this.portletName = pageConst.tilePriorAuthName;
          } else if (splitElementIdArray[0] === 'al') {
            this.portletId = pageConst.tileApplicationLinks;
            this.portletName = pageConst.tileApplicationLinksName;
          }
          if (this.eventTargetId.indexOf('read-more')) {
            this.accessedMessage =  this.portletName + ' : ' +
                                    auditmessagelist.auditMessages.commonClicked + ' ' +
                                    auditmessagelist.auditMessages.commonReadMore;
          } else {
            this.accessedMessage =  this.portletName + ' : ' +
                                    auditmessagelist.auditMessages.commonClicked + ' ' +
                                    accessedTile + ' ' +
                                    auditmessagelist.auditMessages.commonTile;
          }
          this.isCategory = pageConst.constNo;
        } else if (elemId.indexOf('header-main') !== -1 ||
                    elemId.indexOf('navigateHome') !== -1 ||
                    elemId.indexOf('pos') !== -1 ||
                    elemId.indexOf('clientName') !== -1 ||
                    elemId.indexOf('loginPanel') !== -1 ||
                    elemId.indexOf('loggedinPanel') !== -1) {
          // When Event Element Id is on Header
          this.portletId = pageConst.NAPortletId;
          this.eventTargetId =  elemId;
          this.accessedMessage =  auditmessagelist.auditMessages.commonClicked + ' ' +
                                  auditmessagelist.auditMessages.commonHeader;
          this.isCategory = pageConst.NotApplicable;
        } else if (elemId.indexOf('eulaPopUp') !== -1) {
          this.portletId = pageConst.NAPortletId;
          this.eventTargetId =  elemId;
          this.accessedMessage =  auditmessagelist.auditMessages.commonClicked + ' ' +
                                  auditmessagelist.auditMessages.eulaFooter;
          this.isCategory = pageConst.NotApplicable;
        } else if (elemId.indexOf('securityPolicyPopUp') !== -1) {
          this.portletId = pageConst.NAPortletId;
          this.eventTargetId =  elemId;
          this.accessedMessage =  auditmessagelist.auditMessages.commonClicked + ' ' +
                                  auditmessagelist.auditMessages.securityPolicyFooter;
          this.isCategory = pageConst.NotApplicable;
        } else if (elemId.indexOf('securityDisclaimerPopUp') !== -1) {
          this.portletId = pageConst.NAPortletId;
          this.eventTargetId =  elemId;
          this.accessedMessage =  auditmessagelist.auditMessages.commonClicked + ' ' +
                                  auditmessagelist.auditMessages.disclaimerFooter;
          this.isCategory = pageConst.NotApplicable;
        } else if (elemId.indexOf('contactUsPopUp') !== -1) {
          this.portletId = pageConst.NAPortletId;
          this.eventTargetId =  elemId;
          this.accessedMessage =  auditmessagelist.auditMessages.commonClicked + ' ' +
                                  auditmessagelist.auditMessages.contactUsFooter;
          this.isCategory = pageConst.NotApplicable;
        }
      }
    }
    if (this.accessedMessage !== undefined &&
      this.accessedMessage !== '' &&
      this.accessedMessage !== '\n') { // If Accessed Message Exists
        // Service Request Preparation
      this.userActivityTrackRequest = new CONFIGUREPORTALREQUEST();
      this.userAccessedLog = new USERACTIVITYLOG();
      this.userActivityTrackRequest = this.userDet.getConfigPortalReq(this.screenId, this.portletId);
      if (this.userActivityTrackRequest.updatedBy === 'null') {
        this.userActivityTrackRequest.updatedBy = pageConst.publicIP;
      }
      this.userActivityTrackRequest.activityCode = activityCode.userActivityLog;

      if (this.eventTargetId.indexOf('_') !== -1) {
        this.userAccessedLog.accessedMessageId = this.getMessageId(this.eventTargetId);
      } else {
        this.userAccessedLog.accessedMessageId = pageConst.NotApplicable;
      }
      this.userAccessedLog.accessedMessage = this.accessedMessage;
      this.userAccessedLog.isCategory = this.isCategory;

      this.userActivityTrackRequest.userAccessedLog = this.userAccessedLog;

      // Sending request to Service
      this.httpService.updateUserAccessedActivity(this.userActivityTrackRequest).subscribe(
        data => {
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  /**
   * Get Accessed Element Id Number for Rest Call
   */
  getMessageId(elementId) {
    const splitElementIdArray = elementId.split('_');
    return splitElementIdArray[splitElementIdArray.length - 1];
  }

  /**
   * Get Active Accessed Message Id
   * While using Carousel Arrow Icons
   */
  getActiveId(elementId) {
    const eventId = document.getElementsByClassName('active')[1].id;
    let shiftedActiveId = '';
    const welcomeMessageElements = document.getElementsByClassName('carousel-item');
    for (let index = 0; index < welcomeMessageElements.length; index++) {
      const element = welcomeMessageElements[index].id;
      if (element === eventId) {
          if (elementId === 'carousel-left-arrow') {
            if (index === 0) {
              shiftedActiveId = welcomeMessageElements[welcomeMessageElements.length - 1].id;
              break;
            } else {
              shiftedActiveId = welcomeMessageElements[index - 1].id;
              break;
            }
          } else {
            if (index === (welcomeMessageElements.length - 1)) {
              shiftedActiveId = welcomeMessageElements[0].id;
              break;
            } else {
              shiftedActiveId = welcomeMessageElements[index + 1].id;
              break;
            }
          }
      }
    }
    return shiftedActiveId;
  }

  getScreenDetails(): void {
    // this.model = {username: pageConst.username, password: pageConst.password};
    // this.httpService.getToken(this.model).subscribe(
    //  (res: HttpResponse<any>) => {
    //    sessionStorage.setItem('JWTToken', res.headers.get('Authorization'));
        this.showPF = true;
    //    if (res.headers.get('Authorization') !== null) {
          if (!sessionStorage.getItem('screenInfoData')) {
            this.httpService.get_Screen(this.userDet.getScreenId(), this.userDet.getRoleId())
            .pipe(first())
            .subscribe(
              (data: any) => {
                sessionStorage.setItem('screenInfoData', JSON.stringify(data));
                localStorage.setItem('screenInfoData', JSON.stringify(data));
                this.getPublicFacingScreen(data);
                // this.checkSurvey();
              },
              (error) => {
                this.logger.debug('error in get screen service call');
              });
          } else {
            this.getPublicFacingScreen(JSON.parse(sessionStorage.getItem('screenInfoData')));
            // this.checkSurvey();
          }
          
        // }
      // },
    // (error) => {
     // this.logger.debug('error in get token service call');
    // });
  }

  /** Public facing screen display logic */
  getPublicFacingScreen(data) {
    this.pageData = data;
    let flagsArray = [];
    ((this.pageData.pcFlag === 'Y') ? (flagsArray.push(this.pageData.pcFlag)) : (null));
    ((this.pageData.piFlag === 'Y') ? (flagsArray.push(this.pageData.piFlag)) : (null));
    ((this.pageData.naFlag === 'Y') ? (flagsArray.push(this.pageData.naFlag)) : (null));
    ((this.pageData.paFlag === 'Y') ? (flagsArray.push(this.pageData.paFlag)) : (null));
    this.roleId = this.userDet.getRoleId();
    if ((this.roleId === this.constVal.roleConduentInternal || this.roleId === this.constVal.roleClient)) {
      this.colMdVaue = (12 / (flagsArray.length + 1)).toString();
    } else {
      this.colMdVaue = (12 / flagsArray.length).toString();
    }
    //  else if ((this.roleId === this.constVal.roleConduentInternal || this.roleId === this.constVal.roleClient)
    // && flagsArray.length === 2 ){
    //   this.colMdVaue = '4';
    // } else if ((this.roleId === this.constVal.roleConduentInternal || this.roleId === this.constVal.roleClient)
    // && flagsArray.length === 1 ){
    //   this.colMdVaue = '6';
    // } else if ((this.roleId === this.constVal.roleConduentInternal || this.roleId === this.constVal.roleClient)
    // && flagsArray.length === 0 ){
    //   this.colMdVaue = '12';
    // }
    localStorage.setItem('acceptLabelText', data.eulaAccLabel);
    localStorage.setItem('rejectLabelText', data.eulaRejLabel);
    localStorage.setItem('ssoMode', data.ssoMode);
    sessionStorage.setItem('ssoMode', data.ssoMode);
	localStorage.setItem('carouselInterval', data.carouselTime);
    sessionStorage.setItem('carouselInterval', data.carouselTime);
    if (data.disclaimerFlag === this.constVal.constYes && localStorage.getItem('isAgreeClicked') !== 'true') {
      this.router.navigate(['/disclaimer']);
    }
    if (data.disclaimerFlag === this.constVal.constNo || localStorage.getItem('isAgreeClicked') === 'true') {
      document.getElementById('public-facing').classList.remove('blurry');
    }
  }

  /** Check for logout redirection and display survey monkey pop-up */
  checkSurvey() {
    if(sessionStorage.getItem('isLogout')) {
      if( sessionStorage.getItem('isLogout') === pageConst.constYes) {
        const surveyUrl = JSON.parse(sessionStorage.getItem('screenInfoData'));
       this.formSurveyUrl = surveyUrl.formSurveyUrl;
        this.surveyFocus();
        //sessionStorage.setItem('isLogout', pageConst.constNo);
      }
    }
  }

  surveyFocus() {
    this.showAlertMFS = true;
    if (this.formSurveyModal !== undefined) {
      this.formSurveyModal.nativeElement.style.display = 'block';
    }
    document.getElementById('formsSurveyHeader').focus();
  }

  openFormSurvvey() {
    window.open(this.formSurveyUrl, '_blank');
    this.showAlertMFS = false;
    if (this.formSurveyModal !== undefined) {
      this.formSurveyModal.nativeElement.style.display = 'none';
    }
    document.getElementById('welcome').focus();
    sessionStorage.setItem('isLogout', pageConst.constNo);
  }

  closeFormSurvvey() {
    this.showAlertMFS = false;
    if (this.formSurveyModal !== undefined) {
      this.formSurveyModal.nativeElement.style.display = 'none';
    }
    document.getElementById('welcome').focus();
    sessionStorage.setItem('isLogout', pageConst.constNo);
  }
}
