import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { HttpService } from '../../_services/http.service';
import * as configlist from '../../../assets/config/config';
import * as auditmessagelist from '../../../assets/config/auditmessages';
import * as PageSer from '../../../assets/config/pageConfig';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { filter, first } from 'rxjs/operators';
import { FormsModule } from '@angular/forms';
import { UserService } from '../../_services/user.service';
import { CONFIGUREPORTALREQUEST } from '../../_models/ConfigurePortal/configureportalrequest';
import { USERACTIVITYLOG } from '../../_models/UserActivityTrack/useractivitylog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'faqdetails',
  templateUrl: './faqdetails.component.html',
  styleUrls: ['./faqdetails.component.scss'],
  providers: [FormsModule]
})
export class FaqdetailsComponent implements OnInit, OnDestroy {
  private allItems: any[];
  // pager object
  pager: any = {};
  // paged items
  pagedItems: any[];
  resVal = [];
  resKeys: any;
  data = [];
  catId: any;
  questionDat = [];
  navigationSubscription: any;
  pageTitle: string;
  pageName: string;
  categoryName: string;
  pageUrl: string;
  pageFullUrl: string;
  setflg = 'x';
  catkeytot = '';
  filteredcat = '';
  searchList = [];
  faqobj = [];
  Slen = '';
  srflag = false;
  filteredguidelineList = [];
  catkeys = [];
  faqVal = [];
  ClientName = '';
  sid = '';
  sDesc = '';
  catgId = false;
  jKeys = null;
  faqDetailObj: any;
  seletedstring = '';
  searchEnabled = false;
  prgrmTileLabel: string;
  contactsTileLabel: string;
  newsTileLabel: string;
  faqTileLabel: string;
  userHelpLabel: string;
  priorAuthLabel: string;
  appLinksLabel: string;
  homeTitleLabel = configlist.pageConst.Home;
  regex = /(&nbsp;|<([^>]+)>)/ig;
  eventTargetId = '';
  public userActivityTrackRequest: CONFIGUREPORTALREQUEST;
  public userAccessedLog: USERACTIVITYLOG;
  public activityPortletId = 0;
  public activityPortletName = '';
  accessedMessage = '';
  screenId: Number;
  isTargetIdExist = false;
  isCategory = '';
  categoryImgUrl: any;
  catImgExists = true;
  catType = false;

  // tslint:disable-next-line:max-line-length
  constructor(private httpService: HttpService, private route: ActivatedRoute, private router: Router, private logger: NGXLogger, private userDet: UserService,  private sanitizer: DomSanitizer) {
    this.navigationSubscription = this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd))
    // Code Change 1 Starts
    .subscribe(() => {
        let root = this.router.routerState.snapshot.root;
        this.httpService.get_Screen(this.userDet.getScreenId(), this.userDet.getRoleId())
          .pipe(first())
          .subscribe(
            (data: any) => {
              this.prgrmTileLabel = data.piLabel;
              this.contactsTileLabel = data.pcLabel;
              this.newsTileLabel = data.naLabel;
              this.faqTileLabel = data.faqLabel;
              this.userHelpLabel = data.userHelpLabel;
              this.priorAuthLabel = data.paLabel;
              this.appLinksLabel = configlist.pageConst.tileApplicationLinksName;
              // alert('Get Screen Info ApI');
              while (root) {
                if (root.children && root.children.length) {
                  root = root.children[0];
                } else if (root.data && root.data['title']) {
                  this.pageTitle = root.data['title'];
                  if (this.pageTitle === 'FAQ') {
                    this.pageName = this.faqTileLabel;
                    this.activityPortletId = configlist.pageConst.tileFAQ;
                    this.activityPortletName = configlist.pageConst.tileFAQName;
                } else if (this.pageTitle === 'Program Information') {
                    this.pageName = this.prgrmTileLabel;
                    this.activityPortletId = configlist.pageConst.tileProgramInfo;
                    this.activityPortletName = configlist.pageConst.tileProgramInfoName;
                } else if (this.pageTitle === 'Program Contacts') {
                    this.pageName = this.contactsTileLabel;
                    this.activityPortletId = configlist.pageConst.tileProgramContacts;
                    this.activityPortletName = configlist.pageConst.tileProgramContactsName;
                } else if (this.pageTitle === 'News & Alerts') {
                    this.pageName = this.newsTileLabel;
                    this.activityPortletId = configlist.pageConst.tileNewsAndAlerts;
                    this.activityPortletName = configlist.pageConst.tileNewsAndAlertsName;
                  } else if (this.pageTitle === 'User Help') {
                    this.pageName = this.userHelpLabel;
                    this.activityPortletId = configlist.pageConst.tileUserHelp;
                    this.activityPortletName = configlist.pageConst.tileUserHelpName;
                  } else if (this.pageTitle === 'Prior Authorization') {
                    this.pageName = this.priorAuthLabel;
                    this.activityPortletId = configlist.pageConst.tilePriorAuth;
                    this.activityPortletName = configlist.pageConst.tilePriorAuthName;
                  } else if (this.pageTitle === 'Application Links') {
                    this.pageName = this.appLinksLabel;
                    this.activityPortletId = configlist.pageConst.tileApplicationLinks;
                    this.activityPortletName = configlist.pageConst.tileApplicationLinksName;
                  }
                  return;
                } else {
                  return;
                }
              }
            },
            (error) => {
              this.logger.debug('error in get screen service call');
            });
        // Code Change 1 Ends
        while (root) {
          if (root.children && root.children.length) {
            root = root.children[0];
          } else if (root.data && root.data['title']) {
            this.pageTitle = root.data['title'];
            localStorage.setItem('name', root.data['title']);
            console.log(localStorage.getItem('name'));
           // document.getElementById('searchBox').innerText = '';
            if (this.pageTitle === 'FAQ') {
              this.pageUrl = '/faqpage';
              this.pageFullUrl = '/faqpage/faqdetailedpage';
              httpService.getJSON(configlist.faqUrl, this.userDet.getRoleId(), this.userDet.getScreenId()).subscribe(
                (data: any) => {
                  data.faqResponse.forEach(obj => {
                      obj.infoItemList.forEach(infoObj => {
                        infoObj.answer = this.userDet.pushDescription(infoObj.answer);
                      });
                  });
                  this.resVal = Object.values(data.faqResponse);
                  // console.log(this.resVal);
                 this.resKeys = Object.keys(data.faqResponse);
                 // this.resKeys = this.resVal.map(catList => catList.categoryName);
                  this.faqobj = data.faqResponse;
                  this.catId = route.snapshot.params.id;
                  this.pageName = this.faqTileLabel;
                  // tslint:disable-next-line:radix
                  this.catId = parseInt(this.catId);
                  this.setData();
                  this.data = data;
                  this.allItems = this.questionDat;
                  this.pagedItems = this.allItems;
                  //this.setPage(1);
				  this.focusInformation();
                }
              );
            } else if (this.pageTitle === 'Program Information') {
              this.pageUrl = '/programInfo';
              this.pageFullUrl = '/programInfo/programInfoDetailedPage';
              httpService.getJSONPost(configlist.prgmUrl, this.userDet.getRoleId(), this.userDet.getScreenId()).subscribe(
                (data: any) => {
                  data.programInfoResponse.forEach(obj => {
                    obj.infoItemList.forEach(infoObj => {
                      infoObj.information = this.userDet.pushDescription(infoObj.information);
                    });
                  });
                  this.resVal = Object.values(data.programInfoResponse);
                  // console.log(this.resVal);
                  // alert(this.resVal);
                  this.resKeys = Object.keys(data.programInfoResponse);
                  // this.resKeys = this.resVal.map(catList => catList.categoryName);
                  this.faqobj = data.programInfoResponse;
                  this.catId = route.snapshot.params.id;
                  this.pageName = this.prgrmTileLabel;
                  // tslint:disable-next-line:radix
                  this.catId = parseInt(this.catId);
                  // alert('hi before data');
                  this.setData();
                  // alert('hi after data');
                  this.data = data;
                  this.allItems = this.questionDat;
                  this.pagedItems = this.allItems;
                  // this.setPage(1);
				  this.focusInformation();
                }
              );
            } else if (this.pageTitle === 'Prior Authorization') {
              this.pageUrl = '/priorAuth';
              this.pageFullUrl = '/priorAuth/priorAuthDetailedPage';
              httpService.getJSON(configlist.priorAuthUrl, this.userDet.getRoleId(), this.userDet.getScreenId()).subscribe(
                (data: any) => {
                  //console.log(data.priorAuthResponse);
                  data.priorAuthResponse.forEach(obj => {
                    obj.priorAuthItemList.forEach(infoObj => {
                      let url = this.pushUrl(infoObj.priorAuthInfo);
                      if (url !== null){
                        infoObj.priorAuthInfo = this.pushDescription(url, infoObj.priorAuthInfo);
                      }
                    });
                  });
                  let priorAuthDetails = Object.values(data.priorAuthResponse)
                  httpService.getAppLinks(this.userDet.getScreenName()).subscribe((appLinks: any) => {
                    appLinks.applicationResponse.forEach((obj) => {
                      let url = this.pushUrl(obj.categoryDesc);
                      obj.categoryDesc = this.pushDescription(url, obj.categoryDesc);
                      obj.appMgntItemList[0].applicationTextUrl = obj.categoryDesc;
                    });
                    let applications = Object.values(appLinks.applicationResponse);
                    if(applications.find(obj => obj['categoryName'] === 'WebPA')) {
                      this.resVal = [...priorAuthDetails, ...applications];
                      this.faqobj = [...data.priorAuthResponse , ...appLinks.applicationResponse];
                    } else {
                      this.resVal = applications;
                      this.faqobj = appLinks.applicationResponse;
                    }
                    
                   // this.resKeys = this.resVal.map(catList => catList.categoryName);
                   this.resKeys = Object.keys(this.resVal);
                    
                    this.catId = route.snapshot.params.id;
                    this.pageName = this.priorAuthLabel;
                    // tslint:disable-next-line:radix
                    this.catId = parseInt(this.catId);
                    // alert('hi before data');
                    this.setData();
                    // alert('hi after data');
                    this.data = data;
                    this.allItems = this.questionDat;
                    this.pagedItems = this.allItems;
                  //  this.setPage(1);
					this.focusInformation();

                  })
                }
              );
            } else if (this.pageTitle === 'News & Alerts') {
              // alert(this.pageTitle);
              this.pageUrl = '/newsAlerts';
              this.pageFullUrl = '/newsAlerts/newsAlertsDetailedPage';
              httpService.getJSONPost(configlist.newsUrl, this.userDet.getRoleId(), this.userDet.getScreenId()).subscribe(
                (data: any) => {
                  data.newsAlertsResponse.forEach(obj => {
                    obj.newsAlertsItemList.forEach(infoObj => {
                      infoObj.news = this.userDet.pushDescription(infoObj.news);
                    });
                  });
                  this.resVal = Object.values(data.newsAlertsResponse);
                  // console.log(this.resVal);
                  this.resKeys = Object.keys(data.newsAlertsResponse);
                  // this.resKeys = this.resVal.map(catList => catList.categoryName);
                  this.faqobj = data.newsAlertsResponse;
                  this.catId = route.snapshot.params.id;
                  this.pageName = this.newsTileLabel;
                  // tslint:disable-next-line:radix
                  this.catId = parseInt(this.catId);
                  this.setData();
                  this.data = data;
                  this.allItems = this.questionDat;
                  this.pagedItems = this.allItems;
                  //  this.setPage(1);
                  this.focusInformation();
                }
              );
            } else if (this.pageTitle === 'Program Contacts') {
              // alert(this.pageTitle);
              this.pageUrl = '/programContacts';
              this.pageFullUrl = '/programContacts/programContactsDetailedPage';
              httpService.getJSONPost(configlist.contactUrl, this.userDet.getRoleId(), this.userDet.getScreenId()).subscribe(
                (data: any) => {
                  this.resVal = Object.values(data.programContactsResponse);
                  // console.log(this.resVal);
                  this.resKeys = Object.keys(data.programContactsResponse);
                  // this.resKeys = this.resVal.map(catList => catList.categoryName);
                  this.catId = route.snapshot.params.id;
                  this.faqobj = data.programContactsResponse;
                  // tslint:disable-next-line:radix
                  this.catId = parseInt(this.catId);
                  this.pageName = this.contactsTileLabel;
                  this.setData();
                  this.data = data;
                  this.allItems = this.questionDat;
                  this.pagedItems = this.allItems;
                  //  this.setPage(1);
                  this.focusInformation();
                }
              );
            } else if (this.pageTitle === 'User Help') {
              // alert(this.pageTitle);
              this.pageUrl = '/userHelp';
              this.pageFullUrl = '/userHelp/userHelpDetailedpage';
              httpService.getJSONPost(configlist.userHelpUrl, this.userDet.getRoleId(), this.userDet.getScreenId()).subscribe(
                (data: any) => {
                  data.userHelpResponse.forEach(obj => {
                    obj.uhItemList.forEach(infoObj => {
                      infoObj.helpInformation = this.userDet.pushDescription(infoObj.helpInformation);
                    });
                  });
                  this.resVal = Object.values(data.userHelpResponse);
                  // console.log(this.resVal);
                  this.resKeys = Object.keys(data.userHelpResponse);
                  // this.resKeys = this.resVal.map(catList => catList.categoryName);
                  this.catId = route.snapshot.params.id;
                  this.faqobj = data.userHelpResponse;
                  // alert(JSON.stringify(data));
                  // tslint:disable-next-line:radix
                  this.catId = parseInt(this.catId);
                  this.pageName = this.userHelpLabel;
                  this.setData();
                  this.data = data;
                  this.allItems = this.questionDat;
                 this.pagedItems = this.allItems;
                  //  this.setPage(1);
                  this.focusInformation();
                }
              );
            } else if (this.pageTitle === 'Application Links') {
              this.pageUrl = '/applicationLinks';
              this.pageFullUrl = '/applicationLinks/applicationLinksDetailedPage';
              httpService.getAppLinks(this.userDet.getScreenName()).subscribe(
                (data: any) => {
                  data.applicationResponse.forEach((obj) => {
                    obj.appMgntItemList.forEach((infoObj) => {
                      let url = this.pushUrl(infoObj.applicationTextUrl);
                      infoObj.applicationTextUrl = this.pushDescription(url,infoObj.applicationTextUrl);
                    })
                  });
                  this.resVal = Object.values(data.applicationResponse);
                  this.resKeys = Object.keys(data.applicationResponse);
                  this.faqobj = this.resVal.map(catList => {
                    for(var item of catList.appMgntItemList) {
                      item['categoryName'] = catList.categoryName;
                    }
                   return catList;
                  });
                  // this.faqobj = data.applicationResponse;
                  this.catId = route.snapshot.params.id;
                  this.pageName = this.appLinksLabel;
                  // tslint:disable-next-line:radix
                  this.catId = parseInt(this.catId);
                  this.setData();
                  this.data = data;
                  this.allItems = this.questionDat;
                  console.log(this.allItems);
                 this.pagedItems = this.allItems;
                  //  this.setPage(1);
                  this.focusInformation();
                }
              );
            }
            return;
          } else {
            return;
          }
        }
      });


    this.navigationSubscription = this.route.params.subscribe(routeParams => {
      // tslint:disable-next-line:radix
      this.catId = parseInt(routeParams.id);
      this.setData();
    });
  }

  ngOnInit() {
    document.getElementById('faqDetailheader').setAttribute('tabindex', '-1');
    document.getElementById('faqDetailheader').focus();
    // this.setPage(this.pager.currentPage);
  }

  focusInformation() {
    this.httpService.getInformationId().subscribe(val => {
      if(val.id) {
        let accumulatedTime;
        setInterval(() => {
          accumulatedTime += 200;
          let element = document.getElementById('items_' + val.id) as HTMLElement;
          if (element) {
            element.focus();
            val.id = null;
            clearInterval();
            return;
          } else if (accumulatedTime > 5000){
            val.id = null;
            clearInterval();
            return;
          }
          }, 200);
      }  
    });
  }
  
  /**
   * On Mouse Down in a entire page
   * event is triggered
   */
  @HostListener('mousedown', ['$event'])
  onMouseDown(event: any) {
    // Mouse Down event Consts
    this.eventTargetId = '';
    this.accessedMessage = '';
    this.isCategory = '';
    this.screenId = this.userDet.getScreenId();

    const elemId = this.getClosest(event.target); // get event element Id
    this.eventTargetId = elemId;

    if (this.eventTargetId !== undefined) {
      if (!this.isTargetIdExist && event.target.href !== undefined && event.target.href !== '') { // Only used for page links
        if (this.eventTargetId.indexOf('info_') !== -1) {
          this.accessedMessage =  configlist.pageConst.categoryDetails + ' - ' +
                                  this.activityPortletName + ' - ' +  configlist.pageConst.InformationName + ' : ' +
                                  event.target.href;
          this.isCategory = configlist.pageConst.constNo;
        } else if (this.eventTargetId.indexOf('app_') !== -1) {
          this.accessedMessage =  configlist.pageConst.categoryDetails + ' - ' +
                                  this.activityPortletName + ' - ' +  configlist.pageConst.AppLinksName + ' : ' +
                                  event.target.href;
          this.isCategory = configlist.pageConst.constYes;
        }
      } else {
        if (this.eventTargetId.indexOf('qam') !== -1) { // If event Id has Quick Access Menu Category
          this.accessedMessage =  configlist.pageConst.categoryDetails + ' - ' +  configlist.pageConst.QAMName + ' - ' +
                                this.activityPortletName + ' - ' +  configlist.pageConst.categoryName + ' : ' +
                                event.target.innerText;
          this.isCategory = configlist.pageConst.constYes;
        } else if (this.eventTargetId.indexOf('cat_name') !== -1) { // if event Id has Category Name
          this.accessedMessage =  configlist.pageConst.categoryDetails + ' - ' +
                                  this.activityPortletName + ' - ' +  configlist.pageConst.categoryName + ' : ' +
                                  event.target.innerText;
          this.isCategory = configlist.pageConst.constYes;
        } else if (this.eventTargetId.indexOf('info_') !== -1) { // if event Id has Inforamtion
          this.accessedMessage =  configlist.pageConst.categoryDetails + ' - ' +
                                 this.activityPortletName + ' - ' +  configlist.pageConst.InformationName + ' : ' +
                                 event.target.innerText;
          this.isCategory = configlist.pageConst.constNo;
        } else if (this.eventTargetId.indexOf('page_') !== -1) { // if event Id has Pagination
          this.accessedMessage =  configlist.pageConst.categoryDetails + ' - ' +
                                  this.activityPortletName + ' - ' + auditmessagelist.auditMessages.auditPagination;
          this.isCategory = configlist.pageConst.constNo;
        } else if (this.eventTargetId.indexOf('home_') !== -1) { // if event Id has Home Link
          this.accessedMessage =  configlist.pageConst.categoryDetails + ' - ' +
                                 this.activityPortletName + ' - ' + auditmessagelist.auditMessages.auditHomePage;
          this.isCategory = configlist.pageConst.constYes;
        }  else if (this.eventTargetId.indexOf('tile_') !== -1) { // if event Id has Portlet Tile Link
          this.accessedMessage =  configlist.pageConst.categoryDetails + ' - ' +
                                  this.activityPortletName + ' - ' + auditmessagelist.auditMessages.commonClicked + ' ' +
                                  event.target.innerText + ' ' +
                                  auditmessagelist.auditMessages.commonTile;
          this.isCategory = configlist.pageConst.constYes;
        } else if ( this.eventTargetId.indexOf('header-main') !== -1 ||
                    this.eventTargetId.indexOf('navigateHome') !== -1 ||
                    this.eventTargetId.indexOf('pos') !== -1 ||
                    this.eventTargetId.indexOf('clientName') !== -1 ||
                    this.eventTargetId.indexOf('loginPanel') !== -1 ||
                    this.eventTargetId.indexOf('loggedinPanel') !== -1) { // if event Id has Header Page Id's
          this.activityPortletId = configlist.pageConst.NAPortletId;
          this.accessedMessage =  configlist.pageConst.categoryDetails + ' - ' +
                                  auditmessagelist.auditMessages.commonClicked + ' ' +
                                  auditmessagelist.auditMessages.commonHeader;
          this.isCategory = configlist.pageConst.NotApplicable;
      }  else if (this.eventTargetId.indexOf('eulaPopUp') !== -1) {
          this.activityPortletId = configlist.pageConst.NAPortletId;
          this.accessedMessage =  configlist.pageConst.categoryDetails + ' - ' +
                                 auditmessagelist.auditMessages.commonClicked + ' ' +
                                 auditmessagelist.auditMessages.eulaFooter;
          this.isCategory = configlist.pageConst.NotApplicable;
      } else if (this.eventTargetId.indexOf('securityPolicyPopUp') !== -1) {
          this.activityPortletId = configlist.pageConst.NAPortletId;
          this.accessedMessage =  configlist.pageConst.categoryDetails + ' - ' +
                                 auditmessagelist.auditMessages.commonClicked + ' ' +
                                 auditmessagelist.auditMessages.securityPolicyFooter;
          this.isCategory = configlist.pageConst.NotApplicable;
      } else if (this.eventTargetId.indexOf('securityDisclaimerPopUp') !== -1) {
          this.activityPortletId = configlist.pageConst.NAPortletId;
          this.accessedMessage =  configlist.pageConst.categoryDetails + ' - ' +
                                  auditmessagelist.auditMessages.commonClicked + ' ' +
                                 auditmessagelist.auditMessages.disclaimerFooter;
          this.isCategory = configlist.pageConst.NotApplicable;
      } else if (this.eventTargetId.indexOf('contactUsPopUp') !== -1) {
          this.activityPortletId = configlist.pageConst.NAPortletId;
          this.accessedMessage =  configlist.pageConst.categoryDetails + ' - ' +
                                  auditmessagelist.auditMessages.commonClicked + ' ' +
                                  auditmessagelist.auditMessages.contactUsFooter;
          this.isCategory = configlist.pageConst.NotApplicable;
      }
      }
    }
  if (this.accessedMessage !== undefined &&
      this.accessedMessage !== '' &&
      this.accessedMessage !== '\n') { // If Accessed Message Exists
        // Service request Preparation
      this.userActivityTrackRequest = new CONFIGUREPORTALREQUEST();
      this.userAccessedLog = new USERACTIVITYLOG();
      this.userActivityTrackRequest = this.userDet.getConfigPortalReq(this.screenId, this.activityPortletId);
      if (this.userActivityTrackRequest.updatedBy === 'null') {
          this.userActivityTrackRequest.updatedBy = configlist.pageConst.publicIP;
      }
      this.userActivityTrackRequest.activityCode = configlist.activityCode.userActivityLog;

      if (this.eventTargetId.indexOf('_') !== -1) {
        this.userAccessedLog.accessedMessageId = this.getMessageId(this.eventTargetId);
      } else {
          this.userAccessedLog.accessedMessageId = configlist.pageConst.NotApplicable;
      }
      this.userAccessedLog.accessedMessage = this.accessedMessage;
      this.userAccessedLog.isCategory = this.isCategory;

      this.userActivityTrackRequest.userAccessedLog = this.userAccessedLog;
      // Sending request to Service
      this.httpService.updateUserAccessedActivity(this.userActivityTrackRequest).subscribe(
        data => {
        },
        error => {
          console.log(error);
        }
      );
    }

  }

  /**
   * Get Accessed Element Id Number for Rest Call
   */
  getMessageId(elementId) {
    const splitElementIdArray = elementId.split('_');
    return splitElementIdArray[splitElementIdArray.length - 1];
  }



  /**
   * To Get Parent Element Id
   */
  getClosest(elem: any) {
    if (elem.id !== undefined && elem.id !== '') {
      this.isTargetIdExist = true;
      return elem.id;
    } else {
      this.isTargetIdExist = false;
    }
    for ( ; elem && elem !== document; elem = elem.parentNode ) {
      if (elem.id !== undefined && elem.id !== '') {
        return elem.id;
      }
    }
  }

  ngOnDestroy() {
    // avoid memory leaks here by cleaning up after ourselves. If we
    // don't then we will continue to run our initialiseInvites()
    // method on every navigationEnd event.
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
  /**   Navigate Home based on role  Publicfacing, Client & Provider */
  navigateHome() {
      this.userDet.homeNavigation();
  }
  setPage(page: number) {
    // get pager object from service
    this.pager = PageSer.PagerService.getPager(this.allItems, page, 2);
    // get current page of items
    this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
    // alert('pagedItems    '+JSON.stringify(this.pagedItems));
  }

  setData() {
    if (this.ClientName) {
      this.ClientName = '';
      this.searchList = [];
      this.catgId = false;
    //  document.getElementById('search-result').style.border = 'none';
    }

    // alert(this.resVal);
    for (const finalVal of this.resVal) {
      // alert(' this.catId '+  this.catId);
      // alert(' finalVal.categoryId '+  finalVal.categoryId);
      // console.log(finalVal.infoItemList);
      if (finalVal.categoryId === this.catId) {
        // alert(finalVal.infoItemList);
        this.categoryName = finalVal.categoryName;
        this.catImgExists = true;
        if (finalVal.catImageExists && (finalVal.appMgntItemList !== undefined)) {
          this.catType = true;
          this.loadApplicationImage(finalVal.categoryId);
        } else if (!finalVal.catImageExists && (finalVal.appMgntItemList !== undefined)) {
          this.catType = true;
          this.categoryImgUrl = '';
          this.catImgExists = false;
        } else if (finalVal.catImageExists) {
          this.catType = false;
          this.loadCatImage(finalVal.categoryId);
        } else {
          this.catType = false;
          this.categoryImgUrl = '';
          this.catImgExists = false;
        }
        if (this.pageTitle === 'News & Alerts') {
          this.questionDat = finalVal.newsAlertsItemList;
        } else if (this.pageTitle === 'Program Contacts') {
          this.questionDat = finalVal.contactItemList;
        } else if (this.pageTitle === 'User Help') {
          this.questionDat = finalVal.uhItemList;
        }else if (this.pageTitle === 'Prior Authorization' && finalVal.priorAuthItemList) {
          this.questionDat = finalVal.priorAuthItemList;
        }else if (this.pageTitle === 'Prior Authorization' && finalVal.appMgntItemList) {
          this.questionDat = finalVal.appMgntItemList;
        }else if (this.pageTitle === 'Application Links') {
          this.questionDat = finalVal.appMgntItemList;
        } else {
          this.questionDat = finalVal.infoItemList;
        }
        //  alert('questionDat    '+JSON.stringify(this.questionDat));
      }
    }
  }
  /** Load Application image */
  loadApplicationImage(categoryId) {
    this.httpService.getAppImage(categoryId).subscribe(data => {
      const urlCreator = window.URL;
      const imagePath = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(data));
      this.categoryImgUrl = imagePath;
    }, error => {
      let defaultPath = null;
      defaultPath = 'assets/images/app.png';
      this.logger.debug('error in get screen service call');
      this.catImgExists = false;
    });
  }
  /** Load category image */
  loadCatImage(categoryId) {
    if (this.pageTitle === 'Application Links') {
      this.loadApplicationImage(categoryId);
    } else {
    this.httpService.getImage(categoryId, this.userDet.getScreenId(), this.activityPortletId).subscribe(data => {
      const urlCreator = window.URL;
      const imagePath = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(data));
      this.categoryImgUrl = imagePath;
    }, error => {
      // let defaultPath = null;
      // if (this.pageTitle === 'FAQ') {
      //     defaultPath = 'assets/images/faq.jpg';
      // } else if (this.pageTitle === 'Program Information') {
      //     defaultPath = 'assets/images/program.jpg';
      // } else if (this.pageTitle === 'Program Contacts') {
      //     defaultPath = 'assets/images/contact.jpg';
      // } else if (this.pageTitle === 'News & Alerts') {
      //     defaultPath = 'assets/images/news.jpg';
      // } else if (this.pageTitle === 'User Help') {
      //     defaultPath = 'assets/images/userhelp.jpg';
      // }
      // const imagePath = this.sanitizer.bypassSecurityTrustUrl(defaultPath);
      // this.categoryImgUrl = imagePath;
      this.logger.debug('error in get screen service call');
      this.catImgExists = false;
    });
  }
  }
  reload() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return true;
    };
    this.searchEnabled = false;
    // this.setPage(this.pager.currentPage);
    //     this.searchList = [];
    //     this.catId = false;
    //     this.categoryName = '';
    //     this.ClientName = '';
  }
  // Push a search term into the observable stream.
  searchClient(): void {
    // alert(this.ClientName.length)
    // alert(this.ClientName);
    let terms = this.ClientName;
    while (terms.indexOf('  ') !== -1) {
      terms = terms.replace(/  /g, ' ');
    }
    const term = terms.toLowerCase();
    const tlen = term;
    if (this.Slen === '') {
      this.Slen = term;
    } if (this.Slen !== tlen) {
      this.searchList = [];
      this.catgId = false;
    }

    if (!term) {
     // this.setPage(this.pager.currentPage);
      this.searchList = [];
      this.catgId = false;
      this.searchEnabled = false;
    }
    this.srflag = term ? true : false;
    this.searchList = [];
    if (term.length > 2) {
      this.searchList = [];
      this.traverse_data(this.faqobj, term);
    } else {
      this.searchList.push({data:'Please type a minimum of 3 characters to start searching...'});
    }
  }

  onselectClient(selectedObj) {
    // alert(selectedObj);
    if (selectedObj !== 'No Search results found' || selectedObj !== 'Please type minimum 3 letters to start Searching...') {
      let startindex = 0;
      let endindex = 0;
      let seletedcatg = '';
      this.searchEnabled = true;
      if (selectedObj.indexOf('[') !== -1) {
        startindex = selectedObj.indexOf('[');
        endindex = selectedObj.indexOf(']');
        seletedcatg = selectedObj.substring(startindex + 1, endindex);
        this.seletedstring = selectedObj.substring(0, startindex - 3);
        this.pagedItems = [];
        this.pagedItems.push(seletedcatg);
        this.catgId = true;
        this.fetchdata(seletedcatg);
      } else {
        this.pagedItems = [];
        this.pagedItems.push(selectedObj);
        this.catgId = true;
        this.fetchdata(selectedObj);
      }

    }
    // this.fetchdata(selectedObj);
  }

  traverse_data(obj, sTrem) {

    // tslint:disable-next-line:forin
    for (const prop in obj) {
      this.jKeys = '';
      this.catkeys = Object.values(prop);
      this.catkeys.forEach((k) => {
        this.catkeytot += k;
        this.jKeys += k;
      });

      // alert(this.resKeys);
      // this.filteredguidelineList = this.resKeys.filter((el) => {
      //   return el.toLowerCase().indexOf(this.catkeytot.toLowerCase()) > -1;
      // });

      // tslint:disable-next-line:forin
      for (const keys in this.resKeys) {
        if (this.resKeys[keys].toLowerCase() === prop.toLowerCase()) {
          // alert(this.resKeys[keys]+' = '+prop );
          this.filteredguidelineList = [];
          this.filteredguidelineList.push(obj[prop].categoryName);
        }
      }
      // alert(this.filteredguidelineList+' Y  '+prop );
      if (this.filteredguidelineList.length > 0) {
        this.filteredcat = this.filteredguidelineList[0];
      }
      // alert(this.jKeys+'  1->  '+this.filteredcat);
      if (typeof obj[prop] === 'object') {
        // alert('obj->  '+this.filteredcat);
        // object
        // tslint:disable-next-line:radix
        if (this.categoryName.toLowerCase() === this.filteredcat.toLowerCase() &&
          (this.jKeys === 'infoItemList' || this.jKeys === 'uhItemList'
            || this.jKeys === 'newsAlertsItemList' || this.jKeys === 'contactItemList' ||
            this.jKeys === 'appMgntItemList' || this.jKeys === 'priorAuthItemList')) {
          this.faqDetailObj = obj[prop];
          // alert(JSON.stringify(obj[prop]));
        }
        // alert('traverse_data    '+JSON.stringify(obj[prop]));
        this.traverse_data(obj[prop], sTrem);
        this.catkeytot = '';
      } else {
        // alert(this.filteredcat+ 'line->  '+obj[prop].toString());
        const ke = obj[prop].toString().toLowerCase().replace(this.regex, '');
        const searchData = obj[prop].toString();
        this.jKeys = this.jKeys.toLowerCase();

        if (ke.includes(sTrem) && sTrem.length > 2
          && (this.jKeys === 'question' || this.jKeys === 'answer' ||
            this.jKeys === 'information' || this.jKeys === 'news' ||
            this.jKeys === 'contactname' || this.jKeys === 'phnnumber' ||
            this.jKeys === 'emailid' || this.jKeys === 'helpinformation' ||
            this.jKeys === 'priorauthinfo' || this.jKeys === 'applicationtexturl')
          && this.categoryName === this.filteredcat) {
          // alert(ke);
          const pos = ke.indexOf(sTrem);
          // if (this.filteredcat !== null || this.filteredcat !== '' || this.filteredcat) {
          //     this.searchList.push(this.filteredcat + '');
          // }
          if (pos < 10 || this.pageTitle === 'Application Links') {

            const sdata =  this.pageTitle === 'Application Links' ? (searchData.replace(this.regex, '').split(' ').splice(0, 10).join(' ')): 
            (searchData.replace(this.regex, '').split(' ').splice(0, 10).join(' ')) + " - " + obj['description'] ;
            this.searchResult(obj, sdata);
          
            
            // alert(sdata);
          } else {
            let sdata = '';
            const str = searchData.replace(this.regex, '');
            const exp = this.getWordAt(str, pos);
            if (exp) {
              sdata = exp+ " - "+obj['description'];
            } else {
              let informationResult = searchData.replace(this.regex, '');
              sdata = (informationResult.substring((pos - 45), (pos + 55)))+ " - "+obj['description'];
            }
            this.searchResult(obj, sdata);
            
            // const sameWord = str.substring(str.indexOf(exp) - 2);
            // alert(sameWord.split(' ')[1]);
            // alert(exp);
          }
          this.searchList = Array.from(new Set(this.searchList));
          if (this.searchList[0].data === 'No Search results found') {
            this.searchList = this.searchList.slice(1);
          }
          // alert(this.searchList);
        } else {
          if (this.searchList.length < 1) {
            this.searchList.push({data:'No Search results found'});
          }
        }
      }
    }
  }

  searchResult(obj,sdata){
    if(this.pageTitle === 'News & Alerts'){
      this.searchList.push({informationId:obj['naId'],data:sdata});
    } else if(this.pageTitle === 'Program Contacts'){
      this.searchList.push({informationId:obj['contactId'],data:sdata});
    } else if(this.pageTitle === 'Program Information'){
      this.searchList.push({informationId:obj['informationId'],data:sdata});
    } else if(this.pageTitle === 'User Help'){
      this.searchList.push({informationId:obj['userHelpId'],data:sdata});
    } else if(this.pageTitle === 'FAQ'){
      this.searchList.push({informationId:obj['faqId'],data:sdata});
    } else if(this.pageTitle === 'Application Links'){
      this.searchList.push({informationId:obj['applicationId'],data:sdata});
    } else if(this.pageTitle === 'Prior Authorization'){
      this.searchList.push({informationId:obj['paId'],data:sdata});
    }
  }

  getWordAt(str, pos) {

    // Sanitise input
    str = str + '';
    pos = parseInt(pos, 10);
    const wordAry = str.split(' ');

    // Snap to a word on the left
    if (str[pos] === ' ') {
      pos = pos - 1;
    }

    // Handle exceptional cases
    if (pos < 0 || pos >= str.length - 1 || str[pos] === ' ') {
      return '';
    }

    // Build word
    let acc = '';
    for (; pos > 0 && str[pos - 1] !== ' '; pos--) { }
    for (; pos < str.length && str[pos] !== ' '; pos++) {
      acc += str[pos];
    }
    // this.getWords(str, acc);
    return this.getWords(str, acc);
  }

  getWords(str, acc) {
    const ssp = str.split(' ');
    const find = str.indexOf(acc);
    let finalOP = '';
    let pword = 0;
    const totalWords = 10;
    // tslint:disable-next-line:forin
    for (const key in ssp) {
      if (ssp[key] === acc) {
        // alert('key === acc ' + key + '  ' + acc);
        pword = Number(key);
        for (let i = pword - totalWords; i <= pword + totalWords ; i++ ) {
          if (ssp[i]) {
            finalOP += ssp[i] + ' ';
          }
        }
      }
    }
    return finalOP;
    // alert('in get words  ' + find);
  }

  fetchdata(selectedkey) {
    // alert('hi'+selectedkey);
    for (const sval in this.resVal) {
      if (this.resVal[sval].categoryName === selectedkey && this.pageTitle === 'FAQ') {
        this.sid = this.resVal[sval].categoryId;
        this.sDesc = this.resVal[sval].categoryDesc;
        //  alert(this.sid);
        // this.router.navigate(['faqdetailedpage', this.sid]);
      } else if (this.resVal[sval].categoryName === selectedkey && this.pageTitle === 'Program Information') {
        // alert('hi');
        this.sid = this.resVal[sval].categoryId;
        this.sDesc = this.resVal[sval].categoryDesc;
        //   alert(this.sid);
        // this.router.navigate(['faqdetailedpage', this.sid]);
      } else if (this.pageFullUrl === '/faqpage/faqdetailedpage' && this.pageTitle === 'FAQ'
        && this.resVal[sval].categoryName === this.categoryName) { // FAQ detailedpage
        // alert('in');
        // tslint:disable-next-line:forin
        for (const sqval in this.faqDetailObj) {
          const objFaq = this.faqDetailObj[sqval];
          const selectedData = selectedkey.toLowerCase();
          // alert(objFaq.question+'      JIko       '+selectedkey);
          if (((objFaq.question.toLowerCase()).replace(this.regex, '')).includes(selectedData) ||
            ((objFaq.answer.toLowerCase()).replace(this.regex, '')).includes(selectedData)) {
            // alert(JSON.stringify(objFaq));
            this.pagedItems = [];
            this.pagedItems.push(objFaq);
            // alert('qstn    '+JSON.stringify(objFaq));
          }
        }
      } else if (this.pageFullUrl === '/programInfo/programInfoDetailedPage' && this.pageTitle === 'Program Information'
        && this.resVal[sval].categoryName === this.categoryName) { // Program Information detailedpage
        // alert('hi '+selectedkey);
        // tslint:disable-next-line:forin
        for (const sqval in this.faqDetailObj) {
          const objFaq = this.faqDetailObj[sqval];
          const selectedData = selectedkey.toLowerCase();
          // alert(objFaq.information+'      JIko       '+selectedkey);
          if (((objFaq.information.toLowerCase()).replace(this.regex, '')).includes(selectedData)) {
            this.pagedItems = [];
            this.pagedItems.push(objFaq);
            // alert('ans    '+JSON.stringify(objFaq));
          }
        }
      } else if (this.pageFullUrl === '/newsAlerts/newsAlertsDetailedPage' && this.pageTitle === 'News & Alerts'
        && this.resVal[sval].categoryName === this.categoryName) {
        const selectedData = selectedkey.toLowerCase();
        // News and Alerts detailedpage
        // alert('hi '+selectedkey);
        // tslint:disable-next-line:forin
        for (const sqval in this.faqDetailObj) {
          const objFaq = this.faqDetailObj[sqval];
          // alert(objFaq.news+'      JIko       '+selectedkey);
          if (((objFaq.news.toLowerCase()).replace(this.regex, '')).includes(selectedData)) {
            this.pagedItems = [];
            this.pagedItems.push(objFaq);
            // alert('ans    '+JSON.stringify(objFaq));
          }
        }
      } else if (this.pageFullUrl === '/programContacts/programContactsDetailedPage' && this.pageTitle === 'Program Contacts'
        && this.resVal[sval].categoryName === this.categoryName) {// Program contacts detailedpage
        // alert('hi '+this.faqDetailObj.length);
        const selectedData = selectedkey.toLowerCase();
        // tslint:disable-next-line:forin
        for (const sqval in this.faqDetailObj) {
          const objFaq = this.faqDetailObj[sqval];
          // alert(objFaq.contactName+'      JIko       '+selectedkey);
          if (((objFaq.contactName.toLowerCase()).replace(this.regex, '')).includes(selectedData) ||
            ((objFaq.emailId.toLowerCase()).replace(this.regex, '')).includes(selectedData) ||
            (((objFaq.phnNumber).toString().toLowerCase()).replace(this.regex, '')).includes(selectedData)) {
            this.pagedItems = [];
            this.pagedItems.push(objFaq);
            // alert('qstn    '+JSON.stringify(objFaq));
          }
        }
      } else if (this.pageFullUrl === '/userHelp/userHelpDetailedpage' && this.pageTitle === 'User Help'
        && this.resVal[sval].categoryName === this.categoryName) {// user Help Detailedpage
        // alert('hi '+this.faqDetailObj.length);
        const selectedData = selectedkey.toLowerCase();
        // tslint:disable-next-line:forin
        for (const sqval in this.faqDetailObj) {
          const objFaq = this.faqDetailObj[sqval];
          // alert(objFaq.contactName+'      JIko       '+selectedkey);
          if (((objFaq.helpInformation.toLowerCase()).replace(this.regex, '')).includes(selectedData)) {
            this.pagedItems = [];
            this.pagedItems.push(objFaq);
            // alert('qstn    '+JSON.stringify(objFaq));
          }
        }
      } else if (this.pageFullUrl === '/priorAuth/priorAuthDetailedPage' && this.pageTitle === 'Prior Authorization'
        && this.resVal[sval].categoryName === this.categoryName) { // Program Information detailedpage
        // alert('hi '+selectedkey);
        // tslint:disable-next-line:forin
        for (const sqval in this.faqDetailObj) {
          const objFaq = this.faqDetailObj[sqval];
          const selectedData = selectedkey.toLowerCase();
          // alert(objFaq.information+'      JIko       '+selectedkey);
          if (((objFaq.priorAuthInfo.toLowerCase()).replace(this.regex, '')).includes(selectedData)) {
            this.pagedItems = [];
            this.pagedItems.push(objFaq);
            // alert('ans    '+JSON.stringify(objFaq));
          }
        }
      } else if (this.pageFullUrl === '/applicationLinks/applicationLinksDetailedPage' && this.pageTitle === 'Application Links'
        && this.resVal[sval].categoryName === this.categoryName) { // Program Information detailedpage
        // alert('hi '+selectedkey);
        // tslint:disable-next-line:forin
        for (const sqval in this.faqDetailObj) {
          const objFaq = this.faqDetailObj[sqval];
          const selectedData = selectedkey.toLowerCase();
          // alert(objFaq.information+'      JIko       '+selectedkey);
          if (((objFaq.applicationTextUrl.toLowerCase()).replace(this.regex, '')).includes(selectedData)) {
            this.pagedItems = [];
            this.pagedItems.push(objFaq);
            // alert('ans    '+JSON.stringify(objFaq));
          }
        }
      }
    }
    this.searchList = [];
    // this.catId = false;
    document.getElementById('search-result').style.border = 'none';
  }

  loadPageName() {
    this.router.navigate([this.pageUrl]);
  }
  /** Scroll to appropriate info item or category  **/
  scroll(categoryId, infoId) {
    if (categoryId && this.catId !== categoryId) {
      this.httpService.sendInformationId(infoId);
      this.router.navigate([this.pageFullUrl, categoryId]);
    } else {
      let element = document.getElementById('items_' + infoId) as HTMLElement;
      element.focus();
    }
  }
  /** For toggling the information items list */
  clickEvent(id, arrowactive) {
    if(arrowactive ==='down'){
      document.getElementById('down_' + id).classList.add('hidden');
      document.getElementById('right_' + id).classList.remove('hidden');
    } else if(arrowactive ==='right'){
      document.getElementById('down_' + id).classList.remove('hidden');
      document.getElementById('right_' + id).classList.add('hidden');
    }    
  }
  /** Scroll to particular info item and focus on it */
  scrollToInforamtionItem(infoId: Number){
    if(infoId) {
      let element = document.getElementById('items_' + infoId) as HTMLElement;
      element.focus();
      this.searchList=[];
      this.srflag=false;

    }
  }
  pushUrl(catDesc) {
    let contents = catDesc;

    let snippet = document.createElement("div");
    snippet.innerHTML = contents;
    let links = snippet.getElementsByTagName("a");
    
    if (links.length) {
      return links[0].href;
      // window.open(lastURL, "_blank");
    } else {
      return null;
    }
  }

  pushDescription (url,catDesc){
    let snippet = document.createElement("div");
    snippet.innerHTML = catDesc;
    let links = snippet.getElementsByTagName("a");
    let newItem = document.createElement("span");
    let newUrl = "'"+url+"'";
    newItem.innerHTML = '<span tabindex="0" onclick="window.open('+newUrl+',\'_blank\')"  class="mouse-over spanColor">'+links[0].innerHTML+'</span>';
    let tnode = links[0].parentNode;
    tnode.replaceChild(newItem, links[0]);
    return this.sanitizer.bypassSecurityTrustHtml(snippet.innerHTML.toString());
  }
}
