import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { PublicFacingComponent } from './Public-Facing-Pages/public-facing/public-facing.component';
import { SignInAnnouncementComponent } from './sign-in-announcement/sign-in-announcement.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { FaqpageComponent } from './Public-Facing-Pages/faqpage/faqpage.component';
import { FaqdetailsComponent } from './Public-Facing-Pages/faqdetails/faqdetails.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetSecurityQuestionComponent } from './reset-security-question/reset-security-question.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ValidateSecurityQuestionComponent } from './validate-security-question/validate-security-question.component';
import { ActivateUserComponent } from './activate-user/activate-user.component';
import { RegistrationComponent } from './registration/registration.component';
import { DataResolverService } from './_services/data-resolver.service';

const appRoutes: Routes = [
  { path: '', redirectTo: '/publicFacing', pathMatch: 'full' },
  { path: 'disclaimer', component: DisclaimerComponent,
    resolve: {
      userdata: DataResolverService
    },
    data: { title: 'Disclaimer Component' } 
  },
  { path: 'publicFacing', component: PublicFacingComponent, data: { title: 'Public Facing Component' } },
  { path: 'client', component: PublicFacingComponent, data: { title: 'Client Facing Component' } },
  { path: 'provider', component: PublicFacingComponent, data: { title: 'Provider Facing Component' } },
  { path: 'conduentInternal', component: PublicFacingComponent, data: { title: 'Conduent Internal Facing Component' } },
  // { path: 'admin', loadChildren: './Admin-Pages/admin.module#AdminModule' },
  { path: 'unauthorized', component: UnauthorizedComponent, data: { title: 'UnAuthorized Component' } },
  { path: 'signInAnnouncement', component: SignInAnnouncementComponent, data: { title: 'SignIn Announcement Component' } },
  { path: 'faqpage', component: FaqpageComponent, data: { title: 'FAQ' } },
  { path: 'faqpage/faqdetailedpage/:id', component: FaqdetailsComponent, data: { title: 'FAQ' } },
  { path: 'programInfo', component: FaqpageComponent, data: { title: 'Program Information' } },
  { path: 'programInfo/programInfoDetailedPage/:id', component: FaqdetailsComponent, data: { title: 'Program Information' } },
  { path: 'newsAlerts/newsAlertsDetailedPage/:id', component: FaqdetailsComponent, data: { title: 'News & Alerts' } },
  { path: 'programContacts', component: FaqpageComponent, data: { title: 'Program Contacts' } },
  { path: 'programContacts/programContactsDetailedPage/:id', component: FaqdetailsComponent, data: { title: 'Program Contacts' } },
  { path: 'newsAlerts', component: FaqpageComponent, data: { title: 'News & Alerts' } },
  { path: 'priorAuth', component: FaqpageComponent, data: { title: 'Prior Authorization' } },
  { path: 'priorAuth/priorAuthDetailedPage/:id', component: FaqdetailsComponent, data: { title: 'Prior Authorization'}},
  { path: 'userHelp', component: FaqpageComponent, data: { title: 'User Help' } },
  { path: 'userHelp/userHelpDetailedpage/:id', component: FaqdetailsComponent, data: { title: 'User Help' } },
  { path: 'applicationLinks', component: FaqpageComponent, data: { title: 'Application Links' } },
  { path: 'applicationLinks/applicationLinksDetailedPage/:id', component: FaqdetailsComponent, data: { title: 'Application Links' } },
  { path: 'resetPassword', component: ResetPasswordComponent, data: { title: 'Reset Password' } },
  { path: 'resetSecurityQuestion', component: ResetSecurityQuestionComponent, data: { title: 'Reset Security Question' } },
  { path: 'changePassword', component: ChangePasswordComponent, data: { title: 'Change Password' } },
  { path: 'forgotPassword', component: ForgotPasswordComponent, data: { title: 'Forgot Password' } },
  { path: 'validateSecurityQuestion', component: ValidateSecurityQuestionComponent, data: { title: 'Validate Security Question' } },
  { path: 'profile', component: UserProfileComponent, data: { title: 'User Profile' } },
  { path: 'activateUser/:token', component: ActivateUserComponent, data: { title: 'Activate User' } },
  { path: 'register', component: RegistrationComponent, data: { title: 'Registration' } },
];
@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {onSameUrlNavigation: 'reload', useHash: true})
    // RouterModule.forRoot(appRoutes, {onSameUrlNavigation: 'reload', useHash: true, preloadingStrategy: PreloadAllModules})
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule {
}
