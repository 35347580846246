import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
// import { DISCLAIMER } from '../_models/DISCLAIMER';
// import { SCREEN } from '../_models/SCREEN';
import * as configlist from '../../assets/config/config';

//Auth token value
const authToken = btoa(configlist.pageConst.authKey);

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
    // 'Authorization': 'my-auth-token'
  }),
  Observable: 'response'
};
const httpGetOptions = {
  headers: new HttpHeaders({
    // 'Cache-Control': 'no-cache',
    // 'Pragma': 'no-cache'
  })
};
const httpOptionsProviderEnroll = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
	'PBMAuthorization': authToken
  }),
  Observable: 'response'
};
//HTTP auth options included
const httpAuthOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'PBMAuthorization': authToken
  }),
  Observable: 'response'
};

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private subject = new Subject<any>();
  constructor(private http: HttpClient) { }

  /* API call to fetch JWT Authorization token */
  getToken(login): Observable<any> {
    const url = configlist.fetchAuthTokenUrl;
    return this.http.post(url, login, {observe: 'response'});
  }
  getJSON(jname, roleID, screenID): Observable<any> {
    const url = jname + '?roleId=' + roleID + '&screenId=' + screenID;
    return this.http.get(url, httpOptions);
  }
  getJSONPost(jname, roleID, screenID): Observable<any> {
    let jsonObj =  {'roleId':roleID,'screenId':screenID};
    const url = jname;
    return this.http.post(url, jsonObj, httpOptions);
  }

  getJsonContent(url): Observable<any> {
    return this.http.get(url, httpOptions);
  }

  /* API call to fetch screen details */
  get_Screen(screenID, roleID): Observable<any> {
    let screenObj = {'roleId':roleID,'screenId':screenID};
    const url = configlist.screenInfoUrl;
    return this.http.post(url, screenObj, httpOptions);
  }
  /* API call to validate login details */
  validateLogin(login): Observable<any> {
    return this.http.get(configlist.loginData, httpOptions);
  }
  /* API call to fetch program information details */
  getProgInfo(screenID, roleID): Observable<any> {
    // const url = configlist.prgmUrl + '?roleId=' + roleID + '&screenId=' + screenID;
    let programObj = {'roleId': roleID, 'screenId': screenID};
    const url = configlist.prgmUrl;
    return this.http.post(url, programObj, httpOptions);
  }

   /* API call to fetch prior authorization details */
   getPriorAuth(screenID, roleID): Observable<any> {
    const url = configlist.priorAuthUrl + '?roleId=' + roleID + '&screenId=' + screenID;
    return this.http.get(url, httpOptions);
  }
  
  /* API call to fetch program contact details */
  getProgContact(screenID, roleID): Observable<any> {
  	let prgmContObj= {'roleId':roleID, 'screenId':screenID};
    const url = configlist.contactUrl;
    return this.http.post(url, prgmContObj, httpOptions);
  }

  /* API call to fetch program contact details */
  getNewsnAlerts(screenID, roleID): Observable<any> {
    let newsAlertsObj= {'roleId':roleID, 'screenId':screenID};
    const url = configlist.newsUrl;
    return this.http.post(url, newsAlertsObj, httpOptions);
  }
  /* API call to fetch program contact details */
  getFaq(screenID, roleID): Observable<any> {
    const url = configlist.faqUrl + '?roleId=' + roleID + '&screenId=' + screenID;
    return this.http.get(url, httpOptions);
  }
  /* API call to fetch welcome message details */
  getWelcomeMsg(screenID, roleID): Observable<any> {
    let welcomeMsgObj= {'roleId':roleID, 'screenId':screenID};
    const url = configlist.welcomemsgUrl;
    return this.http.post(url, welcomeMsgObj, httpOptions);
  }
  /* API call to fetch disclaimer screen details */
  get_Disclaimer(): Observable<any> {
    return this.http.get<any>(configlist.disclaimerUrl, httpOptions);
  }
  /* API call to fetch footer information details */
  getFooterInfo(): Observable<any> {
    return this.http.get(configlist.footerUrl, httpOptions);
  }
  /* API call to fetch footer information details */
  get_Announcement(roleID, screenID, userId): Observable<any> {
    const signOnUrl = configlist.signAnnouncementUrl + '?roleId=' + roleID + '&screenId=' + screenID +
    '&userId=' + userId;
    return this.http.get(signOnUrl, httpOptions);
  }
  /* API call to fetch client image and logo */
  getClientImage(imageType, screenID, roleID): Observable<Blob> {
    let headerImgObj= {'imageType': imageType,'roleId': roleID,'screenId': screenID};
    const imageURL = configlist.homePageUrl + 'getHeaderImages';
    return this.http.post(imageURL, headerImgObj, { responseType: 'blob' });
  }
  /* API call to fetch facing pages images */
  getImage(catId, screenID, portletId): Observable<Blob> {
    let categoryImgObj = {'categoryId': catId,'screenId': screenID,'portletId': portletId};
    const imageURL = configlist.homePageUrl + 'getCategoryImage';
    return this.http.post(imageURL, categoryImgObj, { responseType: 'blob' });
  }
  /* API call to fetch facing pages images */
  getAppImage(applicationId): Observable<Blob> {
    const imageURL = configlist.appImageUrl + '?applicationId=' + applicationId;
    return this.http.get(imageURL, { responseType: 'blob' });
  }
  /* API call to fetch login details */
  validate_LoginDetails(userId,headers): Observable<any> {
    return this.http.post(configlist.loginDetails, userId, headers);
  }
  /* API call to fetch Security Question */
  getSecurityQuestion(): Observable<any> {
    return this.http.get(configlist.securityQuestionUrl, httpOptions);
  }
  /* API call to update Security Question */
  updateSecurityQuestion(securityQs): Observable<any> {
    const url = configlist.updatesecurityQuestionUrl;
    return this.http.post(url, securityQs, httpOptions);
  }
  /* API call to fetch city and state on basis of zip */
  get_zipDetails(): Observable<any> {
    return this.http.get(configlist.zipdetails, httpOptions);
  }

  /* API call to fetch footer information details */
  getPDF(urlToPrint): Observable<any> {
     console.log(urlToPrint);
    const url = configlist.pdfURL + '?url=' + urlToPrint;
    const httpPdfOptions = {
        'responseType'  : 'blob' as 'json'
    };
    return this.http.get(url, httpPdfOptions);
   }

  /* API call to fetch User Security Question details */
   getUserSecurityQuestions(loginId): Observable<any> {
    // const url = configlist.securityquesUrl + '?userLoginId=' + loginId;
    // return this.http.get(url, httpOptions);
    const url = configlist.securityquesUrl;
    return this.http.post(url, loginId, httpOptions);
  }

  /* API call to update User Login Activity */
  validateUserSecurityQuestions(userSecurityQuesAns): Observable<any> {
    const url = configlist.validateSecurityquesUrl;
    return this.http.post(url, userSecurityQuesAns, httpOptions);
  }

  /* API call to fetch all roles configured by Super Admin */
  get_DisplayroleslistDetails(): Observable<any> {
    return this.http.get(configlist.rolesListUrl, httpOptions);
  }

  /* API call to fetch all roles whose password configuration details can be configured */
  getPasswordConfigRoles(): Observable<any> {
    return this.http.get(configlist.pwdConfigRoles, httpOptions);
  }

  /* API call to fetch Accreditation list */
  get_DisplayAccrediationDetails(): Observable<any> {
    return this.http.get(configlist.accrediationListUrl, httpOptions);
  }

  /* API call to fetch all status lists */
  get_DisplaystatuslistDetails(): Observable<any> {
    return this.http.get(configlist.statusListUrl, httpOptions);
  }

  /* API call to fetch all prefix lists */
  get_prefixlistDetails(): Observable<any> {
    return this.http.get(configlist.prefixListUrl, httpOptions);
  }

  /* API call to fetch account management details for timer */
  getAccountManagementDetails(roleID) {
    // const url = configlist.accountMgmtDetailsUrl + '?roleId=' + roleID;
    // return this.http.get(url, httpOptions);
    const url = configlist.accountMgmtDetailsUrl;
    return this.http.post(url, roleID, httpOptions);
  }

  /* API call to update User Login Activity */
  updateUserLogin(userLoginDetails): Observable<any> {
    const url = configlist.userAccTrackUrl;
    return this.http.post(url, userLoginDetails, httpOptions);
  }

  userLoginTrack(userLoginDetails): Observable<any> {
    const url = configlist.loginAccTrackUrl;
    return this.http.post(url, userLoginDetails, httpOptions);
  }

  /* Secure API call to update User Login Activity */
  secureUpdateUserLogin(userLoginDetails): Observable<any> {
    const url = configlist.secureUserAccTrackUrl;
    return this.http.post(url, userLoginDetails, httpOptions);
  }

  /* API call to update User Login Activity */
  secureUserLoginTrack(userLoginDetails): Observable<any> {
    const url = configlist.secureAccTrackUrl;
    return this.http.post(url, userLoginDetails, httpOptions);
  }

  /* API call to update user accessed activity */
  updateUserAccessedActivity(userAccessedDetails): Observable<any> {
    const url = configlist.updateUserAccessedActivity;
    return this.http.post(url, userAccessedDetails, httpOptions);
  }
  /* API call to fetch application details for a particular user*/
  getAppLinks(userId): Observable<any> {
    const url = configlist.appLinkUrl + '?userId=' + userId;
    return this.http.get(url, httpOptions);
  }

   /** API call to get security questions data answered by the user */
   getUserSecurityQsByLoginId(loginId): Observable<any> {
    // const url = configlist.securityquesUrl + '?userLoginId=' + loginId;
    // return this.http.get(url, httpOptions);
    const url = configlist.securityquesUrl;
    return this.http.post(url, loginId, httpOptions);
  }

   /** API call to get security questions data answered by the user */
  getUserSecurityQsOnlyByLoginId(loginId): Observable<any> {
    // const url = configlist.getUserSecQuesOnlyUrl + '?userLoginId=' + loginId;
    // return this.http.get(url, httpGetOptions);
    const url = configlist.getUserSecQuesOnlyUrl;
    return this.http.post(url, loginId, httpOptions);
  }
  
  /* API call to fetch privileges based on role */
  getPrivilegesByRole(roleId): Observable<any> {
    const url = configlist.rolePrivilegesUrl + '?roleId=' + roleId;
    return this.http.get(url, httpGetOptions);
  }

  /**
   * API call to update User Acceptance Tracking Details
   */
  updateUserAccTrack(userAccTrackReq): Observable<any> {
    const url = configlist.userAccTrackUrl;
    return this.http.post(url, userAccTrackReq, httpOptions);
  }

  /* API call to fetch provider details based on MAID & NPIID */
  validateProviderDetails(validateDetails): Observable<any> {
    let validateUrl = JSON.parse(sessionStorage.getItem('screenInfoData'));
    const url = validateUrl.validateProviderUrl;
    return this.http.post(url, validateDetails, httpAuthOptions);
  }
  /* API call to add new provider user */
  addNewProviderUser(providerUserDet:JSON): Observable<any> {    
    let enrollUrl = JSON.parse(sessionStorage.getItem('screenInfoData'));
    const url = enrollUrl.enrollProviderUrl;
    return this.http.post(url, providerUserDet, httpOptionsProviderEnroll);
  }

  sendInformationId(infoId) {
    this.subject.next({ id: infoId });
  }
  
  clearInformationId() {
    this.subject.next();
  }

  getInformationId(): Observable<any> {
    return this.subject.asObservable();
  }

   /* API call to fetch accereditation details*/
   getAccreditationDetails(): Observable<any> {
    const url = configlist.accreditationUrl;
    return this.http.get(url, httpOptions);
  }

   /* API call to get User Profile data */
   getUserProfileData(loginId): Observable<any> {
    // const url = configlist.getUserProfileDataUrl + '?userLoginId=' + loginId;
    // return this.http.get(url, httpOptions);
    const url = configlist.getUserProfileDataUrl;
    return this.http.post(url, loginId, httpOptions);
  }
}
