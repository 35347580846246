import { Component, OnInit, AfterViewInit , ViewChild, ElementRef,  Renderer2  } from '@angular/core';
import { HttpService } from '../_services/http.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NGXLogger } from 'ngx-logger';
import { UserService } from '../_services/user.service';
import { Router } from '@angular/router';
import * as configList from '../../assets/config/config';
import { CONFIGUREPORTALREQUEST } from '../_models/ConfigurePortal/configureportalrequest';
import { LOGINUSERTRACK } from '../_models/UserActivityTrack/loginusertrack';
import { Idle } from '@ng-idle/core';

import { KeycloakService } from '../keycloak/keycloak.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit  {
  @ViewChild('pos1', {static: true}) pos1: ElementRef;
  @ViewChild('pos2', {static: true}) pos2: ElementRef;
  @ViewChild('pos3', {static: true}) pos3: ElementRef;
  @ViewChild('pos4', {static: true}) pos4: ElementRef;
  @ViewChild('pos5', {static: true}) pos5: ElementRef;
  @ViewChild('pos6', {static: true}) pos6: ElementRef;
  @ViewChild('pos7', {static: true}) pos7: ElementRef;
  @ViewChild('pos8', {static: true}) pos8: ElementRef;
  @ViewChild('pos9', {static: true}) pos9: ElementRef;
  @ViewChild('pos10', {static: true}) pos10: ElementRef;
  @ViewChild('pos11', {static: true}) pos11: ElementRef;
  @ViewChild('pos12', {static: true}) pos12: ElementRef;
  @ViewChild('pos13', {static: true}) pos13: ElementRef;
  @ViewChild('pos14', {static: true}) pos14: ElementRef;
  @ViewChild('pos15', {static: true}) pos15: ElementRef;
  @ViewChild('pos16', {static: true}) pos16: ElementRef;
  @ViewChild('pos17', {static: true}) pos17: ElementRef;
  @ViewChild('pos18', {static: true}) pos18: ElementRef;
  @ViewChild('clientName1', {static: true}) clientName1: ElementRef;
  @ViewChild('clientName2', {static: true}) clientName2: ElementRef;
  @ViewChild('clientName3', {static: true}) clientName3: ElementRef;
  @ViewChild('clientName4', {static: true}) clientName4: ElementRef;
  @ViewChild('clientName5', {static: true}) clientName5: ElementRef;
  @ViewChild('clientName6', {static: true}) clientName6: ElementRef;

  loggedInUserName = '';
  logoToShow: any;
  imageToShow: any;
  bgToShow: any;
  isImageLoading = false;
  public startIdletimer = false;
  public userActivityTrackRequest: CONFIGUREPORTALREQUEST;
  public loginUserTrack: LOGINUSERTRACK;
  public ssoMode: boolean;
  public formSurveyUrl: string;
  //public imageURL: string;
  public constVal = configList.pageConst;
  @ViewChild('formSurveyModal', {static: true}) formSurveyModal: any;
  @ViewChild('loginPanel', {static: true}) loginPanel: ElementRef;
  @ViewChild('loggedinPanel', {static: true}) loggedinPanel: ElementRef;
  
  constructor(private httpService: HttpService, private sanitizer: DomSanitizer, private logger: NGXLogger,
        private userDet: UserService, private router: Router, private idle: Idle, private kc: KeycloakService,
        private renderer:  Renderer2 ) {
      sessionStorage.setItem('location', window.location.href);
      if (localStorage.getItem('isLoggedIn') === 'true' && sessionStorage.getItem('isLoggedIn') !== 'true') {
        sessionStorage.setItem('isLoggedIn', localStorage.getItem('isLoggedIn'));
        sessionStorage.setItem('loggedInUserName', localStorage.getItem('loggedInUserName'));        
        sessionStorage.setItem('loginRoleId', localStorage.getItem('loginRoleId'));
        sessionStorage.setItem('privilegeData', localStorage.getItem('privilegeData'));
        sessionStorage.setItem('userRoleId', localStorage.getItem('userRoleId'));
        sessionStorage.setItem('loginScreenId', localStorage.getItem('loginScreenId'));
        sessionStorage.setItem('loginUserStatus', localStorage.getItem('loginUserStatus'));
        sessionStorage.setItem('logOut', localStorage.getItem('logOut'));
        sessionStorage.setItem('isAgreeClicked', localStorage.getItem('isAgreeClicked'));
        sessionStorage.setItem('addressDetails', localStorage.getItem('addressDetails'));
      }
      this.logoToShow = '';
      this.imageToShow = '';
      this.getssoMode();
  }

  ngOnInit() {
    this.formSurveyUrl = '';
  }

  ngAfterViewInit(): void {
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.
    if (sessionStorage.getItem('loginRoleId') !== null && sessionStorage.getItem('loginRoleId') !== 'null'
        && sessionStorage.getItem('loginRoleId') !== String(configList.pageConst.NAPortletId)) {
      // document.getElementById('loginPanel').classList.add('d-none');
      // document.getElementById('loginPanel').setAttribute('aria-hidden', 'true');
      // document.getElementById('loggedinPanel').classList.remove('d-none');
      // document.getElementById('loggedinPanel').setAttribute('aria-hidden', 'false');
      //console.log(this.loginPanel);
      //console.log(this.loggedinPanel);
      this.renderer.addClass(this.loginPanel.nativeElement, 'd-none');
      this.renderer.removeClass(this.loggedinPanel.nativeElement, 'd-none');
      this.renderer.setAttribute(this.loginPanel.nativeElement, 'aria-hidden', 'true');
      this.renderer.setAttribute(this.loggedinPanel.nativeElement, 'aria-hidden', 'false');
      //Following lines is added only for testing innerhtml and tab index
      //this.renderer.setProperty(this.loggedinPanel.nativeElement,'innerHTML','I am Fine');
      //this.renderer.setAttribute(this.loggedinPanel.nativeElement,'tabIndex','0');
      this.loggedInUserName = sessionStorage.getItem('loggedInFirstName');
      // if (this.pos7.nativeElement !== undefined && this.pos8.nativeElement !== undefined && this.pos9.nativeElement !== undefined){
        // this.getHeaderDisplay(JSON.parse(sessionStorage.getItem('screenInfoData')));
      // }
     }
     if (!sessionStorage.getItem('screenInfoData')) {
      this.httpService.get_Screen(this.userDet.getScreenId(), this.userDet.getRoleId())
      .subscribe(
        (data: any) => {
          this.getHeaderDisplay(data);
        },
        (error) => {
          this.logger.debug('error in get screen service call');
        });
    } else {
      this.getHeaderDisplay(JSON.parse(sessionStorage.getItem('screenInfoData')));
    }
    
    if (sessionStorage.getItem('loginRoleId') !== null && sessionStorage.getItem('loginRoleId') !== 'null'
        && sessionStorage.getItem('loginRoleId') !== String(configList.pageConst.NAPortletId)) {
      document.getElementById('loginPanel').classList.add('d-none');
      document.getElementById('loginPanel').setAttribute('aria-hidden', 'true');
      document.getElementById('loggedinPanel').classList.remove('d-none');
      document.getElementById('loggedinPanel').setAttribute('aria-hidden', 'false');
      this.loggedInUserName = sessionStorage.getItem('loggedInFirstName');
      document.getElementById('head-row').classList.add('pad-Zero');
    }
// 15372 CWP_Admin_User able to login after clicking on reject on EULA and access the website
    if ( localStorage.getItem('isLoggedIn') != 'true' &&  !this.skipRerouteToPF() && (localStorage.getItem('screenInfoData') == null || localStorage.getItem('isAgreeClicked') == null) ){
      this.reroutePF();
    }
    if (localStorage.getItem('isLoggedIn') != 'true' && localStorage.getItem('screenInfoData') != null && JSON.parse(localStorage.getItem('screenInfoData')).disclaimerFlag === this.constVal.constYes && localStorage.getItem('isAgreeClicked') !== 'true') {
      this.renderer.setAttribute(this.loginPanel.nativeElement, 'aria-hidden', 'true');
      this.renderer.addClass(this.loginPanel.nativeElement, 'd-none');
    }
  }

  skipRerouteToPF(){
    console.log('this.router.url'+this.router.url);
    let ignoreRerouteToPFUrls: string[] = ["/activateUser/","/resetPassword","/resetSecurityQuestion"];
    if(!this.router.url){
      return false;
    }

    for(var routeUrl of ignoreRerouteToPFUrls){
      if(this.router.url.startsWith(routeUrl)){
        return true;
      }
    }

    return false;

  }

  /*To Get sso mode is enabled or not if enabled, assign ssoMode variable to true */
  getssoMode(){
    this.ssoMode = sessionStorage.getItem('ssoMode') === 'Y' ? true : false;
   }

  /** Header Display logic */
  getHeaderDisplay(data) {
    localStorage.setItem('ssoMode', data.ssoMode);
    sessionStorage.setItem('ssoMode', data.ssoMode);
    this.getssoMode();
    this.getLogoFromService();
    this.getImageFromService();
    this.getBgImageFromService();
    if (data.clientLogoPosition  === 1 && data.clientNamePosition === 2  && data.clientImagePosition === 3
      && document.getElementById('clientName1') !== null) {
      // document.getElementById('clientName1').innerHTML = data.clientName;
      this.renderer.setProperty(this.clientName1.nativeElement,'innerHTML', data.clientName);
      this.renderer.removeClass(this.pos1.nativeElement, 'hidden');
      this.renderer.removeClass(this.pos2.nativeElement, 'hidden');
      this.renderer.removeClass(this.pos3.nativeElement, 'hidden');
      this.renderer.setAttribute(this.pos1.nativeElement, 'aria-hidden', 'false');
      this.renderer.setAttribute(this.pos2.nativeElement, 'aria-hidden', 'false');
      this.renderer.setAttribute(this.pos3.nativeElement, 'aria-hidden', 'false');
      this.renderer.setAttribute(this.pos1.nativeElement, 'tabIndex', '0');
      this.renderer.setAttribute(this.pos2.nativeElement, 'tabIndex', '0');
      this.renderer.setAttribute(this.pos3.nativeElement, 'tabIndex', '0');
      // document.getElementById('pos1').classList.remove('hidden');
      // document.getElementById('pos2').classList.remove('hidden');
      // document.getElementById('pos3').classList.remove('hidden');
      // document.getElementById('pos1').setAttribute('aria-hidden', 'false');
      // document.getElementById('pos2').setAttribute('aria-hidden', 'false');
      // document.getElementById('pos3').setAttribute('aria-hidden', 'false');
      // document.getElementById('pos1').tabIndex = 0;
      // document.getElementById('pos2').tabIndex = 0;
      // document.getElementById('pos3').tabIndex = 0;
    } else if (data.clientLogoPosition  === 1 && data.clientNamePosition === 3  && data.clientImagePosition === 2
      && document.getElementById('clientName2') !== null) {
      // document.getElementById('clientName2').innerHTML = data.clientName;
      this.renderer.setProperty(this.clientName2.nativeElement,'innerHTML', data.clientName);
      this.renderer.removeClass(this.pos4.nativeElement, 'hidden');
      this.renderer.removeClass(this.pos5.nativeElement, 'hidden');
      this.renderer.removeClass(this.pos6.nativeElement, 'hidden');
      this.renderer.setAttribute(this.pos4.nativeElement, 'aria-hidden', 'false');
      this.renderer.setAttribute(this.pos5.nativeElement, 'aria-hidden', 'false');
      this.renderer.setAttribute(this.pos6.nativeElement, 'aria-hidden', 'false');
      this.renderer.setAttribute(this.pos4.nativeElement, 'tabIndex', '0');
      this.renderer.setAttribute(this.pos5.nativeElement, 'tabIndex', '0');
      this.renderer.setAttribute(this.pos6.nativeElement, 'tabIndex', '0');
      // document.getElementById('pos4').classList.remove('hidden');
      // document.getElementById('pos5').classList.remove('hidden');
      // document.getElementById('pos6').classList.remove('hidden');
      // document.getElementById('pos4').setAttribute('aria-hidden', 'false');
      // document.getElementById('pos5').setAttribute('aria-hidden', 'false');
      // document.getElementById('pos6').setAttribute('aria-hidden', 'false');
      // document.getElementById('pos4').tabIndex = 0;
      // document.getElementById('pos5').tabIndex = 0;
      // document.getElementById('pos6').tabIndex = 0;
    } else if (data.clientLogoPosition  === 2 && data.clientNamePosition === 1  && data.clientImagePosition === 3
      && document.getElementById('clientName3') !== null) {
      // document.getElementById('clientName3').innerHTML = data.clientName;
      this.renderer.setProperty(this.clientName3.nativeElement,'innerHTML', data.clientName);
      this.renderer.removeClass(this.pos7.nativeElement , 'hidden');
      this.renderer.removeClass(this.pos8.nativeElement, 'hidden');
      this.renderer.removeClass(this.pos9.nativeElement, 'hidden');
      this.renderer.setAttribute(this.pos7.nativeElement, 'aria-hidden', 'false');
      this.renderer.setAttribute(this.pos8.nativeElement, 'aria-hidden', 'false');
      this.renderer.setAttribute(this.pos9.nativeElement, 'aria-hidden', 'false');
      this.renderer.setAttribute(this.pos7.nativeElement, 'tabIndex', '0');
      this.renderer.setAttribute(this.pos8.nativeElement, 'tabIndex', '0');
      this.renderer.setAttribute(this.pos9.nativeElement, 'tabIndex', '0');
      // document.getElementById('pos7').classList.remove('hidden');
      // document.getElementById('pos8').classList.remove('hidden');
      // document.getElementById('pos9').classList.remove('hidden');
      // document.getElementById('pos7').setAttribute('aria-hidden', 'false');
      // document.getElementById('pos8').setAttribute('aria-hidden', 'false');
      // document.getElementById('pos9').setAttribute('aria-hidden', 'false');
      // document.getElementById('pos7').tabIndex = 0;
      // document.getElementById('pos8').tabIndex = 0;
      // document.getElementById('pos9').tabIndex = 0;
    } else if (data.clientLogoPosition  === 3 && data.clientNamePosition === 1  && data.clientImagePosition === 2
      && document.getElementById('clientName4') !== null) {
      // document.getElementById('clientName4').innerHTML = data.clientName;
      this.renderer.setProperty(this.clientName4.nativeElement,'innerHTML', data.clientName);
      this.renderer.removeClass(this.pos10.nativeElement, 'hidden');
      this.renderer.removeClass(this.pos11.nativeElement, 'hidden');
      this.renderer.removeClass(this.pos12.nativeElement, 'hidden');
      this.renderer.setAttribute(this.pos10.nativeElement, 'aria-hidden', 'false');
      this.renderer.setAttribute(this.pos11.nativeElement, 'aria-hidden', 'false');
      this.renderer.setAttribute(this.pos12.nativeElement, 'aria-hidden', 'false');
      this.renderer.setAttribute(this.pos10.nativeElement, 'tabIndex', '0');
      this.renderer.setAttribute(this.pos11.nativeElement, 'tabIndex', '0');
      this.renderer.setAttribute(this.pos12.nativeElement, 'tabIndex', '0');
      // document.getElementById('pos10').classList.remove('hidden');
      // document.getElementById('pos11').classList.remove('hidden');
      // document.getElementById('pos12').classList.remove('hidden');
      // document.getElementById('pos10').setAttribute('aria-hidden', 'false');
      // document.getElementById('pos11').setAttribute('aria-hidden', 'false');
      // document.getElementById('pos12').setAttribute('aria-hidden', 'false');
      // document.getElementById('pos10').tabIndex = 0;
      // document.getElementById('pos11').tabIndex = 0;
      // document.getElementById('pos12').tabIndex = 0;
    } else if (data.clientLogoPosition  === 2 && data.clientNamePosition === 3  && data.clientImagePosition === 1
      && document.getElementById('clientName5') !== null) {
      // document.getElementById('clientName5').innerHTML = data.clientName;
      this.renderer.setProperty(this.clientName5.nativeElement,'innerHTML', data.clientName);
      this.renderer.removeClass(this.pos13.nativeElement, 'hidden');
      this.renderer.removeClass(this.pos14.nativeElement, 'hidden');
      this.renderer.removeClass(this.pos15.nativeElement, 'hidden');
      this.renderer.setAttribute(this.pos13.nativeElement, 'aria-hidden', 'false');
      this.renderer.setAttribute(this.pos14.nativeElement, 'aria-hidden', 'false');
      this.renderer.setAttribute(this.pos15.nativeElement, 'aria-hidden', 'false');
      this.renderer.setAttribute(this.pos13.nativeElement, 'tabIndex', '0');
      this.renderer.setAttribute(this.pos14.nativeElement, 'tabIndex', '0');
      this.renderer.setAttribute(this.pos15.nativeElement, 'tabIndex', '0');
      // document.getElementById('pos13').classList.remove('hidden');
      // document.getElementById('pos14').classList.remove('hidden');
      // document.getElementById('pos15').classList.remove('hidden');
      // document.getElementById('pos13').setAttribute('aria-hidden', 'false');
      // document.getElementById('pos14').setAttribute('aria-hidden', 'false');
      // document.getElementById('pos15').setAttribute('aria-hidden', 'false');
      // document.getElementById('pos13').tabIndex = 0;
      // document.getElementById('pos14').tabIndex = 0;
      // document.getElementById('pos15').tabIndex = 0;
    } else if (data.clientLogoPosition  === 3 && data.clientNamePosition === 2  && data.clientImagePosition === 1
      && document.getElementById('clientName6') !== null) {
      // document.getElementById('clientName6').innerHTML = data.clientName;
      this.renderer.setProperty(this.clientName6.nativeElement,'innerHTML', data.clientName);
      this.renderer.removeClass(this.pos16.nativeElement, 'hidden');
      this.renderer.removeClass(this.pos17.nativeElement, 'hidden');
      this.renderer.removeClass(this.pos18.nativeElement, 'hidden');
      this.renderer.setAttribute(this.pos16.nativeElement, 'aria-hidden', 'false');
      this.renderer.setAttribute(this.pos17.nativeElement, 'aria-hidden', 'false');
      this.renderer.setAttribute(this.pos18.nativeElement, 'aria-hidden', 'false');
      this.renderer.setAttribute(this.pos16.nativeElement, 'tabIndex', '0');
      this.renderer.setAttribute(this.pos17.nativeElement, 'tabIndex', '0');
      this.renderer.setAttribute(this.pos18.nativeElement, 'tabIndex', '0');
      // document.getElementById('pos16').classList.remove('hidden');
      // document.getElementById('pos17').classList.remove('hidden');
      // document.getElementById('pos18').classList.remove('hidden');
      // document.getElementById('pos16').setAttribute('aria-hidden', 'false');
      // document.getElementById('pos17').setAttribute('aria-hidden', 'false');
      // document.getElementById('pos18').setAttribute('aria-hidden', 'false');
      // document.getElementById('pos16').tabIndex = 0;
      // document.getElementById('pos17').tabIndex = 0;
      // document.getElementById('pos18').tabIndex = 0;
    }
  }
 getLogoFromService() {
    this.isImageLoading = true;
    this.httpService.getClientImage('clientLogo', this.userDet.getScreenId(), this.userDet.getRoleId()).subscribe(data => {
      const urlCreator = window.URL;
      this.logoToShow = this.sanitizer.bypassSecurityTrustResourceUrl(
        urlCreator.createObjectURL(data));
      this.isImageLoading = false;
    }, error => {
      this.isImageLoading = false;
      this.logoToShow = this.sanitizer.bypassSecurityTrustResourceUrl(configList.defaultImages.headerClientLogo);
      console.log(error);
    });
  }

  getImageFromService() {
    this.isImageLoading = true;
    this.httpService.getClientImage('clientImage', this.userDet.getScreenId(), this.userDet.getRoleId()).subscribe(data => {
      const urlCreator = window.URL;
      this.imageToShow = this.sanitizer.bypassSecurityTrustUrl(
        urlCreator.createObjectURL(data));
      this.isImageLoading = false;
    }, error => {
      this.isImageLoading = false;
      this.imageToShow = this.sanitizer.bypassSecurityTrustResourceUrl(configList.defaultImages.headerClientImage);
      console.log(error);
    });
  }
  getBgImageFromService() {
    this.isImageLoading = true;
    this.httpService.getClientImage('backGroundImage', this.userDet.getScreenId(), this.userDet.getRoleId()).subscribe(data => {
      const urlCreator = window.URL;
      //this.imageURL = urlCreator.createObjectURL(data);
      const imageStyle = `background : url(${urlCreator.createObjectURL(data)}) no-repeat 0px;opacity: 0.99; background-size:cover;`;
      this.bgToShow = this.sanitizer.bypassSecurityTrustStyle(imageStyle);
      this.isImageLoading = false;
    }, error => {
      this.isImageLoading = false;
      const imageStyle = `background : url(` + configList.defaultImages.headerBgImage + `) no-repeat 0px;opacity: 0.99; background-size: cover;`;
      this.bgToShow = this.sanitizer.bypassSecurityTrustStyle(imageStyle);
      console.log(error);
    });
  }

  openLoginPanel() {
    if(this.ssoMode){
      this.kc.login();
        window.open(sessionStorage.getItem('keycloakURL'), 'keycloakWindow', 'width=800,height=500, top=110, left=260');
      } else {
        if (document.getElementById('panel').classList.contains('d-none')) {
          document.getElementById('panel').classList.remove('d-none');
          document.getElementById('panel').classList.add('d-block');
          document.getElementById('chevron').classList.remove('fa-angle-double-down');
          document.getElementById('chevron').classList.add('fa-angle-double-up');
          document.getElementById('chevron').setAttribute('aria-label', 'arrow expanded');
        } else if (document.getElementById('panel').classList.contains('d-block')) {
          document.getElementById('panel').classList.add('d-none');
          document.getElementById('panel').classList.remove('d-block');
          document.getElementById('chevron').classList.remove('fa-angle-double-up');
          document.getElementById('chevron').classList.add('fa-angle-double-down');
          document.getElementById('chevron').setAttribute('aria-label', 'arrow collapsed');
        }
      }  
  }

  logout() {
    let tempLoginRoleId = +sessionStorage.getItem('loginRoleId');
    const surveyFormData = JSON.parse(sessionStorage.getItem('screenInfoData'));
    this.idle.stop();
    this.idle.onTimeout.observers.length = 0;
    this.idle.onIdleStart.observers.length = 0;
    this.idle.onIdleEnd.observers.length = 0;
    this.idle.onTimeoutWarning.observers.length = 0;
    this.userActivityTrackRequest = new CONFIGUREPORTALREQUEST();
    this.loginUserTrack = new LOGINUSERTRACK();
    this.userActivityTrackRequest.screenId = this.userDet.getScreenId();
    this.userActivityTrackRequest.portletId = configList.pageConst.NAPortletId;
    this.userActivityTrackRequest.updatedBy = sessionStorage.getItem('loggedInUserName');
    this.userActivityTrackRequest.activityCode = configList.activityCode.logoutAuditActCode;
    this.loginUserTrack.userLoginId = sessionStorage.getItem('loggedInUserName');
    this.loginUserTrack.roleId = Number(sessionStorage.getItem('userRoleId'));
    this.userActivityTrackRequest.loginUserTrack = this.loginUserTrack;    
    this.httpService.updateUserLogin(this.userActivityTrackRequest).subscribe(
      (data: any) => {
        console.log(data);
    },
    (error: any) => {
      console.log(error);
    });
    
    sessionStorage.setItem('loginRoleId', '-1');
    sessionStorage.setItem('privilegeData', null);
    sessionStorage.setItem('userRoleId', '-1');
    sessionStorage.setItem('loginScreenId', '1');
    sessionStorage.setItem('logOut', 'Y');
    sessionStorage.setItem('isLoggedIn', 'false');
    sessionStorage.setItem('loggedInUserName', null);
    sessionStorage.setItem('loggedInFirstName', null);
    sessionStorage.setItem('isLogout', 'Y');
    sessionStorage.removeItem('authToken');
    sessionStorage.removeItem("LoginToken");
    sessionStorage.removeItem("keycloakURL");
    sessionStorage.removeItem("kcsessionid");
    sessionStorage.removeItem("loggedInUserId");
    sessionStorage.removeItem("loginemail");
    sessionStorage.removeItem("loginUserStatus");
    sessionStorage.removeItem("isTempPwd");
    let tempAgreeClicked = localStorage.getItem('isAgreeClicked');
    let tempScreenInfo = localStorage.getItem('screenInfoData');
    localStorage.clear();
    if(tempScreenInfo != null){
      localStorage.setItem('screenInfoData',tempScreenInfo);
    }
    if(tempAgreeClicked != null){
      localStorage.setItem('isAgreeClicked',tempAgreeClicked);
    }
    if ( this.constVal.roleClient === tempLoginRoleId && surveyFormData.showFeedBackFormClient ) {
      this.surveyFocus();
    } else if ( this.constVal.roleProvider === tempLoginRoleId && surveyFormData.showFeedBackFormProvider ) {
      this.surveyFocus();
    } else if ( this.constVal.roleConduentInternal === tempLoginRoleId && surveyFormData.showFeedBackFormConduentInternal ) {
      this.surveyFocus();
    } else if ( this.constVal.roleAdmin === tempLoginRoleId && surveyFormData.showFeedBackFormAdmin ) {
      this.surveyFocus();
    } else {
      this.reroutePF(true);
    }
  }

  /** Navigation to home based on roles */
  navigateHome() {
    this.userDet.homeNavigation();
  }

  /** Navigation to User Profile */
  toProfile() {
    this.router.navigate(['/profile']);
  }

  /**Mobile check */
  mobileCheck() {
    if((typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)) {
      this.loggedInUserName = this.loggedInUserName.slice(0,5) + '..';
    } 
    return this.loggedInUserName;
  }

  /* Navigate to Forgot Password */
  rerouteFP() {
    this.router.navigate(['/forgotPassword']);
  }
  /* Navigate to Provider Enrollment*/
  rerouteRP() {
    this.router.navigate(['/register']);
  }

  /* Navigate to Public Facing Page*/
  reroutePF(forceRefresh?: boolean) {
    sessionStorage.removeItem('screenInfoData');
    localStorage.removeItem('screenInfoData');
    console.log('reroutePF in: '+forceRefresh);
    if((typeof forceRefresh !== 'undefined')  && this.router.url == '/publicFacing'){
      window.location.reload();
    }else{
      this.router.navigate(['/publicFacing']);
    }
  }


  /** Check for logout redirection and display survey monkey pop-up */
  surveyFocus() {
    const surveyUrl = JSON.parse(sessionStorage.getItem('screenInfoData'));
    this.formSurveyUrl = surveyUrl.formSurveyUrl;
    if (this.formSurveyModal !== undefined) {
      this.formSurveyModal.nativeElement.style.display = 'block';
    }
    document.getElementById('formsSurveyHeader').focus();
  }

  openFormSurvvey() {
    window.open(this.formSurveyUrl, '_blank');
    if (this.formSurveyModal !== undefined) {
      this.formSurveyModal.nativeElement.style.display = 'none';
    }
    this.reroutePF(true);
  }

  closeFormSurvvey() {
    if (this.formSurveyModal !== undefined) {
      this.formSurveyModal.nativeElement.style.display = 'none';
    }
    this.reroutePF(true);
  }

}
