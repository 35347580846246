
import { Component, OnInit, Input, ViewChild } from '@angular/core'; // added by vikranth for msg label
import { HttpService } from '../../_services/http.service';
import { UserService } from '../../_services/user.service';
import { pageConst} from '../../../assets/config/config';
@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  res: any = {
    'title': 'WELCOME MESSAGE'
  };
  @Input() welcomeTileLabel: string; // added by vikranth for msg label
    public constVal = pageConst;
    @ViewChild('carouselRightArrow') carouselRightArrow: any; // needed for ADA
    public carouselSetInterval: any;
  resVal  = [];
  resKeys: any;
  data = [];
  public carouselInterval: string;
  constructor(private httpService: HttpService, private userDet: UserService) {
    httpService.getWelcomeMsg(this.userDet.getScreenId(), this.userDet.getRoleId()).subscribe(
      (data: any) => {
        data.welcomeMsgResponseList.forEach(obj => {
          obj.message = this.userDet.pushDescription(obj.message);
        });
        this.resVal = Object.values(data.welcomeMsgResponseList);
        // this.resKeys = Object.keys(data.programInfoResponse).reverse();
        this.resKeys  = Object.keys(data.welcomeMsgResponseList);
        this.data = data;
        this.setCarouselInterval();
      }
    );
    this.carouselInterval = sessionStorage.getItem('carouselInterval');
  }

  ngOnInit() {
  }

  setCarouselInterval() {
    this.carouselSetInterval = setInterval (() => {
         this.carouselRightArrow.nativeElement.click();
      }, +sessionStorage.getItem('carouselInterval'));
  }
  
  clearCarouselInterval() {
  	clearInterval(this.carouselSetInterval);
  }

}
