// modal.service.ts

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modalOpenSource = new Subject<boolean>();
  modalOpen$ = this.modalOpenSource.asObservable();

  private modalContentSource = new Subject<string>();
  modalContent$ = this.modalContentSource.asObservable();

  openModal(content: string) {
    this.modalOpenSource.next(true);
    this.modalContentSource.next(content);
  }

  closeModal() {
    this.modalOpenSource.next(false);
  }
}
