import { Pipe, PipeTransform } from '@angular/core';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({ name: 'searchFilter' })
export class SearchFilterPipe implements PipeTransform {
    transform(items: Array<{ [key: string]: any }>, term: string): Array<{ [key: string]: any }> {
        const toCompare = term.toLowerCase();

        return items.filter(function (item: any) {
            for (const property in item) {
                if (item[property] === null || item[property] === undefined) {
                    continue;
                }
                if (item[property].toString().toLowerCase().includes(toCompare)) {
                    return true;
                }
            }
            return false;
        });
    }
}
