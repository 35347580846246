import { Component, OnInit, Renderer2, AfterViewInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpService } from '../_services/http.service';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import * as configList from '../../assets/config/config';
import { CONFIGUREPORTALREQUEST } from '../_models/ConfigurePortal/configureportalrequest';
import { UserService } from '../_services/user.service';
// import { MatSort, MatTableDataSource, MatPaginator, MatTable } from '@angular/material';
import { MatTable } from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { USER } from '../_models/USER';
import { ZipErrorMatcher, EmailErrorMatcher, UserIdErrorMatcher } from '../_helpers/errorMatcher.interceptor';
import { DatePipe } from '@angular/common';
import { LoaderService } from '../_services/loader.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit, AfterViewInit {
  configurePortalRequest: CONFIGUREPORTALREQUEST;
  dataSourceTwo: MatTableDataSource<any>;
  displayedColumnsTwo: string[] = ['maId', 'npiId','valid', 'doctorFirstName', 'doctorLastName' , 'delete'];
  maIdTable = [];
  maNpiRequired: boolean;
  itemCounter = 0;
  saveUser: USER;
  npiError = true;
  maIdSpaces = true;
  userDataForm: FormGroup;
  identificationForm: FormGroup;
  tooltipconstdata = configList.tooltipConst;
  consterror = configList.errorConst;
  phoneNumberString;
  mask: any = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  accrediations: any[];
  zipFind: number;
  errorMsg: String
  found: boolean;
  duplicateUser = false;
  addressDetails: any[];
  crossFieldErrorMatcher = new ZipErrorMatcher();
  emailDuplicateErrorMatcher = new EmailErrorMatcher();
  userIdErrorMatcher = new UserIdErrorMatcher();
  @ViewChild('providerForm') providerForm: any;
  @ViewChild('messageModal',{static:true}) messageModal: any;
  showAlertUC: boolean;
  validateProviderEnabled: string;


  constructor(private httpService: HttpService, private router: Router,
    private logger: NGXLogger, private formBuilder: FormBuilder, private userDet: UserService, private datePipe: DatePipe,
    private renderer: Renderer2, private loaderService: LoaderService) {

  }

  ngAfterViewInit() {
    setTimeout(() => {
      let elem = this.renderer.selectRootElement('#ma_id');
      elem.focus();
    }, 100);
  }

  ngOnInit() {
    this.addBreadCrumb();
    this.initializeForm();
    this.getaccrediation();
    this.getzipData();
    this.onChanges();
    this.messageModal.nativeElement.style.display = 'none';
    let validateEnabled = JSON.parse(sessionStorage.getItem('screenInfoData'));
    this.validateProviderEnabled = validateEnabled.validateProviderFlag;
  }

  /**This method used to initialize the identification and user forms */
  initializeForm() {
    this.identificationForm = this.formBuilder.group({
      npiId: ['', [Validators.required, Validators.pattern(configList.pageConst.npiIdRegex)]],
      maId: ['', [Validators.required, this.checkSpaces]],
      doctorFirstName: ['', [Validators.required, this.checkSpaces]],
      doctorLastName: ['', [Validators.required, this.checkSpaces]]
    });
    this.userDataForm = this.formBuilder.group({
      userId: ['', [Validators.required, Validators.pattern(configList.pageConst.userNameRegex), this.checkSpaces]],
      dupUserId: [{ value: 'false' }],
      emailAddress: ['', [Validators.required, Validators.pattern(configList.pageConst.emailRegex)]],
      dupEmail: [{ value: 'false' }],
      lastName: ['', [Validators.required, this.checkSpaces]],
      firstName: ['', [Validators.required, this.checkSpaces]],
      phone: ['', [Validators.required, this.checkPhone]],
      address1: ['', [Validators.required, this.checkSpaces]],
      address2: ['', this.checkSpaces],
      prefix: ['', this.checkSpaces],
      accreditation: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zipCode1: ['', [Validators.required, Validators.pattern(configList.pageConst.zip1Regex)]],
      zipCode2: ['', [Validators.pattern(configList.pageConst.zip2Regex)]],
      faxNumber: ['', this.checkFax],
      zipFound: [{ value: 'false' }]
    }, {
      validator: [this.checkValidZip, this.duplicateEmail, this.duplicateUserId]
    })
  }

  get g() { return this.identificationForm.controls; }
  get m() { return this.userDataForm.controls; }


  /* This method is used to redirect the user to the  homepage if the home link  */
  navigateHome() {
    this.userDet.homeNavigation();
  }

  /** This method is used to fetch the US zip codes */
  getzipData() {
    this.httpService.get_zipDetails().subscribe(
      (data: any) => {
        this.addressDetails = data;
      },
      error => {
        this.logger.debug('Error while getting Zipcode data');
      }
    );
  }
  /** This method is used to fetch the accreditation*/
  getaccrediation() {
    this.httpService.getAccreditationDetails().subscribe(
      (data: any) => {
        this.accrediations = data;
      },
      error => {
        this.logger.debug('Error while getting Accreditation');
      }
    );
  }

  /** Validate provider based on MAID & NPIID */
  providerValidate() {
    if(this.validateProviderEnabled === 'Y') {
      let finalData = Object();
      finalData.messageContent = this.userDet.encryptData({'npiID': this.g.npiId.value,'maID': this.g.maId.value});
      this.httpService.validateProviderDetails(finalData).subscribe(data => {
        console.log(data);
        if(data) {
          this.appendToTable();
        } else {
          this.errorMsg = this.consterror.validateError;
          this.showAlertUC = true;
          document.getElementById('ucModal').style.display = 'block';
        }
        this.identificationForm.markAsPristine();
    	this.identificationForm.markAsUntouched();
      },
      error => {
        this.logger.debug('Error while checking provider details');
      });
    } else {
      this.appendToTable();
      this.identificationForm.markAsPristine();
      this.identificationForm.markAsUntouched();
    }
  }

  /* This method used to validate and show NPI Id, MaId and editable doctor name in the mat-table */
  appendToTable() {
    // if (true) {
      ++this.itemCounter;
      let screenVal = Object();
      screenVal.id = null;
      screenVal.maId = this.g.maId.value;
      screenVal.npiId = this.g.npiId.value;
      screenVal.doctorFirstName = this.g.doctorFirstName.value;
      screenVal.doctorLastName = this.g.doctorLastName.value;
      this.maIdTable.push(screenVal);
      this.dataSourceTwo = new MatTableDataSource(this.maIdTable);
      this.identificationForm.reset();
      this.npiError = true;
      this.maIdSpaces = true;
      this.maNpiRequired = false;
      if (this.maIdTable.length === 9 && !document.getElementById('validate').classList.contains('chk')) {
        document.getElementById('validate').classList.add('chk');
      }
      document.getElementById('userDetailsFormId').classList.remove('chk');
      // this.initializeForm();
    // } 
    // else {
    //   this.errorMsg = '';
    //   this.showAlertUC = true;
    //   document.getElementById('ucModal').style.display = 'block';
    // }
    // this.identificationForm.markAsPristine();
    // this.identificationForm.markAsUntouched();
  }
  /** This mehod used to delete the added npi, ma id's from mat-table */
  deleteMaId(i) {
    this.maIdTable.splice(i, 1);
    this.dataSourceTwo = new MatTableDataSource(this.maIdTable);
    --this.itemCounter;
    if (this.itemCounter < 1) {
      this.maNpiRequired = true;
    }
    if (this.maIdTable.length < 9 && document.getElementById('validate').classList.contains('chk')) {
      document.getElementById('validate').classList.remove('chk');
    }
  }

  /* <-This method is used to un-mask the phone number->*/
  maskphonenum(number) {
    if (isNaN(number)) {
      number = number.substring(1, 4).trim() + number.substring(5, 9).trim() + number.substring(10, 14).trim();
    }
    return number;
  }

  /* This method is used to check if Zip Code is valid */
  checkValidZip(form: FormGroup) {
    let condition = false;
    if (form.get('zipFound').value === 'true') {
      condition = true;
    }
    return condition ? { zipError: true } : null;
  }

  /* This method is used to check if Zip Code is valid */
  checkValidUserId(form: FormGroup) {
    return this.duplicateUser ? { duplicaterUserId: true } : null;
  }

  /**Used to show the page title in the breadcrumb */
  addBreadCrumb() {
    if (document.getElementById('addBreadCrumb').innerHTML === '') {
      const node = document.createElement('a');
      const textnode = document.createTextNode('Provider Web Portal Signup');
      node.appendChild(textnode);
      document.getElementById('addBreadCrumb').innerHTML = '';
      document.getElementById('addBreadCrumb').appendChild(node);
    }
  }

  /* Method to trigger an error message if the phone number is not in the desired format*/
  checkFax(control: FormControl) {
    if (control.value) {
      const faxRegex = configList.pageConst.faxRegex;
      let condition = false;
      if (control.value !== '' && !faxRegex.test(String(control.value).trim())) {
        condition = true;
      }
      return condition ? { faxFormatError: true } : null;
    }
  }

  /* Method to trigger an error message if the phone number is not in the desired format*/
  checkPhone(control: FormControl) {
    const phoneRegex = configList.pageConst.phoneRegex;
    let condition = false;
    if (control.value !== '' && !phoneRegex.test(String(control.value).trim())) {
      condition = true;
    }
    return condition ? { phoneFormatError: true } : null;
  }

  /* This method is used to check if a field contains only spaces */
  checkSpaces(control: FormControl) {
    let condition = false;
    if (control.value !== null && (control.value).toString().trim() === '' && control.value.length > 0) {
      condition = true;
    }
    return condition ? { spaceError: true } : null;
  }

  /* This method returns the appropriate error message for last name field*/
  getmaIdError() {
    return this.identificationForm.get('maId').hasError('required') ? this.consterror.maId :
      this.identificationForm.get('maId').hasError('spaceError') ? this.consterror.onlySpaces : '';
  }

  /* This method returns the appropriate error message for first name field*/
  getNpiIdError() {
    return this.identificationForm.get('npiId').hasError('required') ? this.consterror.npiId :
      this.identificationForm.get('npiId').hasError('pattern') ? this.consterror.NpiPattern : '';
  }
  
  /* This method returns the appropriate error message for doctor first name field*/
  getDocFirstNameError() {
    return this.identificationForm.get('doctorFirstName').hasError('required') ? this.consterror.docFirstName :
    this.identificationForm.get('doctorFirstName').hasError('spaceError') ? this.consterror.onlySpaces : '';
  }

  /* This method returns the appropriate error message for doctor first name field*/
  getDocLastNameError() {
    return this.identificationForm.get('doctorLastName').hasError('required') ? this.consterror.docLastName :
    this.identificationForm.get('doctorLastName').hasError('spaceError') ? this.consterror.onlySpaces : '';
  }

  /* This method returns the appropriate error message for last name field*/
  getLastNameError() {
    return this.userDataForm.get('lastName').hasError('required') ? this.consterror.LastName :
    this.userDataForm.get('lastName').hasError('spaceError') ? this.consterror.onlySpaces : '';
  }

  /* This method returns the appropriate error message for first name field*/
  getFirstNameError() {
    return this.userDataForm.get('firstName').hasError('required') ? this.consterror.FirstName :
    this.userDataForm.get('firstName').hasError('spaceError') ? this.consterror.onlySpaces : '';
  }

  /* This method returns the appropriate error message for accreditation field*/
  getAccreditationError() {
    return this.userDataForm.get('accreditation').hasError('required') ? this.consterror.Accreditation : '';
  }

  /* This method returns the appropriate error message for accreditation field*/
  getUserNameError() {
    return this.userDataForm.get('userId').hasError('required') ? 'User Id must be entered' :
      this.userDataForm.get('userId').hasError('spaceError') ? this.consterror.onlySpaces :
        this.userDataForm.get('userId').hasError('pattern') ? this.consterror.UserNamePattern :
          this.userDataForm.hasError('dupUserError') ? this.consterror.userNameDuplicate : '';
  }

  /* This method returns the appropriate error message for email address field*/
  getEmailAddressError() {
    return this.userDataForm.get('emailAddress').hasError('required') ? this.consterror.EmailAddress :
      this.userDataForm.get('emailAddress').hasError('pattern') ? this.consterror.EmailPattern :
        this.userDataForm.hasError('dupEmailError') ? this.consterror.EmailDuplicate : '';
  }

  /* This method returns the appropriate error message for address1 field*/
  getAddress1Error() {
    return this.userDataForm.get('address1').hasError('required') ? this.consterror.Address :
      this.userDataForm.get('address1').hasError('spaceError') ? this.consterror.onlySpaces : '';
  }

  /* This method returns the appropriate error message for address2 field*/
  getAddress2Error() {
    return this.userDataForm.get('address2').hasError('spaceError') ? this.consterror.onlySpaces : '';
  }

  /* This method returns the appropriate error message for prefix field*/
  getPrefixError() {
    return this.userDataForm.get('prefix').hasError('spaceError') ? this.consterror.onlySpaces : '';
  }

  /* This method returns the appropriate error message for phone field*/
  getPhoneError() {
    return this.userDataForm.get('phone').hasError('required') ? this.consterror.PhoneNumber :
      this.userDataForm.get('phone').hasError('pattern') ? this.consterror.Phone :
        this.userDataForm.get('phone').hasError('phoneFormatError') ? this.consterror.Phone : '';
  }

  /* This method returns the appropriate error message for first Zip code field*/
  getZip1Error() {
    return this.userDataForm.get('zipCode1').hasError('required') ? this.consterror.Zip :
      this.userDataForm.get('zipCode1').hasError('pattern') ? this.consterror.ZipPattern :
        this.userDataForm.hasError('zipError') ? this.consterror.ZipNotFound : '';
  }

  /* This method returns the appropriate error message for Fax field*/
  getFaxError() {
    return this.userDataForm.get('faxNumber').hasError('faxFormatError') ? this.consterror.Fax : '';
  }

  /** This method is used to update the City and State on changing the Zipcode */
  updateAddress() {
    if (this.addressDetails !== null && this.addressDetails !== undefined) {
      if (this.userDataForm.get('zipCode1').value !== undefined &&
        (this.userDataForm.get('zipCode1').value.length === undefined || this.userDataForm.get('zipCode1').value.length === 5)) {
        for (let i = 0; i < this.addressDetails.length; i++) {
          if (+this.m.zipCode1.value === this.addressDetails[i].ZIP) {
            this.m.state.setValue(this.addressDetails[i].stateCode);
            this.m.city.setValue(this.addressDetails[i].City);
            this.userDataForm.get('zipFound').setValue('false');
            break;
          }
        }
      } else {
        this.m.state.setValue('');
        this.m.city.setValue('');
        this.userDataForm.get('zipFound').setValue('true');
      }
    }
  }

  /** This method is used to restrict user from entering alphabets in numeric field */
  onlyNumberKey(event: any) {
    const pattern = configList.pageConst.numberRegex;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode === 32) {
      event.preventDefault();
    }
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  /* This method is used to carry out some functionalities when the values of certian fields change */
  onChanges(): void {
    this.identificationForm.get('maId').valueChanges.subscribe(val => {
      this.identificationForm.get('maId').markAsTouched();
    });
    this.identificationForm.get('npiId').valueChanges.subscribe(val => {
      this.identificationForm.get('npiId').markAsTouched();
    });
    this.identificationForm.get('doctorFirstName').valueChanges.subscribe(val => {
      this.identificationForm.get('doctorFirstName').markAsTouched();
    });
    this.identificationForm.get('doctorLastName').valueChanges.subscribe(val => {
      this.identificationForm.get('doctorLastName').markAsTouched();
    });
    this.userDataForm.get('zipCode1').valueChanges.subscribe(val => {
      if (this.userDataForm.get('zipCode1').enabled) {
        this.updateAddress();
      }
      this.userDataForm.get('zipCode1').markAsTouched();
    });
    this.userDataForm.get('zipCode2').valueChanges.subscribe(val => {
      this.userDataForm.get('zipCode2').markAsTouched();
    });
    this.userDataForm.get('userId').valueChanges.subscribe(val => {
      this.userDataForm.get('userId').markAsTouched();
      this.userDataForm.get('dupUserId').setValue('false');
    });
    this.userDataForm.get('phone').valueChanges.subscribe(val => {
      this.userDataForm.get('phone').markAsTouched();
    });
    this.userDataForm.get('emailAddress').valueChanges.subscribe(val => {
      this.userDataForm.get('emailAddress').markAsTouched();
      this.userDataForm.get('dupEmail').setValue('false');
    });
    this.userDataForm.get('address1').valueChanges.subscribe(val => {
      this.userDataForm.get('address1').markAsTouched();
    });
    this.userDataForm.get('address2').valueChanges.subscribe(val => {
      this.userDataForm.get('address2').markAsTouched();
    });
    this.userDataForm.get('accreditation').valueChanges.subscribe(val => {
      this.userDataForm.get('accreditation').markAsTouched();
    });
    this.userDataForm.get('firstName').valueChanges.subscribe(val => {
      this.userDataForm.get('firstName').markAsTouched();
    });
    this.userDataForm.get('lastName').valueChanges.subscribe(val => {
      this.userDataForm.get('lastName').markAsTouched();
    });
    this.userDataForm.get('prefix').valueChanges.subscribe(val => {
      this.userDataForm.get('prefix').markAsTouched();
    });
    this.userDataForm.get('faxNumber').valueChanges.subscribe(val => {
      this.userDataForm.get('faxNumber').markAsTouched();
    });
  }

  /*This method used to save the provider enrolled details to DB*/
  saveUserData() {
    this.loaderService.isLoading.next(true);
    const modifyUser = Object();
    modifyUser.userLoginId = this.m.userId.value;
    modifyUser.firstName = this.m.firstName.value;
    modifyUser.lastName = this.m.lastName.value;
    modifyUser.emailAddress = this.m.emailAddress.value;
    modifyUser.accrediation = this.m.accreditation.value ? this.m.accreditation.value : null;
    modifyUser.address1 = this.m.address1.value;
    modifyUser.address2 = this.m.address2.value;
    modifyUser.state = this.m.state.value;
    modifyUser.zipCode1 = this.m.zipCode1.value;
    modifyUser.zipCode2 = this.m.zipCode2.value;
    modifyUser.city = this.m.city.value;
    modifyUser.contactNum = +this.maskphonenum(this.m.phone.value);
    modifyUser.faxNumber = this.m.faxNumber.value ? (+this.maskphonenum(this.m.faxNumber.value)) : null;
    modifyUser.roleId = configList.pageConst.roleProvider;
    modifyUser.userStatus = "N";
    modifyUser.userEffectiveDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy');
    modifyUser.userTitle = this.m.prefix.value;
    // let docFirstName: string;
    // let docLastName: string;
    // this.maIdTable.map((id, index) => {
    //   docFirstName = (document.getElementById('dFName_' + index) as HTMLInputElement).value;
    //   docLastName = (document.getElementById('dLName_' + index) as HTMLInputElement).value;
    //   if (docFirstName === '' || docFirstName.trim() === '') {
    //     this.maIdTable[index]['doctorFirstName'] = null;
    //   } else {
    //     this.maIdTable[index]['doctorFirstName'] = docFirstName;
    //   }
    //   if (docLastName === '' || docLastName.trim() === '') {
    //     this.maIdTable[index]['doctorLastName'] = null;
    //   } else {
    //     this.maIdTable[index]['doctorLastName'] = docLastName;
    //   }
    // });

    modifyUser.identificationIdList = this.maIdTable;
    console.log(modifyUser);
    let finalData = Object();
    finalData.messageContent = this.userDet.encryptData(modifyUser);
    this.httpService.addNewProviderUser(finalData).subscribe(data => {
      console.log(data);
      data = this.userDet.decryptData(data.messageContent);
      this.loaderService.isLoading.next(false);
      if (data.userResp.responseCode === 0) {
        this.providerForm.nativeElement.style.display = 'none';
        this.errorMsg = configList.errorConst.generateEmail;
        this.messageModal.nativeElement.style.display = 'block';
      } else if (data.userResp.responseCode === 4) { //Email address is already in use. Please provide another one.
        this.userDataForm.get('dupEmail').setValue('true');
        let elem = this.renderer.selectRootElement('#emailAddress');
        elem.focus();
      } else if (data.userResp.responseCode === 9) { //User Id is already in use. Please provide another one
        this.userDataForm.get('dupUserId').setValue('true');
        let elem = this.renderer.selectRootElement('#user_id');
        elem.focus();
      }
    },
      error => {
        this.logger.debug('Error while submitting provider enrollment');
      });
  }

  confirmUCModal() {
    this.showAlertUC = false;
    document.getElementById('ucModal').style.display = 'none';
  }

  duplicateEmail(form: FormGroup) {
    let condition = false;
    if (form.get('dupEmail').value === 'true') {
      condition = true;
    }
    return condition ? { dupEmailError: true } : null;
  }

  duplicateUserId(form: FormGroup) {
    let condition = false;
    if (form.get('dupUserId').value === 'true') {
      condition = true;
    }
    return condition ? { dupUserError: true } : null;
  }
}

