import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {

  constructor() {
    sessionStorage.setItem('windowLocation', window.location.href);
    window.addEventListener('popstate', function(event) {
      if (sessionStorage.getItem('windowLocation').indexOf('unauth') > -1) {
        this.history.pushState(null, this.document.title, sessionStorage.getItem('windowLocation'));
      }
    });
  }

  ngOnInit() {}

}
