import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const token = sessionStorage.getItem('LoginToken');
        if (token) {
            const tokenJwt = token;
            console.log("🚀 ~ file: jwt.interceptor.ts:12 ~ JwtInterceptor ~ intercept ~ tokenJwt:", tokenJwt)
            request = request.clone({
                 setHeaders: {
                    Authorization: tokenJwt
                 }
             });
         }

        return next.handle(request);
    }
}
