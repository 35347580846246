import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from '../_services/http.service';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';
import * as configList from '../../assets/config/config';
import { UserService } from '../_services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PasswordErrorMatcher } from '../_helpers/errorMatcher.interceptor';
import { CONFIGUREPORTALREQUEST } from '../_models/ConfigurePortal/configureportalrequest';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  @ViewChild('emailAddress') emailAddress: any;
  @ViewChild('newPassword') newPassword: any;
  @ViewChild('confirmPassword') confirmPassword: any;
  @ViewChild('qsModal') qsModal: any; // needed for ADA
  @ViewChild('qsMsg') qsMsg: any; // needed for ADA
  public configurePortalRequest: CONFIGUREPORTALREQUEST;
  public errorMsg: string;
  public showAlertQS = false;
  public validPassword = false;
  public invalidEmail = false;
  public invalidPassword = false;
  public reusePassword = false;
  public systemError = false;
  passwordForm: FormGroup;

  cnfPassFieldErrorMatcher = new PasswordErrorMatcher();

  constructor(private httpService: HttpService, private formBuilder: FormBuilder, private userDet: UserService,
    private logger: NGXLogger, private router: Router) {
      // Method to check if the user is authorised to view this page
      this.userDet.authorizeUser();
  }

  ngOnInit() {
    this.passwordForm = this.formBuilder.group({
      emailAddress: ['', [Validators.required, Validators.pattern(configList.pageConst.emailRegex)]],
      newPassword: ['', [Validators.required, Validators.pattern(this.userDet.getPasswordRegex())]],
      confirmPassword: ['', [Validators.required, Validators.pattern(this.userDet.getPasswordRegex())]]
    }, { validator: this.checkPassChanges
    });
    this.onChanges();
    // this.f.emailAddress.setValue('');
    // this.f.newPassword.setValue('');
  }

  get f() { return this.passwordForm.controls; }

  /* This method is used to carry out some functionalities when the values of certain fields change */
  onChanges(): void {
    this.f.confirmPassword.setValue('');
    this.passwordForm.get('emailAddress').valueChanges.subscribe(val => {
      this.passwordForm.get('emailAddress').markAsTouched();
    });
    this.passwordForm.get('newPassword').valueChanges.subscribe(val => {
      this.passwordForm.get('newPassword').markAsTouched();
    });
    this.passwordForm.get('confirmPassword').valueChanges.subscribe(val => {
      this.passwordForm.get('confirmPassword').markAsTouched();
    });
  }

  /* This method returns the appropriate error message for new password field*/
  getEmailError() {
    return this.passwordForm.get('emailAddress').hasError('required') ? configList.errorConst.EmailAddress :
    this.passwordForm.get('emailAddress').hasError('pattern') ? configList.errorConst.EmailPattern : '';
  }

  /* This method returns the appropriate error message for new password field*/
  getNewPassError() {
    return this.passwordForm.get('newPassword').hasError('required') ? configList.errorConst.fieldError :
    this.passwordForm.get('newPassword').hasError('pattern') ? this.userDet.getPasswordErrorMessage() : '';
  }

  /* This method returns the appropriate error message for confirm password field*/
  getCnfPassError() {
    return this.passwordForm.get('confirmPassword').hasError('required') ? configList.errorConst.fieldError :
    this.passwordForm.hasError('passwordError') ? configList.errorConst.confirmPasswordError : '';
  }

  /** This method is called to Save the new password */
  saveResetPasswordData(reuseIndicator) {
    this.validPassword = false;
    this.invalidEmail = false;
    this.invalidPassword = false;
    this.reusePassword = false;
    this.systemError = false;
    this.showAlertQS = false;
    if (this.qsModal !== undefined) {
      this.qsModal.nativeElement.style.display = 'none';
    }
    this.configurePortalRequest = new CONFIGUREPORTALREQUEST();
    this.configurePortalRequest.screenId = this.userDet.getScreenId();
    this.configurePortalRequest.updatedBy = this.userDet.getScreenName();
    this.configurePortalRequest.portletId = configList.pageConst.NAPortletId;
    this.configurePortalRequest.activityCode = configList.activityCode.changeExpiredPassword;
    const ruleEngineData = Object();
    ruleEngineData.userLoginId = this.userDet.getScreenName();
    ruleEngineData.newPassword = this.f.newPassword.value;
    ruleEngineData.reusePassword = reuseIndicator;
    ruleEngineData.emailAddress = this.f.emailAddress.value;
    this.configurePortalRequest.ruleEngineData = ruleEngineData;

    console.log(this.configurePortalRequest);
    // API call for saving reset password
    this.httpService.secureUpdateUserLogin(this.configurePortalRequest).subscribe(data => {
      data = this.userDet.decryptData(data.messageContent);
      if (data.ruleEngineResp.responseCode === 'RP002') {
        this.validPassword  = true;
        this.errorMsg = data.ruleEngineResp.responseDesc;
      } else if (data.ruleEngineResp.responseCode === 'RP104' || data.ruleEngineResp.responseCode === 'RP106') {
        // Invalid Password  Cases
        this.invalidPassword  = true;
        this.errorMsg = data.ruleEngineResp.responseDesc;
      } else if (data.ruleEngineResp.responseCode === 'RP116') {
        this.invalidEmail = true;
        this.invalidPassword  = true;
        this.errorMsg = data.ruleEngineResp.responseDesc;
      } else if (data.ruleEngineResp.responseCode === 'RP107') {
        // Reuse Password case
        this.reusePassword = true;
        this.errorMsg = data.ruleEngineResp.responseDesc;
      } else {
        this.systemError = true;
        this.errorMsg = configList.errorConst.SystemError;
      }
      this.showAlertQS = true;
      if (this.qsModal !== undefined) {
        this.qsModal.nativeElement.style.display = 'block';
      }
      if (this.qsMsg !== undefined) {
        this.qsMsg.nativeElement.focus();
      }
    },
    error => {
      this.logger.debug('Error while saving reset password');
    }
    );
  }

  /** This method relinquishes all the changes made to the form and redirects to the public-facing page */
  reRoutePublic(): void {
    this.passwordForm.reset();
    sessionStorage.setItem('loginRoleId', '-1');
    sessionStorage.setItem('userRoleId', '-1');
    sessionStorage.setItem('privilegeData', null);
    sessionStorage.setItem('loginScreenId', '1');
    sessionStorage.setItem('logOut', 'Y');
    sessionStorage.setItem('isLoggedIn', 'false');
    sessionStorage.setItem('loggedInUserName', null);
    this.router.navigate(['/publicFacing']);
  }

  /** This method relinquishes all the changes made to the form and redirects to the respective login page */
  reRouteLogin(): void {
    this.showAlertQS = false;
    if (this.qsModal !== undefined) {
      this.qsModal.nativeElement.style.display = 'none';
    }
    this.configurePortalRequest = new CONFIGUREPORTALREQUEST();
    this.configurePortalRequest.screenId = configList.pageConst.pagePublicFacing;
    this.configurePortalRequest.updatedBy = this.userDet.getScreenName();
    this.configurePortalRequest.portletId = configList.pageConst.NAPortletId;
    this.configurePortalRequest.activityCode = configList.activityCode.loginAuditActCode;
    const ruleEngineData = Object();
    ruleEngineData.userLoginId = this.userDet.getScreenName();
    ruleEngineData.password = this.f.newPassword.value;
    this.configurePortalRequest.ruleEngineData = ruleEngineData;

    console.log(this.configurePortalRequest);
    this.httpService.secureUpdateUserLogin(this.configurePortalRequest).subscribe(data => {
      data = this.userDet.decryptData(data.messageContent);
        console.log(data);
    },
    (error: any) => {
      console.log(error);
    });
    this.userDet.homeNavigation();
  }

  /** This method is used to remove the pop-up */
  cancelData(): void {
    this.f.newPassword.setValue('');
    this.f.confirmPassword.setValue('');
    this.showAlertQS = false;
    if (this.qsModal !== undefined) {
      this.qsModal.nativeElement.style.display = 'none';
    }
    if (this.invalidEmail) {
      this.emailAddress.nativeElement.focus();
    } else {
      this.newPassword.nativeElement.focus();
    }
  }

  /* This method is used to check if the content of new password and confirm password fields are same or not*/
  checkPassChanges(form: FormGroup) {
    let condition = false;
    if (form.get('newPassword').value !== form.get('confirmPassword').value) {
      condition = true;
    }
    return condition ? { passwordError: true } : null;
  }
}
