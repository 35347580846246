import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { ContactsComponent } from './Public-Facing-Pages/contacts/contacts.component';
import { FaqComponent } from './Public-Facing-Pages/faq/faq.component';
import { UserHelpComponent } from './Public-Facing-Pages/user-help/user-help.component';
import { NewsComponent } from './Public-Facing-Pages/news/news.component';
import { ProgramComponent } from './Public-Facing-Pages/program/program.component';
import { PublicFacingComponent } from './Public-Facing-Pages/public-facing/public-facing.component';
import { WelcomeComponent } from './Public-Facing-Pages/welcome/welcome.component';
import { SignInAnnouncementComponent } from './sign-in-announcement/sign-in-announcement.component';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { FaqpageComponent } from './Public-Facing-Pages/faqpage/faqpage.component';
import { FaqdetailsComponent } from './Public-Facing-Pages/faqdetails/faqdetails.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared.module';
import { DragulaModule } from 'ng2-dragula';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetSecurityQuestionComponent } from './reset-security-question/reset-security-question.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MaterialDesignModule } from './material-design.module';
import { TextMaskModule } from 'angular2-text-mask';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { SearchFilterPipe } from './custom-pipes/search-filter.pipe';
import { SearchKeyPipe } from './custom-pipes/search-key.pipe';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';
import { ValidateSecurityQuestionComponent } from './validate-security-question/validate-security-question.component';
import { ActivateUserComponent } from './activate-user/activate-user.component';
import { PriotAuthorizationComponent } from './Public-Facing-Pages/priot-authorization/priot-authorization.component';
import { ApplicationLinksComponent } from './Public-Facing-Pages/application-links/application-links.component';
import { LoaderComponent } from './loader/loader.component';
import { DatePipe } from '@angular/common';
// import { LoaderInterceptor } from './_helpers/loader.interceptor';
import { KeycloakService } from './keycloak/keycloak.service';
import { keycloakInitializer } from './keycloak/keycloakInit';
import { RegistrationComponent } from './registration/registration.component';
import { SessionService } from './session.service';

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    DisclaimerComponent,
    UnauthorizedComponent,
    ContactsComponent,
    FaqComponent,
    UserHelpComponent,
    NewsComponent,
    ProgramComponent,
    PublicFacingComponent,
    WelcomeComponent,
    SignInAnnouncementComponent,
    FaqpageComponent,
    FaqdetailsComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    ResetSecurityQuestionComponent,
    UserProfileComponent,
    SearchFilterPipe,
    SearchKeyPipe,
    ScrollTopComponent,
    ValidateSecurityQuestionComponent,
    ActivateUserComponent,
    PriotAuthorizationComponent,
    ApplicationLinksComponent,
    RegistrationComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    LoggerModule.forRoot({ serverLoggingUrl: '/api/logs', level: NgxLoggerLevel.DEBUG, serverLogLevel: NgxLoggerLevel.ERROR }),
    DragulaModule.forRoot(),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MaterialDesignModule,
    TextMaskModule,
    NgIdleKeepaliveModule.forRoot()
  ],
  providers: [
    KeycloakService,
    Title,
    SessionService,
    {
      provide: APP_INITIALIZER,
      useFactory: keycloakInitializer,
      multi: true,
      deps: [KeycloakService]
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
