import { KeycloakService } from './keycloak.service';
import {environment} from '../../environments/environment';
import * as configlist from '../../assets/config/config';

export function keycloakInitializer(): () => Promise<any> {
    return (): Promise<any> => {
       return new Promise(async (resolve, reject) => {
           // const {keycloakUrl} = environment;
           const keycloakConfig = configlist.keycloakjson;
            try {
                await KeycloakService.init({
                    config: keycloakConfig,
                    initOptions: {
                        onLoad: 'check-sso',
                        checkLoginIframe: false
                    },
                    bearerExcludedUrls: [
                        '/assets',
                        '/clients/public'
                    ]
                });
                resolve();
            } catch (error) {
                console.log('error in keyclock Init ts', error)
                reject(error);
            }
        });
    };
}