import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import * as configList from '../../assets/config/config';
import { HttpService } from '../_services/http.service';
import { NGXLogger } from 'ngx-logger';
import { UserService } from '../_services/user.service';
import { PasswordDataService } from '../_services/password-data.service';
import { ZipErrorMatcher, NewPasswordErrorMatcher } from '../_helpers/errorMatcher.interceptor';
import { OldPasswordErrorMatcher } from '../_helpers/errorMatcher.interceptor';
import { PasswordErrorMatcher } from '../_helpers/errorMatcher.interceptor';
import { CONFIGUREPORTALREQUEST } from '../_models/ConfigurePortal/configureportalrequest';
// import { _getOptionScrollPosition } from '@angular/material/';
import { CustomValidators } from '../_validators/password-validator';
// import { MatSort, MatTableDataSource, MatPaginator, MatTable } from '@angular/material';
import { MatTable } from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
// import { ScrollingModule } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  @ViewChild('ucModal') ucModal: any; // needed for ADA
  @ViewChild('ucMsg') ucMsg: any; // needed for ADA
  @ViewChild('passModal') passModal: any; // needed for ADA
  @ViewChild('passMsg') passMsg: any; // needed for ADA
  @ViewChild('oldPassword') oldPassword: any;
  @ViewChild('newPassword') newPassword: any;
  @ViewChild('generatepwd') generatepwd: any;
  @ViewChild('otpValue') otpValue: any;
  @ViewChild('savCan') savCan: any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  myProfileData: any;
  secQuesUser = [];
  addressDetails: any[];
  public consterror = configList.errorConst;
  public tooltipconstdata = configList.tooltipConst;
  securityQSitems: any[];
  public noOfQuestions;
  disableEditUser: boolean;
  disableEditQuestion: boolean;
  public showAlert = false;
  public errorMsg: string;
  public showAlertUC = false;
  public showAlertPass = false;
  public systemError = false;
  passwordForm: FormGroup;
  securityQsForm: FormGroup;
  submittedPassword = false;
  myDataForm: FormGroup;
  submittedData = false;
  submittedQues = false;
  maxShow = true;
  flag = false;
  reuseFlag = false;
  successFlag = false;
  saveCancelAria = true;
  saveCancelQuesAria = true;
  public configurePortalRequest: CONFIGUREPORTALREQUEST;
  // Variable used for phone number masking
  mask: any = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  dataSource: MatTableDataSource<any>;
  tableColumns: string[] = ['maId', 'npiId', 'doctorFirstName', 'doctorLastName'];
  maIdTable = [];
  manpiIdIdRequired: boolean;

  crossFieldErrorMatcher = new ZipErrorMatcher();
  cnfPassFieldErrorMatcher = new PasswordErrorMatcher();
  oldPassFieldErrorMatcher = new OldPasswordErrorMatcher();
  newPassFieldErrorMatcher = new NewPasswordErrorMatcher();
  oldFocus = false;
  newFocus = false;
  ssoMode: boolean;
  public newPassError: any = '';
  public hide = true;
  public reEnterhide = true;
  _initialValue: any;
  pwdConfigRules: any;
  errorMsgMethods: any;
  finalReg: string;
  randomNumberforOtp: string;
  encryptedvalueforSession : string;
  constructor(private httpService: HttpService, private router: Router,
    private logger: NGXLogger, private formBuilder: FormBuilder, private userDet: UserService, private passwordData: PasswordDataService) {
    // Method to check if the user is authorised to view this page
    this.getzipData();
    this.userDet.authorizeUser();
    this.httpService.getSecurityQuestion().subscribe(data => {
      this.securityQSitems = data.usersSecQue;
    },
      error => {
        this.logger.debug('Error while fetching Security Questions');
      }
    );
    this.getssoMode();
    this.errorMsgMethods = this.userDet;
  }

  ngOnInit() {
    this.disableEditUser = false;
    this.disableEditQuestion = false;
    this.getProfileData();
    this.getUserQuestions();
    this.passwordData.getDataFromJson();
    // this.passwordLength = 8;
    if (document.getElementById('addBreadCrumb').innerHTML === '') {
      const node = document.createElement('a');
      const textnode = document.createTextNode(' My Profile');
      node.appendChild(textnode);
      document.getElementById('addBreadCrumb').innerHTML = '';
      document.getElementById('addBreadCrumb').appendChild(node);
    }

    this.configurePortalRequest = new CONFIGUREPORTALREQUEST();
    this.configurePortalRequest.screenId = this.userDet.getScreenId();
    this.configurePortalRequest.updatedBy = this.userDet.getScreenName();
    this.configurePortalRequest.portletId = configList.pageConst.NAPortletId;

    this.securityQsForm = this.formBuilder.group({
      securityQuestions: this.formBuilder.array([]),
    }, {
        validator: this.checkDuplicateQs
      });

    this.passwordForm = this.formBuilder.group({
      reEnterPassword: this.ssoMode ? [''] : ['', [Validators.required]],
      newPassword: [''],
      otpValue:['', [Validators.required, this.compareOtpValues]],
      confirmPassword: ['', [Validators.required]]
    }, {
        // validator: [this.checkPassChanges, this.oldPassChanges, this.newPassChanges(this.myProfileData)]
        validator: [this.checkPassChanges]
      });

      this._initialValue = this.passwordForm.value;

    this.myDataForm = this.formBuilder.group({
      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      prefix: [''],
      accreditation: [''],
      emailAddress: ['', [Validators.required, Validators.pattern(configList.pageConst.emailRegex)]],
      identificationId: [],
      role: ['', Validators.required],
      address1: ['', [Validators.required, this.checkSpaces]],
      address2: ['', this.checkSpaces],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zipCode1: ['', [Validators.required, Validators.pattern(configList.pageConst.zip1Regex)]],
      zipCode2: ['', [Validators.pattern(configList.pageConst.zip2Regex)]],
      phone: ['', [Validators.required, this.checkPhone]],
      fax: ['', this.checkFax],
      status: ['', Validators.required],
      zipFound: [{ value: 'false' }]
    }, {
        validator: this.checkValidZip
      });
    this.onChanges();
    this.getPwdRules();
    this.pwdConfigRules = this.passwordData.passwordRulesData;
  }
  /** Check for the password config file and assign the validations */
  getPwdRules() {

    this.httpService.getJsonContent(configList.pwdconfigjson).subscribe(
      (data: any) => {
        this.pwdConfigRules = data;
        const validatorsArray = [];
        validatorsArray.push(Validators.required);
        let listRegShouldContain = [];
        this.finalReg = '';
        // Min & Max length case condition
        if (this.pwdConfigRules.passwordMinLength || this.pwdConfigRules.passwordMaxLength) {
          validatorsArray.push(CustomValidators.patternValidator(this.userDet.getMinMaxRegex(configList.pageConst.passwordMinMaxRegex,"1"),
          { pswdMinMaxError : true }));
        }
        // Upper case condition
        if (this.pwdConfigRules.passwordShouldContainUpperCase) {
          // validatorsArray.push(CustomValidators.patternValidator(this.userDet.getRegexValidate(configList.pageConst.passwordUpperCaseRegex, 
          //   this.pwdConfigRules.passwordUpperCaseLength),{ pswdUpperCaseError : true }));
            listRegShouldContain.push(this.userDet.getRegexValidate(configList.pageConst.passwordUpperCaseRegex, 
            this.pwdConfigRules.passwordUpperCaseLength));
        }
        // Lower case condition
        if (this.pwdConfigRules.passwordShouldContainLowerCase) {
          // validatorsArray.push(CustomValidators.patternValidator(this.userDet.getMinMaxRegex(configList.pageConst.passwordLowerCaseRegex, 
          //   this.pwdConfigRules.passwordLowerCaseLength),{ pswdLowerCaseError : true }));
            listRegShouldContain.push(this.userDet.getRegexValidate(configList.pageConst.passwordLowerCaseRegex, 
              this.pwdConfigRules.passwordLowerCaseLength)); 
        }
        // Number condition
        if (this.pwdConfigRules.passwordShouldContainNumber) {
          // validatorsArray.push(CustomValidators.patternValidator(this.userDet.getRegexValidate(configList.pageConst.passwordNumberRegex, 
          //   this.pwdConfigRules.passwordNumberLength),{ pswdNumberError : true }));
            listRegShouldContain.push(this.userDet.getRegexValidate(configList.pageConst.passwordNumberRegex, 
              this.pwdConfigRules.passwordNumberLength));
        }
        // Special character condition
        if (this.pwdConfigRules.passwordShouldContainSpecialChar) {
          // validatorsArray.push(CustomValidators.patternValidator(this.userDet.getRegexValidate(configList.pageConst.passwordSpecialCharRegex, 
          //   this.pwdConfigRules.passwordSpecialCharLength),{ pswdSpecialCharError : true }));
            listRegShouldContain.push(this.userDet.getRegexValidate(configList.pageConst.passwordSpecialCharRegex, 
              this.pwdConfigRules.passwordSpecialCharLength));
        }
      
        //Code to form final regex
        if(listRegShouldContain) {
          let lengthOfListRegArr  = listRegShouldContain.length;
          let finalRegexforContains: RegExp ;
          if( lengthOfListRegArr <= this.pwdConfigRules.passwordContainCategories) {
            this.finalReg = '(';
            this.finalReg += listRegShouldContain.join("");
            this.finalReg += ').*';
            finalRegexforContains = new RegExp(this.finalReg);
          } else{
            let storeRegArray = [];
            this.finalReg = '(';
            this.combinationUtil(listRegShouldContain,lengthOfListRegArr,this.pwdConfigRules.passwordContainCategories, 0, storeRegArray, 0);
            this.finalReg = this.finalReg.slice(0,-1);
            this.finalReg += ').*';
            finalRegexforContains = new RegExp(this.finalReg);
          }
          validatorsArray.push(CustomValidators.patternValidator(finalRegexforContains,{ pswdMustContainError : true }));
        } 
        // Consecutive characters condition
        if (this.pwdConfigRules.consecutiveCharNotAllowed) {          
          validatorsArray.push(CustomValidators.containPatternValator(configList.pageConst.passwordConsecutiveRegex,{ pswdContainsError : true }));
        }
        // Capital, Number & Special character condition
        if (this.pwdConfigRules.capNumberSplCharNotAllowed) {          
          validatorsArray.push(CustomValidators.containPatternValator(configList.pageConst.passwordCapNoSpl,{ pswdContainsError : true }));
        }
        // Special character, Number & Capital word condition
        if (this.pwdConfigRules.splCharNumberCapNotAllowed) {          
          validatorsArray.push(CustomValidators.containPatternValator(configList.pageConst.passwordSplNoCap,{ pswdContainsError : true }));
        }
        // User Id & Telephone number condition
        if (this.pwdConfigRules.userIdNotAllowed || this.pwdConfigRules.phoneNumberNotAllowed) {          
          validatorsArray.push(this.checkUserDetails.bind(this));
        }
        // Special character not contain in password
        validatorsArray.push(CustomValidators.containPatternValator(configList.pageConst.passwordSplNoAccept,{ pswdMustContainError : true }));
        // No spaces condition         
        validatorsArray.push(CustomValidators.containPatternValator(configList.pageConst.passwordNoSpaces,{ pswdNoSpaceError : true }));
        
        this.passwordForm.get('newPassword').setValidators(validatorsArray);     
        this.passwordForm.get('newPassword').updateValueAndValidity();
      },
      error => {
        this.logger.debug('Error while getting filters');
      }
    );
  }

  get g() { return this.securityQsForm.controls; }
  get f() { return this.passwordForm.controls; }
  get m() { return this.myDataForm.controls; }

  /* This method is used to carry out some functionalities when the values of certian fields change */
  onChanges(): void {
    // this.getSecurityAnswerError(i);
    this.myDataForm.get('zipCode1').valueChanges.subscribe(val => {
      if (this.myDataForm.get('zipCode1').enabled) {
        this.updateAddress();
      }
      this.myDataForm.get('zipCode1').markAsTouched();
    });
    this.myDataForm.get('zipCode2').valueChanges.subscribe(val => {
      this.myDataForm.get('zipCode2').markAsTouched();
    });
    this.myDataForm.get('phone').valueChanges.subscribe(val => {
      this.myDataForm.get('phone').markAsTouched();
    });
    this.myDataForm.get('emailAddress').valueChanges.subscribe(val => {
      this.myDataForm.get('emailAddress').markAsTouched();
    });
    this.myDataForm.get('address1').valueChanges.subscribe(val => {
      this.myDataForm.get('address1').markAsTouched();
    });
    this.myDataForm.get('address2').valueChanges.subscribe(val => {
      this.myDataForm.get('address2').markAsTouched();
    });
    this.passwordForm.get('reEnterPassword').valueChanges.subscribe(val => {
      this.passwordForm.get('reEnterPassword').markAsTouched();
    });
    this.passwordForm.get('newPassword').valueChanges.subscribe(val => {
      this.passwordForm.get('newPassword').markAsTouched();
    });
    this.passwordForm.get('confirmPassword').valueChanges.subscribe(val => {
      this.passwordForm.get('confirmPassword').markAsTouched();
    });
    this.passwordForm.get('otpValue').valueChanges.subscribe(val => {
      this.passwordForm.get('otpValue').markAsTouched();
    });
  }

  /* This method returns the appropriate error message for last name field*/
  getLastNameError() {
    return this.myDataForm.get('lastName').hasError('required') ? this.consterror.LastName : '';
  }

  /* This method returns the appropriate error message for first name field*/
  getFirstNameError() {
    return this.myDataForm.get('firstName').hasError('required') ? this.consterror.FirstName : '';
  }

  /* This method returns the appropriate error message for accreditation field*/
  getAccreditationError() {
    return this.myDataForm.get('accreditation').hasError('required') ? this.consterror.Accreditation : '';
  }

  /* This method returns the appropriate error message for email address field*/
  getEmailAddressError() {
    return this.myDataForm.get('emailAddress').hasError('required') ? this.consterror.EmailAddress :
      this.myDataForm.get('emailAddress').hasError('pattern') ? this.consterror.EmailPattern : '';
  }

  /* This method returns the appropriate error message for role field*/
  getRoleError() {
    return this.myDataForm.get('role').hasError('required') ? this.consterror.RoleId : '';
  }

  /* This method returns the appropriate error message for address1 field*/
  getAddress1Error() {
    return this.myDataForm.get('address1').hasError('required') ? this.consterror.Address :
      this.myDataForm.get('address1').hasError('spaceError') ? this.consterror.onlySpaces : '';
  }

  /* This method returns the appropriate error message for address2 field*/
  getAddress2Error() {
    return this.myDataForm.get('address2').hasError('spaceError') ? this.consterror.onlySpaces : '';
  }

  /* This method returns the appropriate error message for phone field*/
  getPhoneError() {
    return this.myDataForm.get('phone').hasError('required') ? this.consterror.PhoneNumber :
      this.myDataForm.get('phone').hasError('pattern') ? this.consterror.Phone :
        this.myDataForm.get('phone').hasError('phoneFormatError') ? this.consterror.Phone : '';
  }

  /* This method returns the appropriate error message for first Zip code field*/
  getZip1Error() {
    return this.myDataForm.get('zipCode1').hasError('required') ? this.consterror.Zip :
      this.myDataForm.get('zipCode1').hasError('pattern') ? this.consterror.ZipPattern :
        this.myDataForm.hasError('zipError') ? this.consterror.ZipNotFound : '';
  }

  /* This method returns the appropriate error message for Fax field*/
  getFaxError() {
    return this.myDataForm.get('fax').hasError('faxFormatError') ? this.consterror.Fax : '';
  }

  /* This method returns the appropriate error message for old password field*/
  getOldPassError() {
    return this.passwordForm.get('reEnterPassword').hasError('required') ? this.consterror.fieldError : '';
  }

  compareOtpValues(control:FormControl){
    let passValue = false;
    let injector = Injector.create([ { provide:UserService , useClass:UserService,deps: []}]);
    let service = injector.get(UserService);
    if(control.value !== '' && control.value.length === 8){
      let OtptoCompare = service.decryptData(sessionStorage.getItem('EncryptedOtp'));
      OtptoCompare = String(OtptoCompare) ;
      console.log("OtptoCompare is" +OtptoCompare) ;
      console.log("control.value" +control.value) ;
      if (OtptoCompare !== control.value) {
        passValue = true;
      }
    }
    return passValue ? {otpmatcherror:true} : null ;
 
  }
  /* This method returns the appropriate error message for OTP  field*/
  getOtpValueError() {
    return this.passwordForm.get('otpValue').hasError('required') ? this.consterror.fieldError :
    this.passwordForm.get('otpValue').hasError('otpmatcherror') ? this.consterror.otpError : '' ;
  }

  sendOtpforChangePassword(){
    this.generateandStoreinSession();
    this.sendOtpviaEmail(); 
    
  }

  generateandStoreinSession(){
    this.randomNumberforOtp = String(Math.floor(10000000 + Math.random() * 90000000));
    this.encryptedvalueforSession =  this.userDet.encryptData(this.randomNumberforOtp);
    sessionStorage.setItem('EncryptedOtp', this.encryptedvalueforSession);
    console.log("EncryptedOtp is :"+this.encryptedvalueforSession);
  }

  sendOtpviaEmail() {
    this.configurePortalRequest = new CONFIGUREPORTALREQUEST();
    this.configurePortalRequest.screenId = this.userDet.getScreenId();
    this.configurePortalRequest.updatedBy = this.userDet.getScreenName();
    this.configurePortalRequest.portletId = configList.pageConst.NAPortletId;
    this.configurePortalRequest.activityCode = configList.activityCode.changepasswordOtp;
    const generateOtptoUser = Object();
    generateOtptoUser.userLoginId = this.userDet.getScreenName();
    generateOtptoUser.otpValue = this.randomNumberforOtp;
    generateOtptoUser.emailAddress = this.myProfileData.emailAddress ;
    this.configurePortalRequest.generateOtptoUser = generateOtptoUser;
    let finalData = Object();
    finalData.messageContent = this.userDet.encryptData(this.configurePortalRequest);
    // API call for saving reset password
    this.httpService.secureUpdateUserLogin(finalData).subscribe(data => {
      data = this.userDet.decryptData(data.messageContent);
      console.log("data is response code is  "+ data.otptoUserResponse.responseCode) ;
      let rsCode = data.otptoUserResponse.responseCode ;
	     if (rsCode === 0) {
          this.errorMsg = data.otptoUserResponse.responseDesc;
          console.log("OTP sent sucessfully");
       }
    },
      error => {
        this.logger.debug('Error while sending OTP email to user');
      }
    );
  }


  /* This method returns the appropriate error message for new password field*/
  // getNewPassError() {
  //   this.newPassError = '';
  //   const passwordValue = this.passwordForm.get('newPassword').value;
  //   const oldPasswordvalue = this.passwordForm.get('reEnterPassword').value;
  //   let phoneNumber = '';
  //   let loginId = '';
  //   this.newPassError = '';
  //   if (passwordValue !== null && passwordValue !== undefined
  //     && oldPasswordvalue !== null && oldPasswordvalue !== undefined) {
  //     if (this.myProfileData !== null && this.myProfileData !== undefined) {
  //       phoneNumber = '' + this.myProfileData.contactNum;
  //       loginId = this.myProfileData.userLoginId;
  //       if (passwordValue.includes(phoneNumber) && this.pwdConfigRules.phoneNumberPwdRule) {
  //         this.newPassError = this.consterror.newPasswordPhoneError;
  //       } else if (passwordValue.includes(loginId) && this.pwdConfigRules.userIdPwdRule) {
  //         this.newPassError = this.consterror.newPasswordUsernameError;
  //       } else if (configList.pageConst.passwordConsecutiveRegex.test(passwordValue) && this.pwdConfigRules.consecutiveCharPwdRule) {
  //         this.newPassError = this.consterror.newPasswordConseRegex;
  //       } else if (configList.pageConst.passwordCapNoSpl.test(passwordValue) && this.pwdConfigRules.capNumberSplCharPwdRule) {
  //         this.newPassError = this.consterror.newPasswordCapNumSplReg;
  //       } else if (configList.pageConst.passwordSplNoCap.test(passwordValue) && this.pwdConfigRules.splCharNumberCapPwdRule) {
  //         this.newPassError = this.consterror.newPasswordSplNumCapReg;
  //       } else if (configList.pageConst.passwordNoSpaces.test(passwordValue)) {
  //         this.newPassError = this.consterror.newPasswordNoSpaceError;
  //       } else if (passwordValue === oldPasswordvalue) {
  //         this.newPassError = this.consterror.oldPassSameError;
  //       }
  //     } else {
  //       if (configList.pageConst.passwordConsecutiveRegex.test(passwordValue)) {
  //         this.newPassError = this.consterror.newPasswordConseRegex;
  //       } else if (passwordValue === oldPasswordvalue) {
  //         this.newPassError = this.consterror.oldPassSameError;
  //       }
  //     }
  //   }

  //   return this.passwordForm.get('newPassword').hasError('required') ? this.consterror.fieldError :
  //       this.passwordForm.get('newPassword').hasError('pattern') ? this.userDet.getPasswordErrorMessage() :
  //       this.passwordForm.hasError('newPasswordError') ? this.newPassError : '';
  // }

  /* This method returns the appropriate error message for confirm password field*/
  getCnfPassError() {
    return this.passwordForm.get('confirmPassword').hasError('required') ? this.consterror.fieldError :
      this.passwordForm.hasError('passwordError') ? this.consterror.confirmPasswordError : '';
  }

  /* This method returns the appropriate error message if same question has been selected in two different
  dropdowns in the edit security question form*/
  getDuplicateError() {
    return this.securityQsForm.hasError('duplicateError') ? this.consterror.DuplicateMessage : '';
  }

  /* This method returns the appropriate error message for answer field in the edit security question form*/
  getSecurityAnswerError(i) {
    return this.securityQsForm.get('securityQuestions').get(String(i)).get('answer_' + i).hasError('required') ?
      this.consterror.fieldError :
      this.securityQsForm.get('securityQuestions').get(String(i)).get('answer_' + i).hasError('spaceError') ?
        this.consterror.onlySpaces : '';
  }

  /* This method is used to check if Zip Code is valid */
  checkValidZip(form: FormGroup) {
    let condition = false;
    if (form.get('zipFound').value === 'true') {
      condition = true;
    }
    return condition ? { zipError: true } : null;
  }

  /* This method is used to check if the content of new password and confirm password fields are same or not*/
  checkPassChanges(form: FormGroup) {
    let condition = false;
    if (form.get('newPassword').value !== form.get('confirmPassword').value) {
      condition = true;
    }
    return condition ? { passwordError: true } : null;
  }

  /* This method is used to check if the content of new password and old password fields are same or not*/
  oldPassChanges(form: FormGroup) {
    let condition = false;
    if (form.get('newPassword').value === form.get('reEnterPassword').value) {
      condition = true;
      // oldPasswordError
    }
    return condition ? { oldPasswordError: true } : null;
  }

  newPassChanges(form: FormGroup) {
    const vm = this;
    const passwordValue = form.get('newPassword').value;
    const oldPasswordvalue = form.get('reEnterPassword').value;
    let phoneNumber = '';
    let loginId = '';
    // this.newPassError = '';
    let condition = false;
    if (passwordValue !== null && passwordValue !== undefined
      && oldPasswordvalue !== null && oldPasswordvalue !== undefined) {
      if (vm.myProfileData !== null && vm.myProfileData !== undefined ) {
        phoneNumber = '' + vm.myProfileData.contactNum;
        loginId = vm.myProfileData.userLoginId;
        /* if (passwordValue === oldPasswordvalue || passwordValue.includes(phoneNumber) || passwordValue.includes(loginId) ||
        configList.pageConst.passwordConsecutiveRegex.test(passwordValue)) {
          // this.newPassError =  this.consterror.newPasswordPhoneError;
          condition = true;
        } */
        if (passwordValue.includes(phoneNumber) && this.pwdConfigRules.phoneNumberPwdRule) {
          condition = true;
        } else if (passwordValue.includes(loginId) && this.pwdConfigRules.userIdPwdRule) {
          condition = true;
        } else if (configList.pageConst.passwordConsecutiveRegex.test(passwordValue) && this.pwdConfigRules.consecutiveCharPwdRule) {
          condition = true;
        } else if (configList.pageConst.passwordCapNoSpl.test(passwordValue) && this.pwdConfigRules.capNumberSplCharPwdRule) {
          condition = true;
        } else if (configList.pageConst.passwordSplNoCap.test(passwordValue) && this.pwdConfigRules.splCharNumberCapPwdRule) {
          condition = true;
        } else if (configList.pageConst.passwordNoSpaces.test(passwordValue)) {
          condition = true;
        } else if (passwordValue === oldPasswordvalue) {
          condition = true;
        }
      } else {
        if (passwordValue === oldPasswordvalue || configList.pageConst.passwordConsecutiveRegex.test(passwordValue)) {
          condition = true;
        }
      }
    }

    return condition ? { newPasswordError : true } : null;
  }

  /* This method is called on submitting password form*/
  onSubmitPasswordData() {
    this.submittedPassword = true;

    // stop here if form is invalid
    if (this.passwordForm.invalid) {
      return;
    }
  }

  /* This method is used to redirect the user to the  homepage if the home link  */
  navigateHome() {
    this.userDet.homeNavigation();
  }

  /* Method to trigger an error message if the phone number is not in the desired format*/
  checkPhone(control: FormControl) {
    const phoneRegex = configList.pageConst.phoneRegex;
    let condition = false;
    if (control.value !== '' && !phoneRegex.test(String(control.value).trim())) {
      condition = true;
    }
    return condition ? { phoneFormatError: true } : null;
  }

  /* Method to trigger an error message if the phone number is not in the desired format*/
  checkFax(control: FormControl) {
    if(control.value) {
      const faxRegex = configList.pageConst.faxRegex;
      let condition = false;
      if (control.value !== '' && !faxRegex.test(String(control.value).trim())) {
        condition = true;
      }
      return condition ? { faxFormatError: true } : null;
    } 
  }

  /** This method is used to create breadcrumbbs for the page */
  addBreadCrumb(event: any) {
    const target = event.target || event.srcElement || event.currentTarget;
    const textValue = target.innerHTML;
    const node = document.createElement('a');
    const textnode = document.createTextNode(' ' + textValue);
    node.appendChild(textnode);
    document.getElementById('addBreadCrumb').innerHTML = '';
    document.getElementById('addBreadCrumb').appendChild(node);
  }

  /* This method is used to check if a field contains only spaces */
  checkSpaces(control: FormControl) {
    let condition = false;
    if (control.value !== null && (control.value).toString().trim() === '' && control.value.length > 0) {
      condition = true;
    }
    return condition ? { spaceError: true } : null;
  }

  /** This method is used to get profile data of the user */
  getProfileData() {
    let data = Object();
    data.userLoginId = this.userDet.getScreenName();
    let encryption = Object();
    encryption.messageContent =  this.userDet.encryptData(data);
    this.httpService.getUserProfileData(encryption).subscribe(
      (data: any) => {
        data = this.userDet.decryptData(data.messageContent);
        this.myProfileData = data;
        this.m.lastName.setValue(data.lastName);
        this.m.firstName.setValue(data.firstName);
        this.m.prefix.setValue(data.userTitle);
        this.m.accreditation.setValue(data.accrediation);
        this.m.emailAddress.setValue(data.emailAddress);
        this.m.identificationId.setValue(data.identificationId);
        this.m.role.setValue(data.roleId);
        this.m.address1.setValue(data.address1);
        this.m.address2.setValue(data.address2);
        this.m.city.setValue(data.city);
        this.m.state.setValue(data.state);
        this.m.zipCode1.setValue(data.zipCode1);
        this.m.zipCode2.setValue(data.zipCode2);
        this.m.phone.setValue(this.formatContactNum(data.contactNum));
        this.m.status.setValue(data.userStatus);
        this.m.fax.setValue(data.faxNumber ? this.formatContactNum(data.faxNumber) : data.faxNumber);
        this.maIdTable = data.identificationId;
        this.dataSource = new MatTableDataSource(this.maIdTable);
        this.dataSource.sort = this.sort;
      },
      (error) => {
        console.error('error in fetching user profile data');
      }
    );
  }

  /** This method is used to unmask the phone number */
  maskphonenum() {
    const phoneNumber = this.m.phone.value.substring(1, 4).trim() + this.m.phone.value.substring(5, 9).trim() +
      this.m.phone.value.substring(10, 14).trim();
    return phoneNumber;
  }

  /** This method is used to unmask the phone number */
  maskfaxnum() {
    if (this.m.fax.value) {
      const faxNumber = this.m.fax.value.substring(1, 4).trim() + this.m.fax.value.substring(5, 9).trim() +
        this.m.fax.value.substring(10, 14).trim();
      return faxNumber;
    } else {
      return 'null';
    }
  }

  /** This method is used to format the phone number coming from the database into the desired format */
  formatContactNum(maskNumber) {
    const tempNumber = String(maskNumber);
    const phoneNumber = '(' + (tempNumber).substring(0, 3)
      + ') ' + (tempNumber).substring(3, 6)
      + '-' + (tempNumber).substring(6);
    return phoneNumber;
  }

  /** This method is used to enable the fields that can be edited in MyData form */
  editProfile() {
    this.disableEditUser = true;
    this.saveCancelAria = false;
    // this.m.emailAddress.enable();
    // this.m.address1.enable();
    // this.m.address2.enable();
    // this.m.zipCode1.enable();
    // this.m.zipCode2.enable();
    // this.m.phone.enable();
    document.getElementById('saveCancel').classList.remove('hidden');
  }

  /** This method is called to relinquish all changes made to MyData form and disable the form */
  cancelEdit() {
    this.getProfileData();
    // this.myDataForm.disable();
    this.disableEditUser = false;
    this.saveCancelAria = true;
    document.getElementById('saveCancel').classList.add('hidden');
  }

  /** This method is used to update the City and State on changing the Zipcode */
  updateAddress() {
    // this.addressDetails = JSON.parse(sessionStorage.getItem('addressDetails'));
    if (this.addressDetails !== null && this.addressDetails !== undefined) {
      if (this.myDataForm.get('zipCode1').value !== undefined &&
        (this.myDataForm.get('zipCode1').value.length === undefined || this.myDataForm.get('zipCode1').value.length === 5)) {
        for (let i = 0; i < this.addressDetails.length; i++) {
          if (+this.m.zipCode1.value === this.addressDetails[i].ZIP) {
            this.m.state.setValue(this.addressDetails[i].stateCode);
            this.m.city.setValue(this.addressDetails[i].City);
            this.myDataForm.get('zipFound').setValue('false');
            break;
          }
        }
      } else {
        this.m.state.setValue('');
        this.m.city.setValue('');
        this.myDataForm.get('zipFound').setValue('true');
      }
    }
  }

  /*  My Security Question functionalities start */

  /** This method is used to get the security questions answered by the user */
  getUserQuestions() {
    let data = Object();
    data.userLoginId = this.userDet.getScreenName();
    let encryption = Object();
    encryption.messageContent =  this.userDet.encryptData(data);
    this.httpService.getUserSecurityQsByLoginId(encryption).subscribe(
      (data: any) => {
        data = this.userDet.decryptData(data.messageContent);
        this.secQuesUser = data.usersSeqQue;
        this.noOfQuestions = data.noOfQues;
        (document.getElementById('quesNumber') as HTMLInputElement).value = data.noOfQues;

        for (let i = 0; i < data.noOfQues; i++) {
          const arrayControl = <FormArray>this.securityQsForm.controls['securityQuestions'];
          arrayControl.push(this.getSecurityQSFields(i));

          this.securityQsForm.get('securityQuestions').get(String(i)).get('question_' + i).setValue(data.usersSeqQue[i].questionId);
          this.securityQsForm.get('securityQuestions').get(String(i)).get('answer_' + i).setValue(data.usersSeqQue[i].answer);
          this.securityQsForm.get('securityQuestions').get(String(i)).get('question_' + i).markAsTouched();
          this.securityQsForm.get('securityQuestions').get(String(i)).get('answer_' + i).markAsTouched();
        }
      },
      (error) => {
        console.error('error in fetching security question');
      }
    );
  }

  /** This method is used to define the fields that would be present in the FormArray */
  getSecurityQSFields(i) {
    const ques = 'question_' + i;
    const ans = 'answer_' + i;
    return this.formBuilder.group({
      [ques]: [[Validators.required]],
      [ans]: [{value:''},[Validators.required, this.checkSpaces]]
    });
  }

  /** This method is used to enable the Security Question form and display the Save & Cancel buttons */
  editQuestion() {
    this.maxShow = false;
    this.disableEditQuestion = true;
    this.saveCancelQuesAria = false;
    // for (let i = 0; i < this.noOfQuestions; i++) {
    //   this.securityQsForm.get('securityQuestions').get(String(i)).get('question_' + i).enable();
    //   this.securityQsForm.get('securityQuestions').get(String(i)).get('answer_' + i).enable();
    // }
    if (this.savCan !== undefined) {
      this.savCan.nativeElement.classList.remove('hidden');
    }
  }

  /** This method relinquishes all changes made to edit Security Question form  and disables the form*/
  cancelQuestion() {
    this.maxShow = true;
    this.disableEditQuestion = false;
    this.saveCancelQuesAria = true;
    for (let i = 0; i < this.noOfQuestions; i++) {
      this.securityQsForm.get('securityQuestions').get(String(i)).get('question_' + i).setValue(this.secQuesUser[i].questionId);
      this.securityQsForm.get('securityQuestions').get(String(i)).get('answer_' + i).setValue(this.secQuesUser[i].answer);

      // this.securityQsForm.get('securityQuestions').get(String(i)).get('question_' + i).disable();
      // this.securityQsForm.get('securityQuestions').get(String(i)).get('answer_' + i).disable();
    }
    if (this.savCan !== undefined) {
      this.savCan.nativeElement.classList.add('hidden');
    }
  }

  /** This method is used to check if the same question has been selected in two or more dropdowns */
  checkDuplicateQs(form: FormGroup) {
    const counter = +(document.getElementById('quesNumber') as HTMLInputElement).value;
    let condition = false;
    for (let i = 0; i < counter; i++) {
      if (form.get('securityQuestions').get(String(i)) !== null) {
        const currentVal = form.get('securityQuestions').get(String(i)) !== null ?
          form.get('securityQuestions').get(String(i)).get('question_' + i) : null;
        for (let j = 0; j < counter; j++) {
          const selectedVal = form.get('securityQuestions').get(String(j)) !== null ?
          form.get('securityQuestions').get(String(j)).get('question_' + j) : null;
          if (currentVal !== null && selectedVal !== null && i !== j && currentVal.value !== ''
            && selectedVal.value !== '' && (selectedVal.value === currentVal.value)) {
            condition = true;
            break;
          }
        }
      }
    }
    return condition ? { duplicateError: true } : null;
  }

  /*  My Security Question functionalities end */

  /** This method is used to generate random passwords */
  randomPassword(len) {
    const length = (len) ? (len) : (8);
    const string = 'abcdefghijklmnopqrstuvwxyz';
    const numeric = '0123456789';
    const punctuation = '!@#$%^&*';
    let password = '';
    let character = '';
    while (password.length < length) {
      const entity1 = Math.ceil(string.length * Math.random() * Math.random());
      const entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
      const entity3 = Math.ceil(punctuation.length * Math.random() * Math.random());
      let hold = string.charAt(entity1);
      hold = (password.length % 2 === 0) ? (hold.toUpperCase()) : (hold);
      character += hold;
      character += numeric.charAt(entity2);
      character += punctuation.charAt(entity3);
      password = character;
    }
    password = password.split('').sort(function () { return (0.5 - Math.random()); }).join('');
    return password.substr(0, length);
  }

  /** This method is used to bind the password generated with the field */
  generate() {
    this.generatepwd.nativeElement.value = this.randomPassword('8');
    const pwdVal = this.generatepwd.nativeElement.value;
    if (pwdVal !== '' || pwdVal.length !== 0 || pwdVal !== null) {
      document.getElementById('btnConfirm').removeAttribute('disabled');
    }
  }
  /* This method is used to show a popup which allows the user to auto-generate password*/
  showpopup() {
    this.showAlert = true;
    document.getElementById('alertModal').style.display = 'block';
    const pwdVal = this.generatepwd.nativeElement.value;
    if (pwdVal === '' || pwdVal.length === 0 || pwdVal === null) {
      document.getElementById('btnConfirm').setAttribute('disabled', 'true');
    }

  }

  /** This method binds the selected password with the new password field */
  selectpwd() {
    this.f.newPassword.setValue(this.generatepwd.nativeElement.value);
    this.f.confirmPassword.setValue('');
    document.getElementById('alertModal').style.display = 'none';
    this.showAlert = false;
  }

  /** This method is used to dismiss the auto-generate password popup */
  cancelgenratepwd() {
    document.getElementById('alertModal').style.display = 'none';
    (document.getElementById('generatepwd') as HTMLInputElement).value = '';
    this.showAlert = false;
  }

  /* This method is used to check if an object is empty */
  isEmptyObj(obj: any) {
    return (obj && (Object.keys(obj).length === 0));
  }

  /** This method is used to save all the changes made to the database */
  saveQuestionData() {
    let flag = true;
    this.configurePortalRequest = new CONFIGUREPORTALREQUEST();
    this.configurePortalRequest.screenId = this.userDet.getScreenId();
    this.configurePortalRequest.updatedBy = this.userDet.getScreenName();
    this.configurePortalRequest.portletId = configList.pageConst.NAPortletId;
    for (let i = 0; i < this.noOfQuestions; i++) {
      if (this.securityQsForm.get('securityQuestions').get(String(i)).get('question_' + i).value !== this.secQuesUser[i].questionId ||
        this.securityQsForm.get('securityQuestions').get(String(i)).get('answer_' + i).value !== this.secQuesUser[i].answer) {
        flag = false;
        break;
      }
    }
    if (flag) {
      this.errorMsg = configList.errorConst.unchangedError;
      this.showAlertUC = true;
      if (this.ucModal !== undefined) {
        this.ucModal.nativeElement.style.display = 'block';
      }
      if (this.ucMsg !== undefined) {
        this.ucMsg.nativeElement.focus();
      }
    } else {
      const modifySecQues = Object();
      modifySecQues.userLoginId = this.userDet.getScreenName();
      modifySecQues.detailsList = [];
      const list = [];
      for (let i = 0; i < this.noOfQuestions; i++) {
        const detailList = Object();
        const newValue = Object();
        const oldValue = Object();
        if (this.securityQsForm.get('securityQuestions').get(String(i)).get('question_' + i).value !== this.secQuesUser[i].questionId) {
          oldValue.questionId = this.secQuesUser[i].questionId;
          newValue.questionId = this.securityQsForm.get('securityQuestions').get(String(i)).get('question_' + i).value;
        }
        if (this.securityQsForm.get('securityQuestions').get(String(i)).get('answer_' + i).value !== this.secQuesUser[i].answer) {
          oldValue.answer = this.secQuesUser[i].answer;
          newValue.answer = this.securityQsForm.get('securityQuestions').get(String(i)).get('answer_' + i).value;
        }
        detailList.userSecQueId = this.secQuesUser[i].userSecQueId;
        detailList.oldValue = oldValue;
        detailList.newValue = newValue;

        if (!this.isEmptyObj(oldValue) && !this.isEmptyObj(newValue)) {
          list.push(detailList);
        }
      }
      modifySecQues.detailsList = list;
      this.configurePortalRequest.activityCode = configList.activityCode.modifySecurityQuestion;
      this.configurePortalRequest.modifySecQues = modifySecQues;
      let finalData = Object();
      finalData.messageContent = this.userDet.encryptData(this.configurePortalRequest);

      // API call for saving sec qs
      this.httpService.secureUpdateUserLogin(finalData).subscribe(data => {
        data = this.userDet.decryptData(data.messageContent);
        if (data.userSecQueResp.responseCode === 0) {
          this.errorMsg = data.userSecQueResp.responseDesc;
        } else {
          this.errorMsg = configList.errorConst.SystemError;
        }
        this.showAlertUC = true;
        if (this.ucModal !== undefined) {
          this.ucModal.nativeElement.style.display = 'block';
        }
        if (this.ucMsg !== undefined) {
          this.ucMsg.nativeElement.focus();
        }
        this.maxShow = true;
        this.disableEditQuestion = false;
        let paramData = Object();
        paramData.userLoginId = this.userDet.getScreenName();
        let encryption = Object();
        encryption.messageContent =  this.userDet.encryptData(paramData);
        this.httpService.getUserSecurityQsByLoginId(encryption).subscribe(
          (dataQues: any) => {
            dataQues = this.userDet.decryptData(dataQues.messageContent);
            this.secQuesUser = dataQues.usersSeqQue;
            this.noOfQuestions = dataQues.noOfQues;
            for (let i = 0; i < dataQues.noOfQues; i++) {
              this.securityQsForm.get('securityQuestions').get(String(i)).get('question_' + i).setValue(dataQues.usersSeqQue[i].questionId);
              this.securityQsForm.get('securityQuestions').get(String(i)).get('answer_' + i).setValue(dataQues.usersSeqQue[i].answer);
              this.securityQsForm.get('securityQuestions').get(String(i)).get('question_' + i).markAsTouched();
              this.securityQsForm.get('securityQuestions').get(String(i)).get('answer_' + i).markAsTouched();
              // this.securityQsForm.get('securityQuestions').get(String(i)).get('question_' + i).disable();
              // this.securityQsForm.get('securityQuestions').get(String(i)).get('answer_' + i).disable();
            }
          });
        if (this.savCan !== undefined) {
          this.savCan.nativeElement.classList.add('hidden');
        }
      },
        error => {
          this.logger.debug('Error while saving security question');
        });
    }
  }

  /** This method is used to reset the pasword form */
  cancelPassword(): void {
    this.passwordForm.reset(this._initialValue);
  }

  /** This method is used to save all changes made to the database */
  saveUserData() {
    this.configurePortalRequest = new CONFIGUREPORTALREQUEST();
    this.configurePortalRequest.screenId = this.userDet.getScreenId();
    this.configurePortalRequest.updatedBy = this.userDet.getScreenName();
    this.configurePortalRequest.portletId = configList.pageConst.NAPortletId;
    const phone = this.maskphonenum();
    const fax = this.maskfaxnum();
    if (this.myProfileData.lastName === this.m.lastName.value &&
      this.myProfileData.firstName === this.m.firstName.value &&
      this.myProfileData.accrediation === this.m.accreditation.value &&
      this.myProfileData.emailAddress === this.m.emailAddress.value &&
      this.myProfileData.identificationId === this.m.identificationId.value &&
      this.myProfileData.roleId === this.m.role.value &&
      this.myProfileData.address1 === this.m.address1.value &&
      this.myProfileData.address2 === this.m.address2.value &&
      this.myProfileData.city === this.m.city.value &&
      this.myProfileData.state === this.m.state.value &&
      this.myProfileData.zipCode1 === this.m.zipCode1.value &&
      this.myProfileData.zipCode2 === this.m.zipCode2.value &&
      String(this.myProfileData.contactNum) === phone &&
      String(this.myProfileData.faxNumber) === fax &&
      this.myProfileData.userStatus === this.m.status.value) {
      this.errorMsg = configList.errorConst.unchangedError;
      this.showAlertUC = true;
      if (this.ucModal !== undefined) {
        this.ucModal.nativeElement.style.display = 'block';
      }
      if (this.ucMsg !== undefined) {
        this.ucMsg.nativeElement.focus();
      }
    } else {
      this.configurePortalRequest.activityCode = configList.activityCode.modifyMyData;

      const modifyUserDetails = Object();
      modifyUserDetails.oldValue = Object();
      modifyUserDetails.newValue = Object();

      if (this.myProfileData.lastName !== this.m.lastName.value) {
        modifyUserDetails.oldValue.lastName = this.myProfileData.lastName;
        modifyUserDetails.newValue.lastName = this.m.lastName.value;
      }
      if (this.myProfileData.firstName !== this.m.firstName.value) {
        modifyUserDetails.oldValue.firstName = this.myProfileData.firstName;
        modifyUserDetails.newValue.firstName = this.m.firstName.value;
      }
      if (this.myProfileData.accrediation !== this.m.accreditation.value) {
        modifyUserDetails.oldValue.accrediation = this.myProfileData.accrediation;
        modifyUserDetails.newValue.accrediation = this.m.accreditation.value;
      }
      if (this.myProfileData.roleId !== this.m.role.value) {
        modifyUserDetails.oldValue.role = this.myProfileData.roleId;
        modifyUserDetails.newValue.role = this.m.role.value;
      }
      if (this.myProfileData.address1 !== this.m.address1.value) {
        modifyUserDetails.oldValue.address1 = this.myProfileData.address1;
        modifyUserDetails.newValue.address1 = this.m.address1.value;
      }
      if (this.myProfileData.address2 !== this.m.address2.value) {
        modifyUserDetails.oldValue.address2 = this.myProfileData.address2;
        modifyUserDetails.newValue.address2 = this.m.address2.value;
      }
      if (this.myProfileData.city !== this.m.city.value) {
        modifyUserDetails.oldValue.city = this.myProfileData.city;
        modifyUserDetails.newValue.city = this.m.city.value;
      }
      if (this.myProfileData.state !== this.m.state.value) {
        modifyUserDetails.oldValue.state = this.myProfileData.state;
        modifyUserDetails.newValue.state = this.m.state.value;
      }
      if (this.myProfileData.zipCode1 !== this.m.zipCode1.value) {
        modifyUserDetails.oldValue.zipCode1 = this.myProfileData.zipCode1;
        modifyUserDetails.newValue.zipCode1 = this.m.zipCode1.value;
      }
      if (this.myProfileData.zipCode2 !== this.m.zipCode2.value) {
        modifyUserDetails.oldValue.zipCode2 = this.myProfileData.zipCode2;
        modifyUserDetails.newValue.zipCode2 = +this.m.zipCode2.value;
      }
      if (String(this.myProfileData.contactNum) !== phone) {
        modifyUserDetails.oldValue.contactNum = String(this.myProfileData.contactNum);
        modifyUserDetails.newValue.contactNum = phone;
      }
      if (String(this.myProfileData.faxNumber) !== fax) {
        modifyUserDetails.oldValue.faxNumber = String(this.myProfileData.faxNumber);
        modifyUserDetails.newValue.faxNumber = fax;
      }
      if (this.myProfileData.userStatus !== this.m.status.value) {
        modifyUserDetails.oldValue.userStatus = this.myProfileData.userStatus;
        modifyUserDetails.newValue.userStatus = this.m.status.value;
      }
      if (this.myProfileData.emailAddress !== this.m.emailAddress.value) {
        modifyUserDetails.oldValue.emailAddress = this.myProfileData.emailAddress;
        modifyUserDetails.newValue.emailAddress = this.m.emailAddress.value;
      }
      if (this.myProfileData.identificationId !== this.m.identificationId.value) {
        modifyUserDetails.oldValue.identificationId = this.myProfileData.identificationId;
        modifyUserDetails.newValue.identificationId = this.m.identificationId.value;
      }

      modifyUserDetails.userLoginId = this.userDet.getScreenName();
      this.configurePortalRequest.modifyUserDetails = modifyUserDetails;
      let finalData = Object();
      finalData.messageContent = this.userDet.encryptData(this.configurePortalRequest);
      this.httpService.secureUpdateUserLogin(finalData).subscribe(data => {
        data = this.userDet.decryptData(data.messageContent);
        if (data.userResp.responseCode === 0) {
          this.cancelEdit();
        } else {
          this.errorMsg = configList.errorConst.SystemError;
        }
        this.showAlertUC = true;
        this.errorMsg = data.userResp.responseDesc;
        if (this.ucModal !== undefined) {
          this.ucModal.nativeElement.style.display = 'block';
        }
        if (this.ucMsg !== undefined) {
          this.ucMsg.nativeElement.focus();
        }
      },
        error => {
          this.logger.debug('Error while updating My Data');
        }
      );
    }
  }

  // Is used to  dismiss the success/error popup
  confirmUCModal() {
    this.showAlertUC = false;
    if (this.ucModal !== undefined) {
      this.ucModal.nativeElement.style.display = 'none';
    }
    document.getElementById('homeLink').focus();
  }

  /** This method is used to toggle between the eye and eye-slash fa-icons */
  pwdalert(icon1) {
    const x = document.getElementById('newPassword') as HTMLInputElement;
    if (document.getElementById('pwdvaaal').className === 'fas fa-eye-slash') {
      document.getElementById('pwdvaaal').className = 'fas fa-eye';
      if (x.type === 'password') {
        x.type = 'text';
      }
    } else {
      document.getElementById('pwdvaaal').className = 'fas fa-eye-slash';
      x.type = 'password';
    }

  }

  /** This method is called to save all changes made in the password form */
  savePassword(reuseStatus) {
    this.flag = false;
    this.reuseFlag = false;
    this.oldFocus = false;
    this.newFocus = false;
    this.successFlag = false;
    this.showAlertPass = false;
    this.systemError = false;
    if (this.passModal !== undefined) {
      this.passModal.nativeElement.style.display = 'none';
    }
    this.configurePortalRequest = new CONFIGUREPORTALREQUEST();
    this.configurePortalRequest.screenId = this.userDet.getScreenId();
    this.configurePortalRequest.updatedBy = this.userDet.getScreenName();
    this.configurePortalRequest.portletId = configList.pageConst.NAPortletId;
    this.configurePortalRequest.activityCode = configList.activityCode.changePassword;
    const ruleEngineData = Object();
    ruleEngineData.userLoginId = this.userDet.getScreenName();
    ruleEngineData.oldPassword = !this.ssoMode ? this.f.reEnterPassword.value : null;
    ruleEngineData.newPassword = this.f.newPassword.value;
    ruleEngineData.reusePassword = reuseStatus;
    this.configurePortalRequest.ruleEngineData = ruleEngineData;
    let finalData = Object();
    finalData.messageContent = this.userDet.encryptData(this.configurePortalRequest);
    // API call for saving reset password
    this.httpService.secureUpdateUserLogin(finalData).subscribe(data => {
      data = this.userDet.decryptData(data.messageContent);
      sessionStorage.setItem('LoginToken', data.loginToken);
      if (data.ruleEngineResp.responseCode === 'RP002') {
        this.flag = true;
        this.reuseFlag = false;
        this.successFlag = true;
        this.errorMsg = data.ruleEngineResp.responseDesc;
      } else if (data.ruleEngineResp.responseCode === 'RP103') {
        this.flag = true;
        this.reuseFlag = false;
        this.oldFocus = true;
        this.errorMsg = data.ruleEngineResp.responseDesc;
      } else if (data.ruleEngineResp.responseCode === 'RP104' || data.ruleEngineResp.responseCode === 'RP106') {
        this.flag = true;
        this.reuseFlag = false;
        this.newFocus = true;
        this.errorMsg = data.ruleEngineResp.responseDesc;
      } else if (data.ruleEngineResp.responseCode === 'RP107') {
        this.flag = false;
        this.reuseFlag = true;
        this.newFocus = true;
        this.errorMsg = data.ruleEngineResp.responseDesc;
      } else {
        this.errorMsg = configList.errorConst.SystemError;
        this.flag = true;
        this.reuseFlag = false;
        this.systemError = true;
      }
      this.showAlertPass = true;
      if (this.passModal !== undefined) {
        this.passModal.nativeElement.style.display = 'block';
      }
      if (this.passMsg !== undefined) {
        this.passMsg.nativeElement.focus();
      }
    },
      error => {
        this.logger.debug('Error while saving reset password');
      }
    );
  }

  /** This method is used to dismiss the auto-generate password modal */
  confirmPassModal(): void {
    this.showAlertPass = false;
    if (this.passModal !== undefined) {
      this.passModal.nativeElement.style.display = 'none';
    }
    this.cancelPassword();
    if (this.oldFocus || this.systemError) {
      this.oldPassword.nativeElement.focus();
    } else if (this.newFocus) {
      this.newPassword.nativeElement.focus();
    } else if (this.successFlag) {
      this.ngOnInit();
    }
  }

  /** This method is used to restrict user from entering alphabets in numeric field */
  onlyNumberKey(event: any) {
    const pattern = configList.pageConst.numberRegex;

    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode === 32) {
      event.preventDefault();
    }
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  /** This method is used to fetch the US zip codes */
  getzipData() {
    this.httpService.get_zipDetails().subscribe(
      (data: any) => {
        // sessionStorage.setItem('addressDetails', JSON.stringify(data));
        // localStorage.setItem('addressDetails', JSON.stringify(data));
        this.addressDetails = data;
      },
      error => {
        this.logger.debug('Error while getting Zipcode data');
      }
    );
  }

  /** To get the form element data using form group dynamically */
  get formData() { 
    return <FormArray>this.securityQsForm.get('securityQuestions'); 
  }

  /*To Get sso mode is enabled or not if enabled from session, assign ssoMode variable to true */
  getssoMode(){
    this.ssoMode = sessionStorage.getItem('ssoMode') === 'Y' ? true : false;
  }
  
  /** Based on json parameters forming the password contains error message */
  pswdContainError() {
    let containErrorMsg: string = '';    
    if (this.pwdConfigRules) {
      containErrorMsg = "&nbsp;"+ configList.errorConst.newPasswordContainError;
      containErrorMsg +="<ul class='mb-0'>";
      if (this.pwdConfigRules.userIdNotAllowed) {
        containErrorMsg += "<li>"+configList.errorConst.newPasswordUsernameError + "</li>";
      }
      if (this.pwdConfigRules.phoneNumberNotAllowed) {
        containErrorMsg +=  "<li>"+ configList.errorConst.newPasswordPhoneError + "</li>";
      }
      if (this.pwdConfigRules.consecutiveCharNotAllowed) {
        containErrorMsg += "<li>"+configList.errorConst.newPasswordConseRegex + "</li>";
      }
      if (this.pwdConfigRules.capNumberSplCharNotAllowed) {
        containErrorMsg += "<li>"+configList.errorConst.newPasswordCapNumSplReg + "</li>";
      }
      if (this.pwdConfigRules.splCharNumberCapNotAllowed) {
        containErrorMsg += "<li>"+configList.errorConst.newPasswordSplNumCapReg + "</li>";
      }
      containErrorMsg +="</ul>";
      if (!(this.pwdConfigRules.userIdNotAllowed || this.pwdConfigRules.phoneNumberNotAllowed || 
        this.pwdConfigRules.consecutiveCharNotAllowed || this.pwdConfigRules.capNumberSplCharNotAllowed ||
        this.pwdConfigRules.splCharNumberCapNotAllowed)) {
        containErrorMsg = null;
      }
    }    
    return containErrorMsg;
  }
  /** Based on json parameters forming the password must contains error message */
  pswdMustContainError() {
    let containErrorMsg: string = '';    
    if (this.pwdConfigRules) {
      containErrorMsg = "&nbsp;"+ this.userDet.pswdMustContainErrorMsg();
      containErrorMsg +="<ul class='mb-0'>";
      if (this.pwdConfigRules.passwordShouldContainUpperCase) {
        containErrorMsg += "<li>"+ this.userDet.pswdUpperCaseErrorMessage() + "</li>";
      }
      if (this.pwdConfigRules.passwordShouldContainLowerCase) {
        containErrorMsg +=  "<li>"+ this.userDet.pswdLowerCaseErrorMessage() + "</li>";
      }
      if (this.pwdConfigRules.passwordShouldContainNumber) {
        containErrorMsg += "<li>"+ this.userDet.pswdNumberErrorMessage() + "</li>";
      }
      if (this.pwdConfigRules.passwordShouldContainSpecialChar) {
        containErrorMsg += "<li>"+ this.userDet.pswdSpecialCharErrorMessage() + "</li>";
      }
      containErrorMsg +="</ul>";
      if (!(this.pwdConfigRules.passwordShouldContainUpperCase || this.pwdConfigRules.passwordShouldContainLowerCase || 
        this.pwdConfigRules.passwordShouldContainNumber || this.pwdConfigRules.passwordShouldContainSpecialChar)) {
        containErrorMsg = null;
      }
    }    
    return containErrorMsg;
  }
  /** Check for username & phone number */
  checkUserDetails(control: FormControl) {    
    if (this.myProfileData !== null && this.myProfileData !== undefined) {
      if (control.value !== undefined && (control.value.includes(this.myProfileData.contactNum))||
      (control.value.includes(this.myProfileData.userLoginId))) {
        return { pswdContainsError : true };
      }
      return null;
    }
  }
  /** Form final regex for password must contain values*/
  combinationUtil(arr, n, r, index, data, i) 
  { 
    if (index == r) 
    { 
    for (let j = 0; j < r; j++){
      this.finalReg += data[j];
    }
    this.finalReg += "|";      
      return;
    }
    if (i >= n) 
    return; 
    data[index] = arr[i]; 
    this.combinationUtil(arr, n, r, index + 1, data, i + 1); 
    this.combinationUtil(arr, n, r, index, data, i + 1); 
  }
 
}
