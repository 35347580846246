import { Component } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { LoaderService } from './_services/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [NGXLogger]
})
export class AppComponent {
  title = 'PBM Portal';

  constructor(private logger: NGXLogger, private router: Router, private loaderService: LoaderService) {
    localStorage.setItem('loggerFeature', 'false');
    if (localStorage.getItem('loggerFeature') === 'true') {
      this.logger.debug('Your log message goes here');
      this.logger.debug('Multiple', 'Argument', 'support');
    }
  }
  ngOnInit () {
    // this.router.events.subscribe(event => {
    //     if (event instanceof RouteConfigLoadStart) {
    //         this.loaderService.isLoading.next(true);
    //     } else if (event instanceof RouteConfigLoadEnd) {
    //       this.loaderService.isLoading.next(false);
    //     }
    // });
  }
}

