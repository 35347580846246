import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from '../_services/http.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @ViewChild('eulaModal') eulaModal: any; // needed for ADA
  @ViewChild('contactUsModal') contactUsModal: any; // needed for ADA
  @ViewChild('policyModal') policyModal: any; // needed for ADA
  @ViewChild('securityDisclaimerModal') securityDisclaimerModal: any; // needed for ADA
  public showAlertQS = false;
  data: any = [];
  eulaUrl: SafeResourceUrl;
  securityPolicyUrl: SafeResourceUrl;
  securityDisclaimerContent = '';
  contactName = '';
  contactInfo = '';
  phoneNumber = '';
  emailId = '';
  faxNumber = '';
  myTemplate = '';

  frameId = '';

  eulaUrlToPrint = '';
  policyUrlToPrint = '';
  showContactAlert = false;
  showPolicyAlert = false;
  showDisclaimerAlert = false;

  constructor(private httpService: HttpService, public sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.eulaUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
    this.securityPolicyUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
    this.getFooterInfoMethod();
  }
  getFooterInfoMethod() {
    this.httpService.getFooterInfo().subscribe(
      // httpService.getJSON(configlist.prgmjson).subscribe(
      (data: any) => {
        if (data) {
          this.data = data;
          if (data.footerResponse.eulaUrl !== null && data.footerResponse.eulaUrl !== undefined) {
            this.eulaUrlToPrint = data.footerResponse.eulaUrl;
            this.eulaUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data.footerResponse.eulaUrl);
          }
          if (data.footerResponse.securityPolicyUrl !== null && data.footerResponse.securityPolicyUrl !== undefined) {
            this.policyUrlToPrint = data.footerResponse.securityPolicyUrl;
            this.securityPolicyUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data.footerResponse.securityPolicyUrl);
          }
          if (data.footerResponse.securityDisclaimer !== null && data.footerResponse.securityDisclaimer !== undefined) {
            this.securityDisclaimerContent = data.footerResponse.securityDisclaimer;
          }
          if (data.footerResponse.contactUsName !== null && data.footerResponse.contactUsName !== undefined) {
            this.contactName = data.footerResponse.contactUsName;
          }
          if (data.footerResponse.contactUsInfo !== null && data.footerResponse.contactUsInfo !== undefined) {
            this.contactInfo = data.footerResponse.contactUsInfo;
          }
          if (data.footerResponse.contactUsPhone !== null && data.footerResponse.contactUsPhone !== undefined) {
            // this.phoneNumber = data.footerResponse.contactUsPhone;
            const phoneNumberString = '' + data.footerResponse.contactUsPhone;
            this.phoneNumber = '+1 (' + (phoneNumberString).substring(0, 3)
                            + ')-' + (phoneNumberString).substring(3, 6)
                            + '-' + (phoneNumberString).substring(6);
          }
          if (data.footerResponse.contactUsEmail !== null && data.footerResponse.contactUsEmail !== undefined) {
            this.emailId = data.footerResponse.contactUsEmail;
          }
          if (data.footerResponse.contactUsFax !== null && data.footerResponse.contactUsFax !== undefined) {
           // this.faxNumber = data.footerResponse.contactUsFax;
           const faxNumberString = '' + data.footerResponse.contactUsFax;
            this.faxNumber = '+1 (' + (faxNumberString).substring(0, 3)
                            + ')-' + (faxNumberString).substring(3, 6)
                            + '-' + (faxNumberString).substring(6);
          }
        }
      }
    );
  }
printURL(url: any) {
    let urlF = this.data.footerResponse.eulaUrl;
    if (url === 'eula') {
      urlF = this.data.footerResponse.eulaUrl;
    } else if (url === 'SPolicy') {
      urlF = this.data.footerResponse.securityPolicyUrl;
    }
    // window.open(this.data.footerResponse.securityPolicyUrl, '_blank');
    const printWindow = window.open(
      urlF,
      'Print'
  );
  printWindow.addEventListener('load', function() {
      printWindow.print();
      printWindow.close();
  }, true);
  }

  getPDF(urlToPrint) {
   /* this.httpService.getPDF(urlToPrint).subscribe(
      (data: any) => {
        if (data) {
          console.log('in Data:');
          const file = new Blob([data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      }
    );*/
   console.log('In getPDF:', urlToPrint);
   const w = window.open(urlToPrint);
   w.print();
  }

  eulaFocus() {
    // alert('hi');
    // document.getElementById('eulaPopUp').setAttribute(dataset.target, '#eulaModal');
    // document.getElementById('eulaPopUp').dataset.target = '#eulaModal';
    // console.log(document.getElementById('eulaPopUp').dataset.target);
    this.showAlertQS = true;
    if (this.eulaModal !== undefined) {
      // this.eulaModal.nativeElement.modal({backdrop: 'static', keyboard: false});
      this.eulaModal.nativeElement.style.display = 'block'; 
    }
    document.getElementById('eulaHeader').focus();
  }

  closeEula() {
    this.showAlertQS = false;
    if (this.eulaModal !== undefined) {
      this.eulaModal.nativeElement.style.display = 'none';
    }
    document.getElementById('eulaPopUp').focus();
  }

  contactUsFocus() {
    this.showContactAlert = true;
    if (this.contactUsModal !== undefined) {
      this.contactUsModal.nativeElement.style.display = 'block';
    }
    document.getElementById('contactUs').focus();
  }

  closeContactus() {
    this.showContactAlert = false;
    if (this.contactUsModal !== undefined) {
      this.contactUsModal.nativeElement.style.display = 'none';
    }
    document.getElementById('contactUsPopUp').focus();
  }

  policyFocus() {
    this.showPolicyAlert = true;
    if (this.policyModal !== undefined) {
      this.policyModal.nativeElement.style.display = 'block';
    }
    document.getElementById('policy').focus();
  }

  closePolicy() {
    this.showPolicyAlert = false;
    if (this.policyModal !== undefined) {
      this.policyModal.nativeElement.style.display = 'none';
    }
    document.getElementById('securityPolicyPopUp').focus();
  }

  disclaimerFocus() {
    this.showDisclaimerAlert = true;
    if (this.securityDisclaimerModal !== undefined) {
      this.securityDisclaimerModal.nativeElement.style.display = 'block';
    }
    document.getElementById('disclaimer').focus();
  }

  closeDisclaimer() {
    this.showDisclaimerAlert = false;
    if (this.securityDisclaimerModal !== undefined) {
      this.securityDisclaimerModal.nativeElement.style.display = 'none';
    }
    document.getElementById('securityDisclaimerPopUp').focus();
  }
}
