export class PagerService {

    static itemNo = 0;
    static itemLen = 0;
    static itemsToDisp = 0;
    static itemsToDispDef = 0;
    static limitLen = 800;
    static items = new Set();

    static dive_data(obj) {
        // get all the items from DB and check the lenght of items by char
        // tslint:disable-next-line:forin
        for (const prop in obj) {
            if (typeof obj[prop] === 'object') {
                // object
                this.dive_data(obj[prop]);
            } else {
                const props = prop.toString().toLowerCase();
                if (props === 'question' || props === 'answer' ||
                    props === 'information' || props === 'news' ||
                    props === 'contactname' || props === 'phnnumber' ||
                    props === 'emailid' || props === 'helpinformation') {
                    this.items.add(props);
                    this.itemNo++;
                    const itemVal = obj[prop].toString().toLowerCase();
                    this.itemLen += itemVal.length;
                }
            }
        }

        // items to display in page
        if (this.itemNo > 0) {
            for (let i = this.itemNo; i >= 1; i--) {
                if (this.itemNo === i && this.itemLen < this.limitLen) {
                    this.itemsToDisp = i;
                }
            }
        }


        // if more than 5 items per page than set to 5
        if (this.itemsToDisp > 5) {
            this.itemsToDisp = 5;
        }
        for (const item of this.items) {
            if ((item === 'contactname' || item === 'phnnumber' || item === 'emailid')
            && this.itemsToDisp > this.itemsToDispDef) {
                this.itemsToDisp = this.itemsToDispDef;
            }
            if ((item === 'question' || item === 'answer')
            && this.itemsToDisp > this.itemsToDispDef && this.itemsToDisp > 4) {
                this.itemsToDisp = 3;
            }
        }
    }

    static getPager(AllItems: any, currentPage: number = 1, pageSize: number) {
        let totalItems = 0;
        if (typeof AllItems === 'object') {
            totalItems = AllItems.length;
            this.itemsToDispDef = pageSize;
            this.dive_data(AllItems);
            pageSize = this.itemsToDisp;
        } else if (typeof AllItems === 'number') {
            totalItems = AllItems;
        }
        // calculate total pages
        // tslint:disable-next-line:member-ordering
        const totalPages = Math.ceil(totalItems / pageSize);
        // tslint:disable-next-line:comment-format
        // ensure current page isn't out of range
        if (currentPage < 1) {
            currentPage = 1;
        } else if (currentPage > totalPages) {
            currentPage = totalPages;
        }

        let startPage: number, endPage: number;
        if (totalPages <= 4) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 5 total pages so calculate start and end pages
            if ((currentPage > 3) && (totalPages > 4) && (currentPage + 2 < totalPages)) {
                startPage = currentPage - 2;
                endPage = currentPage + 2;
            } else if (currentPage < 4) {
                startPage = 1;
                endPage = 5;
            } else if (currentPage + 2 >= totalPages) {
                startPage = totalPages - 4;
                endPage = totalPages;
            } else {
                startPage = currentPage - 2;
                endPage = currentPage + 2;
            }
        }

        // calculate start and end item indexes
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        const pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

        this.itemNo = 0;
        this.itemLen = 0;
        this.itemsToDisp = 2;
        this.limitLen = 800;
        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }
}
