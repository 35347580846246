import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  @Input() faqTileLabel: string;
  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  faqpageportlet() {
    this.router.navigate(['/faqpage']);
  }

}
