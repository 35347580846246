import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from '../_services/http.service';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';
import * as configList from '../../assets/config/config';
import { UserService } from '../_services/user.service';
import { PasswordDataService } from '../_services/password-data.service';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { PasswordErrorMatcher, NewPasswordErrorMatcher } from '../_helpers/errorMatcher.interceptor';
import { CONFIGUREPORTALREQUEST } from '../_models/ConfigurePortal/configureportalrequest';
import { CustomValidators } from '../_validators/password-validator';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  @ViewChild('newPassword') newPassword: any;
  @ViewChild('confirmPassword') confirmPassword: any;
  @ViewChild('qsModal') qsModal: any; // needed for ADA
  @ViewChild('qsMsg') qsMsg: any; // needed for ADA
  public consterror = configList.errorConst;
  public configurePortalRequest: CONFIGUREPORTALREQUEST;
  public tooltipconstdata = configList.tooltipConst;
  public errorMsg: string;
  public showAlertQS = false;
  public disableSave = false;
  public validPassword = false;
  public invalidPassword = false;
  public reusePassword = false;
  public systemError = false;
  passwordForm: FormGroup;
  public userData: any;
  cnfPassFieldErrorMatcher = new PasswordErrorMatcher();
  newPassFieldErrorMatcher = new NewPasswordErrorMatcher();
  public newPassError: any = '';
  public hide = true;
  public reEnterhide = true;
  public pwdConfigRules: any;
  public errorMsgMethods: any;
  public finalReg: string;

  constructor(private httpService: HttpService, private formBuilder: FormBuilder, private userDet: UserService,
     private logger: NGXLogger, private router: Router, private passwordData: PasswordDataService) {
    // Method to check if the user is authorised to view this page
    this.userDet.authorizeUser();
    this.errorMsgMethods = this.userDet;
  }

  ngOnInit() {
    this.passwordData.getDataFromJson();
    this.passwordForm = this.formBuilder.group({      
      newPassword: [''],
      confirmPassword: ['', [Validators.required]]
    }, { validator: [this.checkPassChanges]
    });
    this.onChanges();
    this.getUserData();
    this.getPwdRules();
    this.pwdConfigRules = this.passwordData.passwordRulesData;
  }

  get f() { return this.passwordForm.controls; }

  /* This method is used to carry out some functionalities when the values of certain fields change */
  onChanges(): void {
    this.passwordForm.get('newPassword').valueChanges.subscribe(val => {
      this.passwordForm.get('newPassword').markAsTouched();
    });
    this.passwordForm.get('confirmPassword').valueChanges.subscribe(val => {
      this.passwordForm.get('confirmPassword').markAsTouched();
    });
  }

  newPassChanges(form: FormGroup) {
    const vm = this;
    const passwordValue = form.get('newPassword').value;
    let phoneNumber = '';
    let loginId = '';
    // this.newPassError = '';
    let condition = false;
    if (passwordValue !== null && passwordValue !== undefined) {
      if (vm.userData !== null && vm.userData !== undefined) {
        phoneNumber = '' + vm.userData.contactNum;
        loginId = vm.userData.userLoginId;
        if (passwordValue.includes(phoneNumber) || passwordValue.includes(loginId) ||
        configList.pageConst.passwordConsecutiveRegex.test(passwordValue)) {
          // this.newPassError =  this.consterror.newPasswordPhoneError;
          condition = true;
        }
      } else {
        if (configList.pageConst.passwordConsecutiveRegex.test(passwordValue)) {
          condition = true;
        }
      }
    }

    return condition ? { newPasswordError : true } : null;
  }

  getUserData() {
    let data = Object();
    data.userLoginId = this.userDet.getScreenName() ;
    let encryption = Object();
    encryption.messageContent =  this.userDet.encryptData(data);
    this.httpService.getUserProfileData(encryption).subscribe(
      (data: any) => {
        data = this.userDet.decryptData(data.messageContent);
        this.userData = data;
      });
  }

  /* This method returns the appropriate error message for confirm password field*/
  getCnfPassError() {
    return this.passwordForm.get('confirmPassword').hasError('required') ? this.consterror.fieldError :
    this.passwordForm.hasError('passwordError') ? this.consterror.confirmPasswordError : '';
  }

  /** This method is called to Save the new password */
  saveResetPasswordData(reuseIndicator) {
    this.validPassword = false;
    this.invalidPassword = false;
    this.reusePassword = false;
    this.systemError = false;
    this.showAlertQS = false;
    if (this.qsModal !== undefined) {
      this.qsModal.nativeElement.style.display = 'none';
    }
    this.configurePortalRequest = new CONFIGUREPORTALREQUEST();
    this.configurePortalRequest.screenId = this.userDet.getScreenId();
    this.configurePortalRequest.updatedBy = this.userDet.getScreenName();
    this.configurePortalRequest.portletId = configList.pageConst.NAPortletId;
    this.configurePortalRequest.activityCode = configList.activityCode.resetPassword;
    const ruleEngineData = Object();
    ruleEngineData.userLoginId = this.userDet.getScreenName();
    ruleEngineData.newPassword = this.f.newPassword.value;
    ruleEngineData.reusePassword = reuseIndicator;
    // this.configurePortalRequest.encryptedContent = this.userDet.encryptData(ruleEngineData);
    this.configurePortalRequest.ruleEngineData = ruleEngineData;
    let finalData = Object();
    finalData.messageContent = this.userDet.encryptData(this.configurePortalRequest);
    // API call for saving reset password
    this.httpService.secureUpdateUserLogin(finalData).subscribe(data => {
      data = this.userDet.decryptData(data.messageContent);
      sessionStorage.setItem('LoginToken', data.loginToken);
      if (data.ruleEngineResp.responseCode === 'RP002') {
        this.validPassword = true;
        this.errorMsg = data.ruleEngineResp.responseDesc;
      } else if (data.ruleEngineResp.responseCode === 'RP104' || data.ruleEngineResp.responseCode === 'RP106') {
        // Invalid Password  Cases
        this.invalidPassword  = true;
        this.errorMsg = data.ruleEngineResp.responseDesc;
      } else if (data.ruleEngineResp.responseCode === 'RP107') {
        // Reuse Password case
        this.reusePassword = true;
        this.errorMsg = data.ruleEngineResp.responseDesc;
      } else {
        this.systemError = true;
        this.errorMsg = configList.errorConst.SystemError;
      }
      this.showAlertQS = true;
      if (this.qsModal !== undefined) {
        this.qsModal.nativeElement.style.display = 'block';
      }
      if (this.qsMsg !== undefined) {
        this.qsMsg.nativeElement.focus();
      }
    },
    error => {
      this.logger.debug('Error while saving reset password');
    }
    );
  }

  /** This method relinquishes all the changes made to the form and redirects to the public-facing page */
  reRoutePublic(): void {
    this.passwordForm.reset();
    this.disableSave = false;
    sessionStorage.setItem('loginRoleId', '-1');
    sessionStorage.setItem('userRoleId', '-1');
    sessionStorage.setItem('privilegeData', null);
    sessionStorage.setItem('loginScreenId', '1');
    sessionStorage.setItem('logOut', 'Y');
    sessionStorage.setItem('isLoggedIn', 'false');
    sessionStorage.setItem('loggedInUserName', null);
    this.router.navigate(['/publicFacing']);
  }

  /** This method is used to remove the pop-up */
  cancelData(): void {
    this.f.newPassword.setValue('');
    this.f.confirmPassword.setValue('');
    this.showAlertQS = false;
    if (this.qsModal !== undefined) {
      this.qsModal.nativeElement.style.display = 'none';
    }
    this.newPassword.nativeElement.focus();
  }


  /** This method is used to route the control to resetSecurityQuestion component */
  rerouteSecQs() {
    this.showAlertQS = false;
    if (this.qsModal !== undefined) {
      this.qsModal.nativeElement.style.display = 'none';
    }
    if (sessionStorage.getItem('loginUserStatus') === 'N') {
      this.router.navigate(['/resetSecurityQuestion']);
    } else if (sessionStorage.getItem('loginUserStatus') === 'A') {
      this.reRoutePublic();
    }
  }

  /* This method is used to check if the content of new password and confirm password fields are same or not*/
  checkPassChanges(form: FormGroup) {
    let condition = false;
    if (form.get('newPassword').value !== form.get('confirmPassword').value) {
      condition = true;
    }
    return condition ? { passwordError: true } : null;
  }
  /** Check for the password config file and assign the validations */
  getPwdRules() {

    this.httpService.getJsonContent(configList.pwdconfigjson).subscribe(
      (data: any) => {
        this.pwdConfigRules = data;
        const validatorsArray = [];
        validatorsArray.push(Validators.required);
        let listRegShouldContain = [];
        this.finalReg = '';
        // Min & Max length case condition
        if (this.pwdConfigRules.passwordMinLength || this.pwdConfigRules.passwordMaxLength) {
          validatorsArray.push(CustomValidators.patternValidator(this.userDet.getMinMaxRegex(configList.pageConst.passwordMinMaxRegex,"1"),
          { pswdMinMaxError : true }));
        }
        // Upper case condition
        if (this.pwdConfigRules.passwordShouldContainUpperCase) {
          // validatorsArray.push(CustomValidators.patternValidator(this.userDet.getRegexValidate(configList.pageConst.passwordUpperCaseRegex, 
          //   this.pwdConfigRules.passwordUpperCaseLength),{ pswdUpperCaseError : true }));
            listRegShouldContain.push(this.userDet.getRegexValidate(configList.pageConst.passwordUpperCaseRegex, 
            this.pwdConfigRules.passwordUpperCaseLength));
        }
        // Lower case condition
        if (this.pwdConfigRules.passwordShouldContainLowerCase) {
          // validatorsArray.push(CustomValidators.patternValidator(this.userDet.getMinMaxRegex(configList.pageConst.passwordLowerCaseRegex, 
          //   this.pwdConfigRules.passwordLowerCaseLength),{ pswdLowerCaseError : true }));
            listRegShouldContain.push(this.userDet.getRegexValidate(configList.pageConst.passwordLowerCaseRegex, 
              this.pwdConfigRules.passwordLowerCaseLength)); 
        }
        // Number condition
        if (this.pwdConfigRules.passwordShouldContainNumber) {
          // validatorsArray.push(CustomValidators.patternValidator(this.userDet.getRegexValidate(configList.pageConst.passwordNumberRegex, 
          //   this.pwdConfigRules.passwordNumberLength),{ pswdNumberError : true }));
            listRegShouldContain.push(this.userDet.getRegexValidate(configList.pageConst.passwordNumberRegex, 
              this.pwdConfigRules.passwordNumberLength));
        }
        // Special character condition
        if (this.pwdConfigRules.passwordShouldContainSpecialChar) {
          // validatorsArray.push(CustomValidators.patternValidator(this.userDet.getRegexValidate(configList.pageConst.passwordSpecialCharRegex, 
          //   this.pwdConfigRules.passwordSpecialCharLength),{ pswdSpecialCharError : true }));
            listRegShouldContain.push(this.userDet.getRegexValidate(configList.pageConst.passwordSpecialCharRegex, 
              this.pwdConfigRules.passwordSpecialCharLength));
        }
      
        //Code to form final regex
        if(listRegShouldContain) {
          let lengthOfListRegArr  = listRegShouldContain.length;
          let finalRegexforContains: RegExp ;
          if( lengthOfListRegArr <= this.pwdConfigRules.passwordContainCategories) {
            this.finalReg = '(';
            this.finalReg += listRegShouldContain.join("");
            this.finalReg += ').*';
            finalRegexforContains = new RegExp(this.finalReg);
          } else{
            let storeRegArray = [];
            this.finalReg = '(';
            this.combinationUtil(listRegShouldContain,lengthOfListRegArr,this.pwdConfigRules.passwordContainCategories, 0, storeRegArray, 0);
            console.log("start--"+this.finalReg);
            this.finalReg = this.finalReg.slice(0,-1);
            this.finalReg += ').*';
            console.log("end--"+this.finalReg);
            finalRegexforContains = new RegExp(this.finalReg);
            console.log(finalRegexforContains);
          }
          validatorsArray.push(CustomValidators.patternValidator(finalRegexforContains,{ pswdMustContainError : true }));
        } 
        // Consecutive characters condition
        if (this.pwdConfigRules.consecutiveCharNotAllowed) {          
          validatorsArray.push(CustomValidators.containPatternValator(configList.pageConst.passwordConsecutiveRegex,{ pswdContainsError : true }));
        }
        // Capital, Number & Special character condition
        if (this.pwdConfigRules.capNumberSplCharNotAllowed) {          
          validatorsArray.push(CustomValidators.containPatternValator(configList.pageConst.passwordCapNoSpl,{ pswdContainsError : true }));
        }
        // Specail character, Number & Capital word condition
        if (this.pwdConfigRules.splCharNumberCapNotAllowed) {          
          validatorsArray.push(CustomValidators.containPatternValator(configList.pageConst.passwordSplNoCap,{ pswdContainsError : true }));
        }
        // User Id & Telephone number condition
        if (this.pwdConfigRules.userIdNotAllowed || this.pwdConfigRules.phoneNumberNotAllowed) {          
          validatorsArray.push(this.checkUserDetails.bind(this));
        }
        // Special character not contain in password
        validatorsArray.push(CustomValidators.containPatternValator(configList.pageConst.passwordSplNoAccept,{ pswdMustContainError : true }));
        // No spaces condition         
        validatorsArray.push(CustomValidators.containPatternValator(configList.pageConst.passwordNoSpaces,{ pswdNoSpaceError : true }));
        
        this.passwordForm.get('newPassword').setValidators(validatorsArray);     
        this.passwordForm.get('newPassword').updateValueAndValidity();
      },
      error => {
        this.logger.debug('Error while getting filters');
      }
    );
  }
  /** Based on json parameters forming the password contains error message */
  pswdContainError() {
    let containErrorMsg: string = '';    
    if (this.pwdConfigRules) {
      containErrorMsg = "&nbsp;"+ configList.errorConst.newPasswordContainError;
      containErrorMsg +="<ul class='mb-0'>";
      if (this.pwdConfigRules.userIdNotAllowed) {
        containErrorMsg += "<li>"+configList.errorConst.newPasswordUsernameError + "</li>";
      }
      if (this.pwdConfigRules.phoneNumberNotAllowed) {
        containErrorMsg +=  "<li>"+ configList.errorConst.newPasswordPhoneError + "</li>";
      }
      if (this.pwdConfigRules.consecutiveCharNotAllowed) {
        containErrorMsg += "<li>"+configList.errorConst.newPasswordConseRegex + "</li>";
      }
      if (this.pwdConfigRules.capNumberSplCharNotAllowed) {
        containErrorMsg += "<li>"+configList.errorConst.newPasswordCapNumSplReg + "</li>";
      }
      if (this.pwdConfigRules.splCharNumberCapNotAllowed) {
        containErrorMsg += "<li>"+configList.errorConst.newPasswordSplNumCapReg + "</li>";
      }
      containErrorMsg +="</ul>";
      if (!(this.pwdConfigRules.userIdNotAllowed || this.pwdConfigRules.phoneNumberNotAllowed || 
        this.pwdConfigRules.consecutiveCharNotAllowed || this.pwdConfigRules.capNumberSplCharNotAllowed ||
        this.pwdConfigRules.splCharNumberCapNotAllowed)) {
        containErrorMsg = null;
      }
    }    
    return containErrorMsg;
  }
   /** Based on json parameters forming the password must contains error message */
   pswdMustContainError() {
    let containErrorMsg: string = '';    
    if (this.pwdConfigRules) {
      containErrorMsg = "&nbsp;"+ this.userDet.pswdMustContainErrorMsg();
      containErrorMsg +="<ul class='mb-0'>";
      if (this.pwdConfigRules.passwordShouldContainUpperCase) {
        containErrorMsg += "<li>"+ this.userDet.pswdUpperCaseErrorMessage() + "</li>";
      }
      if (this.pwdConfigRules.passwordShouldContainLowerCase) {
        containErrorMsg +=  "<li>"+ this.userDet.pswdLowerCaseErrorMessage() + "</li>";
      }
      if (this.pwdConfigRules.passwordShouldContainNumber) {
        containErrorMsg += "<li>"+ this.userDet.pswdNumberErrorMessage() + "</li>";
      }
      if (this.pwdConfigRules.passwordShouldContainSpecialChar) {
        containErrorMsg += "<li>"+ this.userDet.pswdSpecialCharErrorMessage() + "</li>";
      }
      containErrorMsg +="</ul>";
      if (!(this.pwdConfigRules.passwordShouldContainUpperCase || this.pwdConfigRules.passwordShouldContainLowerCase || 
        this.pwdConfigRules.passwordShouldContainNumber || this.pwdConfigRules.passwordShouldContainSpecialChar)) {
        containErrorMsg = null;
      }
    }    
    return containErrorMsg;
  }
  /** Check for username & phone number */
  checkUserDetails(control: FormControl) {    
    if (this.userData !== null && this.userData !== undefined && control.value) {
      if (control.value !== undefined && (control.value.includes(this.userData.contactNum))||
      (control.value.includes(this.userData.userLoginId))) {
        return { pswdContainsError : true };
      }
      return null;
    }
  }
  /** Form final regex for password must contain values*/
  combinationUtil(arr, n, r, index, data, i) 
  { 
    if (index == r) 
    { 
    for (let j = 0; j < r; j++){
      this.finalReg += data[j];
    }
    this.finalReg += "|";      
      return;
    }
    if (i >= n) 
    return; 
    data[index] = arr[i]; 
    this.combinationUtil(arr, n, r, index + 1, data, i + 1); 
    this.combinationUtil(arr, n, r, index, data, i + 1); 
  }
}
