import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from '../_services/http.service';
import { NGXLogger } from 'ngx-logger';
import { Router, ActivatedRoute } from '@angular/router';
import * as configList from '../../assets/config/config';
import { CONFIGUREPORTALREQUEST } from '../_models/ConfigurePortal/configureportalrequest';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { SECURITYQS } from '../_models/SECURITYQS';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-validate-security-question',
  templateUrl: './validate-security-question.component.html',
  styleUrls: ['./validate-security-question.component.scss']
})
export class ValidateSecurityQuestionComponent implements OnInit {
  @ViewChild('qsModal') qsModal: any; // needed for ADA
  @ViewChild('qsMsg') qsMsg: any; // needed for ADA
  public security: SECURITYQS;
  securityQSitems: any[];
  public ansSpace = false;
  public questionLength;
  public errorMsg: string;
  public showAlertQS = false;
  public answerEmpty = false;
  public disableSave = false;
  duplicateFlag = false;
  duplicateErrorMsg = '';
  securityQsForm: FormGroup;
  disableQuestions = false;
  public tooltipconstdata = configList.tooltipConst;
  secQuesUser = [];
  public noOfQuestions;
  public configurePortalRequest: CONFIGUREPORTALREQUEST;

  constructor(private httpService: HttpService, private logger: NGXLogger,
    private router: Router, private formBuilder: FormBuilder, private userDet: UserService, private route: ActivatedRoute) {
      // Method to check if the user is authorised to view this page
      this.userDet.authorizeUser();
      this.getQuestions();
     }

  ngOnInit() {
    this.security = new SECURITYQS();
    this.getUserQuestionsOnly();
    this.securityQsForm = this.formBuilder.group({
      securityQuestions: this.formBuilder.array([]),
    },
    { validator: this.checkDuplicateQs }
    );
  }

  onChanges() {
    for (let i = 0; i < 3; i++) {
      if (this.securityQsForm.get('securityQuestions').get(String(i)) !== null) {
        this.securityQsForm.get('securityQuestions').get(String(i)).get('answer_' + i).valueChanges.subscribe(val => {
        this.securityQsForm.get('securityQuestions').get(String(i)).get('answer_' + i).markAsTouched();
        });
      }
    }
  }
  /* This method returns the appropriate error message if same question has been selected in two different
  dropdowns in the edit security question form*/
  getDuplicateError() {
    return this.securityQsForm.hasError('duplicateError') ? configList.errorConst.DuplicateMessage :
    this.securityQsForm.hasError('invalidAnswerError') ? 'Invalid Answers' : '';
  }

  /* This method returns the appropriate error message for answer field in the edit security question form*/
  getSecurityQuestionError(i) {
    return this.securityQsForm.get('securityQuestions').get(String(i)).get('question_' + i).hasError('quesError') ?
    'Field must be selected' : '';
}

  /* This method returns the appropriate error message for answer field in the edit security question form*/
  getSecurityAnswerError(i) {
      return this.securityQsForm.get('securityQuestions').get(String(i)).get('answer_' + i).hasError('required') ?
      configList.errorConst.fieldError :
      this.securityQsForm.get('securityQuestions').get(String(i)).get('answer_' + i).hasError('spaceError') ?
      configList.errorConst.onlySpaces : '';
  }

  /** This method is used to define the fields that would be present in the FormArray */
  getSecurityQSFields(i) {
    const ques = 'question_' + i;
    const ans = 'answer_' + i;
    return this.formBuilder.group({
      [ques]: [{value: ''}, [Validators.required, this.checkQuestion]],
      [ans]: [{value: ''}, [Validators.required, this.checkSpaces]]
    });
  }

  /* This method is used to check if a field contains only spaces */
  checkSpaces(control: FormControl) {
    let condition = false;
    if (control.value !== null && (control.value).toString().trim() === '' && (control.value).toString().length > 0) {
      condition = true;
    }
    return condition ? { spaceError: true } : null;
  }
  /* This method is used to check if a field contains only spaces */
  checkQuestion(control: FormControl) {
    let condition = false;
    if (control.value === 0) {
      condition = true;
    }
    return condition ? { quesError: true } : null;
  }

  /* This method is used to check if an object is empty */
  isEmptyObj(obj: any) {
    return (obj && (Object.keys(obj).length === 0));
  }

  /** This method is called to submit the security questions/ answers entered by the user
   * and check if the y match with the previously configured questions/ answers */
  submitSecurityQuestions() {
    // let counter = 0;
    const modifySecQues = Object();
    modifySecQues.userLoginId = this.userDet.getScreenName();
    modifySecQues.usersSeqQue = [];
    const list = [];
    for (let i = 0; i < this.noOfQuestions ; i++) {
      const detailList = Object();
      // const newValue = Object();
      detailList.questionId = Number(this.securityQsForm.get('securityQuestions').get(String(i)).get('question_' + i).value);
      detailList.answer = this.securityQsForm.get('securityQuestions').get(String(i)).get('answer_' + i).value;
      // detailList.newValue = newValue;
      list.push(detailList);
    }
    modifySecQues.usersSeqQue = list;
    console.log(modifySecQues);
    let finalData = Object();
    finalData.messageContent = this.userDet.encryptData(modifySecQues);

    // API call for saving sec qs
    this.httpService.validateUserSecurityQuestions(finalData).subscribe(data => {
       data = this.userDet.decryptData(data.messageContent);
       if (data.responseCode == 1) {
        this.errorMsg = configList.errorConst.invalidSecurityQuestions;
        this.showAlertQS = true;
        if (this.qsModal !== undefined) {
          this.qsModal.nativeElement.style.display = 'block';
        }
        if (this.qsMsg !== undefined) {
          this.qsMsg.nativeElement.focus();
        }
      } else if (data.responseCode == -1) {
        this.errorMsg = configList.errorConst.SystemError;
        this.showAlertQS = true;
        if (this.qsModal !== undefined) {
          this.qsModal.nativeElement.style.display = 'block';
        }
        if (this.qsMsg !== undefined) {
          this.qsMsg.nativeElement.focus();
        }
      } else {
        this.router.navigate(['/resetPassword']);
      }
    },
    error => {
      console.log(error);
      this.errorMsg = configList.errorConst.SystemError;
      this.showAlertQS = true;
      if (this.qsModal !== undefined) {
        this.qsModal.nativeElement.style.display = 'block';
      }
      if (this.qsMsg !== undefined) {
        this.qsMsg.nativeElement.focus();
      }
    }
    );
  }

  /** This method relinquishes all the changes made to the form and redirects to the public-facing page */
  cancelData(): void {
    this.securityQsForm.reset();
    for (let i = 0; i < this.questionLength ; i++) {
      this.securityQsForm.get('securityQuestions').get(String(i)).get('question_' + i).setValue(0);
    }
    this.reroutePublic();
  }

  /** This method is used to re-route to public-facing page */
  reroutePublic() {
    if (this.errorMsg === configList.errorConst.invalidSecurityQuestions) {
      this.showAlertQS = false;
        if (this.qsModal !== undefined) {
          this.qsModal.nativeElement.style.display = 'none';
        }
    } else {
      sessionStorage.setItem('loginRoleId', '-1');
      sessionStorage.setItem('userRoleId', '-1');
      sessionStorage.setItem('privilegeData', null);
      sessionStorage.setItem('loginScreenId', '1');
      sessionStorage.setItem('logOut', 'Y');
      sessionStorage.setItem('isLoggedIn', 'false');
      sessionStorage.setItem('loggedInUserName', null);
      this.router.navigate(['/publicFacing']);
    }
  }

  /** This method is used to check if the same question has been selected in two or more dropdowns */
  checkDuplicateQs(form: FormGroup) {
    const counter = +(document.getElementById('quesNumber') as HTMLInputElement).value;
    let condition = false;
    for ( let i = 0 ; i < counter; i++) {
      if (form.get('securityQuestions').get(String(i)) !== null) {
        const currentVal = form.get('securityQuestions').get(String(i)).get('question_' + i);
        for ( let j = 0 ; j < counter; j++) {
          if (form.get('securityQuestions').get(String(j)) !== null) {
            const selectedVal = form.get('securityQuestions').get(String(j)).get('question_' + j);
            if ( i !== j && currentVal.value !== 0
                && selectedVal.value !== 0 && (selectedVal.value === currentVal.value)) {
              condition = true;
              break;
            }
          }
        }
      }
    }
    return condition ? { duplicateError: true } : null;
  }

  /** This method is used to get all security questions configured in User Management */
  getQuestions() {
    this.httpService.getSecurityQuestion().subscribe(data => {
      this.questionLength = data.noOfQue;
      if (data.usersSecQue.length < this.questionLength) {
        this.questionLength = data.usersSecQue.length;
      }
      this.securityQSitems = data.usersSecQue;
      console.log(this.securityQSitems);
      (document.getElementById('quesNumber') as HTMLInputElement).value = this.questionLength;

    for (let i = 0; i < this.questionLength ; i++) {
      const arrayControl = <FormArray>this.securityQsForm.controls['securityQuestions'];
      arrayControl.push(this.getSecurityQSFields(i));
      this.securityQsForm.get('securityQuestions').get(String(i)).get('question_' + i).setValue(0);
      this.securityQsForm.get('securityQuestions').get(String(i)).get('answer_' + i).setValue('');
      this.onChanges();
    }
    },
    error => {
      this.logger.debug('Error while fetching Security Questions');
    }
    );
  }

  /** This method is used to get the security questions answered by the user */
  getUserQuestionsOnly() {
   	let paramData = Object();
    paramData.userLoginId = this.userDet.getScreenName();
    let encryption = Object();
    encryption.messageContent =  this.userDet.encryptData(paramData);
    this.httpService.getUserSecurityQsOnlyByLoginId(encryption).subscribe(
      (data: any) => {
        data = this.userDet.decryptData(data.messageContent);
        this.secQuesUser = data.userSeqQueOnly;
        this.noOfQuestions = data.noOfQues;
        (document.getElementById('quesNumber') as HTMLInputElement).value = data.noOfQues;
        for (let i = 0; i < this.secQuesUser.length; i++) {
          this.securityQsForm.get('securityQuestions').get(String(i)).get('question_' + i).setValue(this.secQuesUser[i].questionId);
          this.disableQuestions = true;
        }
      },
      (error) => {
        console.error('error in fetching security question');
      }
    );
  }
  /** To get the form element data using form group dynamically */
  get formData() {
    return <FormArray>this.securityQsForm.get('securityQuestions');
  }

  getToolTipData(quesRow) {
    let msg = '';
    this.securityQSitems.forEach(qsItem => {
      if (qsItem.questionId ===
        this.securityQsForm.get('securityQuestions').get(String(quesRow)).get('question_' + quesRow).value) {
          msg = qsItem.question;
        }
    });
    return msg;
  }
}
