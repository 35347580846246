/** To Work In Ng Serve locally dev in VS Code perform changes : Add  ../../ before each json variable */

/** To build and deploy in server EAR perform changes : replace  from below : = '../../ with: = ' */
const localJsonFolder = '';
const ipaddress = '';

// const localJsonFolder = '../../';
// const ipaddress = 'http://localhost:8080/pbmportal/';
//const ipaddress = 'https://mdtestext.pharmacy.services.conduent.com/webportal/';

export const keycloakjson = localJsonFolder + 'assets/config/keycloak.json';
export const pwdconfigjson = localJsonFolder + 'assets/config/pwdConfig.json';
export const newsjson = localJsonFolder + 'assets/config/News_Alerts.json';
export const prgmjson = localJsonFolder + 'assets/config/ProgInfo.json';
export const contactjson = localJsonFolder + 'assets/config/Program_Contacts.json';
export const faqjson = localJsonFolder + 'assets/config/FAQ.json';
export const userData = localJsonFolder + 'assets/config/userData.json';
export const loginData = localJsonFolder + 'assets/config/login.json';
export const zipdetails = localJsonFolder + 'assets/config/zip_code.json';

export const profileDetails = localJsonFolder + 'assets/config/userProfile.json';
export const secQues = localJsonFolder + 'assets/config/securityQuestions.json';
export const messageslist = localJsonFolder + 'assets/config/Messageconfig.json';
export const generateTmpEmail = localJsonFolder + 'assets/config/generateTmpEmail.json';
export const updateStatusData = localJsonFolder + 'assets/config/updateStatus.json';
export const securityques = localJsonFolder + 'assets/config/Viewsecurityques.json';
export const rolesDisplay = localJsonFolder + 'assets/config/RoleList.json';
export const accrediationDisplay = localJsonFolder + 'assets/config/accrediationList.json';
export const Display = localJsonFolder + 'assets/config/Statuslist.json';
export const passConfig = localJsonFolder + 'assets/config/passwordConfig.json';
export const systemConfig = localJsonFolder + 'assets/config/systemConfiguration.json';
export const savePassConfig = localJsonFolder + 'assets/config/savePassResponse.json';
export const saveSystemConfig = localJsonFolder + 'assets/config/saveSystemConfig.json';
export const myProfData = localJsonFolder + 'assets/config/myData.json';
export const saveMyData = localJsonFolder + 'assets/config/saveMyDataRes.json';
export const filterDropDownValues = localJsonFolder + 'assets/config/filterValues.json';
export const roleDropDownValues = localJsonFolder + 'assets/config/roleValues.json';
export const userIdDropDownValues = localJsonFolder + 'assets/config/userIdValues.json';
export const auditRoleActivityData = localJsonFolder + 'assets/config/roleActivityData.json';
export const auditUserIdActivityData = localJsonFolder + 'assets/config/userIdActivityData.json';
export const summaryStatisticsData = localJsonFolder + 'assets/config/summaryStatisticsData.json';
export const detailedReportData = localJsonFolder + 'assets/config/detailedReportData.json';
export const appUrl = localJsonFolder + 'assets/config/appLinks.json';

/** Images default local url's */
export const defaultImages = {
    headerBgImage: localJsonFolder + 'assets/images/background-img.png',
    headerClientLogo: localJsonFolder + 'assets/images/WA-DOH-LOGO.png',
    headerClientImage: localJsonFolder + 'assets/images/Maryland_Flag.png',
    // prgmInfoImg1: localJsonFolder + 'assets/images/program1.jpg',
    // prgmInfoImg2: localJsonFolder + 'assets/images/program2.jpg',
    // prgmInfoImg3: localJsonFolder + 'assets/images/program3.jpg',
    // prgmContactImg1: localJsonFolder + 'assets/images/contact1.jpg',
    // prgmContactImg2: localJsonFolder + 'assets/images/contact2.jpg',
    // prgmContactImg3: localJsonFolder + 'assets/images/contact3.jpg',
    // newsAlertsImg1: localJsonFolder + 'assets/images/news1.jpg',
    // newsAlertsImg2: localJsonFolder + 'assets/images/news2.jpg',
    // newsAlertsImg3: localJsonFolder + 'assets/images/news3.jpg',
};

/** To Work In Ng Serve locally uncomment below line */
/** To build and deploy in server EAR comment below line */

    export const loginDetails = ipaddress + 'login/verifyUserLogin';
/** To Work In Ng Serve locally dev in VS Code perform changes : Add  '/pbmportal/ before each url variable */

/** To build and deploy in server EAR perform changes : replace  from below : = '/pbmportal/ with: = ' */
/** Fetch Authorization token using JWT */
    export const fetchAuthTokenUrl = ipaddress + 'fetchAuthToken';
/** Common URL for Category & Screen Details */
    /* Category Details URL */
    export const adminCategoryDetailsUrl = ipaddress + 'Admin/HomePage/getCategoryDetails';
    export const updateCategoryUrl = ipaddress + 'Admin/modifyCategory';
    /* Screen Details URL */
    export const screenInfoUrl = ipaddress + 'HomePage/getScreenInformation';
    export const updateScreenUrl = ipaddress + 'Admin/modifyScreenInformation';
    /* Ordering of Category and Items */
    export const sortItemsUrl = ipaddress + 'Admin/sortInformation';
 /* Common Admin URL for all portlet changes */
    export const adminConfigureUrl = ipaddress + 'Administrator/configurePortal';
    /** Audit Reports URL */
    export const auditReportsUrl = ipaddress + 'Administrator/generateReports';

    /** Audit Users List URL */
    export const auditUsersListUrl = ipaddress + 'Audit/Reports/getDistinctUserIds';

/** Public-Facing URL */
    /* Program Inforamtion URL */
    export const prgmUrl = ipaddress + 'HomePage/getHomePageProgInfo';
    /* Program Contact URL */
    export const contactUrl = ipaddress + 'HomePage/getHomePageProgContacts';
    /* News & Alerts URL */
    export const newsUrl = ipaddress + 'HomePage/getHomePageNewsAlerts';
    /* FAQ URL */
    export const faqUrl = ipaddress + 'HomePage/getHomePageFAQ';
    /* Welcome Message URL */
    export const welcomemsgUrl = ipaddress + 'HomePage/getHomePageWelcomeMsg';
    /*Prior Authorization */
    export const priorAuthUrl =  ipaddress + 'HomePage/getHomePriorAuth';
    /* Application Links URL */
    export const appLinkUrl = ipaddress + 'HomePage/getApplicationDetailsByUserId';
    /* Disclaimer URL */
    export const disclaimerUrl = ipaddress + 'HomePage/getHomePageSecurityDisclaimer';
    /* Footer URL */
    export const footerUrl = ipaddress + 'HomePage/getFooter';
    /* getPDF URL */
    export const pdfURL = ipaddress + 'HomePage/convertHtmlToPdf';
    /* Sign-In Announcement URL */
    export const signAnnouncementUrl = ipaddress + 'HomePage/getHomePageSignOnAnnouncement';
    /* Usehr Help URL */
    export const userHelpUrl = ipaddress + 'HomePage/getHomePageUserHelp';
	/*Accreditation details */
    export const accreditationUrl = ipaddress + 'Public/getUserAccreditationDetails';

/** Admin Program Information Details URL */
    /* program info get URL */
    export const adminPrgmInfoUrl = ipaddress + 'Admin/HomePage/getAdminProgInfo';
    /* program info post URL */
    export const updateProgInfoUrl = ipaddress + 'Admin/modifyProgInfo';

/** Admin Program Contacts Details URL */
    /* program contact get URL */
    export const adminPrgmContactUrl = ipaddress + 'Admin/HomePage/getAdminProgContacts';
    /* program contact post URL */
    export const updatePrgmContactUrl = ipaddress + 'Admin/modifyProgContacts';

/** Admin  FAQ URL */
    /* faq get URL */
    export const adminFaqUrl = ipaddress + 'Admin/HomePage/getAdminFAQ';
    /* faq post URL */
    export const updateFaqUrl = ipaddress + 'Admin/modifyFAQ';

/** Admin  Welcome Message URL */
    /* welcome message get URL */
    export const adminWelcomeMsgUrl = ipaddress + 'Admin/HomePage/getAdminWelcomeMsgDetails';
    /* welcome message post URL */
    export const updateWelcomeMsgUrl = ipaddress + 'Admin/modifyWlcMsg';

/** Admin  News & Alerts URL */
    /* news & alerts get URL */
    export const adminNewsAlertsUrl = ipaddress + 'Admin/HomePage/getAdminNewsAlerts';
    /* news & alerts post URL */
    export const updateNewsAlertsUrl = ipaddress + 'Admin/modifyNewsAlerts';
    
/* Prior Auth post URL */
export const adminPriorAuthInfoUrl = ipaddress + 'Admin/HomePage/getAdminPriorAuth';

/** Admin  Maintain Header Footer URL */
    /* maintain Header post URL */
    export const updateHeaderUrl = ipaddress + 'Admin/modifyHeader';
    /* maintain Footer get URL */
    export const adminFetchFooterUrl = ipaddress + 'Admin/HomePage/getAdminFooter';
    /* maintain Footer post URL */
    export const updateFooterUrl = ipaddress + 'Admin/modifyFooter';

/** HomePage API URL */
    export const homePageUrl = ipaddress + 'HomePage/';
/** Admin API URL */
    export const adminUrl = ipaddress + 'Admin/uploadHeaderImages';
/** Admin Category Image Upload API URL */
    export const catImgUploadUrl = ipaddress + 'Admin/Upload/uploadCategoryImage';
/** Admin Application Image Upload API URL */
export const appImgUploadUrl = ipaddress + 'Admin/Application/uploadApplicationImage';
/** Application Image Fetch API URL */
    export const appImageUrl = ipaddress + 'Application/getApplicationImage';

/** Admin  User Help URL */
    /* User Help get URL */
    export const adminUserHelpUrl = ipaddress + 'Admin/HomePage/getAdminUserHelp';
    /* User Help post URL */
    export const updateUserHelpUrl = ipaddress + 'Admin/modifyUserHelp';

/** Admin  Signon Announcement URL */
    /* welcome message get URL */
    export const adminSignonUrl = ipaddress + 'Admin/HomePage/getAdminSignOnAnnouncement';
    /* welcome message post URL */
    export const updateSignonUrl = ipaddress + 'Admin/modifySignOnAnnouncement';
    /* maintain SignOnAnnouncement Screen Info post URL */
    export const updateSignOnScreenInfoUrl = ipaddress + 'Admin/modifySignOnScreenInfo';
    /* Update USER Accpetance Tracking */
    export const userAccTrackUrl = ipaddress + 'User/Activity/userActivityTrack';
	
	export const loginAccTrackUrl = ipaddress + 'Public/userLogin';
    /* Secure Update USER Accpetance Tracking */
    export const secureUserAccTrackUrl = ipaddress + 'Secure/activityMessage';
    
    export const secureAccTrackUrl = ipaddress + 'Public/activityMessage';

/** User Management API calls */
    /* User Configuration GET URL */
    export const userConfigUrl = ipaddress + 'Admin/User/getAdminUserDetails';
    /* User Configuration GET all status URL */
    export const statusListUrl = ipaddress + 'Admin/User/getUserStatusDetails';
    /* User Configuration GET all Accreditation URL */
    export const accrediationListUrl = ipaddress + 'Admin/User/getUserAccreditationDetails';
    /* User Configuration GET all Roles URL */
    export const rolesListUrl = ipaddress + 'Admin/User/getAllRoles';
    /* User Configuration GET all Roles whose password configuration details can be configured URL */
    export const pwdConfigRoles = ipaddress + 'Admin/Account/getParentRoleDetails';
    /* User Configuration GET all Prefix URL */
    export const prefixListUrl = ipaddress + 'Admin/getTitleDetails';
    /* User Configuration GET URL for Security questions  */
    export const securityquesUrl = ipaddress + 'User/getSecurityQuestionsByUser';
    /* Security Question GET URL */
    export const securityQsUrl = ipaddress + 'Admin/User/getAdminSecurityQuestions';
    /* Validate Security Question and Answer POST URL */
    export const validateSecurityquesUrl = ipaddress + 'User/validateUserSecurityQuestion';

    /* My Security Questions GET URL */
    export const getUserSecQuesOnlyUrl = ipaddress + 'User/getSecurityQuestionsOnlyByUser';

    /* Reactivate user data GET URL */
    export const reactivateUserUrl = ipaddress + 'Admin/User/getInactiveUser';
/* User Applications GET URL */
export const applicationsUrl = ipaddress + 'Admin/User/getUserAppMgmtDetails';

export const allApplicationsPrivilegesUrl = ipaddress +'Admin/User/getAllAppPrivilegesDetails';

/** Reset Password  API calls */
    /* Security Questions GET URL*/
    export const securityQuestionUrl = ipaddress + 'User/getUserSecurityQuestions';
    /* Security Questions POST URL*/
    export const updatesecurityQuestionUrl = ipaddress + 'User/Config/modifyUserSecurityQuestions';

/** Account Management API calls */
    /* Message Configuration GET URL */
    export const generateMessagesListUrl = ipaddress + 'Admin/Account/getAllMessageDetails';
    /* Email Configuration GET URL */
    export const generateEmailtemplateUrl = ipaddress + 'Admin/Account/getAllMailTemplates';
    /* Password Configuration GET URL */
    export const passConfigUrl = ipaddress + 'Admin/Account/getPwdConfigDetailsByRole';
    /* System Configuration GET URL */
    export const systemConfigUrl = ipaddress + 'Admin/Account/getAdminSystemConfig';
    /* Roles Dropdown GET URL in Password Configuration */
    export const rolesDisplayUrl = ipaddress + 'Admin/Account/getAllRolesDetails';
    /* Account Management Details Get URL for timer */
    export const accountMgmtDetailsUrl = ipaddress + 'User/getAccountMgmtDetails';
        // getAccountManagementDetails
/** User Profile API calls */
    /* My Profile GET URL */
    export const getUserProfileDataUrl = ipaddress + 'User/getUserDetails';
    /* My Security Questions GET URL */
    export const getUserSecQuesUrl = ipaddress + 'User/getSecurityQuestionsByUser';

    export const updateUserAccessedActivity = ipaddress + 'Audit/User/logUserActivity';

/** Role Management API calls */
    /* Historical table GET URL */
    export const roleDataUrl = ipaddress + 'SuperAdmin/getAdminRoles';
    /* Edit Role table GET URL */
    export const rolePrivilegesUrl = ipaddress + 'SuperAdmin/getPrivilegesByRole';
/* Application Management GET URL */
export const applicationManagementUrl = ipaddress + 'Application/getAppMgmtDetails';

/* Constants for Tiles */
export const pageConst = {
    constYes: 'Y',
    constNo: 'N',
    active: 'A',
    tileProgramInfo: 1,
    tileProgramInfoName: 'Program Information',
    tileProgramContacts: 2,
    tileProgramContactsName: 'Program Contacts',
    tileNewsAndAlerts: 3,
    tileNewsAndAlertsName: 'News and Alerts',
    tileFAQ: 4,
    tileFAQName: 'FAQ',
    tileUserHelp: 5,
    tileUserHelpName: 'User Help',
    tileWelcomeMessage: 6,
    tileWelcomeMessageName: 'Welcome Message',
    tilePriorAuth: 7,
    tilePriorAuthName: 'Prior Authorization',
    tileApplicationLinks: 8,
    tileApplicationLinksName: 'Application Links',
    pagePublicFacing: 1,
    pageClient: 2,
    pageProvider: 3,
    pageUserManagement: 4,
    pageConduentInternal: 5,
    categoryName: 'Category content',
    categoryList: 'Categories page',
    categoryDetails: 'Category Detail page',
    InformationName: 'Information content',
    AppLinksName: 'Application link',
    QAMName: 'Quick access menu',
    deleteCat: 1,
    deleteItem: 2,
    NAPortletId: -1,
    NoMsgsConfigured : 'Information items will be updated soon..',
    //userNameRegex: /^(?!\_)([a-z0-9_]{10,15})*$(?<!\_)/,
    // tslint:disable-next-line:max-line-length
    userNameRegex: /^([^A-Z\s|_!@#\$%\^&*\(\)\-\+\=\{\}\[\]\\\:;\"\'\?\/\.\<\>\,\~\`][a-z0-9_]{8,13}[^A-Z\s|_!@#\$%\^&*\(\)\-\+\=\{\}\[\]\\\:;\"\'\?\/\.\<\>\,\~\`])$/,
    onlySpacesRegex: /^\s+$/,
    // tslint:disable-next-line:max-line-length
    passwordRegex: '^((?=.*[\\d])(?=.*[a-z])(?=.*[A-Z])|(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\\$%\\^&\\*\\._])|(?=.*[\\d])(?=.*[A-Z])(?=.*[!@#\\$%\\^&\\*\\._])|(?=.*[\\d])(?=.*[a-z])(?=.*[!@#\\$%\\^&\\*\\._])).{<pwdLegnth>,20}$',
    passwordConsecutiveRegex: /(.)\1\1/,
    passwordCapNoSpl: /([A-Z]{1})+([a-z])+([\d])+([!@#\$%\^&\*\._])/,
    passwordSplNoCap: /([!@#\$%\^&\*\._]{1,})+([\d])+([A-Z]{1})+([a-z])/,
    passwordNoSpaces: /\s/,
    passwordUpperCaseRegex: '(?=(?:.*?[A-Z]){<charLength>})',
    passwordLowerCaseRegex: '(?=(?:.*?[a-z]){<charLength>})',
    passwordNumberRegex: '(?=(?:.*?[0-9]){<charLength>})',
    passwordSpecialCharRegex: '(?=(?:.*?[<splCharacters>]){<charLength>})',
    passwordMinMaxRegex: '\\s*(?:\\S\\s*){<min>,<max>}$',
    passwordSplNoAccept:/^(?=(?:.*?[\(\)\-\+\=\{\}\[\]\\\:;\"\'\?\/\<\>\,\~\`]){1}).*/,
    // tslint:disable-next-line:max-line-length
    emailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    phoneRegex: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
    faxRegex: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
    numberRegex: /[0-9]/,
    identificationRegex: /^[0-9]{10,13}$/,
    npiIdRegex: /^[0-9]{1,}$/,
    
    zip1Regex: /^[0-9]{5}$/,
    zip2Regex: /^[0-9]*$/,
    username: 'public',
    password: 'public1234',
    days: 'Days',
    weeks: 'Weeks',
    months: 'Months',
    Home: 'Home',
    rolePublicFacing: -1,
    roleClient: 1,
    roleProvider: 2,
    roleAdmin: 3,
    roleConduentInternal: 4,
    screenAdmin: 4,
    publicIP: 'NA',
    NotifyUserSignOn: '<<<Sign on Announcement has been modified after the last accept. Please read the same and accept again>>>',
    NotApplicable: 'NA',
    surveyMessageHeader: 'We Welcome Your Feedback',
    surveyMessageBody: 'Help us improve your experience by taking our short survey.',
	cryptoKey: 'cwpjsonsecretkey',
	authKey: 'WEBPA_JJAA00884445566757',
    token : "71d26c6adcf9c9cf"
};

/* Constants for activity codes */
export const activityCode = {
    signOnActivityCode: 'SCS101',
    addSignOn: 'AS101',
    modifySignOn: 'MS101',
    deleteSignOn: 'DS101',
    modifyUserAccTrack: 'SUA101',
    addItemCode: 'AI101',
    modifyItemCode: 'MI101',
    deleteItemCode: 'DI101',
    orderCatCode: 'OC101',
    orderItemCode: 'OI101',
    screenInfoWcMsgCode: 'SC101',
    modifyPasswordConfig: 'PM101',
    modifySystemConfig: 'ASM101',
    modifyMessageConfig: 'MM101',
    modifyMyData: 'MU102',
    modifyNewSecurityQuestion: 'USQ101',
    modifySecurityQuestion: 'USQ102',
    addCatCode: 'AC101',
    modifyCatCode: 'MC101',
    deleteCatCode: 'DC101',
    loginAuditActCode: 'LI101',
    logoutAuditActCode: 'LO101',
    sessionLogoutAuditActCode: 'SL101',
    modifyusermngmntconfig: 'MUS101',
    generateEmailConfig: 'GE101',
    footerEulaCode: 'FE101',
    footerContentCode: 'FC101',
    footerScreenCode: 'FL101',
    footerDataCode: 'FI101',
    headerDataCode: 'HC101',
    userActivityLog: 'UAL101',
    addUser: 'AU101',
    editUser: 'MU101',
    deleteUser: 'DU101',
    reactivateUser: 'RU101',
    addSecurityQuestion: 'ASQ101',
    loginUserCode: 'AUTH101',
    resetPassword: 'RP101',
    forgotPassword: 'FP101',
    changePassword: 'CP101',
    changeExpiredPassword: 'CP102',
    modifyEmailConfig: 'MTM101',
    addRole: 'AR101',
    modifyRole: 'MR101',
    deleteRole: 'DR101',
    RoleAuditReport: 'RAR101',
    userIdAuditReport: 'UAR101',
    activeUsersAuditReport: 'AUR101',
    lockedUsersAuditReport: 'LUR101',
    loginAcitivtyReport: 'LAR101',
    summaryStatisticsReport: 'SSR101',
    userPublicFacingAccess: 'SR101',
    maxFailedLogins: 'SR102',
    sessionsTerminated: 'SR103',
    userClientAccess: 'SR104',
    userProviderAccess: 'SR105',
    accessedPublicFacing: 'APF101',
    activateUser: 'ACT101',
    addAppPrivileges: 'AUP101',
    modifyAppPrivileges: 'MUP101',
    editApplication: 'MA101',
    changepasswordOtp: 'MU103'
};

export const userAccounts = {
    systemAdmin: 'Admin1',
    systemClient: 'Client1',
    systemProvider: 'Provider1',
    ADMIN: 'ADMIN'
};

export const sessionTimeoutConstants = {
    sessionTimeoutTime:60,
    sessionTimeoutInterval:60000,
    warningTimeoutTime:5,
    sessionTimeoutPopupMessage:'Your session is expired! Please relogin now through the portal.',
    warningTimeoutPopupMessage1:'Your session is about to expire in ',
    warningTimeoutPopupMessage2:' mins. Please save your work.',

};

export const tileName = {
    AccountManagement: 'Account Management',
    SystemConfiguration: 'System Configuration',
    PasswordConfiguration: 'Password Configuration',
    MessageConfiguration: 'Message Configuration',
    EmailConfiguration: 'Email Configuration',
    MaintainHeaderFooter: 'Maintain Header & Footer',
    Header: 'Header',
    Footer: 'Footer',
    SignOn: 'SignOn',
    UserManagement: 'User Management',
    UserConfiguration: 'User Configuration',
    SecurityQsConfiguration: 'SecurityQs Configuration',
    AuditLogsReports: 'Audit Logs & Reports',
    StatisticsReports: 'Statistical Report',
    UserActivityReports: 'User Activity Report'
};


export const errorConst = {
    CategoryName: 'Category Name must be entered',
    Information: 'Information must not be empty or should not exceed 4000 characters',
    StartDate: 'Please enter effective Start Date',
    EndDate: 'Please enter effective End Date',
    Category: 'Category must be selected',
    ContactName: 'Contact Name must be entered',
    EmailAddress: 'Email Address must be entered',
    EmailPattern: 'Email Address must follow the pattern "abc@xyz.com"',
    Messagetype: 'Message Type must be selected',
    Messagecontent: 'Message content must not be empty or should not exceed 2000 characters',
    WlcMessagecontent: 'Message content must not be empty or should not exceed 3000 characters',
    ClientName: 'Client Name must not be empty or should not exceed 200 characters',
    ClientLogo: 'Please enter Client Logo',
    ClientImage: 'Please enter Client Image',
    BgImage: 'Please enter Background Image',
    UniquePosition: 'Positions of the client name, logo and width must be unique. Please select appropriately',
    InvalidFileType: 'File type is invalid. Please upload a file of type .bmp, .jpg, .tiff or .png',
    InvalidFileSize: 'File size limit exceeded. Please upload a file less than 500 KB',
    ClientLogoInvalidFileDimension: 'Client logo width and height must not be greater than 65x55 pixels (Width x Height)',
    ClientImageInvalidFileDimension: 'Client image width and height must not be greater than 65x55 pixels (Width x Height)',
    InvalidBgFileType: 'File type is invalid. Please upload a file of type .bmp, .jpg, .gif or .png',
    InvalidBgFileDimension: 'Background image width must be greater than 800 pixels and less than or equal to 1335 pixels, height must be 64 pixels',
    CategoryDescription: 'Category description should not exceed 500 characters',
    CategoryImage: 'Please enter category image',
    InvalidCatImgFileType: 'File type is invalid. Please upload a file of type .bmp, .jpg, .gif or .png',
    InvalidCatImgFileDimension: 'Category Image width and height must be 100x100 pixels (Width x Height)',
    InvalidCatImgFileSize: 'File size limit exceeded. Please upload a file less than 50 KB',
    Success: 'Details have been saved successfully',
    SystemError: 'Unable to perform the operation, please try again. If the issue persist, please contact the support team',
    News: 'News must not be empty or should not exceed 4000 characters',
    Question: 'A question must be entered',
    Answer: 'Answer must not be empty or should not exceed 1500 characters',
    Date: 'Enter a valid date',
    StartPastDate: 'Effective Start Date must be lesser than Effective End Date',
    StartDateInvalidFormat: 'Effective Start Date must be in MM/DD/YYYY format',
    EndPastDate: 'Effective End Date must be greater than Effective Start Date',
    EndDateInvalidFormat: 'Effective End Date must be in MM/DD/YYYY format',
    ValidDate: 'Effective End date cannot be less than Effective Start Date',
    EulaURL: 'Please enter the URL for the End User License Agreement',
    EulaPattern: 'EULA URL must follow the pattern "http(s)://*.*"',
    EulaAcceptLabel: 'Please enter the label for the Accept/Agree button',
    EulaRejectLabel: 'Please enter the label for the Reject/Disagree button',
    EulaAcceptLabelMax: 'Accept/Agree Label can have a maximum length of 6 characters only',
    EulaRejectLabelMax: 'Reject/Disagree Label can have a maximum length of 6 characters only',
    SecurityDisclaimer: 'Please enter the verbiage for the security disclaimer',
    SecurityPolicy: 'Please enter the URL for the security policy',
    SecurityPolicyPattern: 'Security Policy URL must follow the pattern "http(s)://*.*"',
    FooterContactName: 'Please enter the contact name',
    Phone: 'Phone Number can have a maximum length of 10 numbers (0-9) only',
    Fax: 'Fax Number can have a maximum length of 10 numbers(0-9) only',
    Number: 'Fax Number can have a maximum length of 10 numbers (0-9) only',
    Length: 'Only numbers(0-9) allowed with maximum length of 10',
    EulaDetailsUpdate: 'EULA details have been updated successfully',
    SecurityDetailsUpdate: 'Security Disclaimer details have been updated successfully',
    ContactUsDetailsUpdate: 'Contact Us details have been updated successfully',
    FooterDetailsUpdate: 'Footer details have been updated successfully',
    portletTitle: 'Title must be entered',
    signOnOverlap: 'Please modify the dates as there is an overlapping with the existing sign on announcements',
    SignOnAdded: 'Sign on announcement has been added successfully',
    SignOnModified: 'Sign on announcement has been updated successfully',
    SignOnDeleted: 'Sign on announcement has been deleted successfully',
    onlySpaces: 'Cannot contain spaces only, please modify accordingly',
    clientNameMaxCharacter: 'Client Name must not be empty or should not exceed 200 characters',
    State: 'State must be entered',
    City: 'City must be entered',
    Zip: 'Zip Code must be entered',
    ZipPattern: 'Zip Code can have a maximum length of 5 numbers (0-9) only',
    Zip1Pattern: 'Zip Code + 4 can have a maximum length of 4 numbers (0-9) only',
    Address: 'Address Line 1 must be entered',
    RoleId: 'Role must be selected',
    MaId: 'MA ID must be entered, cannot have spaces and MA ID can have a maximum of 20 alphanumeric characters',
    validateError: 'NPI ID, MA ID does not match with the file. Please enter again to validate',
    Identification: 'Identification ID must be entered',
    IdentificationPattern: 'Identification ID must have a minimum length of 10 numbers and a maximum length of 13 numbers (0-9) only',
    NpiPattern: 'NPI Id must be numeric',
    Accreditation: 'Accreditation must be entered',
    maId:'MA Id must be entered',
    npiId:'NPI Id must be entered',
    docFirstName:'Prescriber\'s first name must be entered',
    docLastName:'Prescriber\'s last name must be entered',
    FirstName: 'First Name must be entered',
    LastName: 'Last Name must be entered',
    PhoneNumber: 'Phone Number must be entered',
    UserName: 'User Id must be entered',
    UserNamePattern: 'User Id must have minimum length of 10 and maximum length of 15 alphanumeric (a-z,0-9) characters and ' +
    'allowed only one special character (_) in between and ' +
    'cannot contain leading and trailing special characters.',
    securityQuestionEmpty: 'Please enter the security question',
    securityQuestionAddedSuccess:'Security question has been added successfully',
    newPasswordError: 'Password cannot be empty/did not match password policy ' +
        '(Password must contain characters from 3 of the following 4 categories:' +
        'at least one upper case letter,one lower case letter,' +
        'one number and one special character (!, @, #, $, %, ^, &, *, _, .), ' +
        'with minimum length of <pwdMinLength> and maximum length of 20 characters)',
    confirmPasswordError: 'Re-entered password did not match with the entered password',    
    newPasswordNoSpaceError: 'Password cannot contain spaces',
    newPasswordCapError: '<charLength> upper case letter(s)',
    newPasswordSmallError: '<charLength> lower case letter(s)',
    newPasswordNumberError: '<charLength> number(s)',
    newPasswordSplError: 'Allowed <charLength> special character(s)  <specialChar>',
    newPasswordContainError: 'Password must NOT contain',
    newPasswordMustContainError: 'Password must contain <charLength> out of the following:',
    newPasswordPhoneError: 'Telephone number',
    newPasswordUsernameError: 'Account username',
    newPasswordConseRegex: 'More than two identical characters in a row',
    newPasswordCapNumSplReg: 'Title case word followed by Number, followed by Special character',
    newPasswordSplNumCapReg: 'Special character followed by Number, followed by Title case word',
    newPasswordLengthError: 'Password must have minimum length of <min> and maximum length of <max> characters',
    AnswerResetPassword: 'Answer must be entered',
    // tslint:disable-next-line:max-line-length
    PasswordSuccess: 'Security Questions have been configured successfully. You will now be redirected to the public facing page. Please login with the new credentials',
    DuplicateMessage: 'Security Questions must not be the same. Please select a different question',
    EmailDuplicate: 'This Email Address is already taken. Please select a different Email Address',
    IdDuplicate: 'This Identification ID already exists. Please select a different Identification Id',
    PhoneDuplicate: 'This Phone Number already exists. Please select a different Phone Number',
    userNameDuplicate: 'This User Id is already taken. Please select a different User Id',
    DuplicateSecurityQs: 'Please enter a different security question as the question already exists',
    ZipNotFound: 'Please enter a valid Zip Code',
    FooterContactNameMaxLength: 'Contact name can have a maximum length of 50 characters only',
    SecuritypolicyURLMaxlength: 'Security Policy URL can have a maximum length of 2000 characters only',
    EulaURLMaxlength: 'End User License Agreement URL can have a maximum length of 255 characters only',
    Eulasecuritydisclaimermaxlength: 'Security Disclaimer can have a maximum length of 2000 characters only',
    FooterContactinfoMaxLength: 'Contact information can have a maximum length of 255 characters only',
    maximumEmailLength: 'The Email Id can have maximum length of 128 characters only',
    oldPasswordError: 'Your current password is incorrect',
    fieldError: 'Field must be entered',
    numericError: 'Must be numeric and greater than zero',
    dropdownError: 'Must be selected',
    sessionGreaterError: 'Session Expiration Reminder Time must be lesser than Session Expiration Time',
    maxAttemptsError: 'Maximum number of failed attempts must be greater than Number of failed attempts allowed',
    passResetGreaterError: 'Password reset reminder frequency must be lesser than Password reset frequency',
    roleError: 'Role must be selected',
    unchangedError: 'No changes made',
    prefixError: 'Prefix must be entered',
    itselfEditError: 'You cannot edit your own information',
    itselfDeleteError: 'You cannot delete your own record',
    oldPassSameError: 'New password must not be same as the current password',
    EmailtemplateError: 'Email template must not be empty or should not exceed 3000 characters',
    invalidUser: 'Invalid User Id',
    invalidSecurityQuestions: 'Your answers do not match the answers on file. ' +
                    'Please contact the helpdesk for help with security questions/answers',
    // tslint:disable-next-line:max-line-length
    generateEmail: 'An email with the temporary password has been sent to your registered email address. For further queries, please contact the helpdesk.',
    LastSignOnDontDeleteMsg: 'This item cannot be deleted as it is the only sign on announcement',
    LastSignOnDeleteMsg: 'Are you sure you want to delete the message?',
    NoSignOnEnable: 'At least one announcement must be added to enable the Sign On Announcement',
    SignOnMessage: 'Please enter the message content',
    FilterError: 'Filter must be selected',
    RoleFilterError: 'Role must be selected',
    UserIdFilterError: 'User Id must be selected',
    DateFilterError: 'Date must be selected',
    FromPastDate: 'From Date must be lesser than To Date',
    ToPastDate: 'To Date must be greater than From Date',
    reportsDiscliamer: '** Reports will be generated based on the Eastern Time Zone',
    cannotEdit: 'System configured accounts cannot be edited',
    cannotDelete: 'System configured accounts cannot be deleted',
    cannotEditRole: 'System configured role cannot be edited',
    cannotDeleteRole: 'System configured role cannot be deleted',
    wlcmmsgcreated: 'Welcome message has been added successfully',
    wlcmmsgupdated: 'Welcome message has been updated successfully',
    wlcmmsgdeleted: 'Welcome message has been deleted successfully',
    wlcmmsgdeleteprompt: 'Are you sure you want to delete the message?',
    wlcmmsgorder: 'Order of the welcome messages has been changed successfully',
    Faqcreated: 'FAQ item has been added successfully',
    Faqupdated: 'FAQ item has been updated successfully',
    Faqdeleted: 'FAQ item has been deleted successfully',
    Faqdeleteprompt: 'Are you sure you want to delete the FAQ item?',
    Faqorder: 'Order of the FAQ items has been changed successfully',
    faqLastDelete: 'This item cannot be deleted as it is the only FAQ item in the category',
    userhelpcreated: 'User help item has been added successfully',
    Userhelpupdated: 'User help item has been updated successfully',
    Userhelpdeleted: 'User help item has been deleted successfully',
    Userhelpdeleteprompt: 'Are you sure you want to delete the user help item?',
    Userhelporder: 'Order of the user help items has been changed successfully',
    userHelpLastDelete: 'This item cannot be deleted as it is the only user help item in the category',
    catCreated: 'Category has been added successfully',
    catUpdated: 'Category has been updated successfully',
    catDeletePrompt: 'Are you sure you want to delete the category?',
    catDeleted: 'Category has been deleted successfully',
    catOrder: 'Order of the categories has been changed successfully',
    catJsonError: 'Error in ordering category JSON',
    catSameName: 'Category with the same name already exists',
    newsCreated: 'News item has been added successfully',
    newsUpdated: 'News item has been updated successfully',
    newsDeleted: 'News item has been deleted successfully',
    newsDeletePrompt: 'Are you sure you want to delete the news?',
    newsLastDelete: 'This item cannot be deleted as it is the only news item in the category',
    newsOrder: 'Order of the news items has been changed successfully',
    infoCreated: 'Information item has been added successfully',
    infoUpdated: 'Information item has been updated successfully',
    infoDeleted: 'Information item has been deleted successfully',
    infoOrder: 'Order of the information items has been changed successfully',
    infoDeletePrompt: 'Are you sure you want to delete the information item?',
    infoLastDelete: 'This item cannot be deleted as it is the only information item in the category',
    infoJsonError: 'Error in ordering information JSON',
    contactCreated: 'Program contact has been added successfully',
    contactUpdated: 'Program contact has been updated successfully',
    contactDeleted: 'Program contact has been deleted successfully',
    contactLastDelete: 'This item cannot be deleted as it is the only program contact in the category',
    contactDeletePrompt: 'Are you sure you want to delete the contact?',
    contactOrder: 'Order of the contacts has been changed successfully',
    apiFailure: 'API failure',
    improperRequest: 'Improper Request details',
    internalError: 'Internal error',
    UserAdded: 'User has been added successfully. Would you like to give application privileges to the user?',
    OnlyUserAdded: 'User has been added successfully',
    UserUpdate: 'User has been updated successfully',
    UserDeleteNotify: 'Are you sure you want to delete the user?',
    UserDelete: 'User has been deleted successfully',
    UserReactivate: 'User has been reactivated successfully',
    FaxNumber: 'Fax Number must be entered',
    priorAuthDesc: 'Category description should not exceed 500 characters',
    applicationName: 'Application Name must be entered',
    applicationDesc: 'Application description must not be empty or should not exceed 500 characters',
    roleExist: 'Role Name already exists. Please enter a different role name',
    AddOrModified: 'Details has been successfully added / modified',
    DeleteConfirmation: 'Are you sure you want to delete this role?',
    OnSuccessfulDelete: 'Role has been deleted successfully',
    PriorAuth: 'PriorAuth must not be empty or should not exceed 4000 characters',
    adminAccess: 'Unauthorized access',
	cannotDeleteCat: 'System configured category cannot be deleted',
	otpError: 'Code entered in form and Email does not match'
};

export const tooltipConst = {
    // tslint:disable-next-line:max-line-length
    edittitle: 'Please edit the title of the portlet or enable/disable the section to see the changes in the facing page',
    Categorydescription: 'Please enter the category description. If provided, it will be displayed under the category name',
    /**Progrminfo tooltips constants  */
    // tslint:disable-next-line:max-line-length
    Programinfocatname: 'Please enter the category name which will be displayed in the Program Information portlet. At least one information item must be added to the category',
    programinfoimgupload: 'Please upload the category image which will be displayed in the Program Information portlet',
    Programinfocategory: 'Please select the category for which an information item needs to be added',
    // tslint:disable-next-line:max-line-length
    Programinfoinformation: 'Please enter the information item like article, URL, email address to be displayed in the portlet and limit the character count to 3000 characters as the remaining characters will hold invisible styling',
    Programinfodispenabled: 'Please select the check box to make this information item visible in the portlet',
    Programinfoinformationdesc: 'Please enter the description for the information item',
    Programinfoeffctvestdate: 'Information item will be visible in the portlet from the date selected here',
    // tslint:disable-next-line:max-line-length
    Programinfoeffctveenddate: 'Information item will be visible in the portlet till the date selected here. If the date is not selected, the information item will be visible infinitely',
    /**Programcntcts tooltips constants  */
    // tslint:disable-next-line:max-line-length
    Programcontactcatname: 'Please enter the category name which will be displayed in the Program Contacts portlet. At least one program contact must be added to the category',
    Programcontactimgupload: 'Please upload the category image which will be displayed in the Program Contacts portlet',
    Programcontactcategory: 'Please select the category for which a program contact needs to be added',
    Programcontactmobilenum: 'Please enter the phone number for the program contact',
    Programcontactemailaddress: 'Please enter the email address for the program contact',
    Programcontactdesc: 'Please enter the description for the program contact',
    Programcontactdispenabled: 'Please select the check box to make this program contact visible in the portlet',
    Programcontactname: 'Please enter the name for the program contact',
    Programcontacteffectivestdate: 'Program contact will be visible in the portlet from the date selected here',
    // tslint:disable-next-line:max-line-length
    Programcontacteffectiveenddate: 'Program contact will be visible in the portlet till the date selected here. If the date is not selected, the contact will be visible infinitely',
    Programcontactaddress: 'Please enter the address for the program contact',
    /**Newsalerts  tooltips constants  */
    // tslint:disable-next-line:max-line-length
    Newsalertscategoryname: 'Please enter the category name which will be displayed in the News & Alerts portlet. At least one news item must be added to the category',
    Newsalertsimgupload: 'Please upload the category image which will be displayed in the News & Alerts portlet',
    Newsalertscategory: 'Please select the category for which a news item needs to be added',
    Newsalertsmsgtype: 'Please select a message type to display the corresponding icon on the category detail page',
    // tslint:disable-next-line:max-line-length
    Newssalertsnewsinfo: 'Please enter the news item like article, URL, email address to be displayed in the portlet and limit the character count to 3000 characters as the remaining characters will hold invisible styling',
    Newsalertsdispenabled: 'Please select the check box to make this news item visible in the portlet',
    Newsalertseffectivestdate: 'News item will be visible in the portlet from the date selected here',
    // tslint:disable-next-line:max-line-length
    Newsalertseffectiveendate: 'News item will be visible in the portlet till the date selected here. If the date is not selected, the news item will be visible infinitely',
    Newsalertsdescription: 'Please enter the description for the news item',
    /**Faq tooltips constants  */
    Faqcategoryname: 'Please enter the category name which will be displayed in the FAQ portlet. At least one FAQ item must be added to the category',
    Faqcategory: 'Please select the category for which a FAQ item needs to be added',
    Faqquestion: 'Please enter the question for the FAQ item',
	Faqimage: 'Please upload the category image which will be displayed in the FAQ portlet',
    // tslint:disable-next-line:max-line-length
    Faqanswer: 'Please enter the FAQ item like article, URL, email address to be displayed in the portlet and limit the character count to 1000 characters as the remaining characters will hold invisible styling',
    Faqdispenabled: 'Please select the check box to make this FAQ item visible in the portlet',
    Faqeffectivestdate: 'FAQ item will be visible in the portlet from the date selected here',
    Faqeffectiveenddate: 'FAQ item will be visible in the portlet till the date selected here. If the date is not selected, the FAQ item will be visible infinitely',
    Faqdescription: 'Please enter the description for the FAQ item',
    /**Userhelp tooltips constants */
    // tslint:disable-next-line:max-line-length
    Userhelpcatname: 'Please enter the category name which will be displayed in the User Help portlet. At least one user help item must be added to the category',
    Userhelpcategory: 'Please select the category for which a user help item needs to be added',
	Userhelpimage: 'Please upload the category image which will be displayed in the User Help portlet',
    // tslint:disable-next-line:max-line-length
    Userhelpinformation: 'Please enter the help item like article, URL, email address to be displayed in the portlet and limit the character count to 3000 characters as the remaining characters will hold invisible styling',
    Userhelpdispenabled: 'Please select the check box to make this user help item visible in the portlet',
    Userhelpinfodesc: 'Please enter the description for the user help item',
    Userhelpeffectivestdate: 'User Help item will be visible in the portlet from the date selected here',
    Userhelpeffectiveendate: 'User Help item will be visible in the portlet till the date selected here. If the date is not selected, the user help item will be visible infinitely',
    /**welcmemsg tooltips constants  */
    Welcomemsgtype: 'Please select the message type for the welcome message being added',
    // tslint:disable-next-line:max-line-length
    Welcomemsgcontent: 'Please enter the welcome message item like article, URL, email address to be displayed in the portlet and limit the character count to 2000 characters as the remaining characters will hold invisible styling',
    Welcomemsgdispenabled: 'Please select the check box to make this welcome message visible in the portlet',
    Welcomemsgdesc: 'Please enter the description for the welcome message',
    Welcomemsgeffectvestdate: 'Welcome message will be visible in the portlet from the date selected here',
    Welcomemsgeffectveendate: 'Welcome message will be visible in the portlet till the date selected here.If the date is not selected,the message will be visible infinitely',
    PriorAuthcategoryname: 'Please enter the category name which will be displayed in the Prior Authorization portlet. At least one information item must be added to the category',
    PriorAuthcategoryDesc: 'Please enter the category description like article, URL, email address to be displayed in the portlet and limit the character count to 400 characters as the remaining characters will hold invisible styling',
    PriorAuthimgupload: 'Please upload the category image which will be displayed in the Prior Authorization portlet',
    PriorAuthcategory: 'Please select the category for which an information item needs to be added',
    // tslint:disable-next-line:max-line-length
    PriorAuthsinfo: 'Please enter the news item like article, URL, email address to be displayed in the portlet and limit the character count to 3000 characters as the remaining characters will hold invisible styling',
    PriorAuthdescription: 'Please enter the description for the information item',
    PriorAuthdispenabled: 'Please select the check box to make this information item visible in the portlet',
    PriorAutheffectvestdate: 'Information item will be visible in the portlet from the date selected here',
    PriorAutheffectveendate: 'Information item will be visible in the portlet till the date selected here.If the date is not selected,the information item will be visible infinitely',
    PriorAuthinformation: 'Please enter the information item like article, URL, email address to be displayed in the portlet and limit the character count to 3000 characters as the remaining characters will hold invisible styling',
    /** header tooltips constants */
    // tslint:disable-next-line:max-line-length
    Headerclientname: 'Please enter the client name to be displayed in the application header and limit the character count to 100 characters as the remaining characters will hold invisible styling',
    Headerclientnameposition: 'Please enter a unique position for the client name',
    Headerclientlogo: 'Please upload the client logo to be displayed on the application header',
    Headerclientlogoposition: 'Please enter a unique position for the client logo',
    Headerclientimg: 'Please upload the client image to be displayed on the application header',
    Headerimgposition: 'Please enter a unique position for the client image',
    Headerbckgroundimg: 'Please upload the background image to be displayed on the application header',

    /** signon announcement tooltips constants */
    // tslint:disable-next-line:max-line-length
    Signonedittitle: 'Enabling the Sign On Announcement feature will prompt the user to accept or reject the disclaimer. Enabling the Acknowledgement tracking feature ensures that the system captures the user acceptance; announcement is displayed only if the message has changed after user acceptance',
    Signonmsgcontent: 'Please enter the message content for the Sign On Announcement',
    Signondispenabled: 'Please select the check box to make this announcement visible in the portal',
    Signoneffectivestdate: 'Sign on Announcement will be visible in the portal from the date selected here',
    // tslint:disable-next-line:max-line-length
    Signoneffectiveendate: 'Sign on Announcement will be visible in the portal till the date selected here. If the date is not selected, the announcement will be displayed infinitely',

    /** Audit logs & reports */
    Auditlogsreportsfromdate: 'Please select the start date for search',
    Auditlogsreportstodate: 'Please select the end date for search',
    Auditlogsreportsfilter: 'Please select the filter for search',
    Auditlogsreportssearch: 'Click here to search',
    Auditlogsreportsclear: 'Clear all fields',
    Auditlogsreportspdf: 'Export to PDF',
    Auditlogsreportsexcel: 'Export to Excel',

    satisticalreportfromdate: 'Please select the start date for search',
    satisticalreporttodate: 'Please select the end date for search',


    /** Super User tooltips constants*/
    Superuserrolename: 'Please enter the role name',
    Superuserroledesc: 'Please enter the role description',

    /** Usermanagement tooltips constants*/
    Usermngmntprefix: 'Please enter the prefix',
    Usermngmntusername: 'Please enter the user id',
    Usermngmntlstname: 'Please enter the last name',
    Usermngmntlfstname: 'Please enter the first name',
    Usermngmntacrdtion: 'Please select the accreditation for the user',
    Usermngmntemail: 'Please enter a valid email address which will be used to send the user details',
    Usermngmntidntftnid: 'Please enter a valid Identification ID such as NPI or MEDICAID',
    UsermngmntNpiId: 'Please enter a valid NPI ID',
    UsermngmntMaId: 'Please enter a valid MA ID',
    UsermngmntDocFname: 'Please enter Prescriber\'s first name',
    UsermngmntDocLname: 'Please enter Prescriber\'s last name',
    Usermngmntrole: 'Please select the role for the user',
    Usermngmntaddresline1: 'Please enter the first line of the address',
    Usermngmntaddresline2: 'Please enter the second line of the address',
    Usermngmntcity: 'City will be auto populated when the Zip Code is entered',
    Usermngmntstate: 'State will be auto populated when the Zip Code is entered',
    Usermngmntzipcode: 'Please enter the Zip Code',
    Usermngmntphonenum: 'Please enter the phone number',
    Usermngmmntdetailsinfo: 'Instructions: Please enter the valid NPI ID & MA ID for the prescriber you are trying to enroll',
    // tslint:disable-next-line:max-line-length
    Usermngmmntstatus: 'Field displays the current or future status of the user. Please click on the edit icon to modify the status of the user on request post verification of the security questions',
    Usermngmntstatusdate: 'Field displays the date from which the corresponding status will be applicable for the user',
    Usermngmntuserstatus: 'Please select the status for the user',
    Usermngmntuserdate: 'Please select the status date',
    Usermngmnteffectivefrom: 'Please select the date from which the user can log into the application',
    UserSecurityquestions: 'Security question entered here will be added to the list of questions displayed to the end user',
    UsermngmntApplicationName:'Please enter the application name',
    UsermngmntApplicationTextUrl:'Please enter the url for the application',
    UsermngmntApplicationImage:'Please upload the application image which will be displayed in the portlet',
    /** Account management tooltips constant */
    // tslint:disable-next-line:max-line-length
    Accountmngmntnoffaildattmptsallwd: 'Please enter the number of failed attempts allowed. Number of failed attempts allowed must always be lesser than the Maximum number of failed attempts allowed',
    // tslint:disable-next-line:max-line-length
    Accountmngmntmaximumfaledatmpts: 'Please enter the maximum number of failed login attempts allowed after which an account will be locked. Maximum number of failed attempts must always be greater than the Number of failed attempts allowed',
    Accountmngmntfailedattemptstrialinterval: 'Please enter the time within which the user must make all the failed attempts',
    // tslint:disable-next-line:max-line-length
    Accountmngmntcoolingoffperiod: 'Please enter the time period after which the users will be allowed to login to the system on reaching the number of failed attempts allowed',
    // tslint:disable-next-line:max-line-length
    Accountmngmntuserlckdoutinactvtytime: 'Please enter the time after which an account is locked if the user has not logged in to the portal',
    Accountmngmnttemppwdexprytime: 'Please enter the time after which the temporary password expires',
    // tslint:disable-next-line:max-line-length
    Accountmngmntsessionexptime: 'Please enter the inactivity time after which a session expires. Session Expiration Time must always be greater than the Session Expiration Reminder Time',
    // tslint:disable-next-line:max-line-length
    Accountmngmntssionespremindrtime: 'Please enter the inactivity time after which a session expiry reminder is displayed to the user. Session Expiration Reminder Time must always be lesser than the Session Expiration Time',

    Pwdcnfigrole: 'Please select the role for which the password configuration needs to be updated',
    // tslint:disable-next-line:max-line-length
    Pwdresetfreqncy: 'Please enter the time after which the user must change their password. Password reset frequency must always be greater than the Password reset reminder frequency',
    // tslint:disable-next-line:max-line-length
    Pwdresetremindrfreqncy: 'Please enter the time after which a password reset reminder is sent to the user. Password reset reminder frequency must always be lesser than the Password reset frequency',

    Messagecnfig: 'Please select the message for which the description needs to be updated',
    Messagecnfigdesc: 'Please enter the message description',
    Emailcnfigtype: 'Please select the email type for which the subject and template needs to be updated',
    Emailcngfigsubject: 'Please enter the subject of the email',
    // tslint:disable-next-line:max-line-length
    Emailcnfigtemplate: 'Please enter the body of the email template to be displayed in the email and limit the character count to 2000 characters as the remaining characters will hold invisible styling',
    /** User Profile tooltips constant */
    Myprofilelastname: 'Last Name as configured in the system',
    Myprofilefirstname: 'First Name as configured in the system',
    Myprofileprefix: 'Prefix as configured in the system',
    Myprofileaccrediation: 'Accreditation as configured in the system',
    Myprofileemailadress: 'Please enter the email address',
    Myprofileidentifcationid: 'Identification ID\'s are as configured in the system',
    MyprofileNPIid:'NPI ID as configured in the system',
    Myprofilerole: 'Role as configured in the system',
    Myprofileaddress1: 'Please enter the first line of the address',
    Myprofileaddress2: 'Please enter the second line of the address',
    Myprofilecity: 'City will be auto populated when the Zip Code is entered',
    Myprofilestate: 'State will be auto populated when the Zip Code is entered',
    Myprofilezipcode: 'Please enter the first 5 digits of the Zip Code',
    Myprofilezipcode2: 'Please enter the last 4 digits of the Zip Code',
    Myprofilephonenum: 'Please enter the phone number',
    Myprofilestatus: 'Status as configured in the system',
    Myprofilefaxnum: 'Please enter the fax number',
    Myprofilesecurityque: 'Please select a Security Question',
    Myprofilesecurityans: 'Please enter an answer for the question selected above',
    Oldpassword: 'Please enter the old password',
    Newpassword: 'Please enter the new password',
    Reenterpassword: 'Please re-enter the new password',
    ChangePasswordotp:'Please enter the code received in the email',

    /** Forgot password */
    forgotpwduserid: 'Please enter your User Id',
    /** Reset Password */
    NewPassword: 'Please enter the new Password',
    Reenterpwd: 'Please re-enter the new Password',

    /** Reset security question */
    Resetsecurityques: 'Please select a Security Question',
    Resetsecurityans: 'Please enter an answer for the question selected above',

    /** Validate Security Questuions */
    validateSecurityques: 'Selected Security Question as per User Profile',
    validateSecurityans: 'Please enter an answer for the question shown above',

    /** Login */
    Loginusername: 'Please enter your User Id',
    Loginpassword: 'Please enter your Password',
    Usermngmntfaxnum: 'Please enter the fax number',
};
