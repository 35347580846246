import { Component, OnInit, ViewChild, OnDestroy, NgZone } from "@angular/core";
import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { Router } from "@angular/router";
import { HttpService } from "../_services/http.service";
import { UserService } from "../_services/user.service";
import { NGXLogger } from "ngx-logger";
import * as configList from "../../assets/config/config";
import { CONFIGUREPORTALREQUEST } from "../_models/ConfigurePortal/configureportalrequest";
import { LOGINUSERTRACK } from "../_models/UserActivityTrack/loginusertrack";
import { Subscription } from "rxjs";
import { HttpHeaders } from "@angular/common/http";
import { ModalService } from "../modal.service";

@Component({
  selector: "app-timer",
  templateUrl: "./timer.component.html",
  styleUrls: ["./timer.component.scss"],
})
export class TimerComponent implements OnInit, OnDestroy {
  idleState = "Not started.";
  idleHeader = "Not Started!";
  originalTitle = "PBM PORTAL";
  intervalId: NodeJS.Timeout;
  idleButton = "OK";
  timedOut = false;
  lastPing?: Date = null;

  public showIdleTimer = false;

  public userActivityTrackRequest: CONFIGUREPORTALREQUEST;
  public loginUserTrack: LOGINUSERTRACK;
  idleTimeOut: Subscription;
  idleTimeOutWarning: Subscription;
  idleStart: Subscription;
  idleEnd: Subscription;
  ssoEnabled: boolean;
  private validationInProgress = false;
  /* private sessionIsTimedOut = false; */
  private warningPopup: any;
  public modalOpen: boolean = false;
  public modalContent: string = "";

  constructor(
    private idle: Idle,
    private keepalive: Keepalive,
    private router: Router,
    private httpService: HttpService,
    private logger: NGXLogger,
    private userDet: UserService,
    private modalService: ModalService,
    private zone: NgZone
  ) {
    // this.idleTimeOut = idle.onTimeout.subscribe();
    // this.idleTimeOutWarning = idle.onTimeoutWarning.subscribe();
    // this.idleStart = idle.onIdleStart.subscribe();
    // this.idleEnd = idle.onIdleEnd.subscribe();
    this.ssoEnabled = sessionStorage.getItem("ssoMode") === "Y" ? true : false;
  }
  
  ngOnInit(): void {
    this.modalService.modalOpen$.subscribe((isOpen) => {
      this.modalOpen = isOpen;
    });

    this.modalService.modalContent$.subscribe((content) => {
      this.zone.run(() => {
        this.modalContent = content;
        console.log("Modal Content:", this.modalContent);
      });
    });
  }

  ngOnDestroy() {
    // this.idleTimeOut.unsubscribe();
    // this.idleTimeOutWarning.unsubscribe();
    // this.idleStart.unsubscribe();
    // this.idleEnd.unsubscribe();
    this.idle.stop();
    this.idle.onTimeout.observers.length = 0;
    this.idle.onIdleStart.observers.length = 0;
    this.idle.onIdleEnd.observers.length = 0;
    this.idle.onTimeoutWarning.observers.length = 0;
  }

  /* updateTitle(title: string) {
    let isWarning = false;

    const flashTitle = () => {
      document.title = isWarning ? title : this.originalTitle;
      isWarning = !isWarning;
    };

    this.intervalId = setInterval(flashTitle, 1500);
  } */

  /* validateSession() {
    if (this.validationInProgress) {
      return;
    }
    this.validationInProgress = true;
    const userId = sessionStorage.getItem("loggedInUserId");
    const formData = {
      userid: userId,
      sessionid: sessionStorage.getItem("kcsessionid"),
    };
    const headers = new HttpHeaders()
      .set("Access-Control-Allow-Origin", "*")
      .set("Content-Type", "application/json")
      .set("Accept", "application/json")
      .set("Authorization", configList.pageConst.token);
    if (userId && userId !== "undefined") {
      this.httpService.validate_LoginDetails(formData, headers).subscribe(
        (data: any) => {
          console.log(
            "success responseCode:",
            data.responseCode,
            ", minsLeft:",
            data.minsLeft
          );
          const leftMints = 60 - data.minsLeft;
          if (leftMints <= 0) {
            this.modalContent =
              "Your session is expired! Please relogin now through the portal.";
            this.modalOpen = true;
            clearTimeout(this.intervalId);
            this.logout();
          }

          if (data.responseCode === 0 && leftMints <= 0 && leftMints > 0) {
            if (this.warningPopup) {
              this.warningPopup.closeModal();
            }
            this.modalOpen = true;
            this.modalContent =
              "Your session is about to expire in " +
              leftMints +
              " mins. Please save your work.";
          } else if (data.responseCode === 1) {
            this.logout();
          }
          this.validationInProgress = false;
        },
        (error: any) => {
          console.log(
            "🚀 ~ file: timer.component.ts:124 ~ TimerComponent ~ validateSession ~ error:",
            error
          );
          this.validationInProgress = false;
        }
      );
    }
  } */

  openModal(message: string, confirmAction: () => void) {
    // Set the modal content and open the modal
    // this.modalContent = message;
    this.confirmAction = confirmAction;
    this.modalOpen = true;
  }

  closeModal() {
    this.modalOpen = false;
    console.log(
      "🚀 ~ file: timer.component.ts:151 ~ TimerComponent ~ closeModal ~ this.modalOpen:",
      this.modalOpen
    );
  }

  confirmAction() {
    if (this.confirmAction) {
      this.confirmAction();
    }
  }

  logout() {
    this.idle.stop();
    this.idle.onTimeout.observers.length = 0;
    this.idle.onIdleStart.observers.length = 0;
    this.idle.onIdleEnd.observers.length = 0;
    this.idle.onTimeoutWarning.observers.length = 0;
    this.idleState = "Logged Out";
    this.timedOut = true;
    this.userActivityTrackRequest = new CONFIGUREPORTALREQUEST();
    this.loginUserTrack = new LOGINUSERTRACK();
    this.userActivityTrackRequest.screenId = this.userDet.getScreenId();
    this.userActivityTrackRequest.portletId = configList.pageConst.NAPortletId;
    this.userActivityTrackRequest.updatedBy =
      sessionStorage.getItem("loggedInUserName");
    this.userActivityTrackRequest.activityCode =
      configList.activityCode.sessionLogoutAuditActCode;
    this.loginUserTrack.userLoginId =
      sessionStorage.getItem("loggedInUserName");
    this.loginUserTrack.roleId = Number(sessionStorage.getItem("userRoleId"));
    this.userActivityTrackRequest.loginUserTrack = this.loginUserTrack;

    this.httpService.updateUserLogin(this.userActivityTrackRequest).subscribe(
      (data: any) => {
        console.log(data);
      },
      (error: any) => {
        console.log(error);
      }
    );
    sessionStorage.removeItem("screenInfoData");
    localStorage.removeItem("screenInfoData");
    sessionStorage.setItem("loginRoleId", "-1");
    sessionStorage.setItem("userRoleId", "-1");
    sessionStorage.setItem("privilegeData", null);
    sessionStorage.setItem("loginScreenId", "1");
    sessionStorage.setItem("logOut", "Y");
    sessionStorage.setItem("isLoggedIn", "false");
    sessionStorage.setItem("loggedInUserName", null);
    sessionStorage.setItem("loggedInFirstName", null);
    sessionStorage.removeItem("LoginToken");
    sessionStorage.removeItem("isAgreeClicked");
    sessionStorage.removeItem("keycloakURL");
    sessionStorage.removeItem("kcsessionid");
    sessionStorage.removeItem("loggedInUserId");
    sessionStorage.removeItem("loginemail");
    sessionStorage.removeItem("loginUserStatus");
    sessionStorage.removeItem("isTempPwd");
    sessionStorage.removeItem("authToken");
    let tempAgreeClicked = localStorage.getItem("isAgreeClicked");
    localStorage.clear();
    if (tempAgreeClicked != null) {
      localStorage.setItem("isAgreeClicked", tempAgreeClicked);
    }
    this.router.navigate(["/publicFacing"]);
  }
}
