import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from '../../_services/http.service';
import { UserService } from '../../_services/user.service';
import { pageConst } from '../../../assets/config/config';
import { NGXLogger } from 'ngx-logger';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';


@Component({
  selector: 'app-priot-authorization',
  templateUrl: './priot-authorization.component.html',
  styleUrls: ['./priot-authorization.component.scss']
})
export class PriotAuthorizationComponent implements OnInit {
  resVal = [];
  applicationVal = [];
  priorAuthLength: number;
  data = [];
  @Input() priorAuthLabel: string;
  public constVal = pageConst;
  public priorAuthImgData = [];
  public applicationLinksData = [];
  constructor(private httpService: HttpService, private userDet: UserService,
    private logger: NGXLogger, private sanitizer: DomSanitizer, private router: Router) {
    httpService.getAppLinks(this.userDet.getScreenName()).subscribe((appLinks: any) => {            
      this.applicationVal = Object.values(appLinks.applicationResponse);
      if(this.applicationVal.find(obj => obj.categoryName === 'WebPA')) {
        httpService.getPriorAuth(this.userDet.getScreenId(), this.userDet.getRoleId()).subscribe(
          (data: any) => {
            this.resVal = Object.values(data.priorAuthResponse);
            this.loadImages();
          }
        );        
      } else {
        this.priorAuthLength = 0;
        this.getApplicationImages();
      }
    });
    
  }


  ngOnInit() {
  }

  /** Function to load application images in to list */
  getApplicationImages() {
    this.applicationVal.forEach((imgObj, index) => {
      if ((this.priorAuthLength + index + 1) <= 3) {
        if (imgObj.catImageExists) {
          this.httpService.getAppImage(imgObj.categoryId)
          .subscribe(
            (data: any) => {
                const urlCreator = window.URL;
                const imagePath = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(data));
                let url = this.pushUrl(imgObj.categoryDesc);
                let desc = this.pushDescription(url, imgObj.categoryDesc);
                this.applicationLinksData.splice(index, 0, {categoryName: imgObj.categoryName, categoryId: imgObj.categoryId,
                    categoryDesc: desc, catImgPath: imagePath, catImageExists: imgObj.catImageExists,
                  categoryUrl: url});
            },
            (error) => {
              this.logger.debug('error in get application image call');
              let url = this.pushUrl(imgObj.categoryDesc);
              let desc = this.pushDescription(url, imgObj.categoryDesc);
              this.applicationLinksData.splice(index, 0, {categoryName: imgObj.categoryName, categoryId: imgObj.categoryId,
                categoryDesc: desc, catImageExists: false,
                categoryUrl: url });
            }
          );
        } else {
          let url = this.pushUrl(imgObj.categoryDesc);
          let desc = this.pushDescription(url, imgObj.categoryDesc);
          this.applicationLinksData.push({categoryName: imgObj.categoryName, categoryId: imgObj.categoryId,
            categoryDesc: desc, catImageExists: imgObj.catImageExists,
            categoryUrl: url});
        }
      }
    });
  }

  /** function to load images into list of prior auth*/
  loadImages() {
    this.resVal.forEach((imgObj, index) => {
      if (index < 3) {
        if (imgObj.catImageExists) {
            this.httpService.getImage(imgObj.categoryId, this.userDet.getScreenId(), this.constVal.tilePriorAuth)
              .subscribe(
                (data: any) => {
                  const urlCreator = window.URL;
                  const imagePath = this.sanitizer.bypassSecurityTrustUrl(
                    urlCreator.createObjectURL(data));
                    let url = this.pushUrl(imgObj.categoryDesc);
                    let desc = this.pushDescription(url, imgObj.categoryDesc);
                  this.priorAuthImgData.splice(index, 0, {
                    categoryName: imgObj.categoryName, categoryId: imgObj.categoryId,
                    categoryDesc: desc, catImgPath: imagePath, catImageExists: imgObj.catImageExists,
                    categoryUrl: url
                  });
                },
                (error) => {
                  this.logger.debug('error in get screen service call');
                  let url = this.pushUrl(imgObj.categoryDesc);
                  let desc = this.pushDescription(url, imgObj.categoryDesc);
                  this.priorAuthImgData.push({
                    categoryName: imgObj.categoryName, categoryId: imgObj.categoryId,
                    categoryDesc: desc, catImageExists: imgObj.catImageExists,
                    categoryUrl: url
                  });
                }
              );
        } else {
            let url = this.pushUrl(imgObj.categoryDesc);
            let desc = this.pushDescription(url, imgObj.categoryDesc);
            this.priorAuthImgData.push({
              categoryName: imgObj.categoryName, categoryId: imgObj.categoryId,
              categoryDesc: desc, catImageExists: imgObj.catImageExists,
              categoryUrl: url
            });
        }
      }
    });
  }

  /** navigate to category page */
  categoryNavigate(type, index) {
    let contents;
    if (type === 'priorAuth') {
      contents = this.priorAuthImgData[index].categoryUrl; 
      window.open(contents, "_blank");     
    } else if (type === 'appLink') {
      contents = this.applicationLinksData[index].categoryUrl; 
      window.open(contents, "_blank");
    }
  }

  /** push Url to the JSON */
  pushUrl(catDesc) {
    let contents = catDesc;

    let snippet = document.createElement("div");
    snippet.innerHTML = contents;
    let links = snippet.getElementsByTagName("a");
    if (links.length) {
      return links[0].href;
      // window.open(lastURL, "_blank");
    }
  }

  pushDescription (url,catDesc){
    let snippet = document.createElement("div");
    snippet.innerHTML = catDesc;
    let links = snippet.getElementsByTagName("a");
    let newItem = document.createElement("span");
    let newUrl = "'"+url+"'";
    newItem.innerHTML = '<span tabindex="0" onclick="window.open('+newUrl+',\'_blank\')" class="mouse-over spanColor">'+links[0].innerHTML+'</span>';
    let tnode = links[0].parentNode;
    tnode.replaceChild(newItem, links[0]);
    return this.sanitizer.bypassSecurityTrustHtml(snippet.innerHTML.toString());
  }

  priorauthportlet() {
    this.router.navigate(['/priorAuth']);

  }
}
