import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from '../../_services/http.service';
import { UserService } from '../../_services/user.service';
import { pageConst} from '../../../assets/config/config';
import { NGXLogger } from 'ngx-logger';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.scss']
})

export class ProgramComponent implements OnInit {
  res: any = {
    'title': 'PROGRAM INFORMATION'
  };
  resVal  = [];
  resKeys: any;
  data = [];
  @Input() tileLabel: string;
  public constVal = pageConst;
  public progInfoImgData = [];

  constructor(private httpService: HttpService, private userDet: UserService,
    private logger: NGXLogger, private sanitizer: DomSanitizer, private router: Router) {
    httpService.getProgInfo(this.userDet.getScreenId(), this.userDet.getRoleId()).subscribe(
      (data: any) => {
        this.resVal = Object.values(data.programInfoResponse);
        this.resKeys = Object.keys(data.programInfoResponse);
        this.data = data;
        this.loadImages();
      }
    );
  }

  ngOnInit() {   
  }

  
  /** function to load images into list */
  loadImages() {
    this.resVal.forEach((imgObj, index) => {
      if (index < 3) {
        if (imgObj.catImageExists) {
          this.httpService.getImage(imgObj.categoryId, this.userDet.getScreenId(), this.constVal.tileProgramInfo)
          .subscribe(
            (data: any) => {
                const urlCreator = window.URL;
                const imagePath = this.sanitizer.bypassSecurityTrustUrl(
                  urlCreator.createObjectURL(data));
                this.progInfoImgData.splice(index, 0, {categoryName: imgObj.categoryName, categoryId: imgObj.categoryId,
                    categoryDesc: this.userDet.pushDescription(imgObj.categoryDesc), catImgPath: imagePath, catImageExists: imgObj.catImageExists});
            },
            (error) => {
                this.logger.debug('error in get screen service call');                
                this.progInfoImgData.splice(index, 0, {categoryName: imgObj.categoryName, categoryId: imgObj.categoryId,
                  categoryDesc: this.userDet.pushDescription(imgObj.categoryDesc), catImageExists: false });
            }
          );
        } else {
          this.progInfoImgData.push({categoryName: imgObj.categoryName, categoryId: imgObj.categoryId,
            categoryDesc: this.userDet.pushDescription(imgObj.categoryDesc), catImageExists: imgObj.catImageExists});
        }
      }
    });
  }

  /** navigate to category page */
  categoryNavigate(catId: number) {
    this.router.navigate(['/programInfo/programInfoDetailedPage', catId]);
  }
  Programinfoportlet() {
    // tslint:disable-next-line:max-line-length
    this.router.navigate(['/programInfo']);
  }
}
