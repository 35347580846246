import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { HttpService } from "./_services/http.service"; // Replace with your actual HTTP service
import { CONFIGUREPORTALREQUEST } from "./_models/ConfigurePortal/configureportalrequest";
import { USERACTIVITYLOG } from "./_models/UserActivityTrack/useractivitylog";
import { LOGINUSERTRACK } from "./_models/UserActivityTrack/loginusertrack";
import { UserService } from "./_services/user.service";
import * as configList from "../assets/config/config";
import { Router } from "@angular/router";
import { ModalService } from "./modal.service";

@Injectable({
  providedIn: "root",
})
export class SessionService {
  private intervalId: any;
  public userActivityTrackRequest: CONFIGUREPORTALREQUEST;
  public userAccessedLog: USERACTIVITYLOG;
  private userDet: UserService;
  public loginUserTrack: LOGINUSERTRACK;
  private validationInProgress = false;
  public modalOpen: boolean = false;
  public modalContent: string = "";

  constructor(
    private httpService: HttpService,
    private router: Router,
    private modalService: ModalService
  ) {
    this.router.events.subscribe((event) => {
      console.log(event);
    });
  } // Replace 'YourHttpService' with your actual service name

  startSessionValidation() {
    // Start the initial validation
    this.validateSession();

    // Set up the periodic validation every 1 minute (60,000 milliseconds)
    this.intervalId = setInterval(() => {
      this.validateSession();
    }, configList.sessionTimeoutConstants.sessionTimeoutInterval); //move to config webportal-web\src\main\UI\pbmportal\src\assets\config\config.ts
  }

  stopSessionValidation() {
    // Clear the interval when the session validation is no longer needed
    clearInterval(this.intervalId);
  }

  validateSession() {
    if (this.validationInProgress) {
      return;
    }
    const userId = sessionStorage.getItem("loggedInUserId");
    const formData = {
      userid: userId,
      sessionid: sessionStorage.getItem("kcsessionid"),
    };
    const headers = new HttpHeaders()
      .set("Access-Control-Allow-Origin", "*")
      .set("Content-Type", "application/json")
      .set("Accept", "application/json")
      .set("Authorization", configList.pageConst.token);
    if (userId && userId !== "undefined") {
      this.validationInProgress = true;
      this.httpService.validate_LoginDetails(formData, headers).subscribe(
        (data: any) => {
          console.log(
            "success responseCode:",
            data.responseCode,
            ", minsLeft:",
            data.minsLeft
          );
          /* this.sessionIsTimedOut = false; */
          const leftMints =
            configList.sessionTimeoutConstants.sessionTimeoutTime -
            data.minsLeft;
          if (leftMints <= 0) {
            /* this.modalContent =
              configList.sessionTimeoutConstants.sessionTimeoutPopupMessage;
            this.modalOpen = true; */
            this.stopSessionValidation();
            this.logout();
            /* this.sessionIsTimedOut = true; */
            //sessionStorage.setItem("sessionTimeoutFlag", JSON.stringify(true));
          }

          if (
            data.responseCode === 0 &&
            leftMints <=
              configList.sessionTimeoutConstants.warningTimeoutTime &&
            leftMints > 0
          ) {
            const content =
              configList.sessionTimeoutConstants.warningTimeoutPopupMessage1 +
              leftMints +
              configList.sessionTimeoutConstants.warningTimeoutPopupMessage2;

            this.modalService.openModal(content);

            // Perform any additional action required for the warning condition
          } else if (data.responseCode === 1) {
            //sessionStorage.setItem("sessionTimeoutFlag", JSON.stringify(true));
            this.logout();
            // Perform any additional action required for the response code 1
          }
          this.validationInProgress = false;
        },
        (error: any) => {
          console.log(
            "🚀 ~ file: timer.component.ts:124 ~ TimerComponent ~ validateSession ~ error:",
            error
          );
          this.validationInProgress = false;
          /* this.sessionIsTimedOut = false; */
        }
      );
    }
  }

  logout() {
    this.modalService.closeModal();
    this.stopSessionValidation();
    this.userActivityTrackRequest = new CONFIGUREPORTALREQUEST();
    this.loginUserTrack = new LOGINUSERTRACK();
    const screenId = sessionStorage.getItem("loginScreenId");
    this.userActivityTrackRequest.screenId =
      this.userDet?.getScreenId() || +screenId;
    this.userActivityTrackRequest.portletId = configList.pageConst.NAPortletId;
    this.userActivityTrackRequest.updatedBy =
      sessionStorage.getItem("loggedInUserName");
    this.userActivityTrackRequest.activityCode =
      configList.activityCode.sessionLogoutAuditActCode;
    this.loginUserTrack.userLoginId =
      sessionStorage.getItem("loggedInUserName");
    this.loginUserTrack.roleId = Number(sessionStorage.getItem("userRoleId"));
    this.userActivityTrackRequest.loginUserTrack = this.loginUserTrack;
    this.httpService.updateUserLogin(this.userActivityTrackRequest).subscribe(
      (data: any) => {
        console.log(data);
        sessionStorage.removeItem("screenInfoData");
        localStorage.removeItem("screenInfoData");
        sessionStorage.setItem("loginRoleId", "-1");
        sessionStorage.setItem("userRoleId", "-1");
        sessionStorage.setItem("privilegeData", null);
        sessionStorage.setItem("loginScreenId", "1");
        sessionStorage.setItem("logOut", "Y");
        sessionStorage.setItem("isLoggedIn", "false");
        sessionStorage.setItem("loggedInUserName", null);
        sessionStorage.setItem("loggedInFirstName", null);
        sessionStorage.removeItem("LoginToken");
        sessionStorage.removeItem("keycloakURL");
        sessionStorage.removeItem("kcsessionid");
        sessionStorage.removeItem("loggedInUserId");
        sessionStorage.removeItem("loginemail");
        sessionStorage.removeItem("loginUserStatus");
        sessionStorage.removeItem("isTempPwd");
        sessionStorage.removeItem("authToken");
        let tempAgreeClicked = localStorage.getItem("isAgreeClicked");
        localStorage.clear();
        clearTimeout(this.intervalId);
        if (tempAgreeClicked != null) {
          localStorage.setItem("isAgreeClicked", tempAgreeClicked);
        }
        console.log("Before navigating to /publicFacing");
        this.router.navigateByUrl("/publicFacing");
        window.location.reload()
        console.log("After navigating to /publicFacing");
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
}
