/**
 * Audit Const Messages
 */
export const auditMessages = {
    commonClicked: 'Clicked on',
    commonAccessed: 'Accessed',
    commonSearched: 'from Searched value:',
    auditPagination: 'Clicked on Pagination',
    auditHomePage: 'Clicked on Home link',
    commonTile: 'tile link',
    commonReadMore: 'Read More',
    commonHeader: 'Header',
    eulaFooter: 'Footer EULA link',
    securityPolicyFooter: 'Footer Security Policy link',
    disclaimerFooter: 'Footer Security Disclaimer link',
    contactUsFooter: 'Footer Contact Us link',
    publicfacingPage: 'public portal',
};
