import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-user-help',
  templateUrl: './user-help.component.html',
  styleUrls: ['./user-help.component.scss']
})

export class UserHelpComponent implements OnInit {
  @Input() userTileLabel: string;
  constructor(private router: Router) {
  }

  ngOnInit() {
  }
  userhelpportlet() {
    this.router.navigate(['/userHelp']);
  }
}
