import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { take, map, first } from 'rxjs/operators';

import { UserService } from '../_services/user.service';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class DataResolverService implements Resolve <Observable<any>>{

  constructor(private httpService: HttpService, private userDet: UserService, private logger: NGXLogger,) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
    return this.httpService.get_Screen(this.userDet.getScreenId(), this.userDet.getRoleId()).pipe(
      take(1),
      map((screenData) => {
        sessionStorage.setItem('screenInfoData',  JSON.stringify(screenData));
        return screenData
      })
    )
    // return this.httpService.get_Screen(this.userDet.getScreenId(), this.userDet.getRoleId())
    // .pipe(first())
    // .subscribe(
    //   (data: any) => {
    //     map(data)
    //   },
    //   (error) => {
    //     this.logger.debug('error in get screen service call');
    //   });
  }
}
